import axios from "axios";
import {
  REACT_APP_URL_SUBMIT_CLAIM_API,
  REACT_APP_URL_GET_ID_API,
  API_RESPONSE_STATUS
} from "common/Constants";
import { handleAPIFailResponse } from "common/utils/CommonUtil";

const URL_SUBMIT_CLAIM = REACT_APP_URL_SUBMIT_CLAIM_API;

export let submitClaim = (claim, region, claimId) => {
  let requestBody = { claim, claimId, region };
  return new Promise((resolve, reject) => {
    axios
      .post(URL_SUBMIT_CLAIM, requestBody)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        console.error("ERROR: ", err);
        reject();
      });
  });
};

export const UPDATE_CLAIM_SUBMISSION_ID = async (region, updateState) => {
  try {
    const requestBody = { region };
    const result = await axios.post(REACT_APP_URL_GET_ID_API, requestBody);
    const responseData = result.data;
    if (responseData && responseData.status) {
      if (responseData.status === API_RESPONSE_STATUS.SUCCESS) {
        updateState(responseData.data.newClaimId);
      } else {
        throw new Error(
          "Error: Get New Submission ID API encountered an error."
        );
      }
    } else {
      throw new Error("Error: Get New Submission ID API response is empty.");
    }
  } catch (error) {
    console.error(`Get New Submission ID API has failed with Error: ${error};`);
  }
};

export let getClaimId = region => {
  return new Promise((resolve, reject) => {
    let requestBody = { body: { region } };
    axios
      .post(REACT_APP_URL_GET_ID_API, requestBody)
      .then(result => {
        const responseData = result.data;
        if (responseData && responseData.status) {
          if (responseData.status === API_RESPONSE_STATUS.SUCCESS) {
            resolve(responseData.data.newClaimId);
          } else {
            handleAPIFailResponse(
              responseData.msg,
              reject,
              "Get Claim ID API failed with an Error"
            );
          }
        } else {
          reject("Error: Get Claim ID API response is empty.");
        }
      })
      .catch(error => {
        console.error("ERROR when trying to get claimID: ", error);
        reject(error);
      });
  });
};
