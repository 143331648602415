import React from "react";
import { Text, View } from "@react-pdf/renderer";
import {
  pdfStyles,
  BENEFIT_SECTION_SUBTITLE,
  PDF_DISPLAY_LABEL_VALUE_COMPONENT,
  PDF_DISPLAY_TIME_COMPONENT,
  PDF_DISPLAY_DATE_COMPONENT,
  CLAIMANT_DETAILS_FOR_BENEFIT,
  CLAIM_DETAILS_SUB_SECTION
} from "common/utils/PDFDocumentUtil";
import { BENEFIT_TYPES } from "common/Constants";
import { getClaimTitle, getCurrencyValue } from "common/utils/CommonUtil";

const PDFLostBaggageDetailComponent = props => {
  let title = getClaimTitle(BENEFIT_TYPES.lostBaggageClaim);
  let detail = props.detail;
  let suffix = props.suffix;

  return (
    <React.Fragment>
      {BENEFIT_SECTION_SUBTITLE(suffix, title)}
      {CLAIMANT_DETAILS_FOR_BENEFIT(
        detail.benefitId,
        props.claimants,
        props.region
      )}
      {CLAIM_DETAILS_SUB_SECTION("Claim details")}
      <View style={pdfStyles.container}>
        {PDF_DISPLAY_DATE_COMPONENT("Date of Incident", detail.dateOfLoss)}
        {PDF_DISPLAY_TIME_COMPONENT("Time of Incident", detail.timeOfLoss)}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Place of Incident",
          detail.locationOfLoss
        )}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Please describe how the incident happened",
          detail.description
        )}
        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.subtitle2}>Detail of lost items</Text>
        </View>

        {detail.descriptionOfLostItems.map((item, index) => (
          <DescriptionOfLostItems
            item={item}
            index={index}
            key={"ItemDescription" + index}
          />
        ))}
        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.totalAmountLabel}>
            Total amount claimed for lost baggage
          </Text>
          <View style={pdfStyles.subContainer2}>
            {detail.accumulatedCurrencies.map(listItemClaim => {
              return (
                <Text
                  style={pdfStyles.totalAmountValue}
                  key={listItemClaim.currency}
                >
                  {listItemClaim.currency} {listItemClaim.totalValue}
                </Text>
              );
            })}
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

const DescriptionOfLostItems = props => {
  const item = props.item;
  const index = props.index;
  return (
    <React.Fragment>
      <View style={pdfStyles.subContainer}>
        <Text style={pdfStyles.subtitle2}>Lost Item # {index + 1}</Text>
      </View>
      {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
        "Description of baggage lost/damaged",
        item.description
      )}
      {PDF_DISPLAY_LABEL_VALUE_COMPONENT("Make/Model", item.makeModel)}
      {PDF_DISPLAY_LABEL_VALUE_COMPONENT("Purchase Date", item.dateOfPurchase)}
      {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
        "Purchase Currency",
        item.purchaseCurrency
      )}
      {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
        "Purchase Price",
        getCurrencyValue(item.purchasePrice)
      )}
      {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
        "Amount Claimed",
        getCurrencyValue(item.amountClaimed)
      )}
    </React.Fragment>
  );
};

export default PDFLostBaggageDetailComponent;
