import React from "react";
import { styled } from "@material-ui/core/styles";
import { NAVBAR_WIDTH, NAVBAR_BG_COLOR } from "common/Constants";
import { AppBar, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";

class ClaimsAppBar extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let theme = this.props.theme;
    const MyAppBar = styled(AppBar)({
      color: "#ffffff",
      marginLeft: NAVBAR_WIDTH
    });
    const MenuButton = styled(IconButton)({
      color: "#ac1d31",
      backgroundColor: NAVBAR_BG_COLOR,
      ariaLabel: "open drawer",
      edge: "start",
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        display: "none"
      }
    });
    const hideNavBarClass = this.props.hideNavBarClass;
    return (
      <MyAppBar>
        <Toolbar disableGutters>
          <MenuButton
            className={hideNavBarClass.join(" ")}
            onClick={e => {
              this.props.mobileNavBarToggle(e);
            }}
          >
            <MenuIcon />
          </MenuButton>
          <div className={"app-header-logo"} />
        </Toolbar>
      </MyAppBar>
    );
  }
}

export default ClaimsAppBar;
