export const MAIN_FORM_STYLE = { padding: "1rem" };

export const EXPANSION_PANEL_STYLE = { display: "flow-root", padding: "0px" };

export const BUTTON_MIN_WIDTH_STYLE = { minWidth: "150px" };

export const GRID_ALIGNMENT_PDPA_STYLE = { paddingLeft: "1rem" };

export const REPORTER_FORM_NO_MARGIN_STYLE = { margin: "0px" };

export const FORM_CONTROL_BLOCK_STYLE = { display: "block" };

export const MODAL_OVERFLOW_STYLE = { overflow: "auto" };

export const TRIP_CANCELLATION_CONTAINER_STYLE = { paddingTop: "10px" };

export const TIME_FIELD_STYLE = { width: "200px" };

export const ERROR_COMPONENT_CONTENT_STYLE = {
  padding: "2.5rem 1rem 2rem 1rem"
};

export const FORM_DETAIL_STYLE = {
  padding: "0 24px 0 24px",
  textAlign: "left"
};

export const SUMMARY_FILE_UPLOAD_CONTAINER_STYLE = {
  padding: "10px 24px",
  textAlign: "left"
};

export const CLAIMANT_INFORMATION_BUTTON_STYLE = {
  padding: "16px 0px 16px 0px",
  minWidth: "150px"
};

export const LOST_BAGGAGE_SUMMARY_STYLE = { width: "100%", marginTop: "10px" };

export const MEDICAL_CLAIM_ACCIDENT_CONTAINER_STYLE = {
  padding: "0 12px",
  textAlign: "left"
};

export const FULL_WIDTH_STYLE = { width: "100%" };

export const THANKYOU_PDF_COMPONENT_STYLE = { padding: "12px 0px" };

export const ALIGN_LEFT_STYLE = { textAlign: "left" };

export const NAVBAR_STYLE = { height: 57, textAlign: "center" };

export const NAVBAR_LIST_ITEM_STYLE = { fontWeight: "bold" };

export const SECONDARY_BUTTON_STYLE = {
  textAlign: "right",
  marginRight: "12px",
  padding: "20px 0px 20px 0px"
};

export const PDPA_CONTAINER_STYLE = {
  textAlign: "left",
  padding: "1.5rem 0px"
};

export const PDPA_CONSENT_OPTIONAL_STYLE = {
  textAlign: "left",
  padding: "0px 0px 1.5rem 0px"
};

export const SWITCH_LABEL_DIRECTION_STYLE = {
  flexDirection: "row",
  textAlign: "left",
  margin: "12px 12px 12px 0px"
};

export const SUMMARY_DETAIL_HEADER_STYLE = {
  display: "flex",
  padding: "0 24px 0 24px",
  minHeight: "48px"
};

export const SUMMARY_CONTAINER_STYLE = {
  padding: "0 12px 12px 12px",
  textAlign: "left"
};

export const SUMMARY_LIST_ITEMS_CONTAINER_STYLE = {
  textAlign: "left",
  padding: "20px 12px 12px 12px"
};

export const SUMMARY_PAGE_DETAILS_PADDING_STYLE = {
  paddingBottom: "20px"
};

export const STYLE_HORIZONTAL_ROW_STYLE = {
  borderTop: "1px solid rgb(172, 29, 49, 0.6)"
};

export const ALIGN_CONTENT_LEFT_STYLE = {
  textAlign: "left"
};

export const MODAL_INPUT_STYLE = {
  paddingBottom: "10px"
};

export const MODAL_REQUIRED_FILES_STYLE = {
  paddingBottom: "2rem"
};

export const MODAL_FOOTNOTE_STYLE = {
  paddingBottom: "1rem"
};

export const REQUIREMENTS_MODAL_FOOTNOTE_STYLE = {
  paddingLeft: "1rem"
};

export const DATE_FIELD_ALIGNMENT_STYLE = {
  margin: "0px",
  textAlign: "left"
};

export const SUMMARY_LOST_ITEMS_LIST_STYLE = {
  paddingTop: "20px"
};

export const SUMMARY_MEDICAL_LIST_HEADER_STYLE = {
  paddingTop: "20px",
  paddingLeft: "12px"
};

export const SELECT_CLAIM_TYPE_RADIO_GROUP_STYLE = {
  textAlign: "left",
  padding: "0 12px 20px 20%"
};

export const GRID_CONTAINER_ALIGN_LEFT_STYLE = {
  margin: "0px",
  textAlign: "left"
};

export const SPACE_ELEMENT_STYLE = {
  flex: "20"
};
export const PREVIOUS_BUTTON_STYLE = {
  flex: "33",
  textAlign: "right"
};

export const NEXT_BUTTON_STYLE = {
  flex: "33",
  textAlign: "left"
};

export const NEXT_BUTTON_CENTER_STYLE = {
  textAlign: "center"
};

export const SUMMARY_LOST_ITEMS_DETAILS_SUB_HEADING_STYLE = {
  fontWeight: "800"
};

export const SUMMARY_LOST_ITEMS_DETAILS_CURRENCY_TOTAL_HEADER_STYLE = {
  fontWeight: "800",
  textAlign: "right"
};

export const MODAL_OK_BUTTON_STYLE = { textAlign: "center" };

export const MODAL_CANCEL_BUTTON_STYLE = {
  textAlign: "center",
  paddingLeft: "24px"
};

export const MODAL_HEADER_STYLE = {
  textAlign: "center",
  paddingBottom: "20px"
};

export const MODAL_TEXT_CONTENT = {
  textAlign: "center",
  paddingBottom: "2em"
};

export const FORM_CONTROL_STYLE = {
  minWidth: "100%",
  paddingTop: "17px",
  fontWeight: "400"
};

export const FORM_NAVIGATION_BUTTON_STYLE = {
  minWidth: "9.375rem",
  minHeight: "3.2rem",
  borderRadius: "0.4rem",
  fontWeight: 800
};

export const NAVBAR_MENU_STYLE = {
  boxShadow: "0px 3px 10px -9px rgba(0,0,0,0.75)"
};

export const CLAIMANT_INFORMATION_ACTION_ICONS_STYLE = {
  margin: "0px 5px 0px 5px",
  padding: "0px"
};

export const SWITCH_LABEL_STYLE = {
  marginLeft: "0px",
  flexDirection: "row"
};
