import React from "react";
import { Text, View } from "@react-pdf/renderer";
import {
  pdfStyles,
  BENEFIT_SECTION_SUBTITLE,
  PDF_DISPLAY_LABEL_VALUE_COMPONENT,
  PDF_DISPLAY_TIME_COMPONENT,
  PDF_DISPLAY_DATE_COMPONENT,
  CLAIMANT_DETAILS_FOR_BENEFIT,
  CLAIM_DETAILS_SUB_SECTION
} from "common/utils/PDFDocumentUtil";
import { BENEFIT_TYPES } from "common/Constants";
import { getClaimTitle } from "common/utils/CommonUtil";

const PDFFlightDetailComponent = props => {
  let title = getClaimTitle(BENEFIT_TYPES.flightDelayClaim);
  let detail = props.detail;
  let suffix = props.suffix;
  return (
    <React.Fragment>
      {BENEFIT_SECTION_SUBTITLE(suffix, title)}
      {CLAIMANT_DETAILS_FOR_BENEFIT(
        detail.benefitId,
        props.claimants,
        props.region
      )}
      {CLAIM_DETAILS_SUB_SECTION("Claim details")}
      <View style={pdfStyles.container}>
        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.subtitle2}>Original Departure Details</Text>
        </View>

        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Common Carrier No..",
          detail.originalFlightNumber
        )}

        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Place of Departure",
          detail.originalPlaceOfDeparture
        )}

        {PDF_DISPLAY_DATE_COMPONENT(
          "Original Date of Travel",
          detail.originalDate
        )}

        {PDF_DISPLAY_TIME_COMPONENT(
          "Original Time of Travel",
          detail.originalTime
        )}

        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Transport Provider",
          detail.originalTransportProvider
        )}

        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Type of Common Carrier",
          detail.originalTypeOfCarrier
        )}

        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Reason(s) By Service Provider For the Cause of Delay",
          detail.reason
        )}

        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.subtitle2}>Rescheduled Departure Details</Text>
        </View>

        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Common Carrier No..",
          detail.rescheduledFlightNumber
        )}

        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Place of Departure",
          detail.rescheduledPlaceOfDeparture
        )}

        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Place of Departure",
          detail.rescheduledPlaceOfDeparture
        )}

        {PDF_DISPLAY_DATE_COMPONENT(
          "Actual Date of Travel",
          detail.rescheduledDate
        )}

        {PDF_DISPLAY_TIME_COMPONENT(
          "Actual Time of Travel",
          detail.rescheduledTime
        )}

        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Transport Provider",
          detail.rescheduledTransportProvider
        )}

        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Type of Common Carrier",
          detail.rescheduledTypeOfCarrier
        )}
      </View>
    </React.Fragment>
  );
};

export default PDFFlightDetailComponent;
