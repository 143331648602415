import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { SUMMARY_CONTAINER_STYLE } from "common/styles/styles";
import {
  SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT,
  SUMMARY_DISPLAY_DATE_VALUE_COMPONENT,
  SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT_FULL_LENGTH
} from "common/utils/SummaryUtil";
import { getCurrencyValue } from "common/utils/CommonUtil";

class SummaryOtherDetailComponent extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const detail = this.props.detail;

    return (
      <React.Fragment>
        <Grid container style={SUMMARY_CONTAINER_STYLE} spacing={3}>
          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT_FULL_LENGTH(
            "Type of benefit",
            detail.benefitDescription
          )}
          {SUMMARY_DISPLAY_DATE_VALUE_COMPONENT(
            "Date of Incident",
            detail.dateOfIncident
          )}
          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Location of Incident",
            detail.locationOfIncident
          )}
          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Currency of amount claimed",
            detail.reasonForAmount
          )}
          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Amount claimed",
            getCurrencyValue(detail.amountClaimed)
          )}
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Cause and description of the Incident
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">{detail.description}</Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default SummaryOtherDetailComponent;
