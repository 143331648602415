import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import {
  SUMMARY_CONTAINER_STYLE,
  MEDICAL_CLAIM_ACCIDENT_CONTAINER_STYLE,
  SUMMARY_LIST_ITEMS_CONTAINER_STYLE,
  SUMMARY_MEDICAL_LIST_HEADER_STYLE
} from "common/styles/styles";
import {
  SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT,
  SUMMARY_DISPLAY_DATE_VALUE_COMPONENT,
  SUMMARY_DISPLAY_TIME_VALUE_COMPONENT,
  SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT_FULL_LENGTH
} from "common/utils/SummaryUtil";
import { getDate, getCurrencyValue } from "common/utils/CommonUtil";
import { getCurrency } from "common/Constants";

class SummaryMedicalDetailComponent extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const detail = this.props.detail;
    const region = this.props.region;
    const currency = getCurrency(region);

    const showAccidentClaimSummary = () => (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="h6">Accident Claim Details</Typography>
        </Grid>
        <Grid
          container
          style={MEDICAL_CLAIM_ACCIDENT_CONTAINER_STYLE}
          spacing={3}
        >
          {SUMMARY_DISPLAY_DATE_VALUE_COMPONENT(
            "Date of Accident",
            detail.dateOfAccident
          )}
          {SUMMARY_DISPLAY_TIME_VALUE_COMPONENT(
            "Time of Accident",
            detail.timeOfAccident
          )}
          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Place of Accident",
            detail.placeOfAccident
          )}
          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Description",
            detail.descriptionOfAccident
          )}
          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Description of injuries sustained",
            detail.injuriesInAccident
          )}
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} style={SUMMARY_MEDICAL_LIST_HEADER_STYLE}>
            <Typography variant="h6">Detail of Consultations:</Typography>
          </Grid>
          {showConsultations(detail.multipleConsultationAccident)}
        </Grid>
      </React.Fragment>
    );

    const showConsultations = multipleConsultations => (
      <React.Fragment>
        {multipleConsultations.map((item, index) => {
          return (
            <Grid
              key={item.multipleConsultationId}
              container
              spacing={3}
              style={SUMMARY_LIST_ITEMS_CONTAINER_STYLE}
            >
              <Divider />
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Consultation # {index + 1}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  Date of consultation:
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {typeof item.dateOfConsultation === "string"
                    ? item.dateOfConsultation
                    : getDate(item.dateOfConsultation)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">Amount claimed:</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {currency} {getCurrencyValue(item.amountClaimed)}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </React.Fragment>
    );

    const showMedicalSummary = () => (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="h6">Medical Claim Details</Typography>
        </Grid>
        <Grid container style={SUMMARY_CONTAINER_STYLE} spacing={3}>
          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT_FULL_LENGTH(
            "Type of symptoms",
            detail.typeOfSymptom
          )}
          {SUMMARY_DISPLAY_DATE_VALUE_COMPONENT(
            "Date Symptoms first appeared",
            detail.dateOfSymptom
          )}
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} style={SUMMARY_MEDICAL_LIST_HEADER_STYLE}>
            <Typography variant="h6">Detail of Consultations:</Typography>
          </Grid>
          {showConsultations(detail.multipleConsultationMedical)}
        </Grid>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Grid container style={SUMMARY_CONTAINER_STYLE} spacing={3}>
          {detail.isAccidentRelated
            ? showAccidentClaimSummary()
            : showMedicalSummary()}
          {detail.hasHistory ? (
            <React.Fragment>
              {" "}
              {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
                "Physician and previous treatment details",
                detail.historicalClaimConsultationDetails
              )}
            </React.Fragment>
          ) : null}
        </Grid>
      </React.Fragment>
    );
  }
}

export default SummaryMedicalDetailComponent;
