import React from "react";
import AddAnotherClaimComponent from "components/forms/decisions/AddAnotherClaimComponent";

class AddAnotherClaim extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let data = {
      props: this.props,
      errorHandlers: {
        localValidation: ""
      },
      viewControls: {
        viewControl: ""
      }
    };
    return AddAnotherClaimComponent(data);
  }
}

export default AddAnotherClaim;
