import CreateNewGUID from "common/utils/CommonUtil";
import { FILE_REQUIREMENTS, BENEFIT_TYPES } from "common/Constants";

export let OTHER_CLAIM = region => ({
  benefitId: CreateNewGUID(),
  benefitType: "otherClaim",
  benefitDescription: "",
  dateOfIncident: null,
  locationOfIncident: "",
  description: "",
  amountClaimed: "",
  reasonForAmount: "",
  fileRequirements: FILE_REQUIREMENTS(BENEFIT_TYPES.otherClaim, region)
});

// FIXME dinesh: change benefitId to claimId
export let LOST_BAGGAGE_CLAIM = region => ({
  benefitId: CreateNewGUID(),
  benefitType: "lostBaggageClaim",
  numberOfItems: 0,
  dateOfLoss: null,
  timeOfLoss: null,
  locationOfLoss: "",
  description: "",
  hasNotificationOfSearch: false,
  notificationOfSearchDetails: "",
  wasMoneyOrChequeLost: false,
  // currency/checque loss
  typeOfCurrency: "", // dropdown Money/Cheque/NA
  lossRegion: "", // dropdown Singapore/Hong Kong
  currency: "",
  amountInSGD: "",
  amountInForeignCurrency: "",
  isBaggageLost: false,
  // baggage/item loss
  isBaggageLostInOtherCustody: false,
  // baggage/item loss other custody
  isClaimAgainstOtherTaken: false,
  claimAgainstOtherTakenDescription: "",
  descriptionOfLostItems: [],
  lostItemDescription: "",
  lostItemMakeModel: "",
  lostItemDateOfPurchase: null,
  lostItemPurchaseCurrency: "",
  lostItemPurchasePrice: "0.00",
  lostItemAmountClaimed: "0.00",
  totalClaimForLoss: "0.00", // sum of all lost items
  totalOverallClaim: "0.00", // sum of totalClaimForLoss & amountInSGD
  fileRequirements: FILE_REQUIREMENTS(BENEFIT_TYPES.lostBaggageClaim, region),
  accumulatedCurrencies: []
});

export let TRIP_CANCELLATION_CLAIM = region => ({
  benefitId: CreateNewGUID(),
  benefitType: "tripCancellationClaim",
  wasCancelledByIID: false, // Illness, Injured or Dead
  nameOfIID: "", // if wasCancelledByIID = true
  relationToInsured: "", // Self/Family Member/Employee/Other
  otherRelation: "", // if relationToInsured = Other
  cancellationReason: "",
  dateOfEvent: null,
  depositAmount: "",
  refundAmount: "",
  totalAmount: "0.00", // depositAmount - refundAmount
  noRefundDescription: "", // if refundAmount = 0
  fileRequirements: FILE_REQUIREMENTS(
    BENEFIT_TYPES.tripCurtailmentClaim,
    region
  )
});

export let ACCIDENT_CLAIM = region => ({
  benefitId: CreateNewGUID(),
  benefitType: "accidentClaim",
  dateOfAccident: null,
  timeOfAccident: null,
  placeOfAccident: "",
  descriptionOfAccident: "",
  injuriesInAccident: "",
  dateOfConsultationForAccident: null,
  fileRequirements: FILE_REQUIREMENTS(BENEFIT_TYPES.accidentClaim, region)
});

export let MEDICAL_CLAIM = region => ({
  benefitId: CreateNewGUID(),
  benefitType: "medicalClaim",
  isAccidentRelated: false,
  dateOfAccident: null, //Accident
  timeOfAccident: null, //Accident
  placeOfAccident: "", //Accident
  descriptionOfAccident: "", //Accident
  injuriesInAccident: "", //Accident
  multipleConsultationAccident: [], //MULTIPLE_CONSULTATION Accident
  // dateOfConsultationForAccident: null,
  typeOfSymptom: "", // Medical
  dateOfSymptom: null, // Medical
  multipleConsultationMedical: [], //MULTIPLE_CONSULTATION Medical
  // dateOfConsultation: null,
  hasHistory: false,
  historicalClaimConsultationDetails: "",
  fileRequirements: FILE_REQUIREMENTS(BENEFIT_TYPES.medicalClaim, region)
});

export const MULTIPLE_CONSULTATION = () => ({
  multipleConsultationId: CreateNewGUID(),
  dateOfConsultation: null, // date field
  amountClaimed: "" // currency field
});

export let TRIP_CURTAILMENT_CLAIM = region => ({
  benefitId: CreateNewGUID(),
  benefitType: "tripCurtailmentClaim",
  wasCurtailedByIID: false, // Illness, Injured or Dead
  nameOfIID: "", // if wasCancelledByIID = true
  relationToInsured: "", // Self/Family Member/Employee/Other
  otherRelation: "", // if relationToInsured = Other
  curtailmentReason: "",
  dateOfEvent: null,
  totalAmount: "0.00",
  reasonForClaim: "",
  fileRequirements: FILE_REQUIREMENTS(
    BENEFIT_TYPES.tripCurtailmentClaim,
    region
  )
});

export let DESCRIPTION_OF_LOST_ITEMS = () => ({
  lostItemId: CreateNewGUID(),
  description: "",
  makeModel: "",
  dateOfPurchase: "",
  purchaseCurrency: "",
  purchasePrice: "",
  amountClaimed: ""
});

export let ACCUMULATED_CURRENCIES = () => ({
  totalAmount: []
});

export let FLIGHT_DELAY_CLAIM = region => ({
  benefitId: CreateNewGUID(),
  benefitType: "flightDelayClaim",
  originalFlightNumber: "",
  originalPlaceOfDeparture: "",
  originalDate: null,
  originalTime: null,
  originalTransportProvider: "",
  originalTypeOfCarrier: "",
  rescheduledFlightNumber: "",
  rescheduledPlaceOfDeparture: "",
  rescheduledDate: null,
  rescheduledTime: null,
  rescheduledTransportProvider: "",
  rescheduledTypeOfCarrier: "",
  amount: "0.00",
  reason: "",
  fileRequirements: FILE_REQUIREMENTS(BENEFIT_TYPES.flightDelayClaim, region)
});

export let TRAVEL_MIS_CONNECTION_CLAIM = region => ({
  benefitId: CreateNewGUID(),
  benefitType: "travelMisConnectionClaim",
  originalFlightNumber: "",
  originalPlaceOfDeparture: "",
  originalDate: null,
  originalTime: null,
  originalTransportProvider: "",
  rescheduledFlightNumber: "",
  rescheduledPlaceOfDeparture: "",
  rescheduledDate: null,
  rescheduledTime: null,
  rescheduledTransportProvider: "",
  amount: "0.00",
  reason: "",
  actualArrivalDate: null,
  actualArrivalTime: null,
  fileRequirements: FILE_REQUIREMENTS(
    BENEFIT_TYPES.travelMisConnectionClaim,
    region
  )
});

export let NEW_CLAIMANT_INFORMATION = () => ({
  claimantId: CreateNewGUID(),
  isComplete: false,
  relationToPolicyHolder: "",
  surname: "",
  name: "",
  email: "",
  phone: "",
  sgIDType: "",
  sgIDNumber: "",
  hkIDType: "",
  hkIDNumber: "",
  relatedClaim: []
});

export const CLAIM = () => ({
  claimId: CreateNewGUID(),
  isComplete: false,
  welcomePage: {
    isComplete: false
  },
  aboutSelf: {
    claimantId: CreateNewGUID(),
    isComplete: false,
    relationToPolicyHolder: "self",
    surname: "",
    name: "",
    email: "",
    phone: "",
    sgIDType: "",
    sgIDNumber: "",
    hkIDType: "",
    hkIDNumber: "",
    relatedClaim: []
  },
  reporter: {
    claimantId: CreateNewGUID(),
    isComplete: false,
    isPolicyHolder: false,
    relationToPolicyHolder: "",
    surname: "",
    name: "",
    email: "",
    phone: "",
    sgIDType: "",
    sgIDNumber: "",
    hkIDType: "",
    hkIDNumber: "",
    relatedClaim: []
  },
  basicInformation: {
    isComplete: false,
    policyNumber: "",
    hasRelatedInsurance: false,
    relatedInsuranceCompanyName: "",
    relatedInsurancePolicyNumber: "",
    travelDateFrom: null,
    travelDateTill: null,
    hasHistoricalClaim: false,
    historicalClaimDateOfLoss: new Date(Date.now() - 864e5),
    historicalClaimInsuranceCompany: "",
    historicalClaimDescription: ""
  },
  addAnotherClaim: {
    isComplete: false,
    addAnotherClaim: ""
  },
  claimDetail: {
    lostBaggageClaim: [],
    flightDelayClaim: [],
    tripCancellationClaim: [],
    tripCurtailmentClaim: [],
    accidentClaim: [],
    medicalClaim: [],
    travelMisConnectionClaim: [],
    otherClaim: []
  },
  claimantInformation: [],
  addMoreClaimant: {
    isComplete: false,
    addMoreClaimant: ""
  },
  fileUpload: [],
  submissionId: "",
  summary: {
    isComplete: false
  },
  pdpa: {
    declaration: false,
    authorization: false,
    personalDataConsent: false,
    promotionConsent: false
  },
  currentClaimType: "",
  policyHolderId: "",
  submissionTime: "",
  claimDirectory: null
});

export const NEW_CURRENCY_TOTAL = (currency, totalValue) => {
  return { currency, totalValue };
};
