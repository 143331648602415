import React from "react";
import { Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import { uploadFile } from "common/utils/FileUploadUtil";
import { sendEmail } from "common/utils/SendEmailUtil";
import { isNotLoading } from "common/utils/CommonUtil";
import {
  pdfStyles,
  SubmissionDetail,
  PolicyHolder,
  Reporter,
  BasicInformation,
  ClaimDetails,
  PDFHeader,
  FileUploadDetails,
  SubmissionTime
} from "common/utils/PDFDocumentUtil";
import { PDFDeclarationComponent } from "common/pdfGeneration/PDFDeclarationComponent";
import { PDFContactInformationComponent } from "common/pdfGeneration/PDFContactInformationComponent";
import { SUMMARY_FILE_NAME, FILE_TYPE_APPLICATION_PDF } from "common/Constants";
import { GET_DOCUMENT_UPLOAD_DIRECTORY } from "common/utils/CommonUtil";

export const ClaimDocument = (claim, region) => {
  let aboutSelf = claim.aboutSelf;
  return (
    <Document
      id="pdf-document"
      title="Claims Submission Summary"
      author="Allied World Assurance Company, Ltd (Singapore branch)"
      creator="Allied World Assurance Company"
      producer="Allied World Assurance Company"
      onRender={() => {
        isNotLoading();
      }}
    >
      <Page size="A4" style={pdfStyles.page} wrap>
        {PDFHeader()}
        {SubmissionTime(claim.submissionTime, region)}
        {SubmissionDetail(claim.submissionId)}
        {PolicyHolder(aboutSelf, region)}
        {Reporter(claim, region)}
        {BasicInformation(claim.basicInformation)}
        {ClaimDetails(claim, region)}
        {FileUploadDetails(claim.fileUpload)}
        {PDFDeclarationComponent(claim.pdpa, region)}
        {PDFContactInformationComponent(region)}
      </Page>
    </Document>
  );
};

const getEmailToSend = claim => {
  return claim.aboutSelf.email;
};

const PDFGeneration = props => {
  const claim = props.data;
  const region = props.region;
  const claimId = claim.claimId || "not-found";
  const mainDirectory = claim.claimDirectory;
  let claimSubmissionId = props.claimSubmissionId;
  if (!claimSubmissionId || !mainDirectory) {
    return null;
  }

  const senderEmail = getEmailToSend(claim);

  const postProcessing = async fileToUpload => {
    try {
      const uploadResponse = await uploadFile(fileToUpload, region);
      console.log("Upload Response: ", uploadResponse);
      const sendEmailResponse = await sendEmail(
        claimSubmissionId,
        claimId,
        senderEmail,
        mainDirectory,
        region
      );
      console.log("Send email Response: ", sendEmailResponse);
    } catch (err) {
      console.error(
        "ERROR: Claims submission post processing has failed.",
        err
      );
    }
  };

  const fileName = SUMMARY_FILE_NAME(claimSubmissionId);
  return (
    <React.Fragment>
      Download a PDF of the Claim Summary
      <PDFDownloadLink
        document={ClaimDocument(claim, region)}
        fileName={fileName}
      >
        {({ blob, url, loading, error }) => {
          if (!loading) {
            let fileToUpload = {
              fileDirectory: GET_DOCUMENT_UPLOAD_DIRECTORY(
                mainDirectory,
                claimSubmissionId
              ),
              fileName: fileName,
              fileType: FILE_TYPE_APPLICATION_PDF,
              file: blob
            };

            postProcessing(
              fileToUpload,
              claimSubmissionId,
              claimId,
              senderEmail
            );
          }

          return loading ? "Loading Document Link..." : " here ";
        }}
      </PDFDownloadLink>{" "}
      for reference.
    </React.Fragment>
  );
};
export default PDFGeneration;
