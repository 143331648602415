import React from "react";
import NavButton from "components/elements/NavButton";
import { FOOTER_BUTTON, FORM_LINKS, IS_SG_REGION } from "common/Constants";
import { Typography } from "@material-ui/core";
import Loader from "components/elements/Loader";

function WelcomePageComponent(data) {
  let region = data.props.parentState.region;
  return (
    <React.Fragment>
      {data.isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Typography variant="h1">
            Welcome to Allied World Travel Claims Form
          </Typography>
          <br />
          <Typography variant="subtitle2" id="welcome-sub-text">
            At Allied World, we understand our reputation is built around a
            promise to pay and we take great pride in the reputation of our
            claims department.
          </Typography>
          <Typography variant="subtitle2" id="welcome-sub-text-2">
            Our approach to claims is thoughtful and deliberate. Open dialogue
            and partnership with our insureds during the entire claims-handling
            process is at the core of our approach.
          </Typography>
          <div className="footer">
            <NavButton
              id="start-button"
              name={FOOTER_BUTTON.startButton}
              disabled={false}
              displayNextArrow={true}
              clickHandler={e => {
                data.props.history.push(FORM_LINKS.aboutSelf);
              }}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default WelcomePageComponent;
