import React from "react";
import {
  BENEFIT_TYPES,
  FORM_LINKS,
  FIELD_TYPE,
  REACT_APP_GET_DIRECTORY_NAME_API,
  API_RESPONSE_STATUS,
  REGION,
  SUPPORT_EMAIL_HK,
  SUPPORT_EMAIL_SG,
  REACT_APP_QUERY_SUBMISSION_TIME_API
} from "common/Constants";
import {
  FLIGHT_DELAY_CLAIM,
  TRIP_CURTAILMENT_CLAIM,
  TRIP_CANCELLATION_CLAIM,
  LOST_BAGGAGE_CLAIM,
  OTHER_CLAIM,
  MEDICAL_CLAIM,
  ACCIDENT_CLAIM,
  TRAVEL_MIS_CONNECTION_CLAIM
} from "common/data/defaultClaim";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import SummaryFlightDelayDetailComponent from "components/forms/summaryPage/SummaryFlightDelayDetailComponent";
import SummaryLostBaggageDetailComponent from "components/forms/summaryPage/SummaryLostBaggageDetailComponent";
import SummaryTripCancellationDetailComponent from "components/forms/summaryPage/SummaryTripCancellationDetailComponent";
import SummaryMedicalDetailComponent from "components/forms/summaryPage/SummaryMedicalDetailComponent";
import SummaryTravelMisConnectionDetailComponent from "components/forms/summaryPage/SummaryTravelMisConnectionDetailComponent";
import SummaryOtherDetailComponent from "components/forms/summaryPage/SummaryOtherDetailComponent";
import MessageComponent from "components/messages/MessageComponent";
import { MESSAGE_TYPE } from "common/Constants";
import axios from "axios";

function CreateNewGUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const SCROLL_TO_TOP = () => window.scrollTo(0, 0);

export const getCountForBenefit = (index, benefitType, countResult) => {
  let count = 0;
  switch (benefitType) {
    case BENEFIT_TYPES.flightDelayClaim:
      count = index + 1 + countResult.countOfAccident;
      break;
    case BENEFIT_TYPES.lostBaggageClaim:
      count = index + 1 + countResult.countOfFlightDelay;
      break;

    case BENEFIT_TYPES.medicalClaim:
      count = index + 1 + countResult.countOfLostBaggage;
      break;

    case BENEFIT_TYPES.otherClaim:
      count = index + 1 + countResult.countOfMedical;
      break;

    case BENEFIT_TYPES.travelMisConnectionClaim:
      count = index + 1 + countResult.countOfOther;
      break;

    case BENEFIT_TYPES.tripCancellationClaim:
      count = index + 1 + countResult.countOfTravelMisconnection;
      break;

    default:
      break;
  }
  return count;
};

export const getClaimTitle = benefitType => {
  let title = "";
  switch (benefitType) {
    case BENEFIT_TYPES.flightDelayClaim:
      title = "Travel Delay Claim";
      break;
    case BENEFIT_TYPES.lostBaggageClaim:
      title = "Lost Baggage Claim";
      break;

    case BENEFIT_TYPES.medicalClaim:
      title = "Medical/Accident Claim";
      break;

    case BENEFIT_TYPES.otherClaim:
      title = "Other Claim";
      break;

    case BENEFIT_TYPES.travelMisConnectionClaim:
      title = "Travel Misconnection Claim";
      break;

    case BENEFIT_TYPES.tripCancellationClaim:
      title = "Trip Cancellation Claim";
      break;

    default:
      break;
  }
  return title;
};

export const getFileSize = number => {
  if (number < 1024) {
    return number + "bytes";
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + "KB";
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + "MB";
  }
};

export const getIndexCount = claimDetail => {
  const flightDelayClaims = claimDetail[BENEFIT_TYPES.flightDelayClaim] || [];
  const lostBaggageClaims = claimDetail[BENEFIT_TYPES.lostBaggageClaim] || [];
  const tripCancellationClaims =
    claimDetail[BENEFIT_TYPES.tripCancellationClaim] || [];
  const accidentClaim = claimDetail[BENEFIT_TYPES.accidentClaim] || [];
  const medicalClaims = claimDetail[BENEFIT_TYPES.medicalClaim] || [];
  const travelMisConnectionClaim =
    claimDetail[BENEFIT_TYPES.travelMisConnectionClaim] || [];
  const otherClaims = claimDetail[BENEFIT_TYPES.otherClaim] || [];

  const countOfAccident = accidentClaim.length;
  const countOfFlightDelay = countOfAccident + flightDelayClaims.length;
  const countOfLostBaggage = countOfFlightDelay + lostBaggageClaims.length;
  const countOfMedical = countOfLostBaggage + medicalClaims.length;
  const countOfOther = countOfMedical + otherClaims.length;
  const countOfTravelMisconnection =
    countOfOther + travelMisConnectionClaim.length;
  const countOfTripCancellation =
    countOfTravelMisconnection + tripCancellationClaims.length;
  return {
    countOfAccident,
    countOfFlightDelay,
    countOfLostBaggage,
    countOfMedical,
    countOfOther,
    countOfTravelMisconnection,
    countOfTripCancellation
  };
};

export const getNextSelectedForm = selectedClaimType => {
  let nextFormLink = "";
  switch (selectedClaimType) {
    case BENEFIT_TYPES.flightDelayClaim:
      nextFormLink = FORM_LINKS.flightDelayClaim;
      break;
    case BENEFIT_TYPES.lostBaggageClaim:
      nextFormLink = FORM_LINKS.lostBaggageClaim;
      break;
    case BENEFIT_TYPES.tripCancellationClaim:
      nextFormLink = FORM_LINKS.tripCancellationClaim;
      break;
    case BENEFIT_TYPES.tripCurtailmentClaim:
      nextFormLink = FORM_LINKS.tripCurtailmentClaim;
      break;
    case BENEFIT_TYPES.accidentClaim:
      nextFormLink = FORM_LINKS.accidentClaim;
      break;
    case BENEFIT_TYPES.medicalClaim:
      nextFormLink = FORM_LINKS.medicalClaim;
      break;
    case BENEFIT_TYPES.travelMisConnectionClaim:
      nextFormLink = FORM_LINKS.travelMisConnectionClaim;
      break;
    case BENEFIT_TYPES.otherClaim:
      nextFormLink = FORM_LINKS.otherClaim;
      break;
    default:
      break;
  }
  return nextFormLink;
};

export const handleAPIFailResponse = (apiMessage, localMessage, reject) => {
  console.error(JSON.stringify(apiMessage));
  reject(`${localMessage}: ${JSON.stringify(apiMessage)}`);
};

export const getBenefitCount = claimDetail => {
  let count = 0;
  if (
    claimDetail &&
    claimDetail[BENEFIT_TYPES.accidentClaim] &&
    claimDetail[BENEFIT_TYPES.flightDelayClaim] &&
    claimDetail[BENEFIT_TYPES.lostBaggageClaim] &&
    claimDetail[BENEFIT_TYPES.medicalClaim] &&
    claimDetail[BENEFIT_TYPES.travelMisConnectionClaim] &&
    claimDetail[BENEFIT_TYPES.tripCancellationClaim] &&
    claimDetail([BENEFIT_TYPES.otherClaim]) &&
    claimDetail[BENEFIT_TYPES.tripCurtailmentClaim]
  ) {
    Object.keys(claimDetail).forEach(item => {
      count = item.length() + count;
    });
  } else {
    count = 1;
  }
  return count;
};

export const getCurrencyValue = value => {
  let currencyValue = "0.00";
  if (value) {
    currencyValue = "" + parseFloat(value).toFixed(2);
  }
  return currencyValue;
};

export const getSummaryBenefitComponent = (
  benefitType,
  benefitDetail,
  region
) => {
  let response = {
    editLink: "",
    deleteLink: "",
    detailsComponent: {}
  };

  switch (benefitType) {
    case BENEFIT_TYPES.flightDelayClaim:
      response.detailsComponent = (
        <SummaryFlightDelayDetailComponent
          detail={benefitDetail}
          region={region}
        />
      );
      response.editLink = FORM_LINKS.flightDelayClaim;
      break;

    case BENEFIT_TYPES.lostBaggageClaim:
      response.detailsComponent = (
        <SummaryLostBaggageDetailComponent
          detail={benefitDetail}
          region={region}
        />
      );
      response.editLink = FORM_LINKS.lostBaggageClaim;
      break;

    case BENEFIT_TYPES.tripCancellationClaim:
      response.detailsComponent = (
        <SummaryTripCancellationDetailComponent
          detail={benefitDetail}
          region={region}
        />
      );
      response.editLink = FORM_LINKS.tripCancellationClaim;
      break;

    case BENEFIT_TYPES.tripCurtailmentClaim:
      // FIXME: dinesh add the Summary for TripCurtailment
      // response.detailsComponent = (
      //   <SummaryFlightDelayDetailComponent detail={benefitDetail} region={region} />
      // );
      response.editLink = FORM_LINKS.tripCurtailmentClaim;
      break;

    case BENEFIT_TYPES.travelMisConnectionClaim:
      response.detailsComponent = (
        <SummaryTravelMisConnectionDetailComponent
          detail={benefitDetail}
          region={region}
        />
      );
      response.editLink = FORM_LINKS.travelMisConnectionClaim;
      break;

    case BENEFIT_TYPES.medicalClaim:
      response.detailsComponent = (
        <SummaryMedicalDetailComponent detail={benefitDetail} region={region} />
      );
      response.editLink = FORM_LINKS.medicalClaim;
      break;

    case BENEFIT_TYPES.otherClaim:
      response.detailsComponent = (
        <SummaryOtherDetailComponent detail={benefitDetail} region={region} />
      );
      response.editLink = FORM_LINKS.otherClaim;
      break;

    default:
      break;
  }
  return response;
};

export const getTime = date => {
  if (date) {
    return format(date, "kk:mm aaaa");
  }
  return null;
};

export const getDate = date => {
  if (date) {
    return format(date, "dd/MM/yyyy");
  }
  return null;
};

export let validateEmail = email => {
  if (email) {
    var emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailFormat.test(String(email).toLowerCase());
  }
  return false;
};

export let validatePhoneNumber = value => {
  let numericFormat = /^-{0,1}\d+$/;
  let valueStr = String(value);
  return numericFormat.test(valueStr) && valueStr.length >= 8;
};

export let getBenefitObject = (benefitType, region) => {
  if (!!benefitType) {
    switch (benefitType) {
      case BENEFIT_TYPES.flightDelayClaim:
        return FLIGHT_DELAY_CLAIM(region);
      case BENEFIT_TYPES.lostBaggageClaim:
        return LOST_BAGGAGE_CLAIM(region);
      case BENEFIT_TYPES.tripCancellationClaim:
        return TRIP_CANCELLATION_CLAIM(region);
      case BENEFIT_TYPES.tripCurtailmentClaim:
        return TRIP_CURTAILMENT_CLAIM(region);
      case BENEFIT_TYPES.medicalClaim:
        return MEDICAL_CLAIM(region);
      case BENEFIT_TYPES.accidentClaim:
        return ACCIDENT_CLAIM(region);
      case BENEFIT_TYPES.travelMisConnectionClaim:
        return TRAVEL_MIS_CONNECTION_CLAIM(region);
      case BENEFIT_TYPES.otherClaim:
        return OTHER_CLAIM(region);
      default:
        break;
    }
  }
};

export let shouldDisplaySubQuestions = (benefitDetail, paramName) => {
  return benefitDetail[paramName] ? ["display"] : ["hidden"];
};

export let getTotalTripCancelAmount = (depositAmount, refundAmount) => {
  if (!depositAmount) {
    depositAmount = 0.0;
  }
  if (!refundAmount) {
    refundAmount = 0.0;
  }
  return (
    "" +
    parseFloat(parseFloat(depositAmount) - parseFloat(refundAmount)).toFixed(2)
  );
};

export let handleFloatingCurrencyFormatting = value => {
  let formattedValue = "0";
  if (isNaN(value)) {
    return formattedValue;
  }
  if (!!value) {
    if (value.toString().indexOf(".") !== -1) {
      let strVal = value.toString();
      let valueArr = strVal.split(".");
      let integers = valueArr[0];
      let decimals = valueArr[1];
      if (decimals.length === 0) {
        formattedValue = value;
      } else if (decimals.length === 1) {
        formattedValue = parseFloat(value).toFixed(1);
      } else if (decimals.length === 2) {
        formattedValue = parseFloat(value).toFixed(2);
      } else if (decimals.length > 2) {
        let updatedDecimals = decimals.slice(-3, -1);
        formattedValue = "".concat(integers, ".", updatedDecimals);
      }
    } else if (value === "0") {
      formattedValue = "0";
    } else {
      formattedValue = parseInt(value, 10);
    }
  }
  return formattedValue;
};

export let getLastBenefitDetail = (benefitType, claimDetail) => {
  let benefitDetail = {};
  if (benefitType && claimDetail) {
    let benefitDetailIndex = getLastBenefitIndex(benefitType, claimDetail);
    benefitDetail = claimDetail[benefitType][benefitDetailIndex];
  }
  return benefitDetail;
};

export let getLastBenefitIndex = (benefitType, claimDetail) => {
  return (
    (claimDetail[benefitType].length && claimDetail[benefitType].length - 1) ||
    0
  );
};

export let GET_SUPPORT_EMAIL = region =>
  region === REGION.HK ? SUPPORT_EMAIL_HK : SUPPORT_EMAIL_SG;

export let getBenefitDetailFromProps = (location, claim, benefitType) => {
  let benefitDetail = {};
  let benefitIdFromLocation =
    (location && location.state && location.state.benefitId) || "";
  if (benefitIdFromLocation) {
    benefitDetail = claim.claimDetail[benefitType].filter(item => {
      return item.benefitId === benefitIdFromLocation;
    })[0];
  } else {
    benefitDetail = getLastBenefitDetail(benefitType, claim.claimDetail);
  }
  return benefitDetail;
};

export const HAS_ERROR = error => Object.values(error).indexOf(true) >= 0;

export let getBenefitLabel = (benefitType, suffix) => {
  let label = "";
  if (!!benefitType) {
    label = getClaimTitle(benefitType) + " " + suffix;
  }
  return label;
};

export const DOES_CURRENT_FIELD_HAVE_ERROR = (value, touchedState, type) => {
  let hasError = false;
  if (!touchedState) {
    hasError = false;
  } else if (!!!value) {
    hasError = true;
  } else {
    switch (type) {
      case FIELD_TYPE.text:
        hasError = !!!value;
        break;
      case FIELD_TYPE.date:
        hasError = !isValid(value);
        break;
      case FIELD_TYPE.currency:
        hasError = !/^(?=.*[1-9])\d{0,5}(\.\d{1,2})?$/.test(value);
        break;
      case FIELD_TYPE.time:
        hasError = !/^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(value);
        break;
      case FIELD_TYPE.phone:
        hasError = !validatePhoneNumber(value);
        break;
      case FIELD_TYPE.email:
        hasError = !validateEmail(value);
        break;
      default:
        hasError = true;
        break;
    }
  }
  return hasError;
};

let LOADING = false;

export const getLoading = () => {
  return LOADING;
};
export const setLoading = () => (LOADING = true);

export const isNotLoading = () => {
  LOADING = false;
};

export const IS_CURRENT_FIELD_VALID_CHECK = (
  name,
  msgFieldName,
  defaultHelperText,
  currentFieldValues,
  validationState,
  fieldTouchedState,
  type
) => {
  if (
    DOES_CURRENT_FIELD_HAVE_ERROR(
      currentFieldValues[name],
      fieldTouchedState,
      type
    )
  ) {
    validationState.hasErr[name] = true;
    validationState.errMsg[name] = msgFieldName + " data is not valid.";
  } else {
    validationState.hasErr[name] = false;
    validationState.errMsg[name] = defaultHelperText;
  }
};

export const IS_EMPTY_FIELD_CHECK = (
  name,
  msgFieldName,
  defaultHelperText,
  currentFieldValues,
  validationState
) => {
  if (currentFieldValues[name]) {
    validationState.hasErr[name] = false;
    validationState.errMsg[name] = defaultHelperText;
  } else {
    validationState.hasErr[name] = true;
    validationState.errMsg[name] = msgFieldName + " field cannot be empty";
  }
};

export const displayLostBaggageNotification = () => (
  <React.Fragment>
    <MessageComponent
      type={MESSAGE_TYPE.notify}
      title="Note:"
      subtitle="For Lost Item claim, you can only select a single Claimant."
    />
  </React.Fragment>
);

export const GET_DOCUMENT_UPLOAD_DIRECTORY = (mainDirectory, subDirectory) => {
  let uploadPath = "";
  if (mainDirectory) {
    uploadPath = mainDirectory + "/" + subDirectory;
  } else {
    uploadPath = subDirectory;
  }
  return uploadPath;
};

export const UPDATE_CLAIM_DIRECTORY = async updateState => {
  const claimDirectory = await GET_CLAIM_DIRECTORY();
  updateState(claimDirectory);
};

export const GET_CLAIM_DIRECTORY = async () => {
  let newClaimDirectory = "";
  try {
    const result = await axios.get(REACT_APP_GET_DIRECTORY_NAME_API);

    const responseData = result.data;
    if (responseData && responseData.status) {
      if (responseData.status === API_RESPONSE_STATUS.SUCCESS) {
        newClaimDirectory = responseData.data;
      } else {
        throw new Error(
          `Get Directory API has failed; Error: ${responseData.msg};`
        );
      }
    } else {
      throw new Error(`Get Directory API has failed;`);
    }
  } catch (error) {
    throw new Error(`Get Directory API has failed with Error: ${error};`);
  }
  return newClaimDirectory;
};

export const UPDATE_SUBMISSION_TIME = async updateState => {
  const claimSubmissionTimeDate = await GET_CLAIM_SUBMISSION_TIME();
  return claimSubmissionTimeDate;
};

export const GET_CLAIM_SUBMISSION_TIME = async () => {
  let submissionTime = "";
  try {
    const result = await axios.get(REACT_APP_QUERY_SUBMISSION_TIME_API);

    const responseData = result.data;
    if (
      responseData &&
      responseData.status &&
      responseData.status === API_RESPONSE_STATUS.SUCCESS &&
      !!responseData.data.submissionTime
    ) {
      submissionTime = responseData.data.submissionTime;
      console.log(`Submission Time: ${submissionTime}`);
    } else {
      console.log("Error! Claim submission time query has failed");
    }
  } catch (error) {
    console.log(
      `Error! Could not get the submission time from backend: ${JSON.stringify(
        error
      )}`
    );
  }
  return submissionTime;
};

export const IS_VALID_TEXT_INPUT = text => {
  let result = true;
  if (text && (typeof text === "string" || text instanceof String)) {
    result = text.match(/^[\u0021-\u0084\s]+$/) ? true : false;
  }
  return result;
};

export default CreateNewGUID;
