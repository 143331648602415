import React from "react";
import OtherClaimComponent from "components/forms/benefits/otherClaim/OtherClaimComponent";
import { BENEFIT_TYPES, FIELD_TYPE } from "common/Constants";
import {
  getBenefitDetailFromProps,
  getLastBenefitIndex,
  IS_EMPTY_FIELD_CHECK,
  getClaimTitle,
  HAS_ERROR,
  IS_CURRENT_FIELD_VALID_CHECK
} from "common/utils/CommonUtil";

class OtherClaim extends React.Component {
  constructor() {
    super();
    this.state = {
      touched: {
        benefitDescription: false,
        dateOfIncident: false,
        locationOfIncident: false,
        description: false,
        amountClaimed: false,
        reasonForAmount: false
      },
      requirementModalDisplay: false
    };
  }

  showModal = () => {
    this.setState({ requirementModalDisplay: true });
  };

  closeModal = () => {
    this.setState({ requirementModalDisplay: false });
  };

  handleTouched = e => {
    let targetParam = e.target.name;
    if (!this.state.touched[targetParam]) {
      this.setState(prevState => ({
        touched: {
          ...prevState.touched,
          [targetParam]: true
        }
      }));
    }
  };

  checkForm = (hasError, currentFieldValues) => {
    if (this.benefitIsEmpty(currentFieldValues)) {
      return HAS_ERROR(hasError);
    } else {
      return true;
    }
  };

  getBenefitType = () => BENEFIT_TYPES.otherClaim;

  getBenefitDetail(props) {
    return getBenefitDetailFromProps(
      props.location,
      props.parentState.claim,
      this.getBenefitType()
    );
  }

  validationResult = {
    hasErr: {
      benefitDescription: false,
      dateOfIncident: false,
      locationOfIncident: false,
      description: false,
      amountClaimed: false,
      reasonForAmount: false
    },
    errMsg: {
      benefitDescription: "",
      dateOfIncident: "",
      locationOfIncident: "",
      description: "",
      amountClaimed: "",
      reasonForAmount: ""
    }
  };

  validateFields = (currentFieldValues, touchedState, validationState) => {
    if (
      touchedState.benefitDescription ||
      currentFieldValues.benefitDescription
    ) {
      IS_EMPTY_FIELD_CHECK(
        "benefitDescription",
        "This field ",
        "",
        currentFieldValues,
        validationState
      );
    }

    if (touchedState.dateOfIncident || currentFieldValues.dateOfIncident) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "dateOfIncident",
        "This field ",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.date
      );
    }

    if (
      touchedState.locationOfIncident ||
      currentFieldValues.locationOfIncident
    ) {
      IS_EMPTY_FIELD_CHECK(
        "locationOfIncident",
        "This field ",
        "",
        currentFieldValues,
        validationState
      );
    }

    if (touchedState.description || currentFieldValues.description) {
      IS_EMPTY_FIELD_CHECK(
        "description",
        "This field ",
        "",
        currentFieldValues,
        validationState
      );
    }

    if (touchedState.amountClaimed || currentFieldValues.amountClaimed) {
      IS_EMPTY_FIELD_CHECK(
        "amountClaimed",
        "This field ",
        "",
        currentFieldValues,
        validationState
      );
    }

    if (touchedState.reasonForAmount || currentFieldValues.reasonForAmount) {
      IS_EMPTY_FIELD_CHECK(
        "reasonForAmount",
        "This field ",
        "",
        currentFieldValues,
        validationState
      );
    }
  };

  benefitIsEmpty = currentClaimDetail => {
    return (
      !!currentClaimDetail.benefitDescription &&
      !!currentClaimDetail.dateOfIncident &&
      !!currentClaimDetail.locationOfIncident &&
      !!currentClaimDetail.description &&
      !!currentClaimDetail.amountClaimed &&
      !!currentClaimDetail.reasonForAmount
    );
  };

  componentDidMount() {
    this.showModal();
  }

  componentWillUnmount() {
    let benefitType = this.getBenefitType();
    let claimDetail = this.props.parentState.claim.claimDetail;
    let benefitDetailIndex = getLastBenefitIndex(benefitType, claimDetail);
    const currentClaimDetail = claimDetail[benefitType][benefitDetailIndex];
    let benefitId = currentClaimDetail.benefitId;
    if (this.benefitIsEmpty(currentClaimDetail)) {
      let benefitName = getClaimTitle(BENEFIT_TYPES.otherClaim);
      let parentHandlers = this.props.parentHandlers;
      parentHandlers.handleBenefitDetailInNavBar(
        benefitName,
        benefitId,
        benefitType
      );
    } else {
      this.props.parentHandlers.removeBenefit(benefitId, benefitType);
    }
  }

  render() {
    let benefitDetail = this.getBenefitDetail(this.props);
    this.validateFields(
      benefitDetail,
      this.state.touched,
      this.validationResult
    );
    let data = {
      props: this.props,
      benefitDetail: benefitDetail,
      benefitType: this.getBenefitType(),
      hasErr: this.validationResult.hasErr,
      errMsg: this.validationResult.errMsg,
      requirementModalDisplay: this.state.requirementModalDisplay,
      validationHandlers: {
        handleTouched: this.handleTouched
      },
      viewControls: {
        checkForm: this.checkForm,
        showModal: this.showModal,
        closeModal: this.closeModal
      }
    };
    return OtherClaimComponent(data);
  }
}

export default OtherClaim;
