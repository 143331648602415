import React from "react";
import { BENEFIT_TYPES, REGION } from "common/Constants";
import {
  MODAL_HEADER_STYLE,
  MODAL_REQUIRED_FILES_STYLE
} from "common/styles/styles";
import { Typography, Grid } from "@material-ui/core";
import { DISPLAY_MODAL_FOOTNOTE } from "common/components/CommonComponents";
import {
  getIndexCount,
  getClaimTitle,
  getCountForBenefit
} from "common/utils/CommonUtil";
import FileUploadConfirmationDetails from "common/fileUploadRequirements/FileUploadConfirmationDetails";

class SGFileUploadConfirmation extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const parentState = this.props.parentState;
    const parentHandlers = this.props.parentHandlers;
    const currentClaim = parentState.claim;
    const region = parentState.region;

    let flightDelayClaims =
      currentClaim.claimDetail[BENEFIT_TYPES.flightDelayClaim] || [];
    let lostBaggageClaims =
      currentClaim.claimDetail[BENEFIT_TYPES.lostBaggageClaim] || [];
    let tripCancellationClaims =
      currentClaim.claimDetail[BENEFIT_TYPES.tripCancellationClaim] || [];
    let medicalClaims =
      currentClaim.claimDetail[BENEFIT_TYPES.medicalClaim] || [];
    let travelMisConnectionClaim =
      currentClaim.claimDetail[BENEFIT_TYPES.travelMisConnectionClaim] || [];
    let otherClaims = currentClaim.claimDetail[BENEFIT_TYPES.otherClaim] || [];

    const items = [
      flightDelayClaims,
      lostBaggageClaims,
      medicalClaims,
      otherClaims,
      travelMisConnectionClaim,
      tripCancellationClaims
    ];

    const countResult = getIndexCount(currentClaim.claimDetail);
    return (
      <React.Fragment>
        <div style={MODAL_HEADER_STYLE}>
          <Typography variant="h4">Required documents confirmation</Typography>
        </div>
        <Typography variant="subtitle1">
          Please check the documents that have been uploaded.
        </Typography>
        <br />
        <Grid container style={MODAL_REQUIRED_FILES_STYLE}>
          {items.map(item => {
            return item.map((benefitDetail, index) => {
              return (
                <FileUploadConfirmationDetails
                  key={benefitDetail.benefitId}
                  parentHandlers={parentHandlers}
                  benefitId={benefitDetail.benefitId}
                  benefitType={benefitDetail.benefitType}
                  title={getClaimTitle(benefitDetail.benefitType)}
                  titleSuffix={getCountForBenefit(
                    index,
                    benefitDetail.benefitType,
                    countResult
                  )}
                  benefitDetail={benefitDetail}
                />
              );
            });
          })}
        </Grid>
        {region === REGION.HK ? DISPLAY_MODAL_FOOTNOTE() : null}
      </React.Fragment>
    );
  }
}

export default SGFileUploadConfirmation;
