import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { SUMMARY_FILE_UPLOAD_CONTAINER_STYLE } from "common/styles/styles";

class SummaryFileUploadComponent extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let uploadedFileDetails = this.props.detail || [];

    return (
      <React.Fragment>
        <Grid container style={SUMMARY_FILE_UPLOAD_CONTAINER_STYLE} spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Uploaded files:</Typography>
          </Grid>
          {uploadedFileDetails.map((item, index) => {
            return (
              <Grid item key={item.fileName + index} xs={12} sm={6}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.fileName}
                </a>
              </Grid>
            );
          })}
        </Grid>
      </React.Fragment>
    );
  }
}

export default SummaryFileUploadComponent;
