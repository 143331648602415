import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { SUMMARY_CONTAINER_STYLE } from "common/styles/styles";
import {
  SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT,
  SUMMARY_DISPLAY_DATE_VALUE_COMPONENT,
  SUMMARY_DISPLAY_TIME_VALUE_COMPONENT,
  SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT_FULL_LENGTH
} from "common/utils/SummaryUtil";

class SummaryTravelMisConnectionDetailComponent extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const detail = this.props.detail;

    return (
      <React.Fragment>
        <Grid container style={SUMMARY_CONTAINER_STYLE} spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Original Flight Details</Typography>
          </Grid>

          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Original Flight No.",
            detail.originalFlightNumber
          )}

          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Place of Departure",
            detail.originalPlaceOfDeparture
          )}

          {SUMMARY_DISPLAY_DATE_VALUE_COMPONENT(
            "Original Date of Travel",
            detail.originalDate
          )}

          {SUMMARY_DISPLAY_TIME_VALUE_COMPONENT(
            "Original Time of Travel",
            detail.originalTime
          )}

          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Transport Provider",
            detail.originalTransportProvider
          )}

          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT_FULL_LENGTH(
            "Reason(s) By Service Provider For the Cause of Delay",
            detail.reason
          )}

          {SUMMARY_DISPLAY_DATE_VALUE_COMPONENT(
            "Actual Arrival Date of Incoming Connecting Carrier",
            detail.actualArrivalDate
          )}

          {SUMMARY_DISPLAY_TIME_VALUE_COMPONENT(
            "Actual Arrival Time of Incoming Connecting Carrier",
            detail.actualArrivalTime
          )}

          <Grid item xs={12}>
            <Typography variant="h6">Rescheduled Flight Details</Typography>
          </Grid>

          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Actual Flight No.",
            detail.rescheduledFlightNumber
          )}

          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Place of Departure",
            detail.rescheduledPlaceOfDeparture
          )}

          {SUMMARY_DISPLAY_DATE_VALUE_COMPONENT(
            "Actual Arrival Date of incoming Carrier",
            detail.rescheduledDate
          )}

          {SUMMARY_DISPLAY_TIME_VALUE_COMPONENT(
            "Actual Arrival Time of incoming Carrier",
            detail.rescheduledTime
          )}

          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Transport Provider",
            detail.rescheduledTransportProvider
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

export default SummaryTravelMisConnectionDetailComponent;
