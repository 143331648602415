import React from "react";
import { View } from "@react-pdf/renderer";
import {
  pdfStyles,
  BENEFIT_SECTION_SUBTITLE,
  PDF_DISPLAY_LABEL_VALUE_COMPONENT,
  PDF_DISPLAY_DATE_COMPONENT,
  CLAIMANT_DETAILS_FOR_BENEFIT,
  CLAIM_DETAILS_SUB_SECTION,
  GET_CURRENCY_VALUE_STRING
} from "common/utils/PDFDocumentUtil";
import { BENEFIT_TYPES, getCurrency } from "common/Constants";
import { getClaimTitle, getCurrencyValue } from "common/utils/CommonUtil";

const PDFTripCancellationDetailComponent = props => {
  let title = getClaimTitle(BENEFIT_TYPES.tripCancellationClaim);
  let detail = props.detail;
  let suffix = props.suffix;
  const currency = getCurrency(props.region);

  return (
    <React.Fragment>
      {BENEFIT_SECTION_SUBTITLE(suffix, title)}
      {CLAIMANT_DETAILS_FOR_BENEFIT(
        detail.benefitId,
        props.claimants,
        props.region
      )}
      {CLAIM_DETAILS_SUB_SECTION("Claim details")}
      <View style={pdfStyles.container}>
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Reason(s) for cancelling your trip/cutting short your trip",
          detail.cancellationReason
        )}
        {PDF_DISPLAY_DATE_COMPONENT(
          "Date of event that led to cancellation/trip being cut short",
          detail.dateOfEvent
        )}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Name of person who suffered injury, illness or death",
          detail.nameOfIID
        )}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Deposit Amount",
          GET_CURRENCY_VALUE_STRING(
            currency,
            getCurrencyValue(detail.depositAmount)
          )
        )}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Refund Amount",
          GET_CURRENCY_VALUE_STRING(
            currency,
            getCurrencyValue(detail.refundAmount)
          )
        )}
        {detail.refundAmount === "0.00" ? (
          <React.Fragment>
            {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
              "Reason for no refund",
              detail.noRefundDescription
            )}
          </React.Fragment>
        ) : null}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Total amount claimed for trip cancellation",
          GET_CURRENCY_VALUE_STRING(
            currency,
            getCurrencyValue(detail.totalAmount)
          )
        )}
      </View>
    </React.Fragment>
  );
};

export default PDFTripCancellationDetailComponent;
