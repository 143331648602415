import { Box, Card } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import "App.css";
import theme from "common/theme/Theme";
import { CLAIM } from "common/data/defaultClaim";
import {
  NEW_CLAIMANT_INFORMATION,
  DESCRIPTION_OF_LOST_ITEMS,
  MULTIPLE_CONSULTATION
} from "common/data/defaultClaim";
import { Router } from "react-router-dom";
import {
  getBenefitObject,
  getTotalTripCancelAmount,
  IS_VALID_TEXT_INPUT
} from "common/utils/CommonUtil";
import {
  FORM_LINKS,
  BENEFIT_TYPES,
  BENEFIT_LIST,
  REGION,
  HIDE_COMPONENT,
  DISPLAY_COMPONENT,
  NAV_LINKS,
  RESET_TIMEOUT,
  RESET_TIMEOUT_DEBOUNCE_TIME,
  NAV_LINK_STATUS,
  APP_ROOT_ROUTES
} from "common/Constants";
import TimeOutModal from "common/components/TimeOutModalComponent";
import { MAIN_FORM_STYLE } from "common/styles/styles";
import NavBar from "components/navbar/NavBar";
import ClaimsAppBar from "components/appbar/AppBar";
import { DEFAULT_NAV_STATE } from "common/data/defaultNavItem";
import { AppRoutes } from "components/Routes";
import IdleTimer from "react-idle-timer";
import history from "components/History";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      claim: CLAIM(),
      mobileOpen: false,
      navItem: DEFAULT_NAV_STATE,
      region: REGION.SG,
      hideNavBarClass: DISPLAY_COMPONENT,
      showModal: false,
      isTimedOut: false
    };
    this.idleTimer = null;
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleReset = () => {
    this.setState({ showModal: false });
    this.handleUserTimeout();
  };

  handleDrawerToggle = e => {
    if (e) {
      this.setState(prevState => ({
        ...prevState,
        mobileOpen: !prevState.mobileOpen
      }));
    }
  };

  render() {
    const routeParentHandlers = {
      getParentHandlersWelcomePage: this.getParentHandlersWelcomePage,
      getParentHandlersAboutSelf: this.getParentHandlersAboutSelf,
      getParentHandlersReporter: this.getParentHandlersReporter,
      getParentHandlersBasicInformation: this.getParentHandlersBasicInformation,
      getParentHandlersSelectClaimType: this.getParentHandlersSelectClaimType,
      getParentHandlersFlightDelayClaim: this.getParentHandlersFlightDelayClaim,
      getParentHandlersTravelMisConnectionClaim: this
        .getParentHandlersTravelMisConnectionClaim,
      getParentHandlersLostBaggageClaim: this.getParentHandlersLostBaggageClaim,
      getParentHandlersTripCancellationClaim: this
        .getParentHandlersTripCancellationClaim,
      getParentHandlersAccidentClaim: this.getParentHandlersAccidentClaim,
      getParentHandlersAddAnotherClaim: this.getParentHandlersAddAnotherClaim,
      getParentHandlersClaimantInformation: this
        .getParentHandlersClaimantInformation,
      getParentHandlersFileUpload: this.getParentHandlersFileUpload,
      getParentHandlersSummaryPage: this.getParentHandlersSummaryPage,
      getParentHandlersMedicalClaim: this.getParentHandlersMedicalClaim,
      getParentHandlersOtherClaim: this.getParentHandlersOtherClaim,
      getParentHandlersPDPAPage: this.getParentHandlersPDPAPage
    };

    return (
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <ClaimsAppBar
            theme={theme}
            mobileNavBarToggle={this.handleDrawerToggle}
            hideNavBarClass={this.state.hideNavBarClass}
          />
          <NavBar
            theme={theme}
            mobileNavBarToggle={this.handleDrawerToggle}
            mobileOpen={this.state.mobileOpen}
            parentHandlers={this.getParentHandlersNavBar()}
            parentState={this.state}
          />
          <Box className="app-container" boxShadow={5}>
            <Card className="app-card-style" elevation9="true">
              <main style={MAIN_FORM_STYLE}>
                <div className="mt-2 pb-3">
                  <IdleTimer
                    ref={ref => {
                      this.idleTimer = ref;
                    }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={RESET_TIMEOUT_DEBOUNCE_TIME}
                    timeout={RESET_TIMEOUT}
                  />
                  {AppRoutes(this.state, routeParentHandlers)}
                  <TimeOutModal
                    showModal={this.state.showModal}
                    handleClose={this.handleClose}
                    handleReset={this.handleReset}
                  />
                </div>
              </main>
            </Card>
          </Box>
        </ThemeProvider>
      </Router>
    );
  }

  onAction = e => {
    if (this.state.isTimedOut) {
      this.setState({ isTimedOut: false });
    }
  };

  onActive = e => {
    if (this.state.isTimedOut) {
      this.setState({ isTimedOut: false });
    }
  };

  onIdle = e => {
    let currentPath = history && history.location && history.location.pathname;
    if (!APP_ROOT_ROUTES.includes(currentPath)) {
      this.setState({ showModal: true });
    }
  };

  handleUserTimeout = () => {
    if (history) {
      if (this.state.region === REGION.HK) {
        this.resetNavBarStatus();
        history.push(FORM_LINKS.defaultHKRoot);
      } else {
        this.resetNavBarStatus();
        history.push(FORM_LINKS.defaultSGRoot);
      }
    }
  };

  getParentHandlersNavBar = () => {
    return {};
  };

  getParentHandlersFileUpload = () => {
    return {
      addSuccessURLs: this.addSuccessURLs,
      removeSuccessURLs: this.removeSuccessURLs,
      handleRequirementChecklist: this.handleRequirementChecklist,
      handleClaimDirectoryUpdate: this.handleClaimDirectoryUpdate,
      handleNavBarStatus: this.handleNavBarStatus
    };
  };

  getParentHandlersClaimantInformation = () => {
    return {
      addNewClaimant: this.addNewClaimant,
      handleClaimantInformationDetailsUpdate: this
        .handleClaimantInformationDetailsUpdate,
      addBenefitIdToClaimant: this.addBenefitIdToClaimant,
      updateBenefitIdToClaimant: this.updateBenefitIdToClaimant,
      removeBenefitIdFromClaimant: this.removeBenefitIdFromClaimant,
      updatePolicyHolderGuid: this.updatePolicyHolderGuid,
      removePolicyHolderGuid: this.removePolicyHolderGuid,
      getClaimants: this.getClaimants,
      deleteClaimant: this.deleteClaimant,
      updateClaimantInformationFromModal: this
        .updateClaimantInformationFromModal,
      handleNavBarStatus: this.handleNavBarStatus
    };
  };

  getParentHandlersAddMoreClaimant = () => {
    return {
      handleAddMoreClaimant: this.handleAddMoreClaimant,
      addNewClaimant: this.addNewClaimant,
      addBenefitIdToClaimant: this.addBenefitIdToClaimant,
      updateBenefitIdToClaimant: this.updateBenefitIdToClaimant,
      getPolicyHolderId: this.getPolicyHolderId
    };
  };

  getParentHandlersAddAnotherClaim = () => {
    return {
      handleAddAnotherClaim: this.handleAddAnotherClaim
    };
  };

  getParentHandlersTripCurtailmentClaim = () => {
    return {
      handleTripCurtailmentClaim: this.handleTripCurtailmentClaim,
      handleTripCurtailmentClaimWithParams: this
        .handleTripCurtailmentClaimWithParams,
      handleBenefitDetailInNavBar: this.handleBenefitDetailInNavBar,
      removeBenefit: this.removeBenefit
    };
  };

  getParentHandlersTripCancellationClaim = () => {
    return {
      handleTripCancellationClaim: this.handleTripCancellationClaim,
      handleTripCancellationClaimWithParams: this
        .handleTripCancellationClaimWithParams,
      handleBenefitDetailInNavBar: this.handleBenefitDetailInNavBar,
      handleTravelMisConnectionClaim: this.handleTravelMisConnectionClaim,
      handleTravelMisConnectionClaimWithParams: this
        .handleTravelMisConnectionClaimWithParams,
      removeBenefit: this.removeBenefit
    };
  };

  getParentHandlersAccidentClaim = () => {
    return {
      handleAccidentClaim: this.handleAccidentClaim,
      handleBenefitDetailInNavBar: this.handleBenefitDetailInNavBar,
      handleAccidentClaimWithParams: this.handleAccidentClaimWithParams,
      removeBenefit: this.removeBenefit
    };
  };

  getParentHandlersMedicalClaim = () => {
    return {
      handleBenefitDetailInNavBar: this.handleBenefitDetailInNavBar,
      handleTravelMisConnectionClaim: this.handleTravelMisConnectionClaim,
      handleTravelMisConnectionClaimWithParams: this
        .handleTravelMisConnectionClaimWithParams,
      handleAddNewBenefit: this.handleAddNewBenefit,
      updateCurrentClaimType: this.updateCurrentClaimType,
      removeBenefit: this.removeBenefit,
      getMultipleConsultation: this.getMultipleConsultation,
      addNewMultipleConsultation: this.addNewMultipleConsultation,
      deleteMultipleConsultation: this.deleteMultipleConsultation,
      updateMultipleConsultationFromModal: this
        .updateMultipleConsultationFromModal
    };
  };

  getParentHandlersOtherClaim = () => {
    return {
      handleBenefitDetailInNavBar: this.handleBenefitDetailInNavBar,
      handleOtherClaim: this.handleOtherClaim,
      handleOtherClaimWithParams: this.handleOtherClaimWithParams,
      handleTravelMisConnectionClaim: this.handleTravelMisConnectionClaim,
      handleTravelMisConnectionClaimWithParams: this
        .handleTravelMisConnectionClaimWithParams,
      removeBenefit: this.removeBenefit
    };
  };

  getParentHandlersLostBaggageClaim = () => {
    return {
      handleLostBaggageClaim: this.handleLostBaggageClaim,
      handleBenefitDetailInNavBar: this.handleBenefitDetailInNavBar,
      handleLostBaggageClaimWithParams: this.handleLostBaggageClaimWithParams,
      handleTravelMisConnectionClaim: this.handleTravelMisConnectionClaim,
      handleTravelMisConnectionClaimWithParams: this
        .handleTravelMisConnectionClaimWithParams,
      addNewLostItem: this.addNewLostItem,
      accumulatedCurrencies: this.accumulatedCurrencies,
      getLostItems: this.getLostItems,
      updateLostItemFromModal: this.updateLostItemFromModal,
      deleteLostItem: this.deleteLostItem,

      handleLostBaggageClaimLostItemDescriptions: this
        .handleLostBaggageClaimLostItemDescriptions,
      handleLostBaggageClaimLostItemDescriptionsUpdate: this
        .handleLostBaggageClaimLostItemDescriptionsUpdate,
      handleLostBaggageClaimLostItemDescriptionsDelete: this
        .handleLostBaggageClaimLostItemDescriptionsDelete,
      removeBenefit: this.removeBenefit
    };
  };

  getParentHandlersFlightDelayClaim = () => {
    return {
      handleFlightDelayClaim: this.handleFlightDelayClaim,
      handleFlightDelayClaimWithParams: this.handleFlightDelayClaimWithParams,
      handleTravelMisConnectionClaim: this.handleTravelMisConnectionClaim,
      handleTravelMisConnectionClaimWithParams: this
        .handleTravelMisConnectionClaimWithParams,
      handleBenefitDetailInNavBar: this.handleBenefitDetailInNavBar,
      removeBenefit: this.removeBenefit
    };
  };

  getParentHandlersTravelMisConnectionClaim = () => {
    return {
      handleTravelMisConnectionClaim: this.handleTravelMisConnectionClaim,
      handleTravelMisConnectionClaimWithParams: this
        .handleTravelMisConnectionClaimWithParams,
      handleBenefitDetailInNavBar: this.handleBenefitDetailInNavBar,
      removeBenefit: this.removeBenefit
    };
  };

  getParentHandlersSelectClaimType = () => {
    return {
      handleSelectClaimType: this.handleSelectClaimType,
      handleFlightDelayAddClaim: this.handleFlightDelayAddClaim,
      handleLostBaggageAddClaim: this.handleLostBaggageAddClaim,
      handleAddNewBenefit: this.handleAddNewBenefit
    };
  };

  getParentHandlersAboutSelf = () => {
    return {
      handleAboutSelfStateUpdate: this.handleAboutSelfStateUpdate,
      handleClaimUpdateWithParams: this.handleClaimUpdateWithParams,
      handleAboutSelfSwitch: this.handleAboutSelfSwitch,
      handlePolicyHolderSelfInformation: this.handlePolicyHolderSelfInformation,
      handleClaimDirectoryUpdate: this.handleClaimDirectoryUpdate,
      updateSubmissionId: this.updateSubmissionId,
      handleNavBarStatus: this.handleNavBarStatus
    };
  };

  getParentHandlersReporter = () => {
    return {
      handleClaimUpdateWithParams: this.handleClaimUpdateWithParams,
      handleReporterStateUpdate: this.handleReporterStateUpdate,
      handleReporterStateUpdateWithParams: this
        .handleReporterStateUpdateWithParams,
      handleNavBarStatus: this.handleNavBarStatus
    };
  };

  getParentHandlersPDPAPage = () => {
    return {
      handlePDPAStateUpdate: this.handlePDPAStateUpdate,
      disableNavBar: this.disableNavBar,
      enableNavBar: this.enableNavBar,
      updateSubmissionTime: this.updateSubmissionTime
    };
  };

  getParentHandlersWelcomePage = () => {
    return {
      resetState: this.resetState,
      setRegion: this.setRegion,
      handleClaimDirectoryUpdate: this.handleClaimDirectoryUpdate,
      updateSubmissionId: this.updateSubmissionId,
      handleNavBarStatus: this.handleNavBarStatus
    };
  };

  getParentHandlersSummaryPage = () => {
    return {
      updateSubmissionId: this.updateSubmissionId,
      removeBenefit: this.removeBenefit,
      handleClaimDirectoryUpdate: this.handleClaimDirectoryUpdate
    };
  };

  getParentHandlersBasicInformation = () => {
    return {
      handleBasicInformationSwitchHandler: this
        .handleBasicInformationSwitchHandler,
      handleClaimUpdateWithParams: this.handleClaimUpdateWithParams,
      handleNavBarStatus: this.handleNavBarStatus
    };
  };

  updateSubmissionId = submissionId => {
    if (submissionId) {
      this.setState(prevState => ({
        claim: { ...prevState.claim, submissionId }
      }));
    }
  };

  updateSubmissionTime = submissionTime => {
    return new Promise(resolve => {
      if (submissionTime) {
        this.setState(
          prevState => ({
            claim: { ...prevState.claim, submissionTime }
          }),
          () => {
            resolve(this.state.claim);
          }
        );
      } else {
        resolve(this.state.claim);
      }
    });
  };

  addSuccessURLs = urlList => {
    if (urlList) {
      this.setState(prevState => ({
        claim: {
          ...prevState.claim,
          fileUpload: [...prevState.claim.fileUpload, ...urlList]
        }
      }));
    }
  };

  removeSuccessURLs = fileName => {
    if (fileName) {
      const updatedURLList = this.state.claim.fileUpload.filter(
        item => item.fileName !== fileName
      );
      this.setState(prevState => ({
        claim: {
          ...prevState.claim,
          fileUpload: updatedURLList
        }
      }));
    }
  };

  handleClaimantInformationDetailsUpdate = (event, claimantId) => {
    let targetParam = event.target.name;
    let targetValue = event.target.value;
    this.updateClaimantInformationState(targetParam, targetValue, claimantId);
  };

  updateBenefitIdToClaimant = (claimantId, benefitId, benefitType) => {
    if (benefitType === BENEFIT_TYPES.lostBaggageClaim) {
      let claimantInformation = this.state.claim.claimantInformation || [];
      let relatedClaimants = claimantInformation.filter(item =>
        item.relatedClaim.includes(benefitId)
      );
      relatedClaimants.forEach(item => {
        if (item.claimantId !== claimantId) {
          this.removeBenefitIdFromClaimant(item.claimantId, benefitId);
        }
      });
    }
    this.addBenefitIdToClaimant(claimantId, benefitId);
  };

  addBenefitIdToClaimant = (claimantId, benefitId) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimantInformation: prevState.claim.claimantInformation.map(
          claimant => {
            if (claimant.claimantId === claimantId) {
              let relatedClaim = claimant.relatedClaim;
              if (!relatedClaim.includes(benefitId)) {
                relatedClaim = [...relatedClaim, benefitId];
                claimant.relatedClaim = relatedClaim;
              }
            }
            return claimant;
          }
        )
      }
    }));
  };

  setRegion = region => {
    this.setState({ region });
  };

  removeBenefit = (benefitId, benefitType) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          [benefitType]: this.state.claim.claimDetail[benefitType].filter(
            item => item.benefitId !== benefitId
          )
        },
        claimantInformation: prevState.claim.claimantInformation.map(
          claimant => {
            claimant.relatedClaim = claimant.relatedClaim.filter(
              item => item !== benefitId
            );
            return claimant;
          }
        ),
        navItem: prevState.navItem.map(item => {
          if (item.name === "Claims") {
            let updatedSubMenu = item.subMenu.filter(
              sub => sub.benefitId !== benefitId
            );
            let newSubMenu = [].concat(item.subMenu[0]);
            let count = parseInt(1);
            BENEFIT_LIST.forEach(benefitType => {
              updatedSubMenu.forEach(item => {
                if (item.benefitType === benefitType) {
                  item.text = count + ". " + item.name;
                  newSubMenu.push(item);
                  count++;
                }
              });
            });
            item.subMenu = newSubMenu;
          }
          return item;
        })
      }
    }));
  };

  removeBenefitIdFromClaimant = (claimantId, benefitId) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimantInformation: prevState.claim.claimantInformation.map(
          claimant => {
            if (claimant.claimantId === claimantId) {
              let relatedClaim = claimant.relatedClaim.filter(
                item => item !== benefitId
              );
              claimant.relatedClaim = relatedClaim;
            }
            return claimant;
          }
        )
      }
    }));
  };

  updatePolicyHolderGuid = (claimantIndex, currentClaimGUID) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        policyHolderId: currentClaimGUID
      }
    }));
  };

  disableNavBar = () => {
    this.setState({ hideNavBarClass: HIDE_COMPONENT });
  };

  enableNavBar = () => {
    this.setState({ hideNavBarClass: DISPLAY_COMPONENT });
  };

  // TODO: No provision to remove/update PolicyholderGuid -> Business logic
  removePolicyHolderGuid = currentClaimGUID => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        policyHolderId: ""
      }
    }));
  };

  updateClaimantInformationFromModal = (claimantDetail, claimantId, next) => {
    this.setState(
      prevState => ({
        claim: {
          ...prevState.claim,
          claimantInformation: prevState.claim.claimantInformation.map(
            claimant => {
              if (claimant.claimantId === claimantId) {
                claimant.surname = claimantDetail.surname;
                claimant.name = claimantDetail.name;
                claimant.email = claimantDetail.email;
                claimant.phone = claimantDetail.phone;
                claimant.relationToPolicyHolder =
                  claimantDetail.relationToPolicyHolder;
                claimant.sgIDType = claimantDetail.sgIDType;
                claimant.sgIDNumber = claimantDetail.sgIDNumber;
                claimant.hkIDType = claimantDetail.hkIDType;
                claimant.hkIDNumber = claimantDetail.hkIDNumber;
              }
              return claimant;
            }
          )
        }
      }),
      next
    );
  };

  updateClaimantInformationState = (targetParam, targetValue, claimantId) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimantInformation: prevState.claim.claimantInformation.map(
          claimant => {
            if (claimant.claimantId === claimantId) {
              claimant[targetParam] = targetValue;
            }
            return claimant;
          }
        )
      }
    }));
  };

  deleteClaimant = (claimantId, next) => {
    this.setState(
      prevState => ({
        claim: {
          ...prevState.claim,
          claimantInformation: [].concat(
            prevState.claim.claimantInformation.filter(
              claimant => claimant.claimantId !== claimantId
            )
          )
        }
      }),
      next
    );
  };

  addNewClaimant = next => {
    this.setState(
      prevState => ({
        claim: {
          ...prevState.claim,
          claimantInformation: [
            ...prevState.claim.claimantInformation,
            NEW_CLAIMANT_INFORMATION()
          ]
        }
      }),
      next
    );
  };

  getClaimants = () => {
    return this.state.claim.claimantInformation;
  };

  getNavBarBenefitSubMenu = () => {
    let navBarBenefits = this.state.navItem.filter(
      item => item.name === "Claims"
    );
    return navBarBenefits[0].subMenu;
  };

  currentNavItemStatus = id => {
    let navItem = this.state.navItem.find(value => value.id === id);
    let status = false;
    if (navItem) {
      status = navItem.isDisabled;
    }
    return status;
  };

  resetNavBarStatus = () => {
    this.handleNavBarStatus(
      NAV_LINKS.reporterInformation.id,
      NAV_LINK_STATUS.disable
    );
  };

  handleNavBarStatus = (id, status) => {
    setTimeout(() => {
      if (this.currentNavItemStatus(id) !== status) {
        this.setState(prevState => ({
          navItem: prevState.navItem.map(item => {
            if (status) {
              if (item.id >= id) {
                item.isDisabled = status;
              }
            } else {
              if (item.id === id) {
                item.isDisabled = status;
              }
            }
            return item;
          })
        }));
      }
    }, 50);
  };

  handleBenefitDetailInNavBar = (benefitName, benefitId, benefitType) => {
    let navBarBenefitSubMenuItems = this.getNavBarBenefitSubMenu();
    if (
      !navBarBenefitSubMenuItems.some(
        subMenuItem => subMenuItem.benefitId === benefitId
      )
    ) {
      // add new BenefitToNavBar
      let newBenefit = this.getNewNavBarBenefitItem(
        benefitName,
        benefitType,
        benefitId,
        []
      );
      this.addNewNavBarBenefit(newBenefit);
    }
  };

  updateNavBarBenefitDetails = (
    benefitName,
    benefitType,
    benefitId,
    relatedClaimantNames
  ) => {
    this.setState(prevState => ({
      navItem: prevState.navItem.map(item => {
        if (item.name === "Claims") {
          let updatedSubMenu = item.subMenu.map(subItem => {
            if (subItem.benefitId === benefitId) {
              subItem.name = benefitName;
              subItem.text = benefitName;
              subItem.subMenu = relatedClaimantNames;
              subItem.hasSubMenu = !!relatedClaimantNames.length;
              subItem.isDisabled = false;
            }
            return subItem;
          });
          item.subMenu = updatedSubMenu;
        }
        return item;
      })
    }));
  };

  getClaimantsRelatedTobenefitId = benefitId => {
    let claimants = this.state.claim.claimantInformation || [];
    let relatedClaimants = claimants.filter(
      claimant => claimant.relatedClaim.indexOf(benefitId) >= 0
    );
    return relatedClaimants;
  };

  addNewNavBarBenefit = newBenefit => {
    this.setState(prevState => ({
      navItem: prevState.navItem.map((item, index) => {
        if (item.name === "Claims") {
          item.subMenu.push(newBenefit);
          let newSubMenu = [].concat(item.subMenu[0]);
          let count = parseInt(1);
          BENEFIT_LIST.forEach(benefitType => {
            item.subMenu.forEach(item => {
              if (item.benefitType === benefitType) {
                item.text = count + ". " + item.name;
                newSubMenu.push(item);
                count++;
              }
            });
          });
          item.subMenu = newSubMenu;
        }
        return item;
      })
    }));
  };

  getNewNavBarBenefitItem = (
    benefitName,
    benefitType,
    benefitId,
    claimants
  ) => {
    return {
      link: this.getBenefitUrlForBenefitType(benefitType),
      id: "",
      name: benefitName,
      text: benefitName,
      benefitId: benefitId,
      benefitType: benefitType,
      hasSubMenu: true,
      subMenu: claimants
    };
  };

  getBenefitUrlForBenefitType = benefitType => {
    return FORM_LINKS[benefitType];
  };

  handleAddNewBenefit = (benefitType, next) => {
    const region = this.state.region;
    this.setState(
      prevState => ({
        claim: {
          ...prevState.claim,
          claimDetail: {
            ...prevState.claim.claimDetail,
            [benefitType]: [
              ...prevState.claim.claimDetail[benefitType],
              getBenefitObject(benefitType, region)
            ]
          }
        }
      }),
      next
    );
  };

  handleAddAnotherClaim = event => {
    let targetValue = event.target.value;
    this.updateAddAnotherClaim("addAnotherClaim", targetValue);
  };

  handleAddMoreClaimant = event => {
    let targetValue = event.target.value;
    this.updateAddMoreClaimant("addMoreClaimant", targetValue);
  };

  handleTripCancellationClaim = (benefitType, event, benefitId) => {
    let targetParam = event.target.name;
    let targetValue = event.target.value;
    this.handleTripCancellationClaimWithParams(
      benefitType,
      targetParam,
      targetValue,
      benefitId
    );
  };

  handleTripCancellationClaimWithParams = (
    benefitType,
    targetParam,
    targetValue,
    benefitId
  ) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          [benefitType]: prevState.claim.claimDetail[benefitType].map(item => {
            if (benefitId === item.benefitId) {
              item[targetParam] = targetValue;
              if (
                targetParam === "depositAmount" ||
                targetParam === "refundAmount"
              )
                item.totalAmount = getTotalTripCancelAmount(
                  item.depositAmount,
                  item.refundAmount
                );
            }
            return item;
          })
        }
      }
    }));
  };

  getMultipleConsultation = (benefitId, modalType) => {
    let items = this.state.claim.claimDetail.medicalClaim.filter(
      item => item.benefitId === benefitId
    )[0][modalType];
    return items;
  };

  addNewMultipleConsultation = (benefitId, modalType, next) => {
    this.setState(
      prevState => ({
        claim: {
          ...prevState.claim,
          claimDetail: {
            ...prevState.claim.claimDetail,
            medicalClaim: prevState.claim.claimDetail.medicalClaim.map(
              medical => {
                if (benefitId === medical.benefitId) {
                  medical[modalType].push(MULTIPLE_CONSULTATION());
                }
                return medical;
              }
            )
          }
        }
      }),
      next
    );
  };

  deleteMultipleConsultation = (benefitId, itemId, modalType) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          medicalClaim: prevState.claim.claimDetail.medicalClaim.map(
            medicalClaim => {
              if (medicalClaim.benefitId === benefitId) {
                medicalClaim[modalType] = [].concat(
                  medicalClaim[modalType].filter(
                    item => item.multipleConsultationId !== itemId
                  )
                );
              }
              return medicalClaim;
            }
          )
        }
      }
    }));
  };

  updateMultipleConsultationFromModal = (
    itemId,
    newItem,
    benefitId,
    modalType,
    next
  ) => {
    this.setState(
      prevState => ({
        claim: {
          ...prevState.claim,
          claimDetail: {
            ...prevState.claim.claimDetail,
            medicalClaim: prevState.claim.claimDetail.medicalClaim.map(
              medicalClaim => {
                if (medicalClaim.benefitId === benefitId) {
                  let items = medicalClaim[modalType] || [];
                  items.map(detail => {
                    if (itemId === detail.multipleConsultationId) {
                      detail.dateOfConsultation = newItem.dateOfConsultation;
                      detail.amountClaimed = newItem.amountClaimed;
                    }
                    return detail;
                  });
                }
                return medicalClaim;
              }
            )
          }
        }
      }),
      next
    );
  };

  handleMedicalClaim = (event, benefitId) => {
    let targetParam = event.target.name;
    let targetValue = event.target.value;
    this.handleMedicalClaimWithParams(targetParam, targetValue, benefitId);
  };

  handleMedicalClaimWithParams = (targetParam, targetValue, benefitId) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          medicalClaim: prevState.claim.claimDetail.medicalClaim.map(item => {
            if (benefitId === item.benefitId) {
              item[targetParam] = targetValue;
            }
            return item;
          })
        }
      }
    }));
  };

  handleRequirementChecklist = (
    benefitId,
    benefitType,
    requirementId,
    value
  ) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          [benefitType]: prevState.claim.claimDetail[benefitType].map(item => {
            if (item.benefitId === benefitId) {
              let fileRequirements = item.fileRequirements.map(requirement => {
                if (requirement.id === requirementId) {
                  requirement.uploaded = value;
                }
                return requirement;
              });
              item.fileRequirements = fileRequirements;
            }
            return item;
          })
        }
      }
    }));
  };

  handleOtherClaim = (event, benefitId) => {
    let targetParam = event.target.name;
    let targetValue = event.target.value;
    this.handleOtherClaimWithParams(targetParam, targetValue, benefitId);
  };

  handleOtherClaimWithParams = (targetParam, targetValue, benefitId) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          otherClaim: prevState.claim.claimDetail.otherClaim.map(item => {
            if (benefitId === item.benefitId) {
              item[targetParam] = targetValue;
            }
            return item;
          })
        }
      }
    }));
  };

  handleAccidentClaim = (event, benefitId) => {
    let targetParam = event.target.name;
    let targetValue = event.target.value;
    this.handleAccidentClaimWithParams(targetParam, targetValue, benefitId);
  };

  handleAccidentClaimWithParams = (targetParam, targetValue, benefitId) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          accidentClaim: prevState.claim.claimDetail.accidentClaim.map(item => {
            if (benefitId === item.benefitId) {
              item[targetParam] = targetValue;
            }
            return item;
          })
        }
      }
    }));
  };

  handleTravelMisConnectionClaim = (currentBenefitType, event, benefitId) => {
    let targetParam = event.target.name;
    let targetValue = event.target.value;
    this.handleTravelMisConnectionClaimWithParams(
      currentBenefitType,
      targetParam,
      targetValue,
      benefitId
    );
  };

  handleTravelMisConnectionClaimWithParams = (
    benefitType,
    targetParam,
    targetValue,
    benefitId
  ) => {
    if (IS_VALID_TEXT_INPUT(targetValue)) {
      this.setState(prevState => ({
        claim: {
          ...prevState.claim,
          claimDetail: {
            ...prevState.claim.claimDetail,
            [benefitType]: prevState.claim.claimDetail[benefitType].map(
              item => {
                if (benefitId === item.benefitId) {
                  item[targetParam] = targetValue;
                }
                return item;
              }
            )
          }
        }
      }));
    }
  };

  addNewLostItem = (benefitId, next) => {
    this.setState(
      prevState => ({
        claim: {
          ...prevState.claim,
          claimDetail: {
            ...prevState.claim.claimDetail,
            lostBaggageClaim: prevState.claim.claimDetail.lostBaggageClaim.map(
              item => {
                if (benefitId === item.benefitId) {
                  item.descriptionOfLostItems.push(DESCRIPTION_OF_LOST_ITEMS());
                }
                return item;
              }
            )
          }
        }
      }),
      next
    );
  };

  updateLostItemFromModal = (itemId, newItem, benefitId, next) => {
    this.setState(
      prevState => ({
        claim: {
          ...prevState.claim,
          claimDetail: {
            ...prevState.claim.claimDetail,
            lostBaggageClaim: prevState.claim.claimDetail.lostBaggageClaim.map(
              lostBaggage => {
                if (lostBaggage.benefitId === benefitId) {
                  let lostItems = lostBaggage.descriptionOfLostItems || [];
                  lostItems.map(item => {
                    if (item.lostItemId === itemId) {
                      item.description = newItem.description;
                      item.makeModel = newItem.makeModel;
                      item.dateOfPurchase = newItem.dateOfPurchase;
                      item.purchaseCurrency = newItem.purchaseCurrency;
                      item.purchasePrice = newItem.purchasePrice;
                      item.amountClaimed = newItem.amountClaimed;
                      lostBaggage.totalClaimForLoss = this.getTotalLossItemClaimValue(
                        lostBaggage
                      );
                    }
                    return item;
                  });
                  lostBaggage.accumulatedCurrencies = this.getAccumulatedCurrenciesValue(
                    lostBaggage
                  );
                }
                return lostBaggage;
              }
            )
          }
        }
      }),
      next
    );
  };

  deleteLostItem = (benefitId, lostItemId) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          lostBaggageClaim: prevState.claim.claimDetail.lostBaggageClaim.map(
            lostBaggage => {
              if (lostBaggage.benefitId === benefitId) {
                lostBaggage.descriptionOfLostItems = [].concat(
                  lostBaggage.descriptionOfLostItems.filter(
                    item => item.lostItemId !== lostItemId
                  )
                );
                lostBaggage.totalClaimForLoss = this.getTotalLossItemClaimValue(
                  lostBaggage
                );
              }
              lostBaggage.accumulatedCurrencies = this.getAccumulatedCurrenciesValue(
                lostBaggage
              );
              return lostBaggage;
            }
          )
        }
      }
    }));
  };

  getLostItems = benefitId => {
    let lostItems = this.state.claim.claimDetail.lostBaggageClaim.filter(
      item => item.benefitId === benefitId
    )[0].descriptionOfLostItems;
    return lostItems;
  };

  handleLostBaggageClaim = (event, benefitId) => {
    let targetParam = event.target.name;
    let targetValue = event.target.value;
    this.handleLostBaggageClaimWithParams(targetParam, targetValue, benefitId);
  };

  handleLostBaggageClaimWithParams = (targetParam, targetValue, benefitId) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          lostBaggageClaim: prevState.claim.claimDetail.lostBaggageClaim.map(
            item => {
              if (benefitId === item.benefitId) {
                item[targetParam] = targetValue;
              }
              return item;
            }
          )
        }
      }
    }));
  };

  getOverallLossClaimValue = (totalLossItemClaimValue, amountInSGD) => {
    if (!amountInSGD) {
      amountInSGD = 0.0;
    }
    return (
      "" +
      parseFloat(
        parseFloat(totalLossItemClaimValue) + parseFloat(amountInSGD)
      ).toFixed(2)
    );
  };

  getTotalLossItemClaimValue = benefitDetail => {
    let totalClaimForLoss = 0.0;
    let claimsAmounts = benefitDetail.descriptionOfLostItems.map(item =>
      parseFloat(item.amountClaimed).toFixed(2)
    );
    if (claimsAmounts.length) {
      totalClaimForLoss = claimsAmounts.reduce(
        (prev, current) => parseFloat(prev) + parseFloat(current)
      );
    }
    totalClaimForLoss = "" + parseFloat(totalClaimForLoss).toFixed(2);
    return "" + totalClaimForLoss;
  };

  getAccumulatedCurrenciesValue = benefitDetail => {
    let totalClaim = [];
    benefitDetail.descriptionOfLostItems.forEach(item => {
      const index = totalClaim.findIndex(
        currencyValuePair =>
          currencyValuePair.currency === item.purchaseCurrency
      );

      if (index === -1) {
        totalClaim.push({
          currency: item.purchaseCurrency,
          totalValue: parseFloat(item.amountClaimed).toFixed(2)
        });
      } else {
        let newTotal = (
          parseFloat(totalClaim[index].totalValue) +
          parseFloat(item.amountClaimed)
        ).toFixed(2);
        totalClaim[index] = {
          currency: item.purchaseCurrency,
          totalValue: newTotal
        };
      }
    });
    return totalClaim;
  };

  handleLostBaggageClaimLostItemDescriptionsDelete = (oldItem, benefitId) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          lostBaggageClaim: prevState.claim.claimDetail.lostBaggageClaim.map(
            item => {
              if (benefitId === item.benefitId) {
                const index = item.descriptionOfLostItems.indexOf(oldItem);
                item.descriptionOfLostItems.splice(index, 1);
                item.totalClaimForLoss = this.getTotalLossItemClaimValue(item);
                item.totalOverallClaim = this.getOverallLossClaimValue(
                  item.totalClaimForLoss,
                  item.amountInSGD
                );
              }
              return item;
            }
          )
        }
      }
    }));
  };

  handleLostBaggageClaimLostItemDescriptionsUpdate = (
    newItem,
    oldItem,
    benefitId
  ) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          lostBaggageClaim: prevState.claim.claimDetail.lostBaggageClaim.map(
            item => {
              if (benefitId === item.benefitId) {
                const index = item.descriptionOfLostItems.indexOf(oldItem);
                item.descriptionOfLostItems[index] = newItem;
                item.totalClaimForLoss = this.getTotalLossItemClaimValue(item);
                item.totalOverallClaim = this.getOverallLossClaimValue(
                  item.totalClaimForLoss,
                  item.amountInSGD
                );
              }
              return item;
            }
          )
        }
      }
    }));
  };

  handleLostBaggageClaimLostItemDescriptions = (newItem, benefitId) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          lostBaggageClaim: prevState.claim.claimDetail.lostBaggageClaim.map(
            item => {
              if (benefitId === item.benefitId) {
                item.descriptionOfLostItems.push(newItem);
                item.totalClaimForLoss = this.getTotalLossItemClaimValue(item);
                item.totalOverallClaim = this.getOverallLossClaimValue(
                  item.totalClaimForLoss,
                  item.amountInSGD
                );
              }
              return item;
            }
          )
        }
      }
    }));
  };

  handleFlightDelayClaim = (currentBenefitType, event, benefitId) => {
    let targetParam = event.target.name;
    let targetValue = event.target.value;
    this.handleFlightDelayClaimWithParams(
      currentBenefitType,
      targetParam,
      targetValue,
      benefitId
    );
  };

  handleFlightDelayClaimWithParams = (
    benefitType,
    targetParam,
    targetValue,
    benefitId
  ) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDetail: {
          ...prevState.claim.claimDetail,
          [benefitType]: prevState.claim.claimDetail[benefitType].map(item => {
            if (benefitId === item.benefitId) {
              item[targetParam] = targetValue;
            }
            return item;
          })
        }
      }
    }));
  };

  handleReporterStateUpdate = e => {
    let targetParam = e.target.name;
    let targetValue = e.target.value;
    this.handleReporterStateUpdateWithParams(targetParam, targetValue);
  };

  handleReporterStateUpdateWithParams = (targetParam, targetValue) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        reporter: {
          ...prevState.claim.reporter,
          [targetParam]: targetValue
        }
      }
    }));
  };

  handlePDPAStateUpdate = (targetParam, targetValue) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        pdpa: {
          ...prevState.claim.pdpa,
          [targetParam]: targetValue
        }
      }
    }));
  };

  handleClaimDirectoryUpdate = directory => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimDirectory: directory
      }
    }));
  };

  handleSelectClaimType = selectedClaimType => {
    this.updateCurrentClaimType(selectedClaimType);
  };

  handlePolicyHolderSelfInformation = () => {
    this.copySelfToClaimantInformation();
  };

  handleAboutSelfStateUpdate = event => {
    let targetParam = event.target.name;
    let targetValue = event.target.value;
    this.updateAboutSelfState(targetParam, targetValue);
  };

  handleBasicInformationStateUpdate = event => {
    let targetParam = event.target.name;
    let targetValue = event.target.value;
    this.updateBasicInformationState(targetParam, targetValue);
  };

  handleBasicInformationSwitchHandler = name => event => {
    let checked = event.target.checked;
    this.updateBasicInformationState(name, checked);
  };

  handleBasicInformationFromDateUpdate = selectedDate => {
    this.updateBasicInformationState("travelDateFrom", selectedDate);
  };

  handleBasicInformationDateTillUpdate = selectedDate => {
    this.updateBasicInformationState("travelDateTill", selectedDate);
  };

  handleBasicInformationHistoricalClaimDateUpdate = selectedDate => {
    this.updateBasicInformationState("historicalClaimDateOfLoss", selectedDate);
  };

  resetState = () => {
    this.setState(() => ({
      claim: CLAIM(),
      navItem: DEFAULT_NAV_STATE,
      mobileOpen: false,
      showModal: false,
      isTimedOut: false
    }));

    this.setState(prevState => ({
      navItem: prevState.navItem.map(value => {
        if (value.name === "Claims") {
          value.subMenu = [
            {
              link: FORM_LINKS.selectClaimType,
              id: "",
              name: "Add New Claim",
              text: "Add New Claim",
              benefitId: "",
              benefitType: "",
              hasSubMenu: false,
              subMenu: []
            }
          ];
        }
        return value;
      })
    }));
  };

  updateBasicInformationState = (targetParam, targetValue) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        basicInformation: {
          ...prevState.claim.basicInformation,
          [targetParam]: targetValue
        }
      }
    }));
  };

  updateAboutSelfState = (targetParam, targetValue) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        aboutSelf: {
          ...prevState.claim.aboutSelf,
          [targetParam]: targetValue
        }
      }
    }));
  };

  handleClaimUpdateWithParams = (form, targetParam, targetValue) => {
    if (IS_VALID_TEXT_INPUT(targetValue)) {
      this.setState(prevState => ({
        claim: {
          ...prevState.claim,
          [form]: {
            ...prevState.claim[form],
            [targetParam]: targetValue
          }
        }
      }));
    }
  };

  copySelfToClaimantInformation = () => {
    let selfInformation = this.state.claim.aboutSelf;
    let selfInformationId = this.state.claim.aboutSelf.claimantId;
    let claimants = this.state.claim.claimantInformation || [];
    if (
      !claimants.some(claimant => claimant.claimantId === selfInformationId)
    ) {
      this.setState(prevState => ({
        claim: {
          ...prevState.claim,
          claimantInformation: [
            selfInformation,
            ...prevState.claim.claimantInformation
          ],
          policyHolderId: selfInformationId
        }
      }));
    }
  };

  removeSelfFromClaimantInformation = () => {
    let selfInformationId = this.state.claim.aboutSelf.claimantId;
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        claimantInformation: prevState.claim.claimantInformation.filter(
          item => item.claimantId !== selfInformationId
        ),
        policyHolderId: ""
      }
    }));
  };

  getPolicyHolderId = () => this.state.claim.policyHolderId;

  updateCurrentClaimType = targetValue => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        currentClaimType: targetValue
      }
    }));
  };

  updateAddMoreClaimant = (targetParam, targetValue) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        addMoreClaimant: {
          ...prevState.claim.addMoreClaimant,
          [targetParam]: targetValue
        }
      }
    }));
  };

  updateAddAnotherClaim = (targetParam, targetValue) => {
    this.setState(prevState => ({
      claim: {
        ...prevState.claim,
        addAnotherClaim: {
          ...prevState.claim.addAnotherClaim,
          [targetParam]: targetValue
        }
      }
    }));
  };
}

export default App;
