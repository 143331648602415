import React from "react";
import { View } from "@react-pdf/renderer";
import {
  pdfStyles,
  BENEFIT_SECTION_SUBTITLE,
  PDF_DISPLAY_DATE_COMPONENT,
  PDF_DISPLAY_TIME_COMPONENT,
  PDF_DISPLAY_LABEL_VALUE_COMPONENT
} from "common/utils/PDFDocumentUtil";

const PDFAccidentDetailComponent = (detail, index, suffix) => {
  let title = "Accident Claim ";

  return (
    <React.Fragment>
      {BENEFIT_SECTION_SUBTITLE(suffix, title)}
      <View style={pdfStyles.container}>
        {PDF_DISPLAY_DATE_COMPONENT("Date of Accident", detail.dateOfAccident)}
        {PDF_DISPLAY_TIME_COMPONENT("Place of Accident", detail.timeOfAccident)}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Place of Accident",
          detail.placeOfAccident
        )}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Description",
          detail.descriptionOfAccident
        )}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Description of injuries sustained",
          detail.injuriesInAccident
        )}
        {PDF_DISPLAY_DATE_COMPONENT(
          "Date of Consultation",
          detail.dateOfConsultationForAccident
        )}
      </View>
    </React.Fragment>
  );
};

export default PDFAccidentDetailComponent;
