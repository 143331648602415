import React from "react";
import MedicalClaimComponent from "components/forms/benefits/medicalClaim/MedicalClaimComponent";
import { BENEFIT_TYPES, FIELD_TYPE } from "common/Constants";
import {
  getBenefitDetailFromProps,
  getLastBenefitIndex,
  IS_EMPTY_FIELD_CHECK,
  HAS_ERROR,
  DOES_CURRENT_FIELD_HAVE_ERROR,
  IS_CURRENT_FIELD_VALID_CHECK,
  getClaimTitle
} from "common/utils/CommonUtil";

class MedicalClaim extends React.Component {
  constructor() {
    super();
    this.state = {
      touchedModal: {
        dateOfConsultation: false,
        amountClaimed: false
      },
      modalOpen: false,
      selectedItemId: "",
      modalDetail: {
        dateOfConsultation: null,
        amountClaimed: ""
      },
      touched: {
        dateOfAccident: false,
        timeOfAccident: false,
        placeOfAccident: false,
        descriptionOfAccident: false,
        injuriesInAccident: false,
        typeOfSymptom: false,
        dateOfSymptom: false,
        historicalClaimConsultationDetails: false
      },
      requirementModalDisplay: false
    };
  }

  updateModal = (targetParam, targetValue) => {
    this.setState(prevState => ({
      modalDetail: {
        ...prevState.modalDetail,
        [targetParam]: targetValue
      }
    }));
  };

  handleOpenModal = (e, id, detail) => {
    if (e && id && detail) {
      this.setState({
        modalOpen: true,
        selectedItemId: id,
        modalDetail: {
          dateOfConsultation: detail.dateOfConsultation,
          amountClaimed: detail.amountClaimed
        }
      });
    }
  };

  handleTouchedModal = paramName => {
    if (!this.state.touchedModal[paramName]) {
      this.setState(prevState => ({
        touchedModal: {
          ...prevState.touchedModal,
          [paramName]: true
        }
      }));
    }
  };
  modalIsEmpty = item => {
    return !(item.dateOfConsultation || item.amountClaimed);
  };

  multipleConsultationIsEmpty = itemDetail => {
    return !(
      itemDetail &&
      itemDetail.dateOfConsultation &&
      itemDetail.amountClaimed
    );
  };

  handleCloseModal = (e, parentHandlers, benefitId, modalType) => {
    if (e) {
      if (parentHandlers && benefitId && modalType) {
        let items = parentHandlers.getMultipleConsultation(
          benefitId,
          modalType
        );
        let latestItemIndex = items.length - 1;
        let itemDetail = items[latestItemIndex];
        let itemId = itemDetail.multipleConsultationId;
        if (this.multipleConsultationIsEmpty(itemDetail)) {
          parentHandlers.deleteMultipleConsultation(
            benefitId,
            itemId,
            modalType
          );
        }
      }
    }
    this.setState({
      modalOpen: false,
      selectedItemId: "",
      modalDetail: this.resetModal(),
      touchedModal: this.resetTouchedModal()
    });
  };

  handleSaveAndCloseModal = (e, parentHandlers, benefitId, modalType) => {
    if (e) {
      parentHandlers.updateMultipleConsultationFromModal(
        this.state.selectedItemId,
        this.state.modalDetail,
        benefitId,
        modalType,
        () => {
          this.handleCloseModal();
        }
      );
    }
  };

  resetModal = () => ({
    dateOfConsultation: null,
    amountClaimed: ""
  });

  resetTouchedModal = () => ({
    dateOfConsultation: false,
    amountClaimed: false
  });

  showModal = () => {
    this.setState({ requirementModalDisplay: true });
  };

  closeModal = () => {
    this.setState({ requirementModalDisplay: false });
  };

  handleTouched = e => {
    let targetParam = e.target.name;
    if (!this.state.touched[targetParam]) {
      this.setState(prevState => ({
        touched: {
          ...prevState.touched,
          [targetParam]: true
        }
      }));
    }
  };

  checkModal = detail => {
    let amountClaimedVal = DOES_CURRENT_FIELD_HAVE_ERROR(
      detail.amountClaimed,
      true,
      FIELD_TYPE.currency
    );
    let dateVal = DOES_CURRENT_FIELD_HAVE_ERROR(
      detail.dateOfConsultation,
      true,
      FIELD_TYPE.date
    );
    let result = amountClaimedVal || dateVal;
    return result;
  };

  checkForm = (hasError, currentFieldValues) => {
    if (currentFieldValues.isAccidentRelated) {
      if (
        !!currentFieldValues.dateOfAccident &&
        !!currentFieldValues.timeOfAccident &&
        !!currentFieldValues.placeOfAccident &&
        !!currentFieldValues.descriptionOfAccident &&
        !!currentFieldValues.injuriesInAccident &&
        !!currentFieldValues.multipleConsultationAccident &&
        currentFieldValues.multipleConsultationAccident.length
      ) {
        return (
          HAS_ERROR(hasError) ||
          this.checkFormHistoricalDetails(currentFieldValues, hasError)
        );
      } else {
        return true;
      }
    }
    if (!currentFieldValues.isAccidentRelated) {
      if (
        !!currentFieldValues.typeOfSymptom &&
        !!currentFieldValues.dateOfSymptom &&
        !!currentFieldValues.multipleConsultationMedical &&
        !!currentFieldValues.multipleConsultationMedical.length
      ) {
        return (
          HAS_ERROR(hasError) ||
          this.checkFormHistoricalDetails(currentFieldValues, hasError)
        );
      } else {
        return true;
      }
    }
  };

  checkFormHistoricalDetails = (currentFieldValues, hasError) => {
    if (currentFieldValues.hasHistory) {
      if (!!currentFieldValues.historicalClaimConsultationDetails) {
        return HAS_ERROR(hasError);
      } else {
        return true;
      }
    }
  };

  getBenefitType = () => BENEFIT_TYPES.medicalClaim;

  getBenefitDetail(props) {
    return getBenefitDetailFromProps(
      props.location,
      props.parentState.claim,
      this.getBenefitType()
    );
  }

  validationResult = {
    hasErr: {
      dateOfAccident: false,
      timeOfAccident: false,
      placeOfAccident: false,
      descriptionOfAccident: false,
      injuriesInAccident: false,
      typeOfSymptom: false,
      dateOfSymptom: false,
      historicalClaimConsultationDetails: false
    },
    errMsg: {
      dateOfAccident: "",
      timeOfAccident: "",
      placeOfAccident: "",
      descriptionOfAccident: "",
      injuriesInAccident: "",
      typeOfSymptom: "",
      dateOfSymptom: "",
      historicalClaimConsultationDetails: ""
    }
  };

  modalValidationResult = {
    hasErr: {
      dateOfConsultation: false,
      amountClaimed: false
    },
    errMsg: {
      dateOfConsultation: "",
      amountClaimed: ""
    }
  };

  validateModalFields = (currentFieldValues, touchedState, validationState) => {
    if (
      touchedState.dateOfConsultation ||
      currentFieldValues.dateOfConsultation
    ) {
      IS_EMPTY_FIELD_CHECK(
        "dateOfConsultation",
        "Date of Consultation",
        "",
        currentFieldValues,
        validationState
      );
    }
    if (touchedState.amountClaimed || currentFieldValues.amountClaimed) {
      IS_EMPTY_FIELD_CHECK(
        "amountClaimed",
        "Amount claimed",
        "",
        currentFieldValues,
        validationState
      );
    }
  };

  validateAccidentRelatedFields = (
    currentFieldValues,
    touchedState,
    validationState
  ) => {
    if (touchedState.dateOfAccident || currentFieldValues.dateOfAccident) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "dateOfAccident",
        "Date of Accident",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.date
      );
    }
    if (touchedState.timeOfAccident || currentFieldValues.timeOfAccident) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "timeOfAccident",
        "Time of Accident",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.time
      );
    }
    if (touchedState.placeOfAccident || currentFieldValues.placeOfAccident) {
      IS_EMPTY_FIELD_CHECK(
        "placeOfAccident",
        "Place of Accident",
        "",
        currentFieldValues,
        validationState
      );
    }
    // descriptionOfAccident
    if (
      touchedState.descriptionOfAccident ||
      currentFieldValues.descriptionOfAccident
    ) {
      IS_EMPTY_FIELD_CHECK(
        "descriptionOfAccident",
        "Description",
        "",
        currentFieldValues,
        validationState
      );
    }
    // injuriesInAccident
    if (
      touchedState.injuriesInAccident ||
      currentFieldValues.injuriesInAccident
    ) {
      IS_EMPTY_FIELD_CHECK(
        "injuriesInAccident",
        "This field",
        "",
        currentFieldValues,
        validationState
      );
    }
  };

  validateFields = (currentFieldValues, touchedState, validationState) => {
    if (currentFieldValues.isAccidentRelated) {
      this.validateAccidentRelatedFields(
        currentFieldValues,
        touchedState,
        validationState
      );
      validationState.hasErr.dateOfSymptom = false;
      validationState.errMsg.dateOfSymptom = "";
      validationState.hasErr.typeOfSymptom = false;
      validationState.errMsg.typeOfSymptom = "";
    } else {
      validationState.hasErr.placeOfAccident = false;
      validationState.errMsg.placeOfAccident = "";
      validationState.hasErr.descriptionOfAccident = false;
      validationState.errMsg.descriptionOfAccident = "";
      validationState.hasErr.injuriesInAccident = false;
      validationState.errMsg.injuriesInAccident = "";
    }
    // dateOfSymptom
    if (touchedState.dateOfSymptom || currentFieldValues.dateOfSymptom) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "dateOfSymptom",
        "Date of Symptom",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.date
      );
    }
    // typeOfSymptom
    if (touchedState.typeOfSymptom || currentFieldValues.typeOfSymptom) {
      IS_EMPTY_FIELD_CHECK(
        "typeOfSymptom",
        "Type of Symptom",
        "",
        currentFieldValues,
        validationState
      );
    }
    if (currentFieldValues.hasHistory) {
      // historicalClaimConsultationDetails
      if (
        touchedState.historicalClaimConsultationDetails ||
        currentFieldValues.historicalClaimConsultationDetails
      ) {
        IS_EMPTY_FIELD_CHECK(
          "historicalClaimConsultationDetails",
          "This field ",
          "",
          currentFieldValues,
          validationState
        );
      }
    } else {
      validationState.hasErr.historicalClaimConsultationDetails = false;
      validationState.errMsg.historicalClaimConsultationDetails = "";
    }
  };

  benefitIsEmpty = currentFieldValues => {
    let isEmpty = true;
    if (currentFieldValues && currentFieldValues.isAccidentRelated) {
      // handle Accident
      isEmpty =
        !!currentFieldValues.dateOfAccident &&
        !!currentFieldValues.timeOfAccident &&
        !!currentFieldValues.placeOfAccident &&
        !!currentFieldValues.descriptionOfAccident &&
        !!currentFieldValues.injuriesInAccident &&
        !!currentFieldValues.multipleConsultationAccident &&
        currentFieldValues.multipleConsultationAccident.length;
    } else if (currentFieldValues && !currentFieldValues.isAccidentRelated) {
      // handle Medical/Illness
      isEmpty =
        !!currentFieldValues.typeOfSymptom &&
        !!currentFieldValues.dateOfSymptom &&
        !!currentFieldValues.multipleConsultationMedical &&
        !!currentFieldValues.multipleConsultationMedical.length;
    }
    return isEmpty;
  };

  componentDidMount() {
    this.showModal();
  }

  componentWillUnmount() {
    let benefitType = this.getBenefitType();
    let claimDetail = this.props.parentState.claim.claimDetail;
    let benefitDetailIndex = getLastBenefitIndex(benefitType, claimDetail);
    const currentClaimDetail = claimDetail[benefitType][benefitDetailIndex];
    let benefitId = currentClaimDetail.benefitId;
    if (this.benefitIsEmpty(currentClaimDetail)) {
      let benefitName = getClaimTitle(BENEFIT_TYPES.medicalClaim);
      let parentHandlers = this.props.parentHandlers;
      parentHandlers.handleBenefitDetailInNavBar(
        benefitName,
        benefitId,
        benefitType
      );
    } else {
      this.props.parentHandlers.removeBenefit(benefitId, benefitType);
    }
  }

  render() {
    let benefitDetail = this.getBenefitDetail(this.props);
    this.validateFields(
      benefitDetail,
      this.state.touched,
      this.validationResult
    );

    let data = {
      props: this.props,
      benefitDetail: benefitDetail,
      benefitType: this.getBenefitType(),
      hasErr: this.validationResult.hasErr,
      errMsg: this.validationResult.errMsg,
      requirementModalDisplay: this.state.requirementModalDisplay,
      modalOpen: this.state.modalOpen,
      detail: this.state.modalDetail,
      touchedModal: this.state.touchedModal,
      validationHandlers: {
        handleTouched: this.handleTouched,
        handleTouchedModal: this.handleTouchedModal,
        checkModal: this.checkModal
      },
      viewControls: {
        checkForm: this.checkForm,
        showModal: this.showModal,
        closeModal: this.closeModal,
        //MultipleConsultation Modal related
        handleOpenModal: this.handleOpenModal,
        handleCloseModal: this.handleCloseModal,
        updateModal: this.updateModal,
        handleSaveAndCloseModal: this.handleSaveAndCloseModal
      }
    };

    return MedicalClaimComponent(data);
  }
}

export default MedicalClaim;
