import React from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { REGION } from "common/Constants";
import { FORM_DETAIL_STYLE } from "common/styles/styles";

class SummaryReporterComponent extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const policyholderDetail = this.props.policyholderDetail;
    const reporterDetail = this.props.reporterDetail;
    const region = this.props.region;
    const isPolicyHolder = reporterDetail.isPolicyHolder;

    return (
      <React.Fragment>
        <Grid container style={FORM_DETAIL_STYLE}>
          <Grid container style={FORM_DETAIL_STYLE}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Surname</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                {isPolicyHolder
                  ? policyholderDetail.surname
                  : reporterDetail.surname}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Name</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                {isPolicyHolder ? policyholderDetail.name : reporterDetail.name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">E-Mail</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                {isPolicyHolder
                  ? policyholderDetail.email
                  : reporterDetail.email}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Phone</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                {isPolicyHolder
                  ? policyholderDetail.phone
                  : reporterDetail.phone}
              </Typography>
            </Grid>

            {region === REGION.SG ? (
              <React.Fragment>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">ID Type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">
                    {isPolicyHolder
                      ? policyholderDetail.sgIDType
                      : reporterDetail.sgIDType}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">ID Number</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">
                    {isPolicyHolder
                      ? policyholderDetail.sgIDNumber
                      : reporterDetail.sgIDNumber}
                  </Typography>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">ID Type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">
                    {isPolicyHolder
                      ? policyholderDetail.hkIDType
                      : reporterDetail.hkIDType}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">ID Number</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">
                    {isPolicyHolder
                      ? policyholderDetail.hkIDNumber
                      : reporterDetail.hkIDNumber}
                  </Typography>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default SummaryReporterComponent;
