import { FORM_LINKS, NAV_LINKS, NAV_LINK_STATUS } from "../Constants";

export const DEFAULT_NAV_STATE = [
  {
    link: FORM_LINKS.aboutSelf,
    id: NAV_LINKS.policyholderInformation.id,
    name: NAV_LINKS.policyholderInformation.name,
    text: NAV_LINKS.policyholderInformation.text,
    benefitId: "",
    benefitType: "",
    hasSubMenu: false,
    isDisabled: NAV_LINK_STATUS.disable,
    subMenu: []
  },
  {
    link: FORM_LINKS.reporter,
    id: NAV_LINKS.reporterInformation.id,
    name: NAV_LINKS.reporterInformation.name,
    text: NAV_LINKS.reporterInformation.text,
    benefitId: "",
    benefitType: "",
    hasSubMenu: false,
    isDisabled: NAV_LINK_STATUS.disable,
    subMenu: []
  },
  {
    link: FORM_LINKS.basicInformation,
    id: NAV_LINKS.policyInformation.id,
    name: NAV_LINKS.policyInformation.name,
    text: NAV_LINKS.policyInformation.text,
    benefitId: "",
    benefitType: "",
    hasSubMenu: false,
    isDisabled: NAV_LINK_STATUS.disable,
    subMenu: []
  },
  {
    link: "",
    id: NAV_LINKS.claims.id,
    name: NAV_LINKS.claims.name,
    text: NAV_LINKS.claims.text,
    benefitId: "",
    benefitType: "",
    hasSubMenu: true,
    isDisabled: NAV_LINK_STATUS.disable,
    subMenu: [
      {
        link: FORM_LINKS.selectClaimType,
        id: "",
        name: NAV_LINKS.addNewClaim.name,
        text: NAV_LINKS.addNewClaim.text,
        benefitId: "",
        benefitType: "",
        hasSubMenu: false,
        isDisabled: NAV_LINK_STATUS.enable,
        subMenu: []
      }
    ]
  },
  {
    link: FORM_LINKS.fileUpload,
    id: NAV_LINKS.documentsUpload.id,
    name: NAV_LINKS.documentsUpload.name,
    text: NAV_LINKS.documentsUpload.text,
    benefitId: "",
    benefitType: "",
    hasSubMenu: false,
    isDisabled: NAV_LINK_STATUS.disable,
    subMenu: []
  },
  {
    link: FORM_LINKS.summary,
    id: NAV_LINKS.summary.id,
    name: NAV_LINKS.summary.name,
    text: NAV_LINKS.summary.text,
    benefitId: "",
    benefitType: "",
    hasSubMenu: false,
    isDisabled: NAV_LINK_STATUS.disable,
    subMenu: []
  }
];

export default DEFAULT_NAV_STATE;
