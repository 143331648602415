import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { MESSAGE_TYPE } from "common/Constants";

import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import { ALIGN_LEFT_STYLE } from "common/styles/styles";

class MessageComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      type: MESSAGE_TYPE.notify,
      title: "Notification",
      subtitle: "Message"
    };
  }

  getNotificationContainerClass = type => {
    let notificationClass = "";
    switch (type) {
      case MESSAGE_TYPE.success:
        notificationClass = "notification-message-container-success";
        break;
      case MESSAGE_TYPE.notify:
        notificationClass = "notification-message-container-info";
        break;
      case MESSAGE_TYPE.warn:
        notificationClass = "notification-message-container-warn";
        break;
      case MESSAGE_TYPE.error:
        notificationClass = "notification-message-container-error";
        break;
      default:
        break;
    }
    return notificationClass;
  };

  getNotificationIcon = type => {
    let icon = null;
    switch (type) {
      case MESSAGE_TYPE.success:
        icon = (
          <CheckCircleOutlinedIcon className="notification-message-icon-success notification-message-icon"></CheckCircleOutlinedIcon>
        );
        break;
      case MESSAGE_TYPE.notify:
        icon = (
          <EmojiObjectsOutlinedIcon className="notification-message-icon-info notification-message-icon"></EmojiObjectsOutlinedIcon>
        );
        break;
      case MESSAGE_TYPE.warn:
        icon = (
          <ErrorOutlineIcon className="notification-message-icon-warn notification-message-icon"></ErrorOutlineIcon>
        );
        break;
      case MESSAGE_TYPE.error:
        icon = (
          <HighlightOffIcon className="notification-message-icon-error notification-message-icon"></HighlightOffIcon>
        );
        break;
      default:
        break;
    }
    return icon;
  };

  render() {
    const type = this.props.type ? this.props.type : this.state.type;
    let messageBoxClass = this.getNotificationContainerClass(type);
    messageBoxClass = messageBoxClass.concat(" notification-message-container");
    const title = this.props.title ? this.props.title : this.state.title;
    const subtitle = this.props.subtitle
      ? this.props.subtitle
      : this.state.subtitle;

    return (
      <React.Fragment>
        <div className={messageBoxClass}>
          <Grid container spacing={3}>
            <Grid item xs={2} className="notification-message-icon-container">
              {this.getNotificationIcon(type)}
            </Grid>
            <Grid item xs={10} style={ALIGN_LEFT_STYLE}>
              <div className="notification-message-text-container">
                <div>
                  <Typography variant="h5">{title}</Typography>
                </div>
                <div>
                  <Typography variant="subtitle2">{subtitle}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default MessageComponent;
