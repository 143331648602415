import React from "react";
import { Typography } from "@material-ui/core";
import { GET_SUPPORT_EMAIL } from "common/utils/CommonUtil";
import { LOG_ERROR } from "common/utils/LogFileUtil";
import { ERROR_COMPONENT_CONTENT_STYLE } from "common/styles/styles";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";
import { ERRORS } from "common/Constants";

class ErrorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    if (error || errorInfo) {
      if (error && !("" + error).includes(ERRORS.INVALID_ROUTE)) {
        if (error.message) {
          LOG_ERROR(JSON.stringify(error.message), JSON.stringify(errorInfo));
        } else {
          LOG_ERROR(JSON.stringify(error), JSON.stringify(errorInfo));
        }
      }
      this.setState({
        error: error,
        errorInfo: errorInfo
      });
    }
  }

  supportEmailComponentWithSubmissionId = (supportEmail, submissionId) =>
    submissionId ? (
      <React.Fragment>
        You may try again later or contact{" "}
        <a
          href={`mailto:${supportEmail}?subject=Regarding Error submitting claim: ${submissionId}`}
        >
          {supportEmail}
        </a>{" "}
        quoting Claims number. {submissionId}
      </React.Fragment>
    ) : (
      <React.Fragment>
        You may try again later or contact{" "}
        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
      </React.Fragment>
    );

  render() {
    if (this.state.errorInfo) {
      const parentState = this.props.parentState;
      let submissionId = null;
      let region = null;
      let supportEmail = null;
      if (parentState) {
        submissionId = parentState.claim.submissionId;
        region = parentState.region;
      }
      supportEmail = GET_SUPPORT_EMAIL(region);
      if (
        this.state.error &&
        ("" + this.state.error).includes(ERRORS.INVALID_ROUTE)
      ) {
        return (
          <React.Fragment>
            <ReportProblemRoundedIcon className="notification-message-icon-info error-message-icon" />
            <Typography variant="h5">Oops, that is an invalid page</Typography>
            <div style={ERROR_COMPONENT_CONTENT_STYLE}>
              The Allied World Travel Claims Form was not loaded correctly.
              <br />
              <br />
              Please submit your claims from:
              <br />
              <a href="https://alliedworldinsurance.com/singapore-claims/">
                https://alliedworldinsurance.com/singapore-claims/
              </a>{" "}
              for Singapore <br />
              or{" "}
              <a href="https://alliedworldinsurance.com/hong-kong-claims/">
                https://alliedworldinsurance.com/hong-kong-claims/
              </a>{" "}
              for Hong Kong <br />
            </div>
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          <ReportProblemRoundedIcon className="notification-message-icon-info error-message-icon" />
          <Typography variant="h5">Oops, something went wrong.</Typography>
          <div style={ERROR_COMPONENT_CONTENT_STYLE}>
            {this.supportEmailComponentWithSubmissionId(
              supportEmail,
              submissionId
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorComponent;
