import React from "react";
import ClaimantInformationComponent from "components/forms/claims/claimantInformation/ClaimantInformationComponent";
import {
  validateEmail,
  validatePhoneNumber,
  getBenefitDetailFromProps,
  IS_VALID_TEXT_INPUT
} from "common/utils/CommonUtil";
import { IS_SG_REGION } from "common/Constants";

class ClaimantInformation extends React.Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
      selectedClaimantId: "",
      benefitType: "",
      benefitId: "",
      modalClaimantDetail: {
        relationToPolicyHolder: "",
        surname: "",
        name: "",
        email: "",
        phone: "",
        isRelatedClaim: false,
        sgIDType: "",
        sgIDNumber: "",
        hkIDType: "",
        hkIDNumber: ""
      },
      touched: {
        relationToPolicyHolder: false,
        surname: false,
        name: false,
        email: false,
        phone: false,
        sgIDType: false,
        sgIDNumber: false,
        hkIDType: false,
        hkIDNumber: false
      },
      parentHandlers: {}
    };
  }

  resetTouched = () => ({
    relationToPolicyHolder: false,
    surname: false,
    name: false,
    email: false,
    phone: false,
    sgIDType: false,
    sgIDNumber: false,
    hkIDType: false,
    hkIDNumber: false
  });

  resetModalClaimantDetail = () => ({
    relationToPolicyHolder: "",
    surname: "",
    name: "",
    email: "",
    phone: "",
    isRelatedClaim: false,
    sgIDType: "",
    sgIDNumber: "",
    hkIDType: "",
    hkIDNumber: ""
  });

  updateModalClaimantDetail = (targetParam, targetValue) => {
    if (IS_VALID_TEXT_INPUT(targetValue)) {
      this.setState(prevState => ({
        modalClaimantDetail: {
          ...prevState.modalClaimantDetail,
          [targetParam]: targetValue
        }
      }));
    }
  };

  getBenefitType = props => {
    let benefitType =
      (props.location &&
        props.location.state &&
        props.location.state.benefitType) ||
      "";
    if (!benefitType) {
      benefitType = props.parentState.claim.currentClaimType;
    }
    return benefitType;
  };

  validatePhoneNumber = value => {
    return (
      this.fieldEmptyValidation("phone", value) && validatePhoneNumber(value)
    );
  };

  fieldEmailValidation = value => {
    return this.fieldEmptyValidation("email", value) && validateEmail(value);
  };

  handleOpen = (e, claimantId, claimantDetail, isSG) => {
    if (e && claimantId && claimantDetail) {
      if (isSG) {
        this.setState({
          modalOpen: true,
          selectedClaimantId: claimantId,
          modalClaimantDetail: {
            relationToPolicyHolder: claimantDetail.relationToPolicyHolder,
            surname: claimantDetail.surname,
            name: claimantDetail.name,
            email: claimantDetail.email,
            phone: claimantDetail.phone,
            isRelatedClaim: false,
            sgIDType: claimantDetail.sgIDType,
            sgIDNumber: claimantDetail.sgIDNumber
          }
        });
      } else {
        this.setState({
          modalOpen: true,
          selectedClaimantId: claimantId,
          modalClaimantDetail: {
            relationToPolicyHolder: claimantDetail.relationToPolicyHolder,
            surname: claimantDetail.surname,
            name: claimantDetail.name,
            email: claimantDetail.email,
            phone: claimantDetail.phone,
            isRelatedClaim: false,
            hkIDType: claimantDetail.hkIDType,
            hkIDNumber: claimantDetail.hkIDNumber
          }
        });
      }
    }
  };

  handleClose = (e, parentHandlers) => {
    if (e) {
      if (parentHandlers) {
        let latestClaimantInfo = parentHandlers.getClaimants();
        let latestClaimantIndex = latestClaimantInfo.length - 1;
        let claimantDetail = latestClaimantInfo[latestClaimantIndex];
        let claimantId = claimantDetail.claimantId;
        if (
          this.claimantDetailIsEmpty(
            claimantDetail,
            this.isSG(this.props.parentState.region)
          )
        ) {
          parentHandlers.deleteClaimant(claimantId);
        }
      }

      this.setState({
        modalOpen: false,
        selectedClaimantId: "",
        modalClaimantDetail: this.resetModalClaimantDetail(),
        touched: this.resetTouched()
      });
    }
  };

  claimantDetailIsEmpty = (claimantDetail, isSG) => {
    if (isSG) {
      return !(
        claimantDetail &&
        claimantDetail.surname &&
        claimantDetail.name &&
        claimantDetail.email &&
        claimantDetail.phone &&
        claimantDetail.sgIDType &&
        claimantDetail.sgIDNumber
      );
    } else {
      return !(
        claimantDetail &&
        claimantDetail.surname &&
        claimantDetail.name &&
        claimantDetail.email &&
        claimantDetail.phone &&
        claimantDetail.hkIDType &&
        claimantDetail.hkIDNumber
      );
    }
  };

  handleModalSaveAndClose = (
    e,
    claimantId,
    parentHandlers,
    currentBenefitId,
    currentBenefitType
  ) => {
    if (e) {
      if (this.state.modalClaimantDetail.isRelatedClaim) {
        parentHandlers.updateBenefitIdToClaimant(
          claimantId,
          currentBenefitId,
          currentBenefitType
        );
      } else {
        parentHandlers.removeBenefitIdFromClaimant(
          claimantId,
          currentBenefitId
        );
      }
      parentHandlers.updateClaimantInformationFromModal(
        this.state.modalClaimantDetail,
        claimantId,
        () => {
          this.handleClose(e);
        }
      );
    }
  };

  fieldEmptyValidation = (name, value) => {
    if (name) {
      if (this.state.touched[name]) {
        return !(value && ("" + value).length);
      }
      return false;
    }
    return false;
  };

  handleTouched = e => {
    let targetParam = e.target.name;
    if (!this.state.touched[targetParam]) {
      this.setState(prevState => ({
        touched: {
          ...prevState.touched,
          [targetParam]: true
        }
      }));
    }
  };

  checkForm = (claimant, isSG) => {
    let isValid = false;
    isValid =
      !!claimant.relationToPolicyHolder &&
      !!claimant.surname &&
      !!claimant.name;
    if (claimant.relationToPolicyHolder === "self") {
      isValid =
        isValid &&
        validateEmail(claimant.email) &&
        validatePhoneNumber(claimant.phone);
    }
    if (isSG) {
      // Validating ID number and Type
      isValid = isValid && !!claimant.sgIDNumber && !!claimant.sgIDType;
    } else {
      isValid = isValid && !!claimant.hkIDNumber && !!claimant.hkIDType;
    }
    return !isValid;
  };

  getBenefitDetail(props) {
    return getBenefitDetailFromProps(
      props.location,
      props.parentState.claim,
      this.getBenefitType(props)
    );
  }

  setParentHandlers(props) {
    this.setState(prevState => ({
      parentHandlers: props.parentHandlers
    }));
  }

  isSG = region => IS_SG_REGION(region);

  render() {
    let benefitDetail = this.getBenefitDetail(this.props);
    let region = this.props.parentState.region;
    let data = {
      props: this.props,
      modalOpen: this.state.modalOpen,
      selectedClaimantId: this.state.selectedClaimantId,
      benefitDetail: benefitDetail,
      benefitType: this.getBenefitType(this.props),
      selectedClaimant: this.state.modalClaimantDetail,
      validationHandlers: {},
      viewControls: {
        modalOpen: this.handleOpen,
        modalClose: this.handleClose,
        handleModalSaveAndClose: this.handleModalSaveAndClose,
        checkForm: this.checkForm,
        updateModalClaimantDetail: this.updateModalClaimantDetail,
        isSG: this.isSG(region)
      }
    };

    return ClaimantInformationComponent(data);
  }
}

export default ClaimantInformation;
