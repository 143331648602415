import React from "react";
import { NavLink } from "react-router-dom";
import { ListItem, ListItemText } from "@material-ui/core";
import { NAVBAR_MENU_STYLE } from "common/styles/styles";

class SubMenuComponent extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let subMenu = this.props.subMenu;
    return (
      <React.Fragment>
        <div style={NAVBAR_MENU_STYLE}>
          {subMenu.text ? (
            <ListItem
              button
              component={NavLink}
              disabled={subMenu.isDisabled}
              to={{
                pathname: subMenu.link,
                key: subMenu.name,
                state: {
                  benefitId: subMenu.benefitId,
                  benefitType: subMenu.benefitType
                }
              }}
            >
              <ListItemText
                primary={subMenu.text}
                className="pl-3"
              ></ListItemText>
            </ListItem>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default SubMenuComponent;
