import React from "react";
import {
  Grid,
  Typography,
  Button,
  Modal,
  Card,
  TextField
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {
  MODAL_OK_BUTTON_STYLE,
  MODAL_HEADER_STYLE,
  MODAL_INPUT_STYLE,
  BUTTON_MIN_WIDTH_STYLE
} from "common/styles/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  handleFloatingCurrencyFormatting,
  DOES_CURRENT_FIELD_HAVE_ERROR,
  IS_CURRENT_FIELD_VALID_CHECK
} from "common/utils/CommonUtil";
import { FIELD_TYPE } from "common/Constants";

class ConsultationDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      touched: {
        dateOfConsultation: false,
        amountClaimed: false
      }
    };
  }

  resetModal = () => {
    this.validationResult = {
      hasErr: {
        dateOfConsultation: false,
        amountClaimed: false
      },
      errMsg: {
        dateOfConsultation: "",
        amountClaimed: ""
      }
    };
    this.setState({
      touched: {
        dateOfConsultation: false,
        amountClaimed: false
      }
    });
  };

  validationResult = {
    hasErr: {
      dateOfConsultation: false,
      amountClaimed: false
    },
    errMsg: {
      dateOfConsultation: "",
      amountClaimed: ""
    }
  };

  validateFields = (currentFieldValues, touchedState, validationState) => {
    if (
      touchedState.dateOfConsultation ||
      currentFieldValues.dateOfConsultation
    ) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "dateOfConsultation",
        "Date of Consultation",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.date
      );
    }
    if (touchedState.amountClaimed || currentFieldValues.amountClaimed) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "amountClaimed",
        "Amount claimed",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.currency
      );
    }
  };

  handleTouched = e => {
    let targetParam = e.target.name;
    if (!this.state.touched[targetParam]) {
      this.setState(prevState => ({
        touched: {
          ...prevState.touched,
          [targetParam]: true
        }
      }));
    }
  };

  render() {
    const modalOpen = this.props.modalOpen;
    const claimParentHandlers = this.props.claimParentHandlers;
    const modalType = this.props.modalType;
    const benefitDetail = this.props.benefitDetail;
    const detail = this.props.detail;
    const benefitId = benefitDetail.benefitId;
    const handleCloseModal = this.props.handleCloseModal;
    const updateModal = this.props.updateModal;
    const handleSaveAndCloseModal = this.props.handleSaveAndCloseModal;
    const touchedModal = this.props.touchedModal;
    const checkModal = this.props.checkModal;
    this.validateFields(detail, this.state.touched, this.validationResult);
    return (
      <Modal
        aria-labelledby="Consultation dates"
        aria-describedby="Consultation dates"
        open={modalOpen}
        onClose={e => {
          handleCloseModal(
            e,
            claimParentHandlers,
            benefitDetail.benefitId,
            modalType
          );
          this.resetModal();
        }}
        style={{ overflow: "auto" }}
      >
        <Card className="app-modal-card-style">
          <div style={MODAL_HEADER_STYLE}>
            <Typography variant="h4">Consultation details</Typography>
          </div>
          <Grid container spacing={3} style={MODAL_INPUT_STYLE}>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  showTodayButton={true}
                  autoOk
                  fullWidth
                  id={"dateOfConsultation"}
                  name={"dateOfConsultation"}
                  variant="dialog"
                  format="dd/MM/yyyy"
                  helperText={this.validationResult.errMsg.dateOfConsultation}
                  error={DOES_CURRENT_FIELD_HAVE_ERROR(
                    detail.dateOfConsultation,
                    this.state.touched.dateOfConsultation,
                    FIELD_TYPE.date
                  )}
                  margin="normal"
                  required
                  disableFuture={true}
                  label={"Date of Consultation"}
                  invalidDateMessage="Please enter a valid date"
                  value={detail.dateOfConsultation}
                  onChange={value => {
                    if (value) {
                      updateModal("dateOfConsultation", value);
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "dateOfConsultation"
                  }}
                  onBlur={this.handleTouched}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={DOES_CURRENT_FIELD_HAVE_ERROR(
                  detail.amountClaimed,
                  this.state.touched.amountClaimed,
                  FIELD_TYPE.currency
                )}
                id="amountClaimed"
                fullWidth
                required
                margin="normal"
                helperText={this.validationResult.errMsg.amountClaimed}
                name="amountClaimed"
                placeholder="Amount claimed"
                label="Amount claimed"
                value={detail.amountClaimed}
                onChange={e => {
                  updateModal(
                    e.target.name,
                    handleFloatingCurrencyFormatting(e.target.value)
                  );
                }}
                onBlur={this.handleTouched}
              />
            </Grid>
          </Grid>
          <div style={MODAL_OK_BUTTON_STYLE}>
            <Button
              id="lost-item-detail-ok-button"
              onClick={e => {
                handleSaveAndCloseModal(
                  e,
                  claimParentHandlers,
                  benefitId,
                  modalType
                );
                this.resetModal();
              }}
              color="primary"
              variant="contained"
              style={BUTTON_MIN_WIDTH_STYLE}
              disabled={checkModal(detail, touchedModal)}
            >
              OK
            </Button>
          </div>
        </Card>
      </Modal>
    );
  }
}

export default ConsultationDetail;
