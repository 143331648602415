import React from "react";
import WelcomePageComponent from "components/forms/welcomePage/WelcomePageComponent";
import {
  REGION,
  GET_REGION,
  NAV_LINKS,
  NAV_LINK_STATUS,
  TIMEOUT_DELAY_50MS
} from "common/Constants";
import { UPDATE_CLAIM_DIRECTORY } from "common/utils/CommonUtil";
import { UPDATE_CLAIM_SUBMISSION_ID } from "common/utils/ClaimSubmission";

class WelcomePage extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false
    };
  }

  appLoadCompleted = () => {
    this.setState({ isLoading: false });
  };

  appLoadStarted = () => {
    this.setState({ isLoading: true });
  };

  componentDidMount() {
    let region =
      (this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.region) ||
      REGION.SG;
    region = GET_REGION(region);
    this.props.parentHandlers.setRegion(region);
    setTimeout(() => {
      if (!this.props.parentState.claim.submissionId) {
        UPDATE_CLAIM_SUBMISSION_ID(
          region,
          this.props.parentHandlers.updateSubmissionId
        );
        this.props.parentHandlers.handleNavBarStatus(
          NAV_LINKS.policyholderInformation.id,
          NAV_LINK_STATUS.enable
        );
      }
      if (!this.props.parentState.claim.claimDirectory) {
        UPDATE_CLAIM_DIRECTORY(
          this.props.parentHandlers.handleClaimDirectoryUpdate
        );
      }
    }, TIMEOUT_DELAY_50MS);
  }

  UNSAFE_componentWillMount() {
    setTimeout(() => {
      this.props.parentHandlers.resetState();
    }, 10);
  }

  render() {
    let data = {
      props: this.props,
      state: this.state,
      isLoading: !(
        this.props.parentState.claim.submissionId &&
        this.props.parentState.claim.claimDirectory
      )
    };
    return WelcomePageComponent(data);
  }
}

export default WelcomePage;
