import React from "react";
import {
  pdfStyles,
  PDF_DISPLAY_CHECKBOX,
  PDF_DISPLAY_CHECKBOX_UNCHECKED
} from "common/utils/PDFDocumentUtil";
import { Text, View, Link } from "@react-pdf/renderer";
import {
  PDPA_DOCUMENT_URL,
  REGION,
  PICS_HK_DOCUMENT_URL
} from "common/Constants";

export const PDFDeclarationComponent = (pdpaStatus, region) => {
  const SGPDPAComponent = () => (
    <React.Fragment>
      <View style={pdfStyles.title}>
        <Text>Declaration, Authorization and Personal Data Consent</Text>
      </View>

      <View style={pdfStyles.subtitle}>
        <Text>Declaration</Text>
      </View>

      <View>
        <View style={pdfStyles.subContainer}>
          {PDF_DISPLAY_CHECKBOX()}
          <Text style={pdfStyles.declarationContent}>I/We:</Text>
        </View>
        <View style={pdfStyles.declarationMainContent}>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.declarationMainContentNumbering}>(i)</Text>
            <Text style={pdfStyles.declarationMainContentText}>
              confirm that I am/we are the claimant and/or the Policyholder;
            </Text>
          </View>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.declarationMainContentSubNumberingii}>
              (ii)
            </Text>
            <Text style={pdfStyles.declarationMainContentText}>
              declare to the best of my/our knowledge, and belief, and warrant
              that:
            </Text>
          </View>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.declarationMainContentSubNumbering}>a.</Text>
            <Text style={pdfStyles.declarationMainContentText}>
              all information in this form is true, correct and accurate; and
            </Text>
          </View>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.declarationMainContentSubNumbering}>b.</Text>
            <View style={pdfStyles.subContainer2}>
              <Text style={pdfStyles.declarationMainContentText}>
                I/We have not withheld, concealed or suppressed any material
                information or{" "}
              </Text>
              <Text style={pdfStyles.declarationMainContentFineSubText}>
                made a false statement in relation to the claim and/or the
                accident;
              </Text>
            </View>
          </View>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.declarationMainContentSubNumberingiii}>
              (iii)
            </Text>
            <View style={pdfStyles.subContainer2}>
              <Text style={pdfStyles.declarationMainContentText}>
                further agree and undertake that I/we shall not hereafter make
                any false statement or
              </Text>
              <Text style={pdfStyles.declarationMainContentSubText}>
                conceal or suppress any material fact relating to the claim
                and/or the accident.
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={pdfStyles.subtitle}>
        <Text>Authorisation</Text>
      </View>
      <View>
        <View style={pdfStyles.subContainer}>
          {PDF_DISPLAY_CHECKBOX()}
          <Text style={pdfStyles.authorizationContent}>
            {" "}
            I/We hereby consent to and authorise any medical practitioner,
            hospital, clinic, insurance office or other person or organisation
            who has attended to me and/or the claimant for any reason, to
            disclose to Allied World Assurance Company, Ltd ("Allied World"),
            any and all information with respect to any illness or injury and to
            provide copies of all hospital or medical records/certification
            including earlier medical history. The information given is true and
            correct to the best of my/our knowledge and belief.
          </Text>
        </View>
      </View>
      <View style={pdfStyles.subtitle}>
        <Text>Personal Data Consent</Text>
      </View>
      <View>
        <View style={pdfStyles.subContainer}>
          {PDF_DISPLAY_CHECKBOX()}
          <Text style={pdfStyles.personalDataConsentContent}>
            I/We acknowledge and consent to Allied World collecting, using,
            disclosing and processing my/our personal data including disclosing
            my/our personal data to third party service providers within or
            outside Singapore, for the purposes set out in and in accordance
            with the Allied World Singapore Personal Data Protection Policy
            available at{" "}
            <Link src={PDPA_DOCUMENT_URL} wrap={false}>
              {PDPA_DOCUMENT_URL}
            </Link>
            . If I/we have provided or will provide information to Allied World
            about any other individuals, I/we confirm that I/we are authorised
            to disclose his or her personal data and also give this consent on
            both my/our and their behalf.
          </Text>
        </View>
      </View>
    </React.Fragment>
  );

  const HKPDPAComponent = () => (
    <React.Fragment>
      <View style={pdfStyles.title}>
        <Text>Declaration, Authorization and Personal Data Consent</Text>
      </View>

      <View style={pdfStyles.subtitle}>
        <Text>Declaration</Text>
      </View>

      <View>
        <View style={pdfStyles.subContainer}>
          {PDF_DISPLAY_CHECKBOX()}
          <Text style={pdfStyles.declarationContent}>I/We:</Text>
        </View>
        <View style={pdfStyles.declarationMainContent}>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.declarationMainContentNumbering}>(i)</Text>
            <Text style={pdfStyles.declarationMainContentText}>
              confirm that I am/we are the claimant and/or the Policyholder
              and/or the authorized representative;
            </Text>
          </View>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.declarationMainContentSubNumberingii}>
              (ii)
            </Text>
            <Text style={pdfStyles.declarationMainContentText}>
              declare to the best of my/our knowledge and belief that
            </Text>
          </View>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.declarationMainContentSubNumbering}>a.</Text>
            <Text style={pdfStyles.declarationMainContentText}>
              the information given is true, correct and accurate; and
            </Text>
          </View>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.declarationMainContentSubNumbering}>b.</Text>
            <View style={pdfStyles.subContainer2}>
              <Text style={pdfStyles.declarationMainContentText}>
                I/We have not withheld, concealed or suppressed any material
                information or{" "}
              </Text>
              <Text style={pdfStyles.declarationMainContentFineSubText}>
                made a false statement in relation to the claim and/or the
                accident;
              </Text>
            </View>
          </View>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.declarationMainContentSubNumberingiii}>
              (iii)
            </Text>
            <View style={pdfStyles.subContainer2}>
              <Text style={pdfStyles.declarationMainContentText}>
                further agree and undertake that I/we shall not hereafter make
                any false statement or
              </Text>
              <Text style={pdfStyles.declarationMainContentSubText}>
                conceal or suppress any material fact relating to the claim
                and/or the accident.
              </Text>
            </View>
          </View>

          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.declarationMainContentSubNumberingiii}>
              (iv)
            </Text>
            <View style={pdfStyles.subContainer2}>
              <Text style={pdfStyles.declarationMainContentText}>
                I/W agree that any concealment or incorrect statement in
                connection with this claim{" "}
              </Text>
              <Text style={pdfStyles.declarationMainContentSubText}>
                may result in legal liability and the policy shall become void.
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={pdfStyles.subtitle}>
        <Text>Authorisation</Text>
      </View>
      <View>
        <View style={pdfStyles.subContainer}>
          {PDF_DISPLAY_CHECKBOX()}
          <Text style={pdfStyles.authorizationContent}>
            {" "}
            I/We hereby authorize Allied World Assurance Company, Ltd or its
            authorized representative to collect any and all information with
            respect to the claimant’s or my/our loss, disability, medical
            history, police statement made and the like from any hospital,
            physician, person, party and/or authority that has any records or is
            holding any information of the claimant or me/us; and authorize any
            hospital, physician, person, party and/or authority that has any
            records or is holding any information of the claimant or me/us to
            disclose to Allied World Assurance Company, Ltd or its authorized
            representative, any and all information with respect to the
            claimant’s or my/our loss, disability, medical history, police
            statement made and the like.
          </Text>
        </View>
      </View>
      <View style={pdfStyles.subtitle}>
        <Text>Personal Data Consent</Text>
      </View>
      <View>
        <View style={pdfStyles.subContainer}>
          {PDF_DISPLAY_CHECKBOX()}
          <Text style={pdfStyles.personalDataConsentContent}>
            I/We declare that I/We have read, understood and agreed to the{" "}
            <Link src={PICS_HK_DOCUMENT_URL} wrap={false}>
              Personal Information Collection Statement
            </Link>
            .
          </Text>
        </View>
      </View>

      <View>
        <View style={pdfStyles.subContainer}>
          {pdpaStatus.promotionConsent
            ? PDF_DISPLAY_CHECKBOX()
            : PDF_DISPLAY_CHECKBOX_UNCHECKED()}
          <Text style={pdfStyles.personalDataConsentContent}>
            I/We do not want to receive any promotion materials or updates on
            other products, services or offers of Allied World Assurance
            Company, Ltd.
          </Text>
        </View>
      </View>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {region === REGION.HK ? HKPDPAComponent() : SGPDPAComponent()}
    </React.Fragment>
  );
};
