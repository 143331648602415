import React from "react";
import {
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  Button
} from "@material-ui/core";
import {
  FORM_LINKS,
  RELATION_TO_INSURED_TYPES,
  BENEFIT_TYPES,
  getCurrency
} from "common/Constants";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE,
  FORM_CONTROL_STYLE,
  GRID_CONTAINER_ALIGN_LEFT_STYLE,
  TRIP_CANCELLATION_CONTAINER_STYLE,
  ALIGN_CONTENT_LEFT_STYLE,
  SECONDARY_BUTTON_STYLE
} from "common/styles/styles";
import NavButton from "components/elements/NavButton";
import {
  DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT,
  DISPLAY_TEXT_FIELD_FULL_WIDTH,
  DISPLAY_TEXT_FIELD_COLUMN_LAYOUT,
  DISPLAY_DATE_FIELD
} from "common/components/CommonComponents";
import { getClaimTitle, getCurrencyValue } from "common/utils/CommonUtil";

function TripCancellationClaimComponent(data) {
  const benefitDetail = data.benefitDetail;
  const claimParentHandlers = data.props.parentHandlers;
  const currentClaimDetailGuid = benefitDetail.benefitId;
  const viewControls = data.viewControls;
  const validationHandlers = data.validationHandlers;
  const currentBenefitType = data.benefitType;
  const region = data.props.parentState.region;

  return (
    <React.Fragment>
      <Typography variant="h1">
        {" "}
        {getClaimTitle(BENEFIT_TYPES.tripCancellationClaim)}
      </Typography>
      <div style={SECONDARY_BUTTON_STYLE}>
        <Button
          id="view-requirements-button"
          onClick={e => viewControls.showModal()}
          color="primary"
          variant="outlined"
        >
          VIEW REQUIREMENTS
        </Button>
      </div>
      <Grid container>
        <Grid container spacing={3}>
          {DISPLAY_TEXT_FIELD_FULL_WIDTH(
            {
              helperText: data.errMsg.cancellationReason,
              error: data.hasErr.cancellationReason,
              value: benefitDetail.cancellationReason,
              name: "cancellationReason",
              blur: validationHandlers.handleTouched,
              label:
                "Reason(s) for cancelling your trip/cutting short your trip",
              placeholder:
                "Reason(s) for cancelling your trip/cutting short your trip",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              hasParams: true
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
          <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
            {DISPLAY_DATE_FIELD(
              {
                error: data.hasErr.dateOfEvent,
                blur: validationHandlers.handleTouched,
                value: benefitDetail.dateOfEvent,
                name: "dateOfEvent",
                label: "Date of Event",
                benefitType: currentBenefitType,
                currentClaimDetailGuid: currentClaimDetailGuid,
                helperText: "Date of event that led to cancellation of trip"
              },
              claimParentHandlers.handleTravelMisConnectionClaimWithParams
            )}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
            {DISPLAY_TEXT_FIELD_FULL_WIDTH(
              {
                helperText: data.errMsg.nameOfIID,
                error: data.hasErr.nameOfIID,
                value: benefitDetail.nameOfIID,
                name: "nameOfIID",
                blur: validationHandlers.handleTouched,
                label: "Name of Person who suffered injury, illness or death",
                placeholder:
                  "Name of Person who suffered injury, illness or death",
                benefitType: currentBenefitType,
                currentClaimDetailGuid: currentClaimDetailGuid,
                hasParams: true,
                isNotRequired: true
              },
              claimParentHandlers.handleTravelMisConnectionClaimWithParams
            )}
            <Grid item xs={12} sm={6}>
              <form autoComplete="off">
                <FormControl style={FORM_CONTROL_STYLE}>
                  <InputLabel htmlFor="lossRegion-select">
                    Relation to an Insured Person
                  </InputLabel>
                  <Select
                    value={benefitDetail.relationToInsured}
                    onChange={e => {
                      claimParentHandlers.handleTravelMisConnectionClaimWithParams(
                        currentBenefitType,
                        e.target.name,
                        e.target.value,
                        currentClaimDetailGuid
                      );
                    }}
                    inputProps={{
                      name: "relationToInsured",
                      id: "relationToInsured-select"
                    }}
                  >
                    {RELATION_TO_INSURED_TYPES.map(relation => (
                      <MenuItem key={relation.value} value={relation.label}>
                        <Typography variant="subtitle2">
                          {relation.label}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>

        <div className={data.viewControls.relationToInsuredClass.join(" ")}>
          {DISPLAY_TEXT_FIELD_FULL_WIDTH(
            {
              helperText: data.errMsg.otherRelation,
              error: data.hasErr.otherRelation,
              value: benefitDetail.otherRelation,
              name: "otherRelation",
              blur: validationHandlers.handleTouched,
              label: "If other, please describe.",
              placeholder: "If other, please describe.",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              hasParams: true
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
        </div>
        <Grid container spacing={3}>
          {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
            {
              helperText: "Enter amount of deposit or actual paid amount.",
              error: data.hasErr.depositAmount,
              value: benefitDetail.depositAmount,
              name: "depositAmount",
              label: "Deposit Amount",
              placeholder: "Deposit Amount",
              benefitType: currentBenefitType,
              blur: validationHandlers.handleTouched,
              isCurrency: true,
              currentClaimDetailGuid: currentClaimDetailGuid
            },
            claimParentHandlers.handleTripCancellationClaimWithParams
          )}
          {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
            {
              helperText: "Enter amount of refund you have or will receive.",
              error: data.hasErr.refundAmount,
              value: benefitDetail.refundAmount,
              name: "refundAmount",
              label: "Refund Amount",
              placeholder: "Refund Amount",
              benefitType: currentBenefitType,
              blur: validationHandlers.handleTouched,
              isCurrency: true,
              currentClaimDetailGuid: currentClaimDetailGuid
            },
            claimParentHandlers.handleTripCancellationClaimWithParams
          )}
        </Grid>
        <div className={data.viewControls.refundAmountNILClass.join(" ")}>
          {DISPLAY_TEXT_FIELD_FULL_WIDTH(
            {
              helperText: data.errMsg.noRefundDescription,
              error: data.hasErr.noRefundDescription,
              value: benefitDetail.noRefundDescription,
              name: "noRefundDescription",
              blur: validationHandlers.handleTouched,
              label: "Please explain why you have received no refund.",
              placeholder: "Please explain why you have received no refund.",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              hasParams: true
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
        </div>
        <Grid container spacing={3} style={TRIP_CANCELLATION_CONTAINER_STYLE}>
          <Grid item xs={7}>
            <Typography variant="subtitle1" style={ALIGN_CONTENT_LEFT_STYLE}>
              Total amount claimed for trip cancellation
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1">
              {getCurrency(region)}
              {getCurrencyValue(benefitDetail.totalAmount)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            id="back-button"
            name={"Back"}
            disabled={false}
            clickHandler={() => data.props.history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />
        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            id="next-button"
            name={"Next"}
            disabled={viewControls.checkForm(data.hasErr, benefitDetail)}
            clickHandler={e => {
              data.props.history.push({
                pathname: FORM_LINKS.claimantInformation,
                state: {
                  benefitId: currentClaimDetailGuid,
                  benefitType: currentBenefitType
                }
              });
            }}
          />
        </div>
      </div>
      {/* Modal */}
      {DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT(
        data.requirementModalDisplay,
        viewControls.closeModal,
        BENEFIT_TYPES.tripCancellationClaim,
        region
      )}
      {/* Modal end */}
    </React.Fragment>
  );
}

export default TripCancellationClaimComponent;
