import React from "react";
import {
  Grid,
  Typography,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from "@material-ui/core";
import {
  FORM_LINKS,
  BENEFIT_TYPES,
  TYPE_OF_COMMON_CARRIER
} from "common/Constants";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE,
  GRID_CONTAINER_ALIGN_LEFT_STYLE
} from "common/styles/styles";
import NavButton from "../../../elements/NavButton";
import "date-fns";
import { getClaimTitle } from "common/utils/CommonUtil";
import {
  SECONDARY_BUTTON_STYLE,
  FORM_CONTROL_STYLE
} from "common/styles/styles";
import {
  DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT,
  DISPLAY_TEXT_FIELD_FULL_WIDTH,
  DISPLAY_TEXT_FIELD_COLUMN_LAYOUT,
  DISPLAY_DATE_FIELD,
  DISPLAY_TIME_FIELD
} from "common/components/CommonComponents";

function FlightDelayClaimComponent(data) {
  const benefitDetail = data.benefitDetail;
  const currentClaimDetailGuid = benefitDetail.benefitId;
  const currentBenefitType = data.benefitType;
  const claimParentHandlers = data.props.parentHandlers;
  const validationHandlers = data.validationHandlers;
  const viewControls = data.viewControls;
  const region = data.props.parentState.region;

  return (
    <React.Fragment>
      <Typography variant="h1">
        {getClaimTitle(BENEFIT_TYPES.flightDelayClaim)}
      </Typography>
      <div style={SECONDARY_BUTTON_STYLE}>
        <Button
          id="view-requirements-button"
          onClick={e => viewControls.showModal()}
          color="primary"
          variant="outlined"
        >
          VIEW REQUIREMENTS
        </Button>
      </div>
      <Typography variant="h4">Original Departure Details</Typography>
      <Grid container spacing={3}>
        {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
          {
            helperText: data.errMsg.originalFlightNumber,
            error: data.hasErr.originalFlightNumber,
            value: benefitDetail.originalFlightNumber,
            name: "originalFlightNumber",
            blur: validationHandlers.handleTouched,
            label: "Common Carrier No.",
            placeholder: "Common Carrier No.",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}

        {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
          {
            helperText: data.errMsg.originalPlaceOfDeparture,
            error: data.hasErr.originalPlaceOfDeparture,
            value: benefitDetail.originalPlaceOfDeparture,
            name: "originalPlaceOfDeparture",
            blur: validationHandlers.handleTouched,
            label: "Place of Departure",
            placeholder: "Place of Departure",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
        <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
          {DISPLAY_DATE_FIELD(
            {
              helperText: data.errMsg.originalDate,
              error: data.hasErr.originalDate,
              value: benefitDetail.originalDate,
              name: "originalDate",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              blur: validationHandlers.handleTouched,
              label: "Original Date of Travel"
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
          {DISPLAY_TIME_FIELD(
            {
              helperText: data.errMsg.originalTime,
              error: data.hasErr.originalTime,
              value: benefitDetail.originalTime,
              name: "originalTime",
              label: "Original Time of Travel",
              benefitType: currentBenefitType,
              blur: validationHandlers.handleTouched,
              currentClaimDetailGuid: currentClaimDetailGuid
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
        </Grid>
        {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
          {
            helperText: data.errMsg.originalTransportProvider,
            error: data.hasErr.originalTransportProvider,
            value: benefitDetail.originalTransportProvider,
            name: "originalTransportProvider",
            blur: validationHandlers.handleTouched,
            label: "Name of Transport Provider",
            placeholder: "Name of Transport Provider",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            isNotRequired: true,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
        <Grid item xs={12} sm={6}>
          <FormControl
            style={FORM_CONTROL_STYLE}
            onBlur={e => {
              e.target.name = "originalTypeOfCarrier";
              validationHandlers.handleTouched(e);
            }}
            error={data.hasErr.originalTypeOfCarrier}
          >
            <InputLabel htmlFor="originalTypeOfCarrier" required>
              Type of Common Carrier
            </InputLabel>
            <Select
              id="originalTypeOfCarrier"
              value={benefitDetail.originalTypeOfCarrier}
              onChange={e => {
                claimParentHandlers.handleTravelMisConnectionClaimWithParams(
                  currentBenefitType,
                  e.target.name,
                  e.target.value,
                  currentClaimDetailGuid
                );
              }}
              inputProps={{
                name: "originalTypeOfCarrier",
                id: "originalTypeOfCarrier"
              }}
            >
              {TYPE_OF_COMMON_CARRIER.map(carrierType => (
                <MenuItem key={carrierType.value} value={carrierType.value}>
                  <Typography variant="subtitle2">
                    {carrierType.label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {DISPLAY_TEXT_FIELD_FULL_WIDTH(
          {
            helperText: data.errMsg.reason,
            error: data.hasErr.reason,
            value: benefitDetail.reason,
            name: "reason",
            blur: validationHandlers.handleTouched,
            label: "Reason(s) By Service Provider For the Cause of Delay",
            placeholder: "Reason(s) By Service Provider For the Cause of Delay",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
      </Grid>
      <Typography variant="h4" className="app-h4-padding-top">
        Rescheduled Departure Details
      </Typography>
      <Grid container spacing={3}>
        {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
          {
            helperText: data.errMsg.rescheduledFlightNumber,
            error: data.hasErr.rescheduledFlightNumber,
            value: benefitDetail.rescheduledFlightNumber,
            name: "rescheduledFlightNumber",
            blur: validationHandlers.handleTouched,
            label: "Common Carrier No..",
            placeholder: "Common Carrier No..",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
        {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
          {
            helperText: data.errMsg.rescheduledPlaceOfDeparture,
            error: data.hasErr.rescheduledPlaceOfDeparture,
            value: benefitDetail.rescheduledPlaceOfDeparture,
            name: "rescheduledPlaceOfDeparture",
            blur: validationHandlers.handleTouched,
            label: "Place of Departure",
            placeholder: "Place of Departure",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
        <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
          {DISPLAY_DATE_FIELD(
            {
              helperText: data.errMsg.rescheduledDate,
              error: data.hasErr.rescheduledDate,
              value: benefitDetail.rescheduledDate,
              name: "rescheduledDate",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              blur: validationHandlers.handleTouched,
              label: "Actual Date of Travel"
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
          {DISPLAY_TIME_FIELD(
            {
              helperText: data.errMsg.rescheduledTime,
              error: data.hasErr.rescheduledTime,
              value: benefitDetail.rescheduledTime,
              name: "rescheduledTime",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              blur: validationHandlers.handleTouched,
              label: "Actual Time of Travel"
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
        </Grid>
        {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
          {
            helperText: data.errMsg.rescheduledTransportProvider,
            error: data.hasErr.rescheduledTransportProvider,
            value: benefitDetail.rescheduledTransportProvider,
            name: "rescheduledTransportProvider",
            blur: validationHandlers.handleTouched,
            label: "Name of Transport Provider",
            placeholder: "Name of Transport Provider",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            isNotRequired: true,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
        <Grid item xs={12} sm={6}>
          <FormControl
            style={FORM_CONTROL_STYLE}
            onBlur={e => {
              e.target.name = "rescheduledTypeOfCarrier";
              validationHandlers.handleTouched(e);
            }}
            error={data.hasErr.rescheduledTypeOfCarrier}
          >
            <InputLabel htmlFor="rescheduledTypeOfCarrier" required>
              Type of Common Carrier
            </InputLabel>
            <Select
              id="rescheduledTypeOfCarrier"
              value={benefitDetail.rescheduledTypeOfCarrier}
              onChange={e => {
                claimParentHandlers.handleTravelMisConnectionClaimWithParams(
                  currentBenefitType,
                  e.target.name,
                  e.target.value,
                  currentClaimDetailGuid
                );
              }}
              inputProps={{
                name: "rescheduledTypeOfCarrier",
                id: "rescheduledTypeOfCarrier"
              }}
            >
              {TYPE_OF_COMMON_CARRIER.map(carrierType => (
                <MenuItem key={carrierType.value} value={carrierType.value}>
                  <Typography variant="subtitle2">
                    {carrierType.label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            id="back-button"
            name={"Back"}
            disabled={false}
            clickHandler={() => data.props.history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />
        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            id="next-button"
            name={"Next"}
            disabled={viewControls.checkForm(data.hasErr, benefitDetail)}
            clickHandler={e => {
              data.props.history.push({
                pathname: FORM_LINKS.claimantInformation,
                state: {
                  benefitId: currentClaimDetailGuid,
                  benefitType: BENEFIT_TYPES.flightDelayClaim
                }
              });
            }}
          />
        </div>
      </div>
      {/* Modal start */}
      {DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT(
        data.requirementModalDisplay,
        viewControls.closeModal,
        BENEFIT_TYPES.flightDelayClaim,
        region
      )}
      {/* Modal end */}
    </React.Fragment>
  );
}

export default FlightDelayClaimComponent;
