import React from "react";
import { Grid, Modal, Card, Typography, Button } from "@material-ui/core";
import {
  MODAL_OVERFLOW_STYLE,
  MODAL_HEADER_STYLE,
  MODAL_OK_BUTTON_STYLE,
  BUTTON_MIN_WIDTH_STYLE,
  MODAL_TEXT_CONTENT
} from "common/styles/styles";
import CountdownComponent from "common/components/CountdownComponent";

class TimeOutModal extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { showModal, handleClose, handleReset } = this.props;

    return (
      <React.Fragment>
        <Modal
          aria-labelledby="User is not active"
          aria-describedby="Form will be reset"
          open={showModal}
          style={MODAL_OVERFLOW_STYLE}
        >
          <Card className="app-modal-card-style square">
            <div style={MODAL_HEADER_STYLE}>
              <Typography variant="h3">
                Do you want to continue with the submission?
              </Typography>
            </div>
            <Typography variant="h6" style={MODAL_TEXT_CONTENT}>
              Any information that has been entered will be reset.
            </Typography>
            {showModal ? (
              <CountdownComponent handleReset={handleReset} />
            ) : null}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <div style={MODAL_OK_BUTTON_STYLE}>
                  <Button
                    id="view-requirements-ok-button"
                    onClick={e => handleClose()}
                    color="primary"
                    variant="contained"
                    style={BUTTON_MIN_WIDTH_STYLE}
                  >
                    YES
                  </Button>
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div style={MODAL_OK_BUTTON_STYLE}>
                  <Button
                    id="view-requirements-ok-button"
                    onClick={e => handleReset()}
                    color="secondary"
                    variant="contained"
                    style={BUTTON_MIN_WIDTH_STYLE}
                  >
                    NO
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
        </Modal>
      </React.Fragment>
    );
  }
}

export default TimeOutModal;
