import React from "react";
import BasicInformationComponent from "components/forms/claims/basicInformation/BasicInformationComponent";
import { FIELD_TYPE } from "common/Constants";
import { IS_CURRENT_FIELD_VALID_CHECK } from "common/utils/CommonUtil";
import { isAfter, isSameDay } from "date-fns";

class BasicInformation extends React.Component {
  policyHelperText = "Eg. 'P071C33001' or 'UNKNOWN' if not known";

  constructor() {
    super();
    this.state = {
      touched: {
        policyNumber: false,
        relatedInsuranceCompanyName: false,
        relatedInsurancePolicyNumber: false,
        travelDateFrom: false,
        travelDateTill: false,
        historicalClaimDateOfLoss: false,
        historicalClaimInsuranceCompany: false,
        historicalClaimDescription: false
      }
    };
    this.policyNumberValidation = this.policyNumberValidation.bind(this);
    this.handleTouched = this.handleTouched.bind(this);
  }

  policyNumberValidation(value) {
    if (this.state.touched.policyNumber) {
      return !(value && ("" + value).length);
    }
    return false;
  }

  handleTouched(e) {
    let targetParam = e.target.name;
    if (!this.state.touched[targetParam]) {
      this.setState(prevState => ({
        touched: {
          ...prevState.touched,
          [targetParam]: true
        }
      }));
    }
  }

  checkForm = (hasErr, currentFieldValues) => {
    if (
      !!currentFieldValues.policyNumber &&
      !!currentFieldValues.travelDateFrom &&
      !!currentFieldValues.travelDateTill
    ) {
      if (currentFieldValues.hasRelatedInsurance) {
        if (
          !!currentFieldValues.relatedInsurancePolicyNumber &&
          !!currentFieldValues.relatedInsuranceCompanyName
        ) {
          return Object.values(hasErr).indexOf(true) >= 0;
        } else {
          return true;
        }
      }
      if (currentFieldValues.hasHistoricalClaim) {
        if (
          !!currentFieldValues.historicalClaimDateOfLoss &&
          !!currentFieldValues.historicalClaimInsuranceCompany &&
          !!currentFieldValues.historicalClaimDescription
        ) {
          return Object.values(hasErr).indexOf(true) >= 0;
        } else {
          return true;
        }
      }

      return Object.values(hasErr).indexOf(true) >= 0;
    } else {
      return true;
    }
  };
  validationResult = {
    hasErr: {
      policyNumber: false,
      relatedInsuranceCompanyName: false,
      relatedInsurancePolicyNumber: false,
      travelDateFrom: false,
      travelDateTill: false,
      historicalClaimDateOfLoss: false,
      historicalClaimInsuranceCompany: false,
      historicalClaimDescription: false
    },
    errMsg: {
      policyNumber: this.policyHelperText,
      relatedInsuranceCompanyName: "",
      relatedInsurancePolicyNumber: "",
      travelDateFrom: "",
      travelDateTill: "",
      historicalClaimDateOfLoss: "",
      historicalClaimInsuranceCompany: "",
      historicalClaimDescription: ""
    }
  };

  validateFields = (currentFieldValues, touchedState, validationState) => {
    if (touchedState.policyNumber || currentFieldValues.policyNumber) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "policyNumber",
        "Policy Number ",
        this.policyHelperText,
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.text
      );
    }
    if (currentFieldValues.hasRelatedInsurance) {
      if (
        touchedState.relatedInsuranceCompanyName ||
        currentFieldValues.relatedInsuranceCompanyName
      ) {
        IS_CURRENT_FIELD_VALID_CHECK(
          "relatedInsuranceCompanyName",
          "Company Name",
          "",
          currentFieldValues,
          validationState,
          true,
          FIELD_TYPE.text
        );
      }
      if (
        touchedState.relatedInsurancePolicyNumber ||
        currentFieldValues.relatedInsurancePolicyNumber
      ) {
        IS_CURRENT_FIELD_VALID_CHECK(
          "relatedInsurancePolicyNumber",
          "Related policy number",
          "",
          currentFieldValues,
          validationState,
          true,
          FIELD_TYPE.text
        );
      }
    } else {
      validationState.hasErr.relatedInsuranceCompanyName = false;
      validationState.errMsg.relatedInsuranceCompanyName = "";
      validationState.hasErr.relatedInsurancePolicyNumber = false;
      validationState.errMsg.relatedInsurancePolicyNumber = "";
    }

    if (touchedState.travelDateFrom || currentFieldValues.travelDateFrom) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "travelDateFrom",
        "Travel from date ",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.date
      );
    }
    if (touchedState.travelDateTill || currentFieldValues.travelDateTill) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "travelDateTill",
        "Travel until date ",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.date
      );
      if (
        !validationState.hasErr.travelDateTill &&
        !validationState.hasErr.travelDateFrom
      ) {
        if (
          isSameDay(
            currentFieldValues.travelDateFrom,
            currentFieldValues.travelDateTill
          )
        ) {
          validationState.hasErr.travelDateTill = false;
          validationState.errMsg.travelDateTill = "";
        } else if (
          isAfter(
            currentFieldValues.travelDateFrom,
            currentFieldValues.travelDateTill
          )
        ) {
          validationState.hasErr.travelDateTill = true;
          validationState.errMsg.travelDateTill =
            "Travel until date cannot be after Travel from date.";
        } else {
          validationState.hasErr.travelDateTill = false;
          validationState.errMsg.travelDateTill = "";
        }
      }
    }
  };

  isEmptyFieldCheck = (
    name,
    msgFieldName,
    defaultHelperText,
    currentFieldValues,
    validationState
  ) => {
    if (currentFieldValues[name]) {
      validationState.hasErr[name] = false;
      validationState.errMsg[name] = defaultHelperText;
    } else {
      validationState.hasErr[name] = true;
      validationState.errMsg[name] = msgFieldName + " field cannot be empty";
    }
  };

  render() {
    let parentState = this.props.parentState.claim.basicInformation;
    let hasRelatedInsuranceClass = ["hidden"];
    if (parentState.hasRelatedInsurance) {
      hasRelatedInsuranceClass = ["display"];
    }

    this.validateFields(parentState, this.state.touched, this.validationResult);
    let data = {
      props: this.props,
      hasErr: this.validationResult.hasErr,
      errMsg: this.validationResult.errMsg,
      validationHandlers: {
        handleTouched: this.handleTouched
      },
      viewControls: {
        hasRelatedInsuranceClass: hasRelatedInsuranceClass,
        // hasHistoricalClaimClass: hasHistoricalClaimClass,
        checkForm: this.checkForm
      }
    };
    return BasicInformationComponent(data);
  }
}

export default BasicInformation;
