import React from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { getDate } from "common/utils/CommonUtil";
import { FORM_DETAIL_STYLE } from "common/styles/styles";

class SummaryBasicInformationComponent extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  displayRelatedInsuranceDetails = detail => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="h6">Related Insurance details</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1">Company Name</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            {detail.relatedInsuranceCompanyName}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1">Policy Number</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            {detail.relatedInsurancePolicyNumber}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  displayHistoricalClaimDetails = detail => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="h6">Historical claim details</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1">Date of loss</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            {typeof detail.historicalClaimDateOfLoss === "string"
              ? detail.historicalClaimDateOfLoss
              : getDate(detail.historicalClaimDateOfLoss)}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1">Insurance Company</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            {detail.historicalClaimInsuranceCompany}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1">Description</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            {detail.historicalClaimDescription}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    let detail = this.props.detail;

    return (
      <React.Fragment>
        <Grid container style={FORM_DETAIL_STYLE}>
          <Grid container style={FORM_DETAIL_STYLE}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Policy Number</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{detail.policyNumber}</Typography>
            </Grid>
            {detail.hasRelatedInsurance
              ? this.displayRelatedInsuranceDetails(detail)
              : null}
            <Grid item xs={6}>
              <Typography variant="subtitle1">Travel from date</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                {typeof detail.travelDateFrom === "string"
                  ? detail.travelDateFrom
                  : getDate(detail.travelDateFrom)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Travel until date</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                {typeof detail.travelDateTill === "string"
                  ? detail.travelDateTill
                  : getDate(detail.travelDateTill)}
              </Typography>
            </Grid>

            {detail.hasHistoricalClaim
              ? this.displayHistoricalClaimDetails(detail)
              : null}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default SummaryBasicInformationComponent;
