import React from "react";
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead
} from "@material-ui/core";
import {
  FORM_LINKS,
  BENEFIT_TYPES,
  MEDICAL_CLAIM_MODAL_TYPE,
  getCurrency
} from "common/Constants";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE,
  SWITCH_LABEL_DIRECTION_STYLE,
  GRID_CONTAINER_ALIGN_LEFT_STYLE,
  SECONDARY_BUTTON_STYLE,
  CLAIMANT_INFORMATION_ACTION_ICONS_STYLE
} from "common/styles/styles";
import NavButton from "components/elements/NavButton";
import {
  DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT,
  DISPLAY_TEXT_FIELD_FULL_WIDTH,
  DISPLAY_TEXT_FIELD_COLUMN_LAYOUT,
  DISPLAY_DATE_FIELD,
  DISPLAY_TIME_FIELD
} from "common/components/CommonComponents";
import {
  getDate,
  getClaimTitle,
  getCurrencyValue
} from "common/utils/CommonUtil";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ConsultationDetail from "components/forms/benefits/medicalClaim/ConsultationDetail";

function MedicalClaimComponent(data) {
  let benefitDetail = data.benefitDetail;
  const currentClaimDetailGuid = benefitDetail.benefitId;
  const claimParentHandlers = data.props.parentHandlers;
  const validationHandlers = data.validationHandlers;
  const viewControls = data.viewControls;
  const currentBenefitType = data.benefitType;
  const region = data.props.parentState.region;
  const detail = data.detail;

  const addConsultation = (e, modalType) => {
    if (e) {
      claimParentHandlers.addNewMultipleConsultation(
        benefitDetail.benefitId,
        modalType,
        () => getLatestLostItemId(modalType)
      );
    }
  };

  const deleteIcon = (benefitId, itemId, modalType) => (
    <IconButton
      id={"delete-button-" + benefitId}
      style={CLAIMANT_INFORMATION_ACTION_ICONS_STYLE}
      fontSize="small"
      onClick={e => {
        if (e) {
          claimParentHandlers.deleteMultipleConsultation(
            benefitId,
            itemId,
            modalType
          );
        }
      }}
    >
      <DeleteIcon color="primary" />
    </IconButton>
  );

  const editIcon = (benefitId, item) => (
    <IconButton
      id={"edit-button-" + benefitId}
      style={CLAIMANT_INFORMATION_ACTION_ICONS_STYLE}
      fontSize="small"
      onClick={e => {
        viewControls.handleOpenModal(e, benefitId, item);
      }}
    >
      <EditIcon fontSize="small" color="primary" />
    </IconButton>
  );

  let getLatestLostItemId = modalType => {
    let items = claimParentHandlers.getMultipleConsultation(
      benefitDetail.benefitId,
      modalType
    );
    const itemsIndex = items.length - 1;
    let itemDetail = items[itemsIndex];
    viewControls.handleOpenModal(
      itemDetail,
      itemDetail.multipleConsultationId,
      itemDetail
    );
  };

  let showMultipleConsultationTable = modalType => {
    let multipleConsultationItems = benefitDetail[modalType];
    return (
      <React.Fragment>
        <Paper square className="enable-horizontal-scroll">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                <TableCell>Date Of Consultation</TableCell>
                <TableCell>Amount Claimed ({getCurrency(region)})</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {multipleConsultationItems.map(item => {
                let itemId = item.multipleConsultationId;
                let date = getDate(item.dateOfConsultation);
                return (
                  <TableRow key={itemId}>
                    <TableCell>
                      {deleteIcon(benefitDetail.benefitId, itemId, modalType)}
                      {editIcon(itemId, item)}
                    </TableCell>
                    <TableCell>{date}</TableCell>
                    <TableCell>
                      {getCurrencyValue(item.amountClaimed)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  };

  const showMedicalClaim = () => (
    <React.Fragment>
      {/*Medical related */}
      <Grid container>
        {DISPLAY_TEXT_FIELD_FULL_WIDTH(
          {
            helperText: data.errMsg.typeOfSymptom,
            error: data.hasErr.typeOfSymptom,
            value: benefitDetail.typeOfSymptom,
            name: "typeOfSymptom",
            blur: validationHandlers.handleTouched,
            label: "Please describe the type of symptoms",
            placeholder: "Please describe the type of symptoms",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
      </Grid>
      <Grid container style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
        {DISPLAY_DATE_FIELD(
          {
            helperText: data.errMsg.dateOfSymptom,
            error: data.hasErr.dateOfSymptom,
            value: benefitDetail.dateOfSymptom,
            name: "dateOfSymptom",
            blur: validationHandlers.handleTouched,
            label: "Date symptoms first appeared",
            placeholder: "Date symptoms first appeared",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
      </Grid>
      <div>
        <div style={SECONDARY_BUTTON_STYLE}>
          <Button
            id="add-new-lost-item"
            onClick={e => addConsultation(e, MEDICAL_CLAIM_MODAL_TYPE.medical)}
            color="primary"
            variant="outlined"
          >
            Add Consultation
          </Button>
        </div>
        {benefitDetail[MEDICAL_CLAIM_MODAL_TYPE.medical].length
          ? showMultipleConsultationTable(MEDICAL_CLAIM_MODAL_TYPE.medical)
          : null}
      </div>
      {/* Add/Edit Medical Modal */}
      <ConsultationDetail
        modalOpen={data.modalOpen}
        viewControls={viewControls}
        claimParentHandlers={claimParentHandlers}
        modalType={MEDICAL_CLAIM_MODAL_TYPE.medical}
        benefitDetail={benefitDetail}
        detail={detail}
        handleSaveAndCloseModal={viewControls.handleSaveAndCloseModal}
        handleCloseModal={viewControls.handleCloseModal}
        updateModal={viewControls.updateModal}
        touchedModal={data.touchedModal}
        handleTouchedModal={validationHandlers.handleTouchedModal}
        checkModal={validationHandlers.checkModal}
      />
      {/* Add/Edit Medical Modal - end */}
    </React.Fragment>
  );

  const showAccidentClaim = () => (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
          {DISPLAY_DATE_FIELD(
            {
              value: benefitDetail.dateOfAccident,
              name: "dateOfAccident",
              label: "Date of Accident",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              blur: validationHandlers.handleTouched,
              error: data.hasErr.dateOfAccident,
              helperText: data.errMsg.dateOfAccident
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
          {DISPLAY_TIME_FIELD(
            {
              value: benefitDetail.timeOfAccident,
              name: "timeOfAccident",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              label: "Time of Accident",
              blur: validationHandlers.handleTouched,
              error: data.hasErr.timeOfAccident,
              helperText: data.errMsg.timeOfAccident
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
        </Grid>
      </Grid>
      {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
        {
          helperText: data.errMsg.placeOfAccident,
          error: data.hasErr.placeOfAccident,
          blur: validationHandlers.handleTouched,
          label: "Place of Accident",
          value: benefitDetail.placeOfAccident,
          placeholder: "Place of Accident",
          benefitType: currentBenefitType,
          name: "placeOfAccident",
          currentClaimDetailGuid: currentClaimDetailGuid,
          hasParams: true
        },
        claimParentHandlers.handleTravelMisConnectionClaimWithParams
      )}
      {DISPLAY_TEXT_FIELD_FULL_WIDTH(
        {
          helperText: data.errMsg.descriptionOfAccident,
          error: data.hasErr.descriptionOfAccident,
          blur: validationHandlers.handleTouched,
          label: "Please describe how the accident happened",
          value: benefitDetail.descriptionOfAccident,
          placeholder: "Please describe how the accident happened",
          benefitType: currentBenefitType,
          name: "descriptionOfAccident",
          currentClaimDetailGuid: currentClaimDetailGuid,
          hasParams: true
        },
        claimParentHandlers.handleTravelMisConnectionClaimWithParams
      )}
      {DISPLAY_TEXT_FIELD_FULL_WIDTH(
        {
          helperText: data.errMsg.injuriesInAccident,
          error: data.hasErr.injuriesInAccident,
          blur: validationHandlers.handleTouched,
          label: "Please describe the injuries sustained",
          value: benefitDetail.injuriesInAccident,
          placeholder: "Please describe the injuries sustained",
          benefitType: currentBenefitType,
          name: "injuriesInAccident",
          currentClaimDetailGuid: currentClaimDetailGuid,
          hasParams: true
        },
        claimParentHandlers.handleTravelMisConnectionClaimWithParams
      )}

      <div>
        <div style={SECONDARY_BUTTON_STYLE}>
          <Button
            id="add-new-lost-item"
            onClick={e => addConsultation(e, MEDICAL_CLAIM_MODAL_TYPE.accident)}
            color="primary"
            variant="outlined"
          >
            Add Consultation
          </Button>
        </div>
        {benefitDetail[MEDICAL_CLAIM_MODAL_TYPE.accident].length
          ? showMultipleConsultationTable(MEDICAL_CLAIM_MODAL_TYPE.accident)
          : null}
      </div>
      {/* Add/Edit Medical Modal */}
      <ConsultationDetail
        modalOpen={data.modalOpen}
        viewControls={viewControls}
        claimParentHandlers={claimParentHandlers}
        modalType={MEDICAL_CLAIM_MODAL_TYPE.accident}
        benefitDetail={benefitDetail}
        detail={detail}
        handleSaveAndCloseModal={viewControls.handleSaveAndCloseModal}
        handleCloseModal={viewControls.handleCloseModal}
        updateModal={viewControls.updateModal}
        touchedModal={data.touchedModal}
        handleTouchedModal={validationHandlers.handleTouchedModal}
        checkModal={validationHandlers.checkModal}
      />
      {/* Add/Edit Medical Modal - end */}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Typography variant="h1">
        {getClaimTitle(BENEFIT_TYPES.medicalClaim)}
      </Typography>
      <div style={SECONDARY_BUTTON_STYLE}>
        <Button
          id={"view-requirements-button"}
          onClick={e => viewControls.showModal()}
          color="primary"
          variant="outlined"
        >
          VIEW REQUIREMENTS
        </Button>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              style={SWITCH_LABEL_DIRECTION_STYLE}
              control={
                <Switch
                  id="isAccidentRelated"
                  name="isAccidentRelated"
                  checked={benefitDetail.isAccidentRelated}
                  onChange={e => {
                    if (e) {
                      claimParentHandlers.handleTravelMisConnectionClaimWithParams(
                        currentBenefitType,
                        "isAccidentRelated",
                        !benefitDetail.isAccidentRelated,
                        currentClaimDetailGuid
                      );
                    }
                  }}
                  value="isAccidentRelated"
                  color="primary"
                />
              }
              label="Is your medical claim relating to an Accident?"
              labelPlacement="start"
            />
          </FormGroup>
        </Grid>
      </Grid>
      {/* Accident related */}
      {benefitDetail.isAccidentRelated
        ? showAccidentClaim()
        : showMedicalClaim()}

      <Grid container>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              style={SWITCH_LABEL_DIRECTION_STYLE}
              control={
                <Switch
                  id="hasHistory"
                  name="hasHistory"
                  checked={benefitDetail.hasHistory}
                  onChange={e => {
                    if (e) {
                      claimParentHandlers.handleTravelMisConnectionClaimWithParams(
                        currentBenefitType,
                        "hasHistory",
                        !benefitDetail.hasHistory,
                        currentClaimDetailGuid
                      );
                    }
                  }}
                  value="hasHistory"
                  color="primary"
                />
              }
              label="Have you ever had the same or similar condition/symptom?"
              labelPlacement="start"
            />
          </FormGroup>
        </Grid>
      </Grid>

      {benefitDetail.hasHistory ? (
        <Grid container>
          {DISPLAY_TEXT_FIELD_FULL_WIDTH(
            {
              helperText: data.errMsg.historicalClaimConsultationDetails,
              error: data.hasErr.historicalClaimConsultationDetails,
              value: benefitDetail.historicalClaimConsultationDetails,
              name: "historicalClaimConsultationDetails",
              blur: validationHandlers.handleTouched,
              label:
                "Please state name and address of Physician and date of previous treatment",
              placeholder:
                "Please state name and address of Physician and date of previous treatment",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              hasParams: true
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
        </Grid>
      ) : null}

      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            id={"back-button"}
            name={"Back"}
            disabled={false}
            clickHandler={() => data.props.history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />
        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            id={"next-button"}
            name={"Next"}
            disabled={viewControls.checkForm(data.hasErr, benefitDetail)}
            clickHandler={e => {
              data.props.history.push({
                pathname: FORM_LINKS.claimantInformation,
                state: {
                  benefitId: currentClaimDetailGuid,
                  benefitType: BENEFIT_TYPES.medicalClaim
                }
              });
            }}
          />
        </div>
      </div>

      {/* Modal */}
      {DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT(
        data.requirementModalDisplay,
        viewControls.closeModal,
        BENEFIT_TYPES.medicalClaim,
        region
      )}
      {/* Modal end */}
    </React.Fragment>
  );
}

export default MedicalClaimComponent;
