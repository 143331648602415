import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { BENEFIT_TYPES, REGION } from "common/Constants";
import PDFAccidentDetailComponent from "common/pdfGeneration/PDFAccidentDetailComponent";
import PDFTripCancellationDetailComponent from "common/pdfGeneration/PDFTripCancellationDetailComponent";
import PDFTravelMisconnectionDetailComponent from "common/pdfGeneration/PDFTravelMisconnectionDetailComponent";
import PDFFlightDetailComponent from "common/pdfGeneration/PDFFlightDetailComponent";
import PDFLostBaggageDetailComponent from "common/pdfGeneration/PDFLostBaggageDetailComponent";
import PDFMedicalDetailComponent from "common/pdfGeneration/PDFMedicalDetailComponent";
import PDFOtherDetailComponent from "common/pdfGeneration/PDFOtherDetailComponent";
import { getTime, getDate } from "common/utils/CommonUtil";

const MASK_CHAR = "*";
const UNMASKED_CHAR_LENGTH = 4;

export const BENEFIT_SECTION_SUBTITLE = (prefix, title) => (
  <React.Fragment>
    <View style={pdfStyles.subtitle}>
      <Text>{prefix + ". " + title}</Text>
    </View>
  </React.Fragment>
);

export const CLAIMANT_INFORMATION_SUB_HEADING = (prefix, title) => (
  <React.Fragment>
    <View style={pdfStyles.subtitle3}>
      <Text>{prefix + ". " + title}</Text>
    </View>
  </React.Fragment>
);

export const PDF_DISPLAY_DATE_COMPONENT = (label, value) => (
  <View style={pdfStyles.subContainer}>
    <Text style={pdfStyles.label}>{label}</Text>
    <Text style={pdfStyles.text}>
      {typeof value === "string" ? value : getDate(value)}
    </Text>
  </View>
);

export const PDF_DISPLAY_TIME_COMPONENT = (label, value) => (
  <View style={pdfStyles.subContainer}>
    <Text style={pdfStyles.label}>{label}</Text>
    <Text style={pdfStyles.text}>
      {typeof value === "string" ? value : getTime(value)}
    </Text>
  </View>
);

export const PDF_DISPLAY_LABEL_VALUE_COMPONENT = (label, value) => (
  <View style={pdfStyles.subContainer}>
    <Text style={pdfStyles.label}>{label}</Text>
    <Text style={pdfStyles.text}>{value}</Text>
  </View>
);

export const PDF_DISPLAY_CONTACT_INFO = (label, value) => (
  <View style={pdfStyles.subContainer}>
    <Text style={pdfStyles.contactInfoLabel}>{label}</Text>
    <Text style={pdfStyles.contactInfoText}>{value}</Text>
  </View>
);

export const PDF_DISPLAY_CHECKBOX = () => (
  <Image style={pdfStyles.checkbox} source={"./checkbox_selected.png"} />
);

export const PDF_DISPLAY_CHECKBOX_UNCHECKED = () => (
  <Image style={pdfStyles.checkbox} source={"./checkbox_unselected.png"} />
);

export const pdfStyles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  label: {
    flex: 1,
    fontWeight: "bold",
    fontSize: 9
  },
  subContainer: {
    display: "flex",
    flexDirection: "row"
  },
  contactInfoLabel: {
    flex: 1,
    textAlign: "justify",
    paddingLeft: "20px",
    fontSize: 9,
    marginTop: 2
  },
  contactInfoText: {
    flex: 1,
    textAlign: "justify",
    fontSize: 9,
    marginTop: 2
  },
  subContainer2: {
    display: "flex",
    flexDirection: "column"
  },
  submissionTimeContainer: {
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end"
  },
  submissionTime: {
    fontSize: 9,
    marginTop: 2,
    flexBasis: 80,
    flexShrink: 1
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    padding: "16px 0px 12px 0px",
    textAlign: "center"
  },
  mainTitle: {
    fontSize: 18,
    fontWeight: "bold",
    padding: "12px 0px",
    textAlign: "center"
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "bold",
    margin: 0,
    padding: "12px 0px"
  },
  subtitle2: {
    fontSize: 12,
    fontWeight: "bold",
    padding: "6px 0px"
  },
  subtitle3: {
    fontSize: 10,
    fontWeight: "bold",
    padding: "6px 0px"
  },
  totalAmountLabel: {
    fontSize: 12,
    fontWeight: "bold",
    padding: "6px 0px",
    flex: 1
  },
  totalAmountValue: {
    flex: 1,
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify"
  },
  text: {
    flex: 1,
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify",
    borderBottomStyle: "dashed",
    borderBottomWidth: 1
  },
  subTotalText: {
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify",
    paddingTop: "12px",
    paddingLeft: "30%"
  },
  declarationContent: {
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify"
  },
  authorizationContent: {
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify",
    paddingRight: "50px"
  },
  personalDataConsentContent: {
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify",
    paddingRight: "50px"
  },
  declarationMainContent: {
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify",
    paddingTop: "12px"
  },
  declarationMainContentNumbering: {
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify",
    paddingRight: "12px"
  },
  declarationMainContentText: {
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify"
  },
  declarationMainContentSubNumbering: {
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify",
    paddingRight: "12px",
    paddingLeft: "12px"
  },
  declarationMainContentSubNumberingii: {
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify",
    paddingRight: "11px"
  },
  declarationMainContentSubNumberingiii: {
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify",
    paddingRight: "10px"
  },
  declarationMainContentFineSubText: {
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify",
    paddingLeft: "20px"
  },
  declarationMainContentSubText: {
    fontSize: 9,
    marginTop: 2,
    textAlign: "justify",
    paddingLeft: "12px"
  },
  checkbox: {
    height: 15,
    width: 20
  },
  headerSubContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingBottom: "16px"
  },
  headerImage: {
    height: 58,
    width: 178
  },
  fileUploadContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "stretch"
  },
  fileUploadText: {
    maxWidth: "30%",
    width: "30%",
    paddingLeft: 10,
    paddingBottom: 6,
    fontSize: 9,
    marginTop: 2
  }
});

export const BasicInformation = basicInformation => {
  return (
    <React.Fragment>
      <View style={pdfStyles.title}>
        <Text>Policy Information</Text>
      </View>
      <View style={pdfStyles.subContainer}>
        <Text style={pdfStyles.label}>Policy number:</Text>
        <Text style={pdfStyles.text}>{basicInformation.policyNumber}</Text>
      </View>
      {PDF_DISPLAY_DATE_COMPONENT(
        "Travel from date:",
        basicInformation.travelDateFrom
      )}
      {PDF_DISPLAY_DATE_COMPONENT(
        "Travel until date:",
        basicInformation.travelDateTill
      )}
      {basicInformation.hasRelatedInsurance ? (
        <React.Fragment>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.label}>Insurance Company name:</Text>
            <Text style={pdfStyles.text}>
              {basicInformation.relatedInsuranceCompanyName}
            </Text>
          </View>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.label}>Policy/Certificate No.:</Text>
            <Text style={pdfStyles.text}>
              {basicInformation.relatedInsurancePolicyNumber}
            </Text>
          </View>
        </React.Fragment>
      ) : null}
      {basicInformation.hasHistoricalClaim ? (
        <React.Fragment>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.label}>Claim Date of loss:</Text>
            <Text style={pdfStyles.text}>
              {basicInformation.historicalClaimDateOfLoss}
            </Text>
          </View>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.label}>Claim Insurance company:</Text>
            <Text style={pdfStyles.text}>
              {basicInformation.historicalClaimInsuranceCompany}
            </Text>
          </View>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.label}>Claim description:</Text>
            <Text style={pdfStyles.text}>
              {basicInformation.historicalClaimDescription}
            </Text>
          </View>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export const CLAIM_DETAILS_SUB_SECTION = text => (
  <React.Fragment>
    <View style={pdfStyles.subtitle2}>
      <Text>{text}</Text>
    </View>
  </React.Fragment>
);

export const CLAIMANT_DETAILS_FOR_BENEFIT = (benefitId, claimants, region) => {
  let relatedClaimants = claimants.filter(claimant =>
    claimant.relatedClaim.includes(benefitId)
  );
  return (
    <React.Fragment>
      {CLAIM_DETAILS_SUB_SECTION("Claimant details")}
      {relatedClaimants.map((value, index) => {
        return (
          <ClaimantDetail
            key={value.claimantId}
            index={index + 1}
            detail={value}
            region={region}
          />
        );
      })}
    </React.Fragment>
  );
};

export const ClaimantDetail = data => {
  const claimantDetail = data.detail;
  const region = data.region;
  const index = data.index;
  return (
    <React.Fragment>
      {CLAIMANT_INFORMATION_SUB_HEADING(index, "Claimant Detail")}

      <View style={pdfStyles.container}>
        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.label}>Name:</Text>
          <Text style={pdfStyles.text}>
            {claimantDetail.name + " " + claimantDetail.surname}
          </Text>
        </View>

        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.label}>Email:</Text>
          <Text style={pdfStyles.text}>{claimantDetail.email}</Text>
        </View>

        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.label}>Phone:</Text>
          <Text style={pdfStyles.text}>{claimantDetail.phone}</Text>
        </View>

        {/* MASK_ID_NUMBER */}
        {region === REGION.SG ? (
          <React.Fragment>
            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>ID Type:</Text>
              <Text style={pdfStyles.text}>{claimantDetail.sgIDType}</Text>
            </View>

            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>ID Number:</Text>
              <Text style={pdfStyles.text}>
                {MASK_ID_NUMBER(claimantDetail.sgIDNumber)}
              </Text>
            </View>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>ID Type:</Text>
              <Text style={pdfStyles.text}>{claimantDetail.hkIDType}</Text>
            </View>

            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>ID Number:</Text>
              <Text style={pdfStyles.text}>
                {MASK_ID_NUMBER(claimantDetail.hkIDNumber)}
              </Text>
            </View>
          </React.Fragment>
        )}
      </View>
    </React.Fragment>
  );
};

export const FileUploadDetails = fileUploads => {
  return (
    <React.Fragment>
      <View style={pdfStyles.title}>
        <Text>Uploaded Files</Text>
      </View>
      <View style={pdfStyles.fileUploadContainer}>
        {fileUploads.map((file, index) => {
          return (
            <Text key={index} style={pdfStyles.fileUploadText}>
              {file.fileName}
            </Text>
          );
        })}
      </View>
    </React.Fragment>
  );
};

export const ClaimDetails = (currentClaim, region) => {
  let claimants = currentClaim.claimantInformation || [];
  let flightDelayClaim =
    currentClaim.claimDetail[BENEFIT_TYPES.flightDelayClaim] || [];
  let lostBaggageClaim =
    currentClaim.claimDetail[BENEFIT_TYPES.lostBaggageClaim] || [];
  let tripCancellation =
    currentClaim.claimDetail[BENEFIT_TYPES.tripCancellationClaim] || [];
  let medicalClaim = currentClaim.claimDetail[BENEFIT_TYPES.medicalClaim] || [];
  let travelMisConnectionClaim =
    currentClaim.claimDetail[BENEFIT_TYPES.travelMisConnectionClaim] || [];
  let accidentClaim =
    currentClaim.claimDetail[BENEFIT_TYPES.accidentClaim] || [];
  let otherClaim = currentClaim.claimDetail[BENEFIT_TYPES.otherClaim] || [];

  let countOfAccident = accidentClaim.length;
  let countOfFlightDelay = countOfAccident + flightDelayClaim.length;
  let countOfLostBaggage = countOfFlightDelay + lostBaggageClaim.length;
  let countOfMedical = countOfLostBaggage + medicalClaim.length;
  let countOfOther = countOfMedical + otherClaim.length;
  let countOfTravelMisconnection =
    countOfOther + travelMisConnectionClaim.length;

  return (
    <React.Fragment>
      <View style={pdfStyles.title}>
        <Text>Claim Details</Text>
      </View>

      {accidentClaim.map((detail, index) => {
        return (
          <PDFAccidentDetailComponent
            detail={detail}
            index={index}
            suffix={index + 1}
            key={detail.benefitId}
            region={region}
            claimants={claimants}
          />
        );
      })}

      {flightDelayClaim.map((detail, index) => {
        return (
          <PDFFlightDetailComponent
            detail={detail}
            index={index}
            suffix={index + 1 + countOfAccident}
            key={detail.benefitId}
            region={region}
            claimants={claimants}
          />
        );
      })}

      {lostBaggageClaim.map((detail, index) => (
        <PDFLostBaggageDetailComponent
          detail={detail}
          index={index}
          suffix={index + 1 + countOfFlightDelay}
          key={detail.benefitId}
          region={region}
          claimants={claimants}
        />
      ))}

      {medicalClaim.map((detail, index) => (
        <PDFMedicalDetailComponent
          detail={detail}
          index={index}
          suffix={index + 1 + countOfLostBaggage}
          key={detail.benefitId}
          region={region}
          claimants={claimants}
        />
      ))}

      {otherClaim.map((detail, index) => (
        <PDFOtherDetailComponent
          detail={detail}
          index={index}
          suffix={index + 1 + countOfMedical}
          key={detail.benefitId}
          region={region}
          claimants={claimants}
        />
      ))}

      {travelMisConnectionClaim.map((detail, index) => (
        <PDFTravelMisconnectionDetailComponent
          detail={detail}
          index={index}
          suffix={index + 1 + countOfOther}
          key={detail.benefitId}
          region={region}
          claimants={claimants}
        />
      ))}

      {tripCancellation.map((detail, index) => (
        <PDFTripCancellationDetailComponent
          detail={detail}
          index={index}
          suffix={index + 1 + countOfTravelMisconnection}
          key={detail.benefitId}
          region={region}
          claimants={claimants}
        />
      ))}
    </React.Fragment>
  );
};

export const RelatedClaimantNames = (claiamants, benefitId, benefitType) => {
  // FIXME get related claimant names
  return <React.Fragment></React.Fragment>;
};

export const Reporter = (claim, region) => {
  let reporterDetails = claim.reporter.isPolicyHolder
    ? claim.aboutSelf
    : claim.reporter;

  return (
    <React.Fragment>
      <View style={pdfStyles.title}>
        <Text>Reporter Information</Text>
      </View>
      <View style={pdfStyles.container}>
        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.label}>Name:</Text>
          <Text style={pdfStyles.text}>
            {reporterDetails.name + " " + reporterDetails.surname}
          </Text>
        </View>

        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.label}>Email:</Text>
          <Text style={pdfStyles.text}>{reporterDetails.email}</Text>
        </View>

        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.label}>Phone:</Text>
          <Text style={pdfStyles.text}>{reporterDetails.phone}</Text>
        </View>

        {/* MASK_ID_NUMBER */}
        {region === REGION.SG ? (
          <React.Fragment>
            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>ID Type:</Text>
              <Text style={pdfStyles.text}>{reporterDetails.sgIDType}</Text>
            </View>

            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>ID Number:</Text>
              <Text style={pdfStyles.text}>
                {MASK_ID_NUMBER(reporterDetails.sgIDNumber)}
              </Text>
            </View>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>ID Type:</Text>
              <Text style={pdfStyles.text}>{reporterDetails.hkIDType}</Text>
            </View>

            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>ID Number:</Text>
              <Text style={pdfStyles.text}>
                {MASK_ID_NUMBER(reporterDetails.hkIDNumber)}
              </Text>
            </View>
          </React.Fragment>
        )}
      </View>
    </React.Fragment>
  );
};

export const PolicyHolder = (aboutSelf, region) => {
  return (
    <React.Fragment>
      <View style={pdfStyles.title}>
        <Text id="policyholder-title">Policyholder Information</Text>
      </View>
      <View style={pdfStyles.container}>
        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.label}>Name:</Text>
          <Text id="policyholder-name" style={pdfStyles.text}>
            {aboutSelf.name + " " + aboutSelf.surname}
          </Text>
        </View>

        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.label}>Email:</Text>
          <Text style={pdfStyles.text} id="policyholder-email">
            {aboutSelf.email}
          </Text>
        </View>

        <View style={pdfStyles.subContainer}>
          <Text style={pdfStyles.label}>Phone:</Text>
          <Text style={pdfStyles.text} id="policyholder-phone">
            {aboutSelf.phone}
          </Text>
        </View>

        {/* MASK_ID_NUMBER */}
        {region === REGION.SG ? (
          <React.Fragment>
            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>ID Type:</Text>
              <Text style={pdfStyles.text} id="policyholder-sg-idType">
                {aboutSelf.sgIDType}
              </Text>
            </View>

            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>ID Number:</Text>
              <Text style={pdfStyles.text} id="policyholder-sg-idNumber">
                {MASK_ID_NUMBER(aboutSelf.sgIDNumber)}
              </Text>
            </View>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>ID Type:</Text>
              <Text style={pdfStyles.text} id="policyholder-sg-idType">
                {aboutSelf.hkIDType}
              </Text>
            </View>

            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>ID Number:</Text>
              <Text style={pdfStyles.text} id="policyholder-sg-idNumber">
                {MASK_ID_NUMBER(aboutSelf.hkIDNumber)}
              </Text>
            </View>
          </React.Fragment>
        )}
      </View>
    </React.Fragment>
  );
};

export const PDFHeader = () => {
  return (
    <React.Fragment>
      <View style={pdfStyles.container}>
        <View style={pdfStyles.headerSubContainer}>
          <Image
            id="header-image"
            style={pdfStyles.headerImage}
            source={"./AlliedWorld_Logo.png"}
          />
        </View>
      </View>
    </React.Fragment>
  );
};

export const SubmissionTime = (submissionTime, region) => {
  return (
    <React.Fragment>
      {!!submissionTime ? (
        <View style={pdfStyles.submissionTimeContainer}>
          <View style={pdfStyles.submissionTime}>
            <Text id="submission-time-label">
              Reported Time {region === REGION.HK ? "(HK)" : "(SG)"}:{" "}
            </Text>
            <Text id="submission-time-value">{submissionTime}</Text>
          </View>
        </View>
      ) : null}
    </React.Fragment>
  );
};

export const SubmissionDetail = submissionId => {
  return (
    <React.Fragment>
      <View style={pdfStyles.mainTitle}>
        <Text>Claims Submission Summary</Text>
      </View>
      <View style={pdfStyles.title}>
        <Text id="submission-detail-title">Claim ID: {submissionId}</Text>
      </View>
    </React.Fragment>
  );
};

export const MASK_ID_NUMBER = idNumber => {
  if (typeof idNumber === "string") {
    idNumber = idNumber.trim();
    const idLength = idNumber.length;
    if (idLength < 8) {
      return "****";
    }
    let maskedId = "*";
    maskedId = "".concat(
      MASK_CHAR.repeat(idLength - UNMASKED_CHAR_LENGTH),
      idNumber.slice(idLength - UNMASKED_CHAR_LENGTH)
    );
    return maskedId;
  } else {
    console.error("ID Number is not string.");
    return "*****";
  }
};

export const GET_CURRENCY_VALUE_STRING = (currency, value) =>
  "" + currency + " " + value;
