import React from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Collapse,
  Typography,
  ListItemText,
  ListItemSecondaryAction,
  List,
  ListItem,
  Checkbox
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { EXPANSION_PANEL_STYLE } from "common/styles/styles";

class FileUploadConfirmationDetails extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let title = this.props.titleSuffix + ". " + this.props.title;
    const benefitId = this.props.benefitId;
    const benefitDetail = this.props.benefitDetail;
    const benefitType = this.props.benefitType;
    const fileRequirements = benefitDetail.fileRequirements || [];
    const parentHandlers = this.props.parentHandlers;

    return (
      <React.Fragment>
        <ExpansionPanel
          defaultExpanded
          square={true}
          TransitionComponent={Collapse}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel1c-header"
          >
            <div className="claimsAppColumn">
              <Typography variant="subtitle1">{title}</Typography>
            </div>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails style={EXPANSION_PANEL_STYLE}>
            <List>
              {fileRequirements.map((item, index) => {
                return (
                  <ListItem key={index + "medical-claim-requirement-detail"}>
                    <ListItemText>
                      <Typography variant="subtitle2" key={index + "medical"}>
                        {index + 1}. {item.requirement}
                      </Typography>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={item.uploaded}
                        onChange={() => {
                          parentHandlers.handleRequirementChecklist(
                            benefitId,
                            benefitType,
                            item.id,
                            !item.uploaded
                          );
                        }}
                        value="false"
                        color="primary"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </React.Fragment>
    );
  }
}

export default FileUploadConfirmationDetails;
