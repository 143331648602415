import { Button } from "@material-ui/core";
import React from "react";
import { FORM_NAVIGATION_BUTTON_STYLE } from "common/styles/styles";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@material-ui/icons/NavigateBeforeRounded";

class NavButton extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "button",
      disabled: false
    };
  }

  render() {
    const name = this.props.name ? this.props.name : this.state.name;
    const className = this.props.className ? this.props.className : "";
    const clickHandler = this.props.clickHandler;
    const disabled = !!this.props.disabled;
    const id = this.props.id ? this.props.id : name;
    const displayNextArrow = this.props.displayNextArrow || false;
    const displayBackArrow = this.props.displayBackArrow || false;

    return (
      <Button
        id={id}
        variant="contained"
        color="primary"
        className={className}
        style={FORM_NAVIGATION_BUTTON_STYLE}
        onClick={e => {
          clickHandler(name);
        }}
        disabled={disabled}
      >
        {!disabled && displayBackArrow ? <NavigateBeforeRoundedIcon /> : null}{" "}
        {name}{" "}
        {!disabled && displayNextArrow ? <NavigateNextRoundedIcon /> : null}
      </Button>
    );
  }
}

export default NavButton;
