import React from "react";
import AboutSelfComponent from "components/forms/aboutSelf/AboutSelfComponent";
import {
  validateEmail,
  validatePhoneNumber,
  IS_EMPTY_FIELD_CHECK
} from "common/utils/CommonUtil";
import { IS_SG_REGION, TIMEOUT_DELAY_50MS } from "common/Constants";
import {
  UPDATE_CLAIM_DIRECTORY,
  GET_CLAIM_DIRECTORY
} from "common/utils/CommonUtil";
import { UPDATE_CLAIM_SUBMISSION_ID } from "common/utils/ClaimSubmission";

class AboutSelf extends React.Component {
  constructor() {
    super();
    this.state = {
      touched: {
        surname: false,
        name: false,
        email: false,
        phone: false,
        sgIDType: false,
        sgIDNumber: false,
        hkIDType: false,
        hkIDNumber: false
      }
    };
  }

  componentWillUnmount() {
    this.props.parentHandlers.handlePolicyHolderSelfInformation();
  }

  componentDidMount() {
    let currentClaimDirectory = this.props.parentState.claim.claimDirectory;
    const region = this.props.parentState.region;
    setTimeout(async () => {
      if (currentClaimDirectory) {
        let newClaimDirectory = await GET_CLAIM_DIRECTORY();
        if (newClaimDirectory && newClaimDirectory !== currentClaimDirectory) {
          console.log(`Updating to new Claim Directory: ${newClaimDirectory};`);
          this.props.parentHandlers.handleClaimDirectoryUpdate(
            newClaimDirectory
          );
          UPDATE_CLAIM_SUBMISSION_ID(
            region,
            this.props.parentHandlers.updateSubmissionId
          );
        }
      } else {
        UPDATE_CLAIM_DIRECTORY(
          this.props.parentHandlers.handleClaimDirectoryUpdate
        );
        UPDATE_CLAIM_SUBMISSION_ID(
          region,
          this.props.parentHandlers.updateSubmissionId
        );
      }
    }, TIMEOUT_DELAY_50MS);
  }

  handleTouched = e => {
    let targetParam = e.target.name;
    if (!this.state.touched[targetParam]) {
      this.setState(prevState => ({
        touched: {
          ...prevState.touched,
          [targetParam]: true
        }
      }));
    }
  };

  checkForm = (hasErr, currentFieldValues, isSG) => {
    if (isSG) {
      if (
        !!currentFieldValues.email &&
        !!currentFieldValues.surname &&
        !!currentFieldValues.name &&
        !!currentFieldValues.phone &&
        !!currentFieldValues.sgIDType &&
        !!currentFieldValues.sgIDNumber
      ) {
        return Object.values(hasErr).indexOf(true) >= 0;
      } else {
        return true;
      }
    } else {
      if (
        !!currentFieldValues.email &&
        !!currentFieldValues.surname &&
        !!currentFieldValues.name &&
        !!currentFieldValues.phone &&
        !!currentFieldValues.hkIDType &&
        !!currentFieldValues.hkIDNumber
      ) {
        return Object.values(hasErr).indexOf(true) >= 0;
      } else {
        return true;
      }
    }
  };

  validationResult = {
    hasErr: {
      surname: false,
      name: false,
      email: false,
      phone: false,
      sgIDType: false,
      sgIDNumber: false,
      hkIDType: false,
      hkIDNumber: false
    },
    errMsg: {
      surname: "",
      name: "",
      email: "",
      phone: "",
      sgIDType: "",
      sgIDNumber: "",
      hkIDType: "",
      hkIDNumber: ""
    }
  };

  aboutSelfValidateFields = (
    currentFieldValues,
    touchedState,
    validationState,
    region
  ) => {
    if (touchedState.email || currentFieldValues.email) {
      // Email ID Verification
      if (currentFieldValues.email) {
        if (!validateEmail(currentFieldValues.email)) {
          validationState.hasErr.email = true;
          validationState.errMsg.email = "Email format is not correct";
        } else {
          validationState.hasErr.email = false;
          validationState.errMsg.email = "";
        }
      } else {
        validationState.hasErr.email = true;
        validationState.errMsg.email = "Email field cannot be empty";
      }
    }
    if (touchedState.phone || currentFieldValues.phone) {
      // Phone number Verification
      if (currentFieldValues.phone) {
        if (!validatePhoneNumber(currentFieldValues.phone)) {
          validationState.hasErr.phone = true;
          validationState.errMsg.phone =
            "Phone number format is not valid, please enter at least 8 digits";
        } else {
          validationState.hasErr.phone = false;
          validationState.errMsg.phone = "";
        }
      } else {
        validationState.hasErr.phone = true;
        validationState.errMsg.phone = "Phone number field cannot be empty";
      }
    }
    if (touchedState.name || currentFieldValues.name) {
      // First Name Check
      IS_EMPTY_FIELD_CHECK(
        "name",
        "First Name",
        "",
        currentFieldValues,
        validationState
      );
    }
    if (touchedState.surname || currentFieldValues.surname) {
      //  Surname Check
      IS_EMPTY_FIELD_CHECK(
        "surname",
        "Surname",
        "",
        currentFieldValues,
        validationState
      );
    }
    if (this.isSG(region)) {
      if (touchedState.sgIDNumber || currentFieldValues.sgIDNumber) {
        //  ID number check
        IS_EMPTY_FIELD_CHECK(
          "sgIDNumber",
          "ID Number",
          "",
          currentFieldValues,
          validationState
        );
      }
      if (touchedState.sgIDType || currentFieldValues.sgIDType) {
        // ID Type Check
        IS_EMPTY_FIELD_CHECK(
          "sgIDType",
          "ID Type",
          "",
          currentFieldValues,
          validationState
        );
      }
    } else {
      if (touchedState.hkIDNumber || currentFieldValues.hkIDNumber) {
        //  ID number check  for HK
        IS_EMPTY_FIELD_CHECK(
          "hkIDNumber",
          "ID Number",
          "",
          currentFieldValues,
          validationState
        );
      }
      if (touchedState.hkIDType || currentFieldValues.hkIDType) {
        // ID Type Check for HK
        IS_EMPTY_FIELD_CHECK(
          "hkIDType",
          "ID Type",
          "",
          currentFieldValues,
          validationState
        );
      }
    }
  };

  isSG = region => IS_SG_REGION(region);

  render() {
    let aboutSelfParentState = this.props.parentState.claim.aboutSelf;
    let region = this.props.parentState.region;
    this.aboutSelfValidateFields(
      aboutSelfParentState,
      this.state.touched,
      this.validationResult,
      region
    );
    let data = {
      props: this.props,
      hasErr: this.validationResult.hasErr,
      errMsg: this.validationResult.errMsg,
      validationHandlers: {
        handleTouched: this.handleTouched
      },
      viewControls: {
        checkForm: this.checkForm,
        isSG: this.isSG(region)
      }
    };
    return AboutSelfComponent(data);
  }
}

export default AboutSelf;
