import {
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Grid,
  Typography
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React from "react";
import { FORM_LINKS, CLAIM_FORM_TYPES, NAV_LINKS } from "common/Constants";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE,
  DATE_FIELD_ALIGNMENT_STYLE,
  SWITCH_LABEL_DIRECTION_STYLE
} from "common/styles/styles";
import NavButton from "components/elements/NavButton";

function BasicInformationComponent(data) {
  const parentState = data.props.parentState.claim.basicInformation;
  const parentHandlers = data.props.parentHandlers;
  const validationHandlers = data.validationHandlers;
  const viewControls = data.viewControls;
  const removeSubGridContainerMargin = { margin: "0px" };
  const formType = CLAIM_FORM_TYPES.basicInformation;
  const currentNavState = data.props.parentState.navItem || [];
  const formCheckStatus = viewControls.checkForm(data.hasErr, parentState);
  if (
    currentNavState &&
    formCheckStatus !== currentNavState[NAV_LINKS.claims.id].isDisabled
  ) {
    parentHandlers.handleNavBarStatus(NAV_LINKS.claims.id, formCheckStatus);
  }
  return (
    <React.Fragment>
      <Typography variant="h1">
        Please provide some basic information
      </Typography>
      <div className="form-box">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="policynumber"
              fullWidth
              name="policyNumber"
              required
              margin="normal"
              placeholder="Policy Number"
              label="Policy Number"
              helperText={data.errMsg.policyNumber}
              error={data.hasErr.policyNumber}
              value={parentState.policyNumber}
              onChange={e =>
                parentHandlers.handleClaimUpdateWithParams(
                  formType,
                  e.target.name,
                  e.target.value
                )
              }
              onBlur={validationHandlers.handleTouched}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                style={SWITCH_LABEL_DIRECTION_STYLE}
                labelPlacement="start"
                label="Do you have related insurance?"
                control={
                  <Switch
                    id="hasrelatedinsurance"
                    name="hasRelatedInsurance"
                    checked={parentState.hasRelatedInsurance}
                    onChange={() => {
                      parentHandlers.handleClaimUpdateWithParams(
                        formType,
                        "hasRelatedInsurance",
                        !parentState.hasRelatedInsurance
                      );
                    }}
                    value="hasRelatedInsurance"
                    color="primary"
                  />
                }
              />
              <div
                style={{
                  color: "rgba(0, 0, 0, 0.54)",
                  marginTop: "-8px",
                  fontSize: "0.75rem",
                  minHeight: "1em",
                  textAlign: "left",
                  fontWeight: "400",
                  lineHeight: "1em",
                  letterSpacing: "0.03333em"
                }}
              >
                Is there any other insurance in-force covering this loss of
                expenses?
              </div>
            </FormGroup>
          </Grid>
          <div className={data.viewControls.hasRelatedInsuranceClass.join(" ")}>
            <Grid container spacing={3} style={removeSubGridContainerMargin}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="relatedinsurancecompanyname"
                  fullWidth
                  name="relatedInsuranceCompanyName"
                  required
                  margin="normal"
                  placeholder="Insurance Company"
                  label="Insurance Company Name"
                  helperText={data.errMsg.relatedInsuranceCompanyName}
                  error={data.hasErr.relatedInsuranceCompanyName}
                  value={parentState.relatedInsuranceCompanyName}
                  onChange={e =>
                    parentHandlers.handleClaimUpdateWithParams(
                      formType,
                      e.target.name,
                      e.target.value
                    )
                  }
                  onBlur={validationHandlers.handleTouched}
                />
              </Grid>
              <Grid item xs={12} sm={6} display="none">
                <TextField
                  id="relatedinsurancepolicynumber"
                  fullWidth
                  name="relatedInsurancePolicyNumber"
                  required
                  margin="normal"
                  placeholder="Policy/Certificate No."
                  label="Policy/Certificate No."
                  helperText={data.errMsg.relatedInsurancePolicyNumber}
                  error={data.hasErr.relatedInsurancePolicyNumber}
                  value={parentState.relatedInsurancePolicyNumber}
                  onChange={e =>
                    parentHandlers.handleClaimUpdateWithParams(
                      formType,
                      e.target.name,
                      e.target.value
                    )
                  }
                  onBlur={validationHandlers.handleTouched}
                />
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={3} style={DATE_FIELD_ALIGNMENT_STYLE}>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="travel-date-from"
                  disableToolbar
                  showtodaybutton="true"
                  autoOk
                  fullWidth
                  disableFuture
                  name="travelDateFrom"
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Travel From Date*"
                  invalidDateMessage="Please enter a valid date"
                  helperText={data.errMsg.travelDateFrom}
                  error={data.hasErr.travelDateFrom}
                  value={parentState.travelDateFrom}
                  onChange={value => {
                    parentHandlers.handleClaimUpdateWithParams(
                      formType,
                      "travelDateFrom",
                      value
                    );
                  }}
                  onBlur={validationHandlers.handleTouched}
                  KeyboardButtonProps={{
                    "aria-label": "travel from date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="travel-date-till"
                  disableToolbar
                  showtodaybutton="true"
                  autoOk
                  fullWidth
                  minDate={parentState.travelDateFrom}
                  name="travelDateTill"
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Travel Until Date*"
                  invalidDateMessage="Please enter a valid date"
                  helperText={data.errMsg.travelDateTill}
                  error={data.hasErr.travelDateTill}
                  value={parentState.travelDateTill}
                  onChange={value => {
                    parentHandlers.handleClaimUpdateWithParams(
                      formType,
                      "travelDateTill",
                      value
                    );
                  }}
                  onBlur={validationHandlers.handleTouched}
                  KeyboardButtonProps={{
                    "aria-label": "travel until date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            id="back-button"
            name={"Back"}
            disabled={false}
            clickHandler={() => data.props.history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />
        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            id="next-button"
            name={"Next"}
            disabled={formCheckStatus}
            clickHandler={e => {
              data.props.history.push(FORM_LINKS.selectClaimType);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default BasicInformationComponent;
