import React from "react";
import {
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import { FILE_REQUIREMENTS, REGION } from "common/Constants";
import {
  MODAL_HEADER_STYLE,
  MODAL_OK_BUTTON_STYLE,
  MODAL_REQUIRED_FILES_STYLE,
  BUTTON_MIN_WIDTH_STYLE,
  REQUIREMENTS_MODAL_FOOTNOTE_STYLE
} from "common/styles/styles";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import { DISPLAY_MODAL_FOOTNOTE } from "common/components/CommonComponents";

class SGFileUploadRequirements extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  displayUploadRequirements = (benefitType, region) => (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <List>
          {FILE_REQUIREMENTS(benefitType, region).map((item, index) => (
            <ListItem key={`${index}-${benefitType}-detail`}>
              <ListItemIcon>
                <ArrowForwardRoundedIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="subtitle2" key={index + "-" + benefitType}>
                  {item.requirement}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
    </React.Fragment>
  );

  render() {
    const benefitType = this.props.benefitType;
    const closeModal = this.props.closeModal;

    return (
      <React.Fragment>
        <div style={MODAL_HEADER_STYLE}>
          <Typography variant="h4">Required Documents</Typography>
        </div>
        <Grid container style={MODAL_REQUIRED_FILES_STYLE}>
          {this.displayUploadRequirements(benefitType, this.props.region)}
          <div style={REQUIREMENTS_MODAL_FOOTNOTE_STYLE}>
            {this.props.region === REGION.HK ? DISPLAY_MODAL_FOOTNOTE() : null}
          </div>
        </Grid>
        <div style={MODAL_OK_BUTTON_STYLE}>
          <Button
            id="view-requirements-ok-button"
            onClick={e => closeModal()}
            color="primary"
            variant="contained"
            style={BUTTON_MIN_WIDTH_STYLE}
          >
            OK
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default SGFileUploadRequirements;
