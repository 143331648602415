import { Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import { FORM_LINKS, BENEFIT_TYPES } from "common/Constants";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE,
  GRID_CONTAINER_ALIGN_LEFT_STYLE
} from "common/styles/styles";
import NavButton from "../../../elements/NavButton";
import "date-fns";
import { SECONDARY_BUTTON_STYLE } from "common/styles/styles";
import {
  DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT,
  DISPLAY_TEXT_FIELD_FULL_WIDTH,
  DISPLAY_TEXT_FIELD_COLUMN_LAYOUT,
  DISPLAY_DATE_FIELD,
  DISPLAY_TIME_FIELD
} from "common/components/CommonComponents";
import { getClaimTitle } from "common/utils/CommonUtil";

function TravelMisConnectionClaimComponent(data) {
  let benefitDetail = data.benefitDetail;
  let currentClaimDetailGuid = benefitDetail.benefitId;
  let currentBenefitType = data.benefitType;
  let claimParentHandlers = data.props.parentHandlers;
  const validationHandlers = data.validationHandlers;
  const viewControls = data.viewControls;
  const region = data.props.parentState.region;

  return (
    <React.Fragment>
      <Typography variant="h1">
        {" "}
        {getClaimTitle(BENEFIT_TYPES.travelMisConnectionClaim)}
      </Typography>
      <div style={SECONDARY_BUTTON_STYLE}>
        <Button
          id="view-requirements-button"
          onClick={e => viewControls.showModal()}
          color="primary"
          variant="outlined"
        >
          VIEW REQUIREMENTS
        </Button>
      </div>
      <Typography variant="h4">Original Flight Details</Typography>
      <Grid container spacing={3}>
        {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
          {
            helperText: data.errMsg.originalFlightNumber,
            error: data.hasErr.originalFlightNumber,
            value: benefitDetail.originalFlightNumber,
            name: "originalFlightNumber",
            blur: validationHandlers.handleTouched,
            label: "Original Flight No",
            placeholder: "Original Flight No",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}

        {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
          {
            helperText: data.errMsg.originalPlaceOfDeparture,
            error: data.hasErr.originalPlaceOfDeparture,
            value: benefitDetail.originalPlaceOfDeparture,
            name: "originalPlaceOfDeparture",
            blur: validationHandlers.handleTouched,
            label: "Place of Departure",
            placeholder: "Place of Departure",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}

        <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
          {DISPLAY_DATE_FIELD(
            {
              helperText: data.errMsg.originalDate,
              error: data.hasErr.originalDate,
              blur: validationHandlers.handleTouched,
              value: benefitDetail.originalDate,
              name: "originalDate",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              label: "Original Date of Travel"
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
          {DISPLAY_TIME_FIELD(
            {
              helperText: data.errMsg.originalTime,
              error: data.hasErr.originalTime,
              blur: validationHandlers.handleTouched,
              value: benefitDetail.originalTime,
              name: "originalTime",
              label: "Original Time of Travel",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
        </Grid>
        {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
          {
            helperText: data.errMsg.originalTransportProvider,
            error: data.hasErr.originalTransportProvider,
            value: benefitDetail.originalTransportProvider,
            name: "originalTransportProvider",
            blur: validationHandlers.handleTouched,
            label: "Name of Transport Provider",
            placeholder: "Name of Transport Provider",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            isNotRequired: true,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}

        {DISPLAY_TEXT_FIELD_FULL_WIDTH(
          {
            helperText: data.errMsg.reason,
            error: data.hasErr.reason,
            value: benefitDetail.reason,
            name: "reason",
            blur: validationHandlers.handleTouched,
            label: "Reason(s) By Service Provider For the Cause of Delay",
            placeholder: "Reason(s) By Service Provider For the Cause of Delay",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
        <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
          {DISPLAY_DATE_FIELD(
            {
              helperText: data.errMsg.actualArrivalDate,
              error: data.hasErr.actualArrivalDate,
              blur: validationHandlers.handleTouched,
              value: benefitDetail.actualArrivalDate,
              name: "actualArrivalDate",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              label: "Actual Arrival Date of incoming Carrier"
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
          {DISPLAY_TIME_FIELD(
            {
              helperText: data.errMsg.actualArrivalTime,
              error: data.hasErr.actualArrivalTime,
              blur: validationHandlers.handleTouched,
              value: benefitDetail.actualArrivalTime,
              name: "actualArrivalTime",
              label: "Actual Arrival Time of incoming Carrier",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
        </Grid>
      </Grid>
      <Typography variant="h4" className="app-h4-padding-top">
        Rescheduled Flight Details
      </Typography>
      <Grid container spacing={3}>
        {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
          {
            helperText: data.errMsg.rescheduledFlightNumber,
            error: data.hasErr.rescheduledFlightNumber,
            value: benefitDetail.rescheduledFlightNumber,
            name: "rescheduledFlightNumber",
            blur: validationHandlers.handleTouched,
            label: "Actual Flight No.",
            placeholder: "Actual Flight No.",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
        {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
          {
            helperText: data.errMsg.rescheduledPlaceOfDeparture,
            error: data.hasErr.rescheduledPlaceOfDeparture,
            value: benefitDetail.rescheduledPlaceOfDeparture,
            name: "rescheduledPlaceOfDeparture",
            blur: validationHandlers.handleTouched,
            label: "Place of Departure",
            placeholder: "Place of Departure",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
        <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
          {DISPLAY_DATE_FIELD(
            {
              helperText: data.errMsg.rescheduledDate,
              error: data.hasErr.rescheduledDate,
              blur: validationHandlers.handleTouched,
              value: benefitDetail.rescheduledDate,
              name: "rescheduledDate",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              label: "Actual Date of Travel"
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
          {DISPLAY_TIME_FIELD(
            {
              helperText: data.errMsg.rescheduledTime,
              error: data.hasErr.rescheduledTime,
              blur: validationHandlers.handleTouched,
              value: benefitDetail.rescheduledTime,
              name: "rescheduledTime",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid,
              label: "Actual Time of Travel"
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
        </Grid>
        {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
          {
            helperText: data.errMsg.rescheduledTransportProvider,
            error: data.hasErr.rescheduledTransportProvider,
            value: benefitDetail.rescheduledTransportProvider,
            name: "rescheduledTransportProvider",
            blur: validationHandlers.handleTouched,
            label: "Name of Transport Provider",
            placeholder: "Name of Transport Provider",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            isNotRequired: true,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
      </Grid>
      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            id="back-button"
            name={"Back"}
            disabled={false}
            clickHandler={() => data.props.history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />
        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            id="next-button"
            name={"Next"}
            disabled={viewControls.checkForm(data.hasErr, benefitDetail)}
            clickHandler={e => {
              data.props.history.push({
                pathname: FORM_LINKS.claimantInformation,
                state: {
                  benefitId: currentClaimDetailGuid,
                  benefitType: BENEFIT_TYPES.travelMisConnectionClaim
                }
              });
            }}
          />
        </div>
      </div>
      {/* Modal */}
      {DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT(
        data.requirementModalDisplay,
        viewControls.closeModal,
        BENEFIT_TYPES.travelMisConnectionClaim,
        region
      )}
      {/* Modal end */}
    </React.Fragment>
  );
}

export default TravelMisConnectionClaimComponent;
