import React from "react";
import {
  Grid,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button
} from "@material-ui/core";
import {
  FORM_LINKS,
  BENEFIT_TYPES,
  COUNTRY,
  CURRENCY_TYPE
} from "common/Constants";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE,
  FORM_CONTROL_STYLE,
  GRID_CONTAINER_ALIGN_LEFT_STYLE
} from "common/styles/styles";
import NavButton from "components/elements/NavButton";
import { SECONDARY_BUTTON_STYLE } from "common/styles/styles";
import {
  DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT,
  DISPLAY_TEXT_FIELD_FULL_WIDTH,
  DISPLAY_TEXT_FIELD_COLUMN_LAYOUT,
  DISPLAY_DATE_FIELD
} from "common/components/CommonComponents";
import { getClaimTitle } from "common/utils/CommonUtil";

function OtherClaimComponent(data) {
  const benefitDetail = data.benefitDetail;
  const currentClaimDetailGuid = benefitDetail.benefitId;
  const claimParentHandlers = data.props.parentHandlers;
  const validationHandlers = data.validationHandlers;
  const currentBenefitType = data.benefitType;
  const viewControls = data.viewControls;
  const region = data.props.parentState.region;

  return (
    <React.Fragment>
      <Typography variant="h1">
        {" "}
        {getClaimTitle(BENEFIT_TYPES.otherClaim)}
      </Typography>
      <div style={SECONDARY_BUTTON_STYLE}>
        <Button
          id="view-requirements-button"
          onClick={e => viewControls.showModal()}
          color="primary"
          variant="outlined"
        >
          VIEW REQUIREMENTS
        </Button>
      </div>
      <Grid container spacing={3}>
        {DISPLAY_TEXT_FIELD_FULL_WIDTH(
          {
            helperText: data.errMsg.benefitDescription,
            error: data.hasErr.benefitDescription,
            blur: validationHandlers.handleTouched,
            label: "Please describe the type of benefit you want to claim",
            value: benefitDetail.benefitDescription,
            placeholder:
              "Please describe the type of benefit you want to claim",
            benefitType: currentBenefitType,
            name: "benefitDescription",
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}

        <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
          {DISPLAY_DATE_FIELD(
            {
              helperText: data.errMsg.dateOfIncident,
              error: data.hasErr.dateOfIncident,
              blur: validationHandlers.handleTouched,
              value: benefitDetail.dateOfIncident,
              name: "dateOfIncident",
              label: "Date of Event",
              benefitType: currentBenefitType,
              currentClaimDetailGuid: currentClaimDetailGuid
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
          <Grid item xs={12} sm={6}>
            <FormControl
              onBlur={e => {
                e.target.name = "locationOfIncident";
                validationHandlers.handleTouched(e);
              }}
              error={data.hasErr.locationOfIncident}
              style={FORM_CONTROL_STYLE}
            >
              <InputLabel htmlFor="locationOfIncident" required>
                Country of Incident
              </InputLabel>
              <Select
                id="locationOfIncident"
                value={benefitDetail.locationOfIncident}
                onChange={e => {
                  claimParentHandlers.handleTravelMisConnectionClaimWithParams(
                    currentBenefitType,
                    e.target.name,
                    e.target.value,
                    currentClaimDetailGuid
                  );
                }}
                inputProps={{
                  name: "locationOfIncident",
                  id: "locationOfIncident-select"
                }}
              >
                {COUNTRY.map(country => (
                  <MenuItem key={country.value} value={country.value}>
                    <Typography variant="subtitle2">{country.label}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
          <Grid item xs={12} sm={6}>
            <FormControl
              style={FORM_CONTROL_STYLE}
              onBlur={e => {
                e.target.name = "reasonForAmount";
                validationHandlers.handleTouched(e);
              }}
              error={data.hasErr.reasonForAmount}
            >
              <InputLabel htmlFor="reasonForAmount" required>
                Currency of amount claimed
              </InputLabel>
              <Select
                id="reasonForAmount"
                value={benefitDetail.reasonForAmount}
                onChange={e => {
                  claimParentHandlers.handleTravelMisConnectionClaimWithParams(
                    currentBenefitType,
                    e.target.name,
                    e.target.value,
                    currentClaimDetailGuid
                  );
                }}
                inputProps={{
                  name: "reasonForAmount",
                  id: "reasonForAmount"
                }}
              >
                {CURRENCY_TYPE.map(currencyType => (
                  <MenuItem key={currencyType.value} value={currencyType.value}>
                    <Typography variant="subtitle2">
                      {currencyType.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {DISPLAY_TEXT_FIELD_COLUMN_LAYOUT(
            {
              helperText: "Amount being claimed for this Benefit and Incident.",
              error: data.hasErr.amountClaimed,
              blur: validationHandlers.handleTouched,
              label: "Amount claimed",
              value: benefitDetail.amountClaimed,
              placeholder: "Amount claimed",
              benefitType: currentBenefitType,
              name: "amountClaimed",
              currentClaimDetailGuid: currentClaimDetailGuid,
              isCurrency: true
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {DISPLAY_TEXT_FIELD_FULL_WIDTH(
          {
            helperText: data.errMsg.description,
            error: data.hasErr.description,
            blur: validationHandlers.handleTouched,
            label: "Describe the incident.",
            value: benefitDetail.description,
            placeholder:
              "Please provide us with a short summary on what happened, how it happened and who was involved.",
            benefitType: currentBenefitType,
            name: "description",
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
      </Grid>

      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            id="back-button"
            name={"Back"}
            disabled={false}
            clickHandler={() => data.props.history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />
        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            id="next-button"
            name={"Next"}
            disabled={viewControls.checkForm(data.hasErr, benefitDetail)}
            clickHandler={e => {
              data.props.history.push({
                pathname: FORM_LINKS.claimantInformation,
                state: {
                  benefitId: currentClaimDetailGuid,
                  benefitType: BENEFIT_TYPES.otherClaim
                }
              });
            }}
          />
        </div>
      </div>
      {/* Modal */}
      {DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT(
        data.requirementModalDisplay,
        viewControls.closeModal,
        BENEFIT_TYPES.otherClaim,
        region
      )}
      {/* Modal end */}
    </React.Fragment>
  );
}

export default OtherClaimComponent;
