import React from "react";
import {
  pdfStyles,
  PDF_DISPLAY_CONTACT_INFO
} from "common/utils/PDFDocumentUtil";
import { Text, View } from "@react-pdf/renderer";
import { REGION, SUPPORT_EMAIL_HK, SUPPORT_EMAIL_SG } from "common/Constants";

const hkContactInformation = (
  <React.Fragment>
    {PDF_DISPLAY_CONTACT_INFO("Claims Hotline", "+852 2968 3221")}
    {PDF_DISPLAY_CONTACT_INFO("Facsimile", "+852 2917 6179")}
    {PDF_DISPLAY_CONTACT_INFO("Email", SUPPORT_EMAIL_HK)}
    {PDF_DISPLAY_CONTACT_INFO(
      "Operating Hours",
      "9:00am to 12:45pm and 2:00pm to 5:30pm"
    )}
  </React.Fragment>
);

const sgContactInformation = (
  <React.Fragment>
    {PDF_DISPLAY_CONTACT_INFO("Customer Service Hotline", "(65) 6423 0888")}
    {PDF_DISPLAY_CONTACT_INFO("Facsimile", "(65) 6423 0798")}
    {PDF_DISPLAY_CONTACT_INFO("Email", SUPPORT_EMAIL_SG)}
    {PDF_DISPLAY_CONTACT_INFO("Operating Hours", "9am - 5pm")}
  </React.Fragment>
);
const showContactInformation = region =>
  region === REGION.HK ? hkContactInformation : sgContactInformation;

export const PDFContactInformationComponent = region => {
  return (
    <React.Fragment>
      <View style={pdfStyles.title}>
        <Text>Contact Information</Text>
      </View>

      <View style={pdfStyles.subtitle2}>
        <Text>
          Should you require further assistance, please contact Allied World
          {region === REGION.HK ? " Claims Hotline" : " Customer Service"} at:
        </Text>
      </View>
      {showContactInformation(region)}
    </React.Fragment>
  );
};
