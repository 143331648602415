import React from "react";
import {
  TextField,
  Grid,
  Modal,
  Card,
  Typography,
  Button
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import SGFileUploadRequirements from "common/fileUploadRequirements/SGFileUploadRequirements";
import { handleFloatingCurrencyFormatting } from "common/utils/CommonUtil";
import DateFnsUtils from "@date-io/date-fns";
import {
  MODAL_OVERFLOW_STYLE,
  TIME_FIELD_STYLE,
  MODAL_FOOTNOTE_STYLE,
  MODAL_HEADER_STYLE,
  MODAL_OK_BUTTON_STYLE,
  BUTTON_MIN_WIDTH_STYLE,
  MODAL_TEXT_CONTENT
} from "common/styles/styles";

export const IdleTimeOutModal = ({
  showModal,
  handleClose,
  handleReset,
  remainingtime
}) => {
  return (
    <React.Fragment>
      <Modal
        aria-labelledby="User is not active"
        aria-describedby="Form will be reset"
        open={showModal}
        style={MODAL_OVERFLOW_STYLE}
      >
        <Card className="app-modal-card-style square">
          <div style={MODAL_HEADER_STYLE}>
            <Typography variant="h4">
              Do you want to continue with the submission?
            </Typography>
          </div>
          <div style={MODAL_TEXT_CONTENT}>
            Any information that has been entered will be reset.
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <div style={MODAL_OK_BUTTON_STYLE}>
                <Button
                  id="view-requirements-ok-button"
                  onClick={e => handleClose()}
                  color="primary"
                  variant="contained"
                  style={BUTTON_MIN_WIDTH_STYLE}
                >
                  YES
                </Button>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={MODAL_OK_BUTTON_STYLE}>
                <Button
                  id="view-requirements-ok-button"
                  onClick={e => handleReset()}
                  color="secondary"
                  variant="contained"
                  style={BUTTON_MIN_WIDTH_STYLE}
                >
                  NO
                </Button>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export const DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT = (
  openModal,
  closeModal,
  benefitType,
  region
) => (
  <React.Fragment>
    <Modal
      aria-labelledby="Display files"
      aria-describedby="Display files required for claim submission"
      open={openModal}
      onClose={e => closeModal()}
      style={MODAL_OVERFLOW_STYLE}
    >
      <Card className="app-modal-card-style square">
        <SGFileUploadRequirements
          benefitType={benefitType}
          closeModal={closeModal}
          region={region}
        />
      </Card>
    </Modal>
  </React.Fragment>
);

export const DISPLAY_MODAL_FOOTNOTE = () => (
  <React.Fragment>
    <div style={MODAL_FOOTNOTE_STYLE}>
      <Typography variant="subtitle2">
        Note: We may request for further information / document in dealing with
        the claim.
      </Typography>
    </div>
  </React.Fragment>
);

export const DISPLAY_TEXT_FIELD_COLUMN_LAYOUT = (data, handler) => {
  const helperText = data.helperText || "";
  const error = data.error || false;
  const blur = data.blur || null;
  const label = data.label || "";
  const value = data.value || "";
  const name = data.name || "";
  const placeholder = data.placeholder || "";
  const benefitType = data.benefitType || "";
  const currentClaimDetailGuid = data.currentClaimDetailGuid || "";
  const isCurrency = !!data.isCurrency;
  const hasParams = !!data.hasParams;
  const isRequired = data.isNotRequired ? false : true;
  return (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
        {DISPLAY_TEXT_FIELD(
          isRequired,
          helperText,
          error,
          blur,
          name,
          placeholder,
          label,
          value,
          benefitType,
          currentClaimDetailGuid,
          isCurrency,
          hasParams,
          handler
        )}
      </Grid>
    </React.Fragment>
  );
};

export const DISPLAY_TEXT_FIELD_FULL_WIDTH = (data, handler) => {
  const helperText = data.helperText || "";
  const error = data.error || false;
  const blur = data.blur || null;
  const label = data.label || "";
  const value = data.value || "";
  const name = data.name || "";
  const placeholder = data.placeholder || "";
  const benefitType = data.benefitType || "";
  const currentClaimDetailGuid = data.currentClaimDetailGuid || "";
  const isCurrency = !!data.isCurrency;
  const hasParams = !!data.hasParams;
  const isRequired = data.isNotRequired ? false : true;
  return (
    <React.Fragment>
      <Grid item xs={12}>
        {DISPLAY_TEXT_FIELD(
          isRequired,
          helperText,
          error,
          blur,
          name,
          placeholder,
          label,
          value,
          benefitType,
          currentClaimDetailGuid,
          isCurrency,
          hasParams,
          handler
        )}
      </Grid>
    </React.Fragment>
  );
};

export const DISPLAY_TEXT_FIELD = (
  isRequired,
  helperText,
  error,
  blur,
  name,
  placeholder,
  label,
  value,
  benefitType,
  benefitId,
  isCurrency,
  hasParams,
  handler
) => {
  return (
    <TextField
      fullWidth
      required={isRequired}
      margin="normal"
      helperText={helperText}
      error={error}
      onBlur={blur}
      name={name}
      id={name}
      placeholder={placeholder}
      label={label}
      value={value}
      onChange={e =>
        isCurrency
          ? handler(
              benefitType,
              name,
              handleFloatingCurrencyFormatting(e.target.value),
              benefitId
            )
          : hasParams
          ? handler(benefitType, name, e.target.value, benefitId)
          : handler(benefitType, e, benefitId)
      }
    />
  );
};

export const DISPLAY_DATE_FIELD = (data, handler) => {
  const value = data.value || null;
  const name = data.name || "";
  const benefitType = data.benefitType || "";
  const currentClaimDetailGuid = data.currentClaimDetailGuid || "";
  const label = data.label || "";
  const helperText = data.helperText || "";
  const error = data.error || false;
  const disableFuture = data.disableFuture || false;
  const disablePast = data.disablePast || false;
  const blur = data.blur || null;
  const isRequired = data.isNotRequired ? false : true;
  return (
    <Grid item xs={12} sm={6}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          showtodaybutton="true"
          required={isRequired}
          autoOk
          fullWidth
          id={name}
          name={name}
          variant="dialog"
          format="dd/MM/yyyy"
          helperText={helperText}
          error={error}
          margin="normal"
          disableFuture={disableFuture}
          disablePast={disablePast}
          label={label}
          invalidDateMessage="Please enter a valid date"
          value={value}
          onBlur={blur}
          onChange={value => {
            if (value) {
              handler(benefitType, name, value, currentClaimDetailGuid);
            }
          }}
          KeyboardButtonProps={{
            "aria-label": label
          }}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
};

export const DISPLAY_TIME_FIELD = (data, handler) => {
  const value = data.value || "";
  const name = data.name || "";
  const benefitType = data.benefitType || "";
  const currentClaimDetailGuid = data.currentClaimDetailGuid || "";
  const isRequired = data.isNotRequired ? false : true;
  const label = isRequired ? data.label + "*" : data.label;
  const helperText = data.helperText || "";
  const error = data.error || false;
  const blur = data.blur || "";
  return (
    <Grid item xs={12} sm={6}>
      <TextField
        fullWidth
        margin="normal"
        model="selectedTime"
        id={name}
        name={name}
        label={label}
        type="time"
        helperText={helperText}
        error={error}
        value={value}
        onBlur={blur}
        onChange={e => {
          handler(benefitType, name, e.target.value, currentClaimDetailGuid);
        }}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          step: 60 // 1 min
        }}
        style={TIME_FIELD_STYLE}
      />
    </Grid>
  );
};
