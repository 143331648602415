import React from "react";
import NavButton from "components/elements/NavButton";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE,
  PDPA_CONTAINER_STYLE,
  GRID_ALIGNMENT_PDPA_STYLE
} from "common/styles/styles";
import { Grid, Typography, Checkbox } from "@material-ui/core";
import {
  PDPA_DOCUMENT_URL,
  REGION,
  PICS_HK_DOCUMENT_URL
} from "common/Constants";

function PDPAComponent(data, handleSubmission) {
  let props = data.props;
  let currentClaim = props.parentState.claim;
  let submissionId = props.parentState.claim.submissionId;
  let region = props.parentState.region;
  let history = props.history;
  let pdpa = currentClaim.pdpa;
  let parentHandlers = props.parentHandlers;
  let viewControls = data.viewControls;
  let btnEnable = viewControls.btnEnable;

  const HKPDPAComponent = () => (
    <React.Fragment>
      <Typography variant="h1">
        Declaration, Authorization and Personal Data Consent
      </Typography>
      <Typography variant="h3">Declaration</Typography>
      <Grid
        container
        wrap="nowrap"
        alignItems="baseline"
        justify="flex-start"
        spacing={0}
        style={PDPA_CONTAINER_STYLE}
      >
        <Grid item xs={1}>
          <Checkbox
            id="pdpa-declaration-checkbox"
            checked={pdpa.declaration}
            onChange={() => {
              parentHandlers.handlePDPAStateUpdate(
                "declaration",
                !pdpa.declaration
              );
            }}
            value="false"
            color="primary"
          />
        </Grid>
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1">I/We: </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                (i) confirm that I am/we are the claimant and/or the
                Policyholder and/or the authorized representative;
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                (ii) declare to the best of my/our knowledge and belief that
              </Typography>
            </Grid>
            <Grid container style={GRID_ALIGNMENT_PDPA_STYLE}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  a. the information given is true, correct and accurate; and
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  b. I/We have not withheld, concealed or suppressed any
                  material information or made a false statement in relation to
                  the claim and/or the accident;
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                (iii) further agree and undertake that I/we shall not hereafter
                make any false statement or conceal or suppress any material
                fact relating to the claim and/or the accident.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                (iv) I/W agree that any concealment or incorrect statement in
                connection with this claim may result in legal liability and the
                policy shall become void.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Typography variant="h3">Authorisation</Typography>
      <Grid
        container
        wrap="nowrap"
        alignItems="baseline"
        justify="flex-start"
        spacing={0}
        style={PDPA_CONTAINER_STYLE}
      >
        <Grid item xs={1}>
          <Checkbox
            id="pdpa-authorization-checkbox"
            checked={pdpa.authorization}
            onChange={() => {
              parentHandlers.handlePDPAStateUpdate(
                "authorization",
                !pdpa.authorization
              );
            }}
            value="false"
            color="primary"
          />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body1">
            I/We hereby authorize Allied World Assurance Company, Ltd or its
            authorized representative to collect any and all information with
            respect to the claimant’s or my/our loss, disability, medical
            history, police statement made and the like from any hospital,
            physician, person, party and/or authority that has any records or is
            holding any information of the claimant or me/us; and authorize any
            hospital, physician, person, party and/or authority that has any
            records or is holding any information of the claimant or me/us to
            disclose to Allied World Assurance Company, Ltd or its authorized
            representative, any and all information with respect to the
            claimant’s or my/our loss, disability, medical history, police
            statement made and the like.
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h3">Personal Data Consent</Typography>
      <Grid
        container
        wrap="nowrap"
        alignItems="baseline"
        justify="flex-start"
        spacing={0}
        style={PDPA_CONTAINER_STYLE}
      >
        <Grid item xs={1}>
          <Checkbox
            id="pdpa-personalDataConsent-checkbox"
            checked={pdpa.personalDataConsent}
            onChange={() => {
              parentHandlers.handlePDPAStateUpdate(
                "personalDataConsent",
                !pdpa.personalDataConsent
              );
            }}
            value="false"
            color="primary"
          />
        </Grid>
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1">
                I/We declare that I/We have read, understood and agreed to the{" "}
                <a
                  href={PICS_HK_DOCUMENT_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Personal Information Collection Statement
                </a>
                .
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        wrap="nowrap"
        alignItems="baseline"
        justify="flex-start"
        spacing={0}
        style={PDPA_CONTAINER_STYLE}
      >
        <Grid item xs={1}>
          <Checkbox
            id="pdpa-promotionConsent-checkbox"
            checked={pdpa.promotionConsent}
            onChange={() => {
              parentHandlers.handlePDPAStateUpdate(
                "promotionConsent",
                !pdpa.promotionConsent
              );
            }}
            value="false"
            color="primary"
          />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body1">
            I/We do not want to receive any promotion materials or updates on
            other products, services or offers of Allied World Assurance
            Company, Ltd.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const SGPDPAComponent = () => (
    <React.Fragment>
      <Typography variant="h1">
        Declaration, Authorization and Personal Data Consent
      </Typography>
      <Typography variant="h3">Declaration</Typography>
      <Grid
        container
        wrap="nowrap"
        alignItems="baseline"
        justify="flex-start"
        spacing={0}
        style={PDPA_CONTAINER_STYLE}
      >
        <Grid item xs={1}>
          <Checkbox
            id="pdpa-declaration-checkbox"
            checked={pdpa.declaration}
            onChange={() => {
              parentHandlers.handlePDPAStateUpdate(
                "declaration",
                !pdpa.declaration
              );
            }}
            value="false"
            color="primary"
          />
        </Grid>
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1">I/We: </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                (i) confirm that I am/we are the claimant and/or the
                Policyholder;
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                (ii) declare to the best of my/our knowledge, and belief, and
                warrant that:
              </Typography>
            </Grid>
            <Grid container style={GRID_ALIGNMENT_PDPA_STYLE}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  a. all information in this form is true, correct and accurate;
                  and
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  b. I/We have not withheld, concealed or suppressed any
                  material information or made a false statement in relation to
                  the claim and/or the accident;
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                (iii) further agree and undertake that I/we shall not hereafter
                make any false statement or conceal or suppress any material
                fact relating to the claim and/or the accident.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Typography variant="h3">Authorisation</Typography>
      <Grid
        container
        wrap="nowrap"
        alignItems="baseline"
        justify="flex-start"
        spacing={0}
        style={PDPA_CONTAINER_STYLE}
      >
        <Grid item xs={1}>
          <Checkbox
            id="pdpa-authorization-checkbox"
            checked={pdpa.authorization}
            onChange={() => {
              parentHandlers.handlePDPAStateUpdate(
                "authorization",
                !pdpa.authorization
              );
            }}
            value="false"
            color="primary"
          />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body1">
            I/We hereby consent to and authorise any medical practitioner,
            hospital, clinic, insurance office or other person or organisation
            who has attended to me and/or the claimant for any reason, to
            disclose to Allied World Assurance Company, Ltd ("Allied World"),
            any and all information with respect to any illness or injury and to
            provide copies of all hospital or medical records/certification
            including earlier medical history. The information given is true and
            correct to the best of my/our knowledge and belief.
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h3">Personal Data Consent</Typography>
      <Grid
        container
        wrap="nowrap"
        alignItems="baseline"
        justify="flex-start"
        spacing={0}
        style={PDPA_CONTAINER_STYLE}
      >
        <Grid item xs={1}>
          <Checkbox
            id="pdpa-personalDataConsent-checkbox"
            checked={pdpa.personalDataConsent}
            onChange={() => {
              parentHandlers.handlePDPAStateUpdate(
                "personalDataConsent",
                !pdpa.personalDataConsent
              );
            }}
            value="false"
            color="primary"
          />
        </Grid>
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1">
                I/We acknowledge and consent to Allied World collecting, using,
                disclosing and processing my/our personal data including
                disclosing my/our personal data to third party service providers
                within or outside Singapore, for the purposes set out in and in
                accordance with the Allied World Singapore Personal Data
                Protection Policy available at{" "}
                <a
                  href={PDPA_DOCUMENT_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {PDPA_DOCUMENT_URL}
                </a>
                .
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                If I/we have provided or will provide information to Allied
                World about any other individuals, I/we confirm that I/we are
                authorised to disclose his or her personal data and also give
                this consent on both my/our and their behalf.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {region === REGION.HK ? HKPDPAComponent() : SGPDPAComponent()}
      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            id="back-button"
            name={"Back"}
            disabled={false}
            clickHandler={() => history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />

        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            id="next-button"
            name={"Next"}
            disabled={viewControls.checkForm(pdpa) || !btnEnable}
            clickHandler={e => {
              handleSubmission(
                currentClaim,
                region,
                history,
                submissionId,
                parentHandlers
              );
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default PDPAComponent;
