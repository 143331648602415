import React from "react";
import TripCancellationClaimComponent from "components/forms/benefits/tripCancellationClaim/TripCancellationClaimComponent";
import {
  shouldDisplaySubQuestions,
  getBenefitDetailFromProps,
  getLastBenefitIndex,
  IS_EMPTY_FIELD_CHECK,
  HAS_ERROR,
  getClaimTitle,
  IS_CURRENT_FIELD_VALID_CHECK
} from "common/utils/CommonUtil";
import { BENEFIT_TYPES, FIELD_TYPE } from "common/Constants";

class TripCancellationClaim extends React.Component {
  constructor() {
    super();
    this.state = {
      touched: {
        nameOfIID: false,
        relationToInsured: false,
        otherRelation: false,
        cancellationReason: false,
        dateOfEvent: false,
        depositAmount: false,
        refundAmount: false,
        totalAmount: false,
        noRefundDescription: false
      },
      requirementModalDisplay: false
    };
  }

  showModal = () => {
    this.setState({ requirementModalDisplay: true });
  };

  closeModal = () => {
    this.setState({ requirementModalDisplay: false });
  };

  getBenefitType = () => BENEFIT_TYPES.tripCancellationClaim;

  benefitIsEmpty = claimDetail => {
    return (
      !!claimDetail.cancellationReason || claimDetail.totalAmount !== "0.00"
    );
  };

  componentDidMount() {
    this.showModal();
  }

  validationResult = {
    hasErr: {
      nameOfIID: false,
      relationToInsured: false,
      otherRelation: false,
      cancellationReason: false,
      dateOfEvent: false,
      depositAmount: false,
      refundAmount: false,
      totalAmount: false,
      noRefundDescription: false
    },
    errMsg: {
      nameOfIID: "",
      relationToInsured: "",
      otherRelation: "",
      cancellationReason: "",
      dateOfEvent: "",
      depositAmount: "",
      refundAmount: "",
      totalAmount: "",
      noRefundDescription: ""
    }
  };

  checkForm = (hasError, currentFieldValues) => {
    if (this.noRefundAmount(currentFieldValues)) {
      if (!!currentFieldValues.noRefundDescription) {
        return HAS_ERROR(hasError);
      } else {
        return true;
      }
    }
    if (this.isOtherRelation(currentFieldValues)) {
      if (!!currentFieldValues.otherRelation) {
        return HAS_ERROR(hasError);
      } else {
        return true;
      }
    }
    if (this.benefitIsEmpty(currentFieldValues)) {
      return HAS_ERROR(hasError);
    } else {
      return true;
    }
  };

  benefitIsEmpty = currentClaimDetail => {
    return (
      !!currentClaimDetail.cancellationReason &&
      !!currentClaimDetail.dateOfEvent &&
      !!currentClaimDetail.depositAmount &&
      !!currentClaimDetail.refundAmount &&
      !!currentClaimDetail.totalAmount
    );
  };

  validateFields = (currentFieldValues, touchedState, validationState) => {
    if (this.isOtherRelation(currentFieldValues)) {
      if (touchedState.otherRelation || currentFieldValues.otherRelation) {
        IS_EMPTY_FIELD_CHECK(
          "otherRelation",
          "Other Relation description",
          "Please describe the relation",
          currentFieldValues,
          validationState
        );
      }
    } else {
      validationState.hasErr.otherRelation = false;
      validationState.errMsg.otherRelation = "";
    }
    if (
      touchedState.cancellationReason ||
      currentFieldValues.cancellationReason
    ) {
      IS_EMPTY_FIELD_CHECK(
        "cancellationReason",
        "Cancellation reason",
        "",
        currentFieldValues,
        validationState
      );
    }
    if (touchedState.dateOfEvent || currentFieldValues.dateOfEvent) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "dateOfEvent",
        "Date of Event",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.date
      );
    }
    if (touchedState.depositAmount || currentFieldValues.depositAmount) {
      IS_EMPTY_FIELD_CHECK(
        "depositAmount",
        "Deposit amount",
        "Enter amount of deposit or actual paid amount.",
        currentFieldValues,
        validationState
      );
    }
    if (touchedState.refundAmount || currentFieldValues.refundAmount) {
      IS_EMPTY_FIELD_CHECK(
        "refundAmount",
        "Refund amount",
        "Enter amount of refund you have or will receive.",
        currentFieldValues,
        validationState
      );
    }
    if (touchedState.totalAmount || currentFieldValues.totalAmount) {
      IS_EMPTY_FIELD_CHECK(
        "totalAmount",
        "Total amount",
        "",
        currentFieldValues,
        validationState
      );
    }
    if (this.noRefundAmount(currentFieldValues)) {
      if (
        touchedState.noRefundDescription ||
        currentFieldValues.noRefundDescription
      ) {
        IS_EMPTY_FIELD_CHECK(
          "noRefundDescription",
          "",
          "Please explain why you have received no refund.",
          currentFieldValues,
          validationState
        );
      }
    } else {
      validationState.hasErr.noRefundDescription = false;
      validationState.errMsg.noRefundDescription = "";
    }
  };

  componentWillUnmount() {
    let benefitType = this.getBenefitType();
    let claimDetail = this.props.parentState.claim.claimDetail;
    let benefitDetailIndex = getLastBenefitIndex(benefitType, claimDetail);
    let benefitId = claimDetail[benefitType][benefitDetailIndex].benefitId;
    const currentClaimDetail = claimDetail[benefitType][benefitDetailIndex];
    if (this.benefitIsEmpty(currentClaimDetail)) {
      let benefitName = getClaimTitle(BENEFIT_TYPES.tripCancellationClaim);
      let parentHandlers = this.props.parentHandlers;
      parentHandlers.handleBenefitDetailInNavBar(
        benefitName,
        benefitId,
        benefitType
      );
    } else {
      this.props.parentHandlers.removeBenefit(benefitId, benefitType);
    }
  }

  getBenefitDetail(props) {
    return getBenefitDetailFromProps(
      props.location,
      props.parentState.claim,
      this.getBenefitType()
    );
  }

  handleTouched = e => {
    let targetParam = e.target.name;
    if (!this.state.touched[targetParam]) {
      this.setState(prevState => ({
        touched: {
          ...prevState.touched,
          [targetParam]: true
        }
      }));
    }
  };

  render() {
    let benefitDetail = this.getBenefitDetail(this.props);
    let relationToInsuredClass = this.isOtherRelation(benefitDetail)
      ? ["display"]
      : ["hidden"];
    let wasCancelledByIIDClass = shouldDisplaySubQuestions(
      benefitDetail,
      "wasCancelledByIID"
    );

    let wasCancelledByIIDInvertClass = benefitDetail.wasCancelledByIID
      ? ["hidden"]
      : ["display"];

    let refundAmountNILClass = this.noRefundAmount(benefitDetail)
      ? ["display"]
      : ["hidden"];

    this.validateFields(
      benefitDetail,
      this.state.touched,
      this.validationResult
    );
    let data = {
      props: this.props,
      benefitDetail: benefitDetail,
      requirementModalDisplay: this.state.requirementModalDisplay,
      benefitType: this.getBenefitType(),
      hasErr: this.validationResult.hasErr,
      errMsg: this.validationResult.errMsg,
      validationHandlers: {
        handleTouched: this.handleTouched
      },
      viewControls: {
        relationToInsuredClass: relationToInsuredClass,
        wasCancelledByIIDClass: wasCancelledByIIDClass,
        wasCancelledByIIDInvertClass: wasCancelledByIIDInvertClass,
        refundAmountNILClass: refundAmountNILClass,
        showModal: this.showModal,
        closeModal: this.closeModal,
        checkForm: this.checkForm
      }
    };
    return TripCancellationClaimComponent(data);
  }

  noRefundAmount(benefitDetail) {
    return benefitDetail.refundAmount === "0";
  }

  isOtherRelation(benefitDetail) {
    return benefitDetail.relationToInsured === "Other";
  }
}

export default TripCancellationClaim;
