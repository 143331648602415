import React from "react";
import PDPAComponent from "components/forms/pdpa/PDPAComponent";
import { submitClaim } from "common/utils/ClaimSubmission";
import { FORM_LINKS } from "common/Constants";
import { UPDATE_SUBMISSION_TIME } from "common/utils/CommonUtil";

class PDPAPage extends React.Component {
  constructor() {
    super();
    this.state = {
      btnEnable: true
    };
  }

  validationResult = {
    hasErr: {},
    errMsg: {}
  };

  handleSubmission = (claim, region, history, submissionId, parentHandlers) => {
    this.setState({ btnEnable: false });
    parentHandlers.disableNavBar();
    if (submissionId) {
      UPDATE_SUBMISSION_TIME()
        .then(submissionTime => {
          parentHandlers
            .updateSubmissionTime(submissionTime)
            .then(updatedClaim => {
              submitClaim(updatedClaim, region, submissionId)
                .then(response => {
                  history.push({
                    pathname: FORM_LINKS.thankyouPage
                  });
                })
                .catch(error => {
                  parentHandlers.enableNavBar();
                  console.error(
                    "Error: Could not updated submit the claim; ",
                    error
                  );
                });
            });
        })
        .catch(() => {
          console.log("Error getting submission time.");
          submitClaim(claim, region, submissionId)
            .then(response => {
              history.push({
                pathname: FORM_LINKS.thankyouPage
              });
            })
            .catch(error => {
              parentHandlers.enableNavBar();
              console.error("Error: Could not submit the claim; ", error);
            });
        });
    } else {
      parentHandlers.enableNavBar();
      console.error("ERROR: Claim Submission Id was not obtained.");
    }
  };

  checkForm = currentFieldValues => {
    return !(
      !!currentFieldValues.declaration &&
      !!currentFieldValues.authorization &&
      !!currentFieldValues.personalDataConsent
    );
  };

  render() {
    let data = {
      props: this.props,
      hasErr: this.validationResult.hasErr,
      errMsg: this.validationResult.errMsg,
      validationHandlers: {},
      viewControls: {
        checkForm: this.checkForm,
        btnEnable: this.state.btnEnable
      }
    };
    return PDPAComponent(data, this.handleSubmission);
  }
}

export default PDPAPage;
