import React from "react";
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Grid,
  Typography,
  Button,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  RELATION_TO_POLICYHOLDER_TYPES,
  SG_ID_TYPE,
  HK_ID_TYPE,
  FIELD_TYPE,
  REGION
} from "common/Constants";
import {
  SWITCH_LABEL_DIRECTION_STYLE,
  MODAL_OK_BUTTON_STYLE,
  MODAL_HEADER_STYLE,
  MODAL_INPUT_STYLE,
  FORM_CONTROL_STYLE,
  BUTTON_MIN_WIDTH_STYLE,
  FORM_CONTROL_BLOCK_STYLE
} from "common/styles/styles";
import {
  DOES_CURRENT_FIELD_HAVE_ERROR,
  IS_CURRENT_FIELD_VALID_CHECK
} from "common/utils/CommonUtil";
class ClaimantDetailComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      touched: {
        surname: false,
        name: false,
        email: false,
        phone: false,
        sgIDType: false,
        sgIDNumber: false,
        hkIDType: false,
        hkIDNumber: false,
        relationToPolicyHolder: false
      }
    };
  }

  resetModal = () => {
    this.validationResult = {
      hasErr: {
        surname: false,
        name: false,
        email: false,
        phone: false,
        sgIDType: false,
        sgIDNumber: false,
        hkIDType: false,
        hkIDNumber: false,
        relationToPolicyHolder: false
      },
      errMsg: {
        surname: "",
        name: "",
        email: "",
        phone: "",
        sgIDType: "",
        sgIDNumber: "",
        hkIDType: "",
        hkIDNumber: "",
        relationToPolicyHolder: ""
      }
    };
    this.setState({
      touched: {
        surname: false,
        name: false,
        email: false,
        phone: false,
        sgIDType: false,
        sgIDNumber: false,
        hkIDType: false,
        hkIDNumber: false,
        relationToPolicyHolder: false
      }
    });
  };

  validationResult = {
    hasErr: {
      surname: false,
      name: false,
      email: false,
      phone: false,
      sgIDType: false,
      sgIDNumber: false,
      hkIDType: false,
      hkIDNumber: false,
      relationToPolicyHolder: false
    },
    errMsg: {
      surname: "",
      name: "",
      email: "",
      phone: "",
      sgIDType: "",
      sgIDNumber: "",
      hkIDType: "",
      hkIDNumber: "",
      relationToPolicyHolder: ""
    }
  };

  validateFields = (
    currentFieldValues,
    touchedState,
    validationState,
    region
  ) => {
    const isNotPolicyHolder =
      currentFieldValues.relationToPolicyHolder !== "self";
    if (touchedState.surname || currentFieldValues.surname) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "surname",
        "Surname",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.text
      );
    }
    if (touchedState.name || currentFieldValues.name) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "name",
        "First Name",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.text
      );
    }
    if (isNotPolicyHolder) {
      if (
        touchedState.relationToPolicyHolder ||
        currentFieldValues.relationToPolicyHolder
      ) {
        IS_CURRENT_FIELD_VALID_CHECK(
          "relationToPolicyHolder",
          "Relation type",
          "",
          currentFieldValues,
          validationState,
          true,
          FIELD_TYPE.text
        );
      } else {
        this.validationResult.hasErr.relationToPolicyHolder = false;
        this.validationResult.errMsg.relationToPolicyHolder = "";
      }
      if (currentFieldValues.phone) {
        IS_CURRENT_FIELD_VALID_CHECK(
          "phone",
          "Phone",
          "",
          currentFieldValues,
          validationState,
          true,
          FIELD_TYPE.phone
        );
      } else {
        this.validationResult.hasErr.phone = false;
        this.validationResult.errMsg.phone = "";
      }
      if (currentFieldValues.email) {
        IS_CURRENT_FIELD_VALID_CHECK(
          "email",
          "Email",
          "",
          currentFieldValues,
          validationState,
          true,
          FIELD_TYPE.email
        );
      } else {
        this.validationResult.hasErr.email = false;
        this.validationResult.errMsg.email = "";
      }
    } else {
      if (touchedState.email || currentFieldValues.email) {
        IS_CURRENT_FIELD_VALID_CHECK(
          "email",
          "Email",
          "",
          currentFieldValues,
          validationState,
          true,
          FIELD_TYPE.email
        );
      }
      if (touchedState.phone || currentFieldValues.phone) {
        IS_CURRENT_FIELD_VALID_CHECK(
          "phone",
          "Phone",
          "",
          currentFieldValues,
          validationState,
          true,
          FIELD_TYPE.phone
        );
      }
    }
    if (region === REGION.SG) {
      if (touchedState.sgIDNumber || currentFieldValues.sgIDNumber) {
        IS_CURRENT_FIELD_VALID_CHECK(
          "sgIDNumber",
          "ID Number",
          "",
          currentFieldValues,
          validationState,
          true,
          FIELD_TYPE.text
        );
      }
      if (touchedState.sgIDType || currentFieldValues.sgIDType) {
        IS_CURRENT_FIELD_VALID_CHECK(
          "sgIDType",
          "ID Type",
          "",
          currentFieldValues,
          validationState,
          true,
          FIELD_TYPE.text
        );
      }
    } else {
      if (touchedState.hkIDNumber || currentFieldValues.hkIDNumber) {
        IS_CURRENT_FIELD_VALID_CHECK(
          "hkIDNumber",
          "ID Number",
          "",
          currentFieldValues,
          validationState,
          true,
          FIELD_TYPE.text
        );
      }
      if (touchedState.hkIDType || currentFieldValues.hkIDType) {
        IS_CURRENT_FIELD_VALID_CHECK(
          "hkIDType",
          "ID Type",
          "",
          currentFieldValues,
          validationState,
          true,
          FIELD_TYPE.text
        );
      }
    }
  };

  handleTouched = e => {
    let targetParam = e.target.name;
    if (!this.state.touched[targetParam]) {
      this.setState(prevState => ({
        touched: {
          ...prevState.touched,
          [targetParam]: true
        }
      }));
    }
  };

  render() {
    let currentBenefitId = this.props.currentBenefitId;
    let currentBenefitType = this.props.currentBenefitType;
    let claimantInformation = this.props.selectedClaimant;
    let claimantId = this.props.selectedClaimantId;
    let relatedClaims = claimantInformation.relatedClaim || [];
    let viewControls = this.props.viewControls;
    const isSG = viewControls.isSG;
    let claimantRelationToPolicyHolder =
      claimantInformation.relationToPolicyHolder;
    let isRelatedClaim = relatedClaims.includes(currentBenefitId);
    if (isRelatedClaim && !claimantInformation.isRelatedClaim) {
      viewControls.updateModalClaimantDetail("isRelatedClaim", true);
    }
    const isNotPolicyHolder =
      claimantInformation.relationToPolicyHolder !== "self";
    let parentHandlers = this.props.props.props.parentHandlers;
    let region = this.props.region;
    this.validateFields(
      claimantInformation,
      this.state.touched,
      this.validationResult,
      region
    );

    const displaySGDocuments = () => {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={6}>
            <form autoComplete="off">
              <FormControl
                style={FORM_CONTROL_STYLE}
                onBlur={e => {
                  e.target.name = "sgIDType";
                  this.handleTouched(e);
                }}
                error={DOES_CURRENT_FIELD_HAVE_ERROR(
                  claimantInformation.sgIDType,
                  this.state.touched.sgIDType,
                  FIELD_TYPE.text
                )}
              >
                <InputLabel htmlFor="sgIDType-select" required>
                  ID Type
                </InputLabel>
                <Select
                  value={claimantInformation.sgIDType}
                  onChange={e =>
                    viewControls.updateModalClaimantDetail(
                      e.target.name,
                      e.target.value
                    )
                  }
                  inputProps={{
                    name: "sgIDType",
                    id: "sgIDType-select"
                  }}
                >
                  {SG_ID_TYPE.map(idType => (
                    <MenuItem key={idType.value} value={idType.value}>
                      <Typography variant="subtitle2">
                        {idType.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {this.validationResult.errMsg.sgIDType}
                </FormHelperText>
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={DOES_CURRENT_FIELD_HAVE_ERROR(
                claimantInformation.sgIDNumber,
                this.state.touched.sgIDNumber,
                FIELD_TYPE.text
              )}
              helperText={this.validationResult.errMsg.sgIDNumber}
              fullWidth
              required
              margin="normal"
              name="sgIDNumber"
              placeholder="ID Number"
              label="ID Number"
              value={claimantInformation.sgIDNumber}
              onChange={e =>
                viewControls.updateModalClaimantDetail(
                  e.target.name,
                  e.target.value
                )
              }
              onBlur={this.handleTouched}
            />
          </Grid>
        </React.Fragment>
      );
    };

    const displayHKDocuments = () => {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={6}>
            <form autoComplete="off">
              <FormControl
                style={FORM_CONTROL_STYLE}
                onBlur={e => {
                  e.target.name = "hkIDType";
                  this.handleTouched(e);
                }}
                error={DOES_CURRENT_FIELD_HAVE_ERROR(
                  claimantInformation.hkIDType,
                  this.state.touched.hkIDType,
                  FIELD_TYPE.text
                )}
              >
                <InputLabel htmlFor="hkIDType-select" required>
                  ID Type
                </InputLabel>
                <Select
                  value={claimantInformation.hkIDType}
                  onChange={e =>
                    viewControls.updateModalClaimantDetail(
                      e.target.name,
                      e.target.value
                    )
                  }
                  inputProps={{
                    name: "hkIDType",
                    id: "hkIDType-select"
                  }}
                >
                  {HK_ID_TYPE.map(idType => (
                    <MenuItem key={idType.value} value={idType.value}>
                      <Typography variant="subtitle2">
                        {idType.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {this.validationResult.errMsg.hkIDType}
                </FormHelperText>
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={DOES_CURRENT_FIELD_HAVE_ERROR(
                claimantInformation.hkIDNumber,
                this.state.touched.hkIDNumber,
                FIELD_TYPE.text
              )}
              helperText={this.validationResult.errMsg.hkIDNumber}
              fullWidth
              required
              margin="normal"
              name="hkIDNumber"
              placeholder="ID Number"
              label="ID Number"
              value={claimantInformation.hkIDNumber}
              onChange={e =>
                viewControls.updateModalClaimantDetail(
                  e.target.name,
                  e.target.value
                )
              }
              onBlur={this.handleTouched}
            />
          </Grid>
        </React.Fragment>
      );
    };

    return (
      <React.Fragment>
        <div style={MODAL_HEADER_STYLE}>
          <Typography variant="h4">Update claimant details</Typography>
        </div>
        <Grid container spacing={3} style={MODAL_INPUT_STYLE}>
          {claimantRelationToPolicyHolder === "self" ? (
            <Grid item xs={12} sm={6}>
              <form autoComplete="off">
                <FormControl
                  style={FORM_CONTROL_BLOCK_STYLE}
                  className="modal-select-style"
                  disabled
                >
                  <InputLabel htmlFor="relationToPolicyHolder-select" required>
                    Relation To Policy holder
                  </InputLabel>
                  <Select
                    fullWidth
                    value={claimantInformation.relationToPolicyHolder}
                    onChange={e => {
                      viewControls.updateModalClaimantDetail(
                        e.target.name,
                        e.target.value
                      );
                    }}
                    inputProps={{
                      name: "relationToPolicyHolder",
                      id: "relationToPolicyHolder-select"
                    }}
                  >
                    <MenuItem value="self">Self</MenuItem>
                  </Select>
                </FormControl>
              </form>
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              <form autoComplete="off">
                <FormControl
                  className="modal-select-style"
                  style={FORM_CONTROL_BLOCK_STYLE}
                  onBlur={e => {
                    e.target.name = "relationToPolicyHolder";
                    this.handleTouched(e);
                  }}
                  error={DOES_CURRENT_FIELD_HAVE_ERROR(
                    claimantInformation.relationToPolicyHolder,
                    this.state.touched.relationToPolicyHolder,
                    FIELD_TYPE.text
                  )}
                >
                  <InputLabel htmlFor="relationToPolicyHolder-select" required>
                    Relation To Policy holder
                  </InputLabel>
                  <Select
                    fullWidth
                    value={claimantInformation.relationToPolicyHolder}
                    onChange={e => {
                      viewControls.updateModalClaimantDetail(
                        e.target.name,
                        e.target.value
                      );
                    }}
                    inputProps={{
                      name: "relationToPolicyHolder",
                      id: "relationToPolicyHolder-select"
                    }}
                  >
                    {RELATION_TO_POLICYHOLDER_TYPES.filter(
                      relationship => relationship.value !== "Self"
                    ).map(relation => (
                      <MenuItem key={relation.value} value={relation.value}>
                        <Typography variant="subtitle2">
                          {relation.label}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {this.validationResult.errMsg.relationToPolicyHolder}
                  </FormHelperText>
                </FormControl>
              </form>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              error={DOES_CURRENT_FIELD_HAVE_ERROR(
                claimantInformation.surname,
                this.state.touched.surname,
                FIELD_TYPE.text
              )}
              fullWidth
              required
              margin="normal"
              helperText={this.validationResult.errMsg.surname}
              name="surname"
              placeholder="Surname"
              label="Surname"
              value={claimantInformation.surname}
              onChange={e => {
                viewControls.updateModalClaimantDetail(
                  e.target.name,
                  e.target.value
                );
              }}
              onBlur={this.handleTouched}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={DOES_CURRENT_FIELD_HAVE_ERROR(
                claimantInformation.name,
                this.state.touched.name,
                FIELD_TYPE.text
              )}
              fullWidth
              required
              margin="normal"
              helperText={this.validationResult.errMsg.name}
              name="name"
              placeholder="First Name"
              label="First Name"
              value={claimantInformation.name}
              onChange={e => {
                viewControls.updateModalClaimantDetail(
                  e.target.name,
                  e.target.value
                );
              }}
              onBlur={this.handleTouched}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={
                isNotPolicyHolder
                  ? false
                  : DOES_CURRENT_FIELD_HAVE_ERROR(
                      claimantInformation.email,
                      this.state.touched.email,
                      FIELD_TYPE.email
                    )
              }
              helperText={this.validationResult.errMsg.email}
              fullWidth
              margin="normal"
              name="email"
              placeholder="Email"
              label="Email"
              value={claimantInformation.email}
              onChange={e => {
                viewControls.updateModalClaimantDetail(
                  e.target.name,
                  e.target.value
                );
              }}
              onBlur={this.handleTouched}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={
                isNotPolicyHolder
                  ? false
                  : DOES_CURRENT_FIELD_HAVE_ERROR(
                      claimantInformation.phone,
                      this.state.touched.phone,
                      FIELD_TYPE.phone
                    )
              }
              helperText={this.validationResult.errMsg.phone}
              fullWidth
              margin="normal"
              name="phone"
              placeholder="Phone Number"
              label="Phone Number"
              value={claimantInformation.phone}
              onChange={e => {
                viewControls.updateModalClaimantDetail(
                  e.target.name,
                  e.target.value
                );
              }}
              onBlur={this.handleTouched}
            />
          </Grid>
          {isSG ? displaySGDocuments() : displayHKDocuments()}
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                style={SWITCH_LABEL_DIRECTION_STYLE}
                control={
                  <Checkbox
                    checked={claimantInformation.isRelatedClaim}
                    onChange={() => {
                      viewControls.updateModalClaimantDetail(
                        "isRelatedClaim",
                        !claimantInformation.isRelatedClaim
                      );
                    }}
                    value="false"
                    color="primary"
                  />
                }
                label="Applicable to this claim?"
                labelPlacement="start"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <div style={MODAL_OK_BUTTON_STYLE}>
          <Button
            id="claimant-information-modal-ok-button"
            onClick={e =>
              this.props.handleModalSaveAndClose(
                e,
                claimantId,
                parentHandlers,
                currentBenefitId,
                currentBenefitType
              )
            }
            color="primary"
            variant="contained"
            style={BUTTON_MIN_WIDTH_STYLE}
            disabled={viewControls.checkForm(claimantInformation, isSG)}
          >
            OK
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default ClaimantDetailComponent;
