import React from "react";
import ReporterComponent from "components/forms/reporter/ReporterComponent";
import {
  validateEmail,
  validatePhoneNumber,
  IS_EMPTY_FIELD_CHECK
} from "common/utils/CommonUtil";
import { IS_SG_REGION } from "common/Constants";

class Reporter extends React.Component {
  constructor() {
    super();
    this.state = {
      touched: {
        surname: false,
        name: false,
        email: false,
        phone: false,
        sgIDType: false,
        sgIDNumber: false
      }
    };
  }

  handleTouched = e => {
    let targetParam = e.target.name;
    if (!this.state.touched[targetParam]) {
      this.setState(prevState => ({
        touched: {
          ...prevState.touched,
          [targetParam]: true
        }
      }));
    }
  };

  checkForm = (hasErr, currentFieldValues, isSG) => {
    if (isSG) {
      if (currentFieldValues.isPolicyHolder) {
        return false;
      } else {
        if (
          !!currentFieldValues.email &&
          !!currentFieldValues.surname &&
          !!currentFieldValues.name &&
          !!currentFieldValues.phone &&
          !!currentFieldValues.sgIDType &&
          !!currentFieldValues.sgIDNumber
        ) {
          return Object.values(hasErr).indexOf(true) >= 0;
        } else {
          return true;
        }
      }
    } else {
      if (currentFieldValues.isPolicyHolder) {
        return false;
      } else {
        if (
          !!currentFieldValues.email &&
          !!currentFieldValues.surname &&
          !!currentFieldValues.name &&
          !!currentFieldValues.phone
        ) {
          return Object.values(hasErr).indexOf(true) >= 0;
        } else {
          return true;
        }
      }
    }
  };

  validationResult = {
    hasErr: {
      surname: false,
      name: false,
      email: false,
      phone: false,
      sgIDType: false,
      sgIDNumber: false
    },
    errMsg: {
      surname: "",
      name: "",
      email: "",
      phone: "",
      sgIDType: "",
      sgIDNumber: ""
    }
  };

  reporterValidateFields = (
    currentFieldValues,
    touchedState,
    validationState,
    region
  ) => {
    if (currentFieldValues.isPolicyHolder) {
      validationState.hasErr.email = false;
      validationState.errMsg.email = "";
      validationState.hasErr.phone = false;
      validationState.errMsg.phone = "";
      validationState.hasErr.name = false;
      validationState.errMsg.name = "";
      validationState.hasErr.surname = false;
      validationState.errMsg.surname = "";
    } else {
      if (touchedState.email || currentFieldValues.email) {
        if (currentFieldValues.email) {
          if (!validateEmail(currentFieldValues.email)) {
            validationState.hasErr.email = true;
            validationState.errMsg.email = "Email format is not correct";
          } else {
            validationState.hasErr.email = false;
            validationState.errMsg.email = "";
          }
        } else {
          validationState.hasErr.email = true;
          validationState.errMsg.email = "Email field cannot be empty";
        }
      }
      if (touchedState.phone || currentFieldValues.phone) {
        if (currentFieldValues.phone) {
          if (!validatePhoneNumber(currentFieldValues.phone)) {
            validationState.hasErr.phone = true;
            validationState.errMsg.phone =
              "Phone number format is not valid, please enter at least 8 digits";
          } else {
            validationState.hasErr.phone = false;
            validationState.errMsg.phone = "";
          }
        } else {
          validationState.hasErr.phone = true;
          validationState.errMsg.phone = "Phone number field cannot be empty";
        }
      }
      if (touchedState.name || currentFieldValues.name) {
        IS_EMPTY_FIELD_CHECK(
          "name",
          "First Name",
          "",
          currentFieldValues,
          validationState
        );
      }
      if (touchedState.surname || currentFieldValues.surname) {
        IS_EMPTY_FIELD_CHECK(
          "surname",
          "Surname",
          "",
          currentFieldValues,
          validationState
        );
      }
      if (this.isSG(region)) {
        if (touchedState.sgIDNumber || currentFieldValues.sgIDNumber) {
          IS_EMPTY_FIELD_CHECK(
            "sgIDNumber",
            "ID Number",
            "",
            currentFieldValues,
            validationState
          );
        }
        if (touchedState.sgIDType || currentFieldValues.sgIDType) {
          IS_EMPTY_FIELD_CHECK(
            "sgIDType",
            "ID Type",
            "",
            currentFieldValues,
            validationState
          );
        }
      }
    }
  };

  isSG = region => IS_SG_REGION(region);

  render() {
    let parentState = this.props.parentState.claim.reporter;
    let region = this.props.parentState.region;
    this.reporterValidateFields(
      parentState,
      this.state.touched,
      this.validationResult,
      region
    );

    let isPolicyHolderClass = ["display"];
    if (parentState.isPolicyHolder) {
      isPolicyHolderClass = ["hidden"];
    }

    let data = {
      props: this.props,
      hasErr: this.validationResult.hasErr,
      errMsg: this.validationResult.errMsg,
      validationHandlers: {
        handleTouched: this.handleTouched
      },
      viewControls: {
        checkForm: this.checkForm,
        isPolicyHolderClass: isPolicyHolderClass,
        isSG: this.isSG(region)
      }
    };
    return ReporterComponent(data);
  }
}

export default Reporter;
