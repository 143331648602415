import React from "react";
import { Grid, Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  SUMMARY_LOST_ITEMS_LIST_STYLE,
  SUMMARY_LOST_ITEMS_DETAILS_SUB_HEADING_STYLE,
  SUMMARY_CONTAINER_STYLE,
  LOST_BAGGAGE_SUMMARY_STYLE,
  SUMMARY_LIST_ITEMS_CONTAINER_STYLE
} from "common/styles/styles";
import {
  SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT,
  SUMMARY_DISPLAY_DATE_VALUE_COMPONENT,
  SUMMARY_DISPLAY_TIME_VALUE_COMPONENT
} from "common/utils/SummaryUtil";
import { getCurrencyValue } from "common/utils/CommonUtil";

class SummaryLostBaggageDetailComponent extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const detail = this.props.detail;

    return (
      <React.Fragment>
        <Grid container style={SUMMARY_CONTAINER_STYLE} spacing={3}>
          {SUMMARY_DISPLAY_DATE_VALUE_COMPONENT(
            "Date of Incident",
            detail.dateOfLoss
          )}
          {SUMMARY_DISPLAY_TIME_VALUE_COMPONENT(
            "Time of Incident",
            detail.timeOfLoss
          )}
          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Place of Incident",
            detail.locationOfLoss
          )}
          {SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT(
            "Please describe how the incident happened",
            detail.description
          )}
        </Grid>
        <Grid container>
          <Grid item xs={12} style={SUMMARY_LOST_ITEMS_LIST_STYLE}>
            <Typography variant="h6">Detail of lost items:</Typography>
          </Grid>
          {detail.descriptionOfLostItems.map((item, index) => {
            return (
              <Grid
                key={item.makeModel + "_" + index}
                container
                spacing={3}
                style={SUMMARY_LIST_ITEMS_CONTAINER_STYLE}
              >
                <Divider />
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Lost Item # {index + 1}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    style={SUMMARY_LOST_ITEMS_DETAILS_SUB_HEADING_STYLE}
                  >
                    Description of baggage lost/damaged
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {item.description}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    style={SUMMARY_LOST_ITEMS_DETAILS_SUB_HEADING_STYLE}
                  >
                    Make/Model
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">{item.makeModel}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    style={SUMMARY_LOST_ITEMS_DETAILS_SUB_HEADING_STYLE}
                  >
                    Purchase Date
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {item.dateOfPurchase}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    style={SUMMARY_LOST_ITEMS_DETAILS_SUB_HEADING_STYLE}
                  >
                    Purchase Currency
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {item.purchaseCurrency}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    style={SUMMARY_LOST_ITEMS_DETAILS_SUB_HEADING_STYLE}
                  >
                    Purchase Price
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {getCurrencyValue(item.purchasePrice)}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    style={SUMMARY_LOST_ITEMS_DETAILS_SUB_HEADING_STYLE}
                  >
                    Amount Claimed
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {getCurrencyValue(item.amountClaimed)}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        <Divider
          variant="fullWidth"
          color="primary"
          style={LOST_BAGGAGE_SUMMARY_STYLE}
        ></Divider>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Typography variant="subtitle1">
              Total amount claimed for lost baggage
            </Typography>
          </Grid>
          <Grid item xs={5}>
            {detail.accumulatedCurrencies.map(listItemClaim => {
              return (
                <Typography variant="subtitle1" key={listItemClaim.currency}>
                  {listItemClaim.currency} {listItemClaim.totalValue}
                </Typography>
              );
            })}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default SummaryLostBaggageDetailComponent;
