import axios from "axios";
import {
  FILE_UPLOAD_HEADER,
  REACT_APP_URL_FILE_UPLOAD_API,
  API_RESPONSE_STATUS
} from "common/Constants";
import { handleAPIFailResponse } from "common/utils/CommonUtil";

export let uploadFile = (fileToUpload, region) => {
  return new Promise((resolve, reject) => {
    convertToBase64(fileToUpload.file)
      .then(result => {
        let requestBody = {
          fileDirectory: fileToUpload.fileDirectory,
          fileName: fileToUpload.fileName,
          fileType: fileToUpload.fileType,
          file: result,
          region
        };

        uploadFileToS3(requestBody)
          .then(result => {
            const responseData = result.data;
            if (responseData && responseData.status) {
              if (responseData.status === API_RESPONSE_STATUS.SUCCESS) {
                let response = {
                  url: responseData.data.signedURL,
                  fileName: responseData.data.fileName
                };
                resolve(response);
              } else {
                handleAPIFailResponse(
                  responseData.msg,
                  reject,
                  "File Upload failed with an Error"
                );
              }
            } else {
              reject("File upload responded with empty result.");
            }
          })
          .catch(err => {
            console.error("Upload Error: ", err);
            reject(err);
          });
      })
      .catch(err => {
        reject(err);
      });
  });
};

const uploadFileToS3 = requestBody => {
  return axios.post(
    REACT_APP_URL_FILE_UPLOAD_API,
    requestBody,
    FILE_UPLOAD_HEADER
  );
};

const convertToBase64 = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.split(",")[1]);
      } else {
        reject("File reader failed on load for file: " + file);
      }
    };
    reader.onerror = err => {
      reject("File reader error for file: " + file + "; With error: ", err);
    };
  });
};
