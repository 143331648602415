import {
  TextField,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from "@material-ui/core";
import React from "react";
import {
  FORM_LINKS,
  SG_ID_TYPE,
  HK_ID_TYPE,
  CLAIM_FORM_TYPES,
  NAV_LINKS
} from "common/Constants";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE,
  FORM_CONTROL_STYLE,
  REPORTER_FORM_NO_MARGIN_STYLE,
  GRID_CONTAINER_ALIGN_LEFT_STYLE
} from "common/styles/styles";
import NavButton from "../../elements/NavButton";

function ReporterComponent(data) {
  let props = data.props;
  let parentState = props.parentState.claim.reporter;
  let parentHandlers = props.parentHandlers;
  let validationHandlers = data.validationHandlers;
  let viewControls = data.viewControls;
  const isSG = viewControls.isSG;
  const formType = CLAIM_FORM_TYPES.reporter;

  const currentNavState = data.props.parentState.navItem || [];
  const formCheckStatus = viewControls.checkForm(data.hasErr, parentState);
  if (
    currentNavState &&
    formCheckStatus !==
      currentNavState[NAV_LINKS.policyInformation.id].isDisabled
  ) {
    parentHandlers.handleNavBarStatus(
      NAV_LINKS.policyInformation.id,
      formCheckStatus
    );
  }

  const displaySGDocuments = () => {
    return (
      <React.Fragment>
        <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
          <Grid item xs={12} sm={6}>
            <form autoComplete="off">
              <FormControl
                style={FORM_CONTROL_STYLE}
                onBlur={e => {
                  e.target.name = "sgIDType";
                  validationHandlers.handleTouched(e);
                }}
                error={data.hasErr.sgIDType}
              >
                <InputLabel htmlFor="sgIDType-select" required>
                  ID Type
                </InputLabel>
                <Select
                  id="reporter-sgidtype"
                  value={parentState.sgIDType}
                  onChange={e =>
                    parentHandlers.handleClaimUpdateWithParams(
                      formType,
                      e.target.name,
                      e.target.value
                    )
                  }
                  inputProps={{
                    name: "sgIDType",
                    id: "reporter-sgidtype"
                  }}
                >
                  {SG_ID_TYPE.map(idType => (
                    <MenuItem key={idType.value} value={idType.value}>
                      <Typography variant="subtitle2">
                        {idType.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="reporter-sgidnumber"
              error={data.hasErr.sgIDNumber}
              helperText={data.errMsg.sgIDNumber}
              fullWidth
              required
              margin="normal"
              name="sgIDNumber"
              placeholder="ID Number"
              label="ID Number"
              value={parentState.sgIDNumber}
              onChange={e =>
                parentHandlers.handleClaimUpdateWithParams(
                  formType,
                  e.target.name,
                  e.target.value
                )
              }
              onBlur={validationHandlers.handleTouched}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const displayHKDocuments = () => {
    return (
      <React.Fragment>
        <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
          <Grid item xs={12} sm={6}>
            <form autoComplete="off">
              <FormControl
                style={FORM_CONTROL_STYLE}
                onBlur={e => {
                  e.target.name = "hkIDType";
                  validationHandlers.handleTouched(e);
                }}
                error={data.hasErr.hkIDType}
              >
                <InputLabel htmlFor="hkIDType-select" required>
                  ID Type
                </InputLabel>
                <Select
                  id="reporter-hkidtype"
                  value={parentState.hkIDType}
                  onChange={e =>
                    parentHandlers.handleClaimUpdateWithParams(
                      formType,
                      e.target.name,
                      e.target.value
                    )
                  }
                  inputProps={{
                    name: "hkIDType",
                    id: "reporter-hkidtype"
                  }}
                >
                  {HK_ID_TYPE.map(idType => (
                    <MenuItem key={idType.value} value={idType.value}>
                      <Typography variant="subtitle2">
                        {idType.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="reporter-hkidnumber"
              error={data.hasErr.hkIDNumber}
              helperText={data.errMsg.hkIDNumber}
              fullWidth
              required
              margin="normal"
              name="hkIDNumber"
              placeholder="ID Number"
              label="ID Number"
              value={parentState.hkIDNumber}
              onChange={e =>
                parentHandlers.handleClaimUpdateWithParams(
                  formType,
                  e.target.name,
                  e.target.value
                )
              }
              onBlur={validationHandlers.handleTouched}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Typography variant="h1">
        Please provide the reporter's information
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <FormGroup style={{ flexDirection: "row" }}>
            <FormControlLabel
              style={REPORTER_FORM_NO_MARGIN_STYLE}
              control={
                <Checkbox
                  id="reporter-ispolicyholder"
                  checked={parentState.isPolicyHolder}
                  onChange={() => {
                    parentHandlers.handleClaimUpdateWithParams(
                      formType,
                      "isPolicyHolder",
                      !parentState.isPolicyHolder
                    );
                  }}
                  value="false"
                  color="primary"
                />
              }
              label="I am the policyholder"
              labelPlacement="start"
            />
          </FormGroup>
        </Grid>
      </Grid>

      <div
        className={data.viewControls.isPolicyHolderClass.join(" ")}
        id="reporter-is-not-policyholder"
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="reporter-surname"
              error={data.hasErr.surname}
              helperText={data.errMsg.surname}
              fullWidth
              required
              margin="normal"
              name="surname"
              placeholder="Surname"
              label="Surname"
              value={parentState.surname}
              onChange={e =>
                parentHandlers.handleClaimUpdateWithParams(
                  formType,
                  e.target.name,
                  e.target.value
                )
              }
              onBlur={validationHandlers.handleTouched}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="reporter-name"
              error={data.hasErr.name}
              helperText={data.errMsg.name}
              fullWidth
              required
              margin="normal"
              name="name"
              placeholder="First Name"
              label="First Name"
              value={parentState.name}
              onChange={e =>
                parentHandlers.handleClaimUpdateWithParams(
                  formType,
                  e.target.name,
                  e.target.value
                )
              }
              onBlur={validationHandlers.handleTouched}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="reporter-email"
              error={data.hasErr.email}
              helperText={data.errMsg.email}
              fullWidth
              required
              margin="normal"
              name="email"
              placeholder="Email"
              label="Email"
              value={parentState.email}
              onChange={e =>
                parentHandlers.handleClaimUpdateWithParams(
                  formType,
                  e.target.name,
                  e.target.value
                )
              }
              onBlur={validationHandlers.handleTouched}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="reporter-phone"
              error={data.hasErr.phone}
              helperText={data.errMsg.phone}
              fullWidth
              required
              margin="normal"
              name="phone"
              placeholder="Phone"
              label="Phone"
              value={parentState.phone}
              onChange={e =>
                parentHandlers.handleClaimUpdateWithParams(
                  formType,
                  e.target.name,
                  e.target.value
                )
              }
              onBlur={validationHandlers.handleTouched}
            />
          </Grid>
          {isSG ? displaySGDocuments() : displayHKDocuments()}
        </Grid>
      </div>

      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            id="back-button"
            name={"Back"}
            disabled={false}
            clickHandler={() => props.history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />
        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            id="next-button"
            name={"Next"}
            disabled={viewControls.checkForm(data.hasErr, parentState, isSG)}
            clickHandler={e => {
              props.history.push(FORM_LINKS.basicInformation);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ReporterComponent;
