import React from "react";
import { NavLink } from "react-router-dom";
import SubMenuComponent from "components/navbar/SubMenuComponent";
import { ListItem, ListItemText, Collapse } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { NAVBAR_MENU_STYLE } from "common/styles/styles";

class NavBarComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      navOpen: false
    };
  }

  handleClick = () => {
    this.setState({
      navOpen: !this.state.navOpen
    });
  };

  render() {
    let navItem = this.props.navItem;
    // render links or menu items
    return (
      <React.Fragment>
        {navItem.hasSubMenu ? (
          <div style={NAVBAR_MENU_STYLE}>
            <ListItem
              button
              disabled={navItem.isDisabled}
              onClick={e => {
                if (e) {
                  this.handleClick();
                }
              }}
            >
              <ListItemText primary={navItem.text}></ListItemText>
              {this.state.navOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.navOpen} timeout="auto" unmountOnExit>
              <div style={NAVBAR_MENU_STYLE}>
                {navItem.subMenu.map((value, index) => {
                  return (
                    <SubMenuComponent
                      key={index}
                      subMenu={value}
                      index={index}
                    ></SubMenuComponent>
                  );
                })}
              </div>
            </Collapse>
          </div>
        ) : (
          <div style={NAVBAR_MENU_STYLE}>
            <ListItem
              button
              component={NavLink}
              disabled={navItem.isDisabled}
              to={{
                pathname: navItem.link,
                key: navItem.name,
                state: {
                  benefitId: navItem.benefitId,
                  benefitType: navItem.benefitType
                }
              }}
            >
              <ListItemText primary={navItem.name}></ListItemText>
            </ListItem>
          </div>
        )}

        <NavLink to=""></NavLink>
      </React.Fragment>
    );
  }
}

export default NavBarComponent;
