import React from "react";
import { View } from "@react-pdf/renderer";
import {
  pdfStyles,
  BENEFIT_SECTION_SUBTITLE,
  PDF_DISPLAY_LABEL_VALUE_COMPONENT,
  PDF_DISPLAY_DATE_COMPONENT,
  CLAIMANT_DETAILS_FOR_BENEFIT,
  CLAIM_DETAILS_SUB_SECTION
} from "common/utils/PDFDocumentUtil";
import { BENEFIT_TYPES } from "common/Constants";
import { getClaimTitle, getCurrencyValue } from "common/utils/CommonUtil";

const PDFOtherDetailComponent = props => {
  let title = getClaimTitle(BENEFIT_TYPES.otherClaim);
  let suffix = props.suffix;
  let detail = props.detail;

  return (
    <React.Fragment>
      {BENEFIT_SECTION_SUBTITLE(suffix, title)}
      {CLAIMANT_DETAILS_FOR_BENEFIT(
        detail.benefitId,
        props.claimants,
        props.region
      )}
      {CLAIM_DETAILS_SUB_SECTION("Claim details")}
      <View style={pdfStyles.container}>
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Type of benefit",
          detail.benefitDescription
        )}
        {PDF_DISPLAY_DATE_COMPONENT("Date of Incident", detail.dateOfIncident)}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Location of incident",
          detail.locationOfIncident
        )}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Currency of amount claimed",
          detail.reasonForAmount
        )}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Amount claimed",
          getCurrencyValue(detail.amountClaimed)
        )}
        {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
          "Cause and description of the IncidentAmount claimed",
          detail.description
        )}
      </View>
    </React.Fragment>
  );
};

export default PDFOtherDetailComponent;
