import {
  Grid,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  Modal
} from "@material-ui/core";
import React from "react";
import { FORM_LINKS, BENEFIT_TYPES } from "common/Constants";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE,
  CLAIMANT_INFORMATION_ACTION_ICONS_STYLE,
  GRID_CONTAINER_ALIGN_LEFT_STYLE
} from "common/styles/styles";
import NavButton from "components/elements/NavButton";
import { SECONDARY_BUTTON_STYLE } from "common/styles/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LostItemDetail from "components/forms/benefits/lostBaggageClaim/LostItemDetail";
import {
  displayLostBaggageNotification,
  getClaimTitle,
  getCurrencyValue
} from "common/utils/CommonUtil";
import {
  DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT,
  DISPLAY_TEXT_FIELD_FULL_WIDTH,
  DISPLAY_DATE_FIELD,
  DISPLAY_TIME_FIELD
} from "common/components/CommonComponents";

function LostBaggageClaimComponent(data) {
  const validationHandlers = data.validationHandlers;
  const viewControls = data.viewControls;
  const benefitDetail = data.benefitDetail;
  const currentClaimDetailGuid = benefitDetail.benefitId;
  const claimParentHandlers = data.props.parentHandlers;
  const lostItems = benefitDetail.descriptionOfLostItems || [];
  const currentBenefitType = data.benefitType;
  const totalLostItemsClaim = benefitDetail.accumulatedCurrencies || [];
  const region = data.props.parentState.region;

  let showSummary = () => {
    return (
      <React.Fragment>
        {totalLostItemsClaim.map(listItemClaim => {
          return (
            <Typography variant="subtitle1" key={listItemClaim.currency}>
              {listItemClaim.currency} {listItemClaim.totalValue}
            </Typography>
          );
        })}
      </React.Fragment>
    );
  };

  const addNewLostItem = e => {
    if (e) {
      claimParentHandlers.addNewLostItem(
        benefitDetail.benefitId,
        getLatestLostItemId
      );
    }
  };

  const deleteIcon = (benefitId, lostItemId) => (
    <IconButton
      id={"delete-button-" + benefitId}
      style={CLAIMANT_INFORMATION_ACTION_ICONS_STYLE}
      fontSize="small"
      onClick={e => {
        if (e) {
          claimParentHandlers.deleteLostItem(benefitId, lostItemId);
        }
      }}
    >
      <DeleteIcon color="primary" />
    </IconButton>
  );

  const editIcon = (id, item) => (
    <IconButton
      id={"edit-button-" + id}
      style={CLAIMANT_INFORMATION_ACTION_ICONS_STYLE}
      fontSize="small"
      onClick={e => {
        viewControls.handleOpen(e, id, item);
      }}
    >
      <EditIcon fontSize="small" color="primary" />
    </IconButton>
  );

  let getLatestLostItemId = () => {
    let latestLostItems = claimParentHandlers.getLostItems(
      benefitDetail.benefitId
    );
    const latestLostItemIndex = latestLostItems.length - 1;
    let lostItemDetail = latestLostItems[latestLostItemIndex];
    viewControls.handleOpen(
      lostItemDetail,
      lostItemDetail.lostItemId,
      lostItemDetail
    );
  };

  let showTable = () => {
    return (
      <React.Fragment>
        <Paper square className="enable-horizontal-scroll">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                <TableCell>Description of item</TableCell>
                <TableCell>Make/Model</TableCell>
                <TableCell>Purchase Date</TableCell>
                <TableCell>Purchase Currency</TableCell>
                <TableCell>Purchase Price</TableCell>
                <TableCell>Amount Claimed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lostItems.map(lostItem => {
                let lostItemId = lostItem.lostItemId;
                return (
                  <TableRow key={lostItemId}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ padding: "16px 0px 16px 0px" }}
                    >
                      {deleteIcon(benefitDetail.benefitId, lostItemId)}
                      {editIcon(lostItemId, lostItem)}
                    </TableCell>
                    <TableCell>{lostItem.description}</TableCell>
                    <TableCell>{lostItem.makeModel}</TableCell>
                    <TableCell>{lostItem.dateOfPurchase}</TableCell>
                    <TableCell>{lostItem.purchaseCurrency}</TableCell>
                    <TableCell>
                      {getCurrencyValue(lostItem.purchasePrice)}
                    </TableCell>
                    <TableCell>
                      {getCurrencyValue(lostItem.amountClaimed)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Typography variant="h1">
        {" "}
        {getClaimTitle(BENEFIT_TYPES.lostBaggageClaim)}
      </Typography>
      <div style={SECONDARY_BUTTON_STYLE}>
        <Button
          id="view-requirements-button"
          onClick={e => viewControls.showModal()}
          color="primary"
          variant="outlined"
        >
          VIEW REQUIREMENTS
        </Button>
      </div>
      <Grid container spacing={3}>
        <Grid container spacing={3} style={GRID_CONTAINER_ALIGN_LEFT_STYLE}>
          {DISPLAY_DATE_FIELD(
            {
              helperText: data.errMsg.dateOfLoss,
              error: data.hasErr.dateOfLoss,
              value: benefitDetail.dateOfLoss,
              name: "dateOfLoss",
              label: "Date of Incident",
              benefitType: currentBenefitType,
              blur: validationHandlers.handleTouchedLostBaggage,
              currentClaimDetailGuid: currentClaimDetailGuid
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
          {DISPLAY_TIME_FIELD(
            {
              helperText: data.errMsg.timeOfLoss,
              error: data.hasErr.timeOfLoss,
              value: benefitDetail.timeOfLoss,
              name: "timeOfLoss",
              label: "Time of Incident",
              benefitType: currentBenefitType,
              blur: validationHandlers.handleTouchedLostBaggage,
              currentClaimDetailGuid: currentClaimDetailGuid
            },
            claimParentHandlers.handleTravelMisConnectionClaimWithParams
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {DISPLAY_TEXT_FIELD_FULL_WIDTH(
          {
            helperText: data.errMsg.locationOfLoss,
            error: data.hasErr.locationOfLoss,
            value: benefitDetail.locationOfLoss,
            name: "locationOfLoss",
            blur: validationHandlers.handleTouchedLostBaggage,
            label: "Place of Incident",
            placeholder: "Place of Incident",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
        {DISPLAY_TEXT_FIELD_FULL_WIDTH(
          {
            helperText: data.errMsg.description,
            error: data.hasErr.description,
            value: benefitDetail.description,
            name: "description",
            blur: validationHandlers.handleTouchedLostBaggage,
            label: "Please describe how the incident happened",
            placeholder: "Please describe how the incident happened",
            benefitType: currentBenefitType,
            currentClaimDetailGuid: currentClaimDetailGuid,
            hasParams: true
          },
          claimParentHandlers.handleTravelMisConnectionClaimWithParams
        )}
      </Grid>
      {displayLostBaggageNotification()}
      <div>
        <div style={SECONDARY_BUTTON_STYLE}>
          <Button
            id="add-new-lost-item"
            onClick={e => addNewLostItem(e)}
            color="primary"
            variant="outlined"
          >
            Add Lost Item
          </Button>
        </div>
        {/* Claimant Information display -- start */}
        {lostItems.length ? showTable() : null}
        {/* Claimant Information display -- end */}
      </div>
      <Grid
        container
        spacing={3}
        style={{ textAlign: "left", paddingTop: "12px" }}
      >
        <Grid item xs={7}>
          <Typography variant="subtitle1">
            Total amount claimed for lost baggage
          </Typography>
        </Grid>
        <Grid item xs={5}>
          {totalLostItemsClaim.length ? showSummary() : null}
        </Grid>
      </Grid>
      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            id="back-button"
            name={"Back"}
            disabled={false}
            clickHandler={() => data.props.history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />
        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            id="next-button"
            name={"Next"}
            disabled={viewControls.checkForm(data.hasErr, benefitDetail)}
            clickHandler={e => {
              data.props.history.push({
                pathname: FORM_LINKS.claimantInformation,
                state: {
                  benefitId: currentClaimDetailGuid,
                  benefitType: BENEFIT_TYPES.lostBaggageClaim
                }
              });
            }}
          />
        </div>
      </div>
      {/* Add Item Modal */}
      <Modal
        aria-labelledby="Add Lost Item Title"
        aria-describedby="Enter details for new Lost Item"
        open={data.modalOpen}
        onClose={e =>
          viewControls.handleClose(
            e,
            claimParentHandlers,
            benefitDetail.benefitId
          )
        }
        style={{ overflow: "auto" }}
      >
        <Card className="app-modal-card-style">
          <LostItemDetail
            key={data.selectedClaimantId + "-modal"}
            id={data.selectedClaimantId + "-modal"}
            props={data}
            currentBenefitId={benefitDetail.benefitId}
            validationHandlers={validationHandlers}
            viewControls={viewControls}
            closeHandler={viewControls.handleClose}
            handleModalSaveAndClose={viewControls.handleModalSaveAndClose}
            detail={data.detail}
          />
        </Card>
      </Modal>
      {/* Add Item Modal - end */}

      {/* Requirements Modal */}
      {DISPLAY_FILE_UPLOAD_REQUIREMENTS_MODAL_COMPONENT(
        data.requirementModalDisplay,
        viewControls.closeModal,
        BENEFIT_TYPES.lostBaggageClaim,
        region
      )}
      {/* Requirements Modal end */}
    </React.Fragment>
  );
}

export default LostBaggageClaimComponent;
