import React from "react";
import SelectClaimTypeComponent from "components/forms/benefits/selectClaimType/SelectClaimTypeComponent";

class SelectClaimType extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return SelectClaimTypeComponent(this.props);
  }
}

export default SelectClaimType;
