import {
  Grid,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Typography
} from "@material-ui/core";
import React from "react";
import { FORM_LINKS } from "common/Constants";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE
} from "common/styles/styles";
import NavButton from "components/elements/NavButton";

function AddAnotherClaimComponent(data) {
  let value =
    data.props.parentState.claim.addAnotherClaim.addAnotherClaim || "no";
  let addClaimParentHandlers = data.props.parentHandlers;
  let getNextForm = () => {
    let nextFormLink = "";
    switch (value) {
      case "yes":
        nextFormLink = FORM_LINKS.selectClaimType;
        break;
      case "no":
        nextFormLink = FORM_LINKS.fileUpload;
        break;
      default:
        break;
    }
    return nextFormLink;
  };
  return (
    <React.Fragment>
      <Typography variant="h1">Do you want to add another claim?</Typography>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="addClaim"
            name="addClaim"
            value={value}
            onChange={addClaimParentHandlers.handleAddAnotherClaim}
          >
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" />}
              label="Yes"
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            name={"Back"}
            disabled={false}
            clickHandler={() => data.props.history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />
        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            name={"Next"}
            disabled={false}
            clickHandler={e => {
              data.props.history.push(getNextForm());
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddAnotherClaimComponent;
