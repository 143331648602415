import React from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  Typography,
  Checkbox
} from "@material-ui/core";
import { BENEFIT_TYPES } from "common/Constants";
import ClaimantDetailComponent from "components/forms/claims/claimantInformation/ClaimantDetailComponent";
import { SECONDARY_BUTTON_STYLE } from "common/styles/styles";
import { displayLostBaggageNotification } from "common/utils/CommonUtil";
import { FORM_LINKS, NAV_LINKS } from "common/Constants";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE,
  CLAIMANT_INFORMATION_ACTION_ICONS_STYLE,
  MODAL_OVERFLOW_STYLE,
  CLAIMANT_INFORMATION_BUTTON_STYLE
} from "common/styles/styles";
import NavButton from "components/elements/NavButton";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@material-ui/core/Modal";

function ClaimantInformationComponent(data) {
  let viewControls = data.viewControls;
  const isSG = viewControls.isSG;
  let currentBenefitDetail = data.benefitDetail;
  let currentBenefitType = data.benefitType;
  let currentBenefitId = currentBenefitDetail.benefitId;
  let claimantInformation =
    data.props.parentState.claim.claimantInformation || [];
  let parentHandlers = data.props.parentHandlers;
  let parentState = data.props.parentState;
  let subMenuItems =
    parentState.navItem.filter(item => item.name === "Claims")[0].subMenu || [];
  let subMenuItem =
    subMenuItems.filter(item => item.benefitId === currentBenefitId)[0] || {};
  let currentBenefitLabel = subMenuItem.text || "";

  let formCheckStatus = !claimantInformation.some(
    claimant => claimant.relatedClaim.indexOf(currentBenefitId) >= 0
  );
  const currentNavState = data.props.parentState.navItem || [];
  if (
    currentNavState &&
    formCheckStatus !== currentNavState[NAV_LINKS.documentsUpload.id].isDisabled
  ) {
    parentHandlers.handleNavBarStatus(
      NAV_LINKS.documentsUpload.id,
      formCheckStatus
    );
  }
  /* Claimant Information display -- start */
  const deleteIcon = claimantId => (
    <IconButton
      id={"delete-button-" + claimantId}
      fontSize="small"
      style={CLAIMANT_INFORMATION_ACTION_ICONS_STYLE}
      onClick={e => {
        if (e) {
          data.props.parentHandlers.deleteClaimant(claimantId);
        }
      }}
    >
      <DeleteIcon color="primary" />
    </IconButton>
  );

  const deleteDisabled = () => (
    <IconButton
      id="delete-button-disabled"
      fontSize="small"
      disableFocusRipple
      style={CLAIMANT_INFORMATION_ACTION_ICONS_STYLE}
    >
      <DeleteIcon color="disabled" />
    </IconButton>
  );

  const editIcon = (claimantId, claimant, isSG) => {
    return (
      <IconButton
        id={"edit-button-" + claimantId}
        style={CLAIMANT_INFORMATION_ACTION_ICONS_STYLE}
        fontSize="small"
        onClick={e => {
          viewControls.modalOpen(e, claimantId, claimant, isSG);
        }}
      >
        <EditIcon fontSize="small" color="primary" />
      </IconButton>
    );
  };

  let addNewClaimant = e => {
    if (e) {
      data.props.parentHandlers.addNewClaimant(getLatestClaimantId);
    }
  };

  let getLatestClaimantId = () => {
    let latestClaimantInfo = data.props.parentHandlers.getClaimants();
    let latestClaimantIndex = latestClaimantInfo.length - 1;
    let claimantDetail = latestClaimantInfo[latestClaimantIndex];
    let latestClaimantId = claimantDetail.claimantId;
    viewControls.modalOpen(latestClaimantId, latestClaimantId, claimantDetail);
  };

  /* Claimant Information display -- end */
  return (
    <React.Fragment>
      <Typography variant="h1">
        Please tell us who is involved in this claim:
      </Typography>
      <Typography variant="h3">{currentBenefitLabel}</Typography>
      {currentBenefitType === BENEFIT_TYPES.lostBaggageClaim
        ? displayLostBaggageNotification()
        : null}

      <div>
        <div style={SECONDARY_BUTTON_STYLE}>
          <Button
            id="add-claimant-button"
            onClick={e => addNewClaimant(e)}
            color="primary"
            variant="outlined"
          >
            Add New Claimant
          </Button>
        </div>
        {/* Claimant Information display -- start */}
        <Paper square className="enable-horizontal-scroll">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                <TableCell>Applicable to this claim?</TableCell>
                <TableCell>Surname</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Relation to Policy holder</TableCell>
                {isSG ? (
                  <React.Fragment>
                    <TableCell>ID Type</TableCell>
                    <TableCell>ID Number</TableCell>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <TableCell>ID Type</TableCell>
                    <TableCell>ID Number</TableCell>
                  </React.Fragment>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {claimantInformation.map(claimant => {
                let isApplicableToCurrentBenefit = claimant.relatedClaim.includes(
                  currentBenefitId
                );
                let claimantId = claimant.claimantId;
                return (
                  <TableRow key={claimantId}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={CLAIMANT_INFORMATION_BUTTON_STYLE}
                    >
                      {claimant && claimant.relationToPolicyHolder === "self"
                        ? deleteDisabled()
                        : deleteIcon(claimantId)}
                      {editIcon(claimantId, claimant, isSG)}
                    </TableCell>
                    <TableCell>
                      {
                        <React.Fragment>
                          <Checkbox
                            checked={isApplicableToCurrentBenefit}
                            onChange={() => {
                              isApplicableToCurrentBenefit
                                ? data.props.parentHandlers.removeBenefitIdFromClaimant(
                                    claimantId,
                                    currentBenefitId
                                  )
                                : data.props.parentHandlers.updateBenefitIdToClaimant(
                                    claimantId,
                                    currentBenefitId,
                                    currentBenefitType
                                  );
                            }}
                            value="false"
                            color="primary"
                          />
                        </React.Fragment>
                      }
                    </TableCell>
                    <TableCell>{claimant.surname}</TableCell>
                    <TableCell>{claimant.name}</TableCell>
                    <TableCell>{claimant.email}</TableCell>
                    <TableCell>{claimant.phone}</TableCell>
                    <TableCell>
                      {String(claimant.relationToPolicyHolder).toUpperCase()}
                    </TableCell>
                    {isSG ? (
                      <React.Fragment>
                        <TableCell>{claimant.sgIDType}</TableCell>
                        <TableCell>{claimant.sgIDNumber}</TableCell>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <TableCell>{claimant.hkIDType}</TableCell>
                        <TableCell>{claimant.hkIDNumber}</TableCell>
                      </React.Fragment>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            id="back-button"
            name={"Back"}
            disabled={false}
            clickHandler={() => data.props.history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />
        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            id="next-button"
            name={"Next"}
            disabled={formCheckStatus}
            clickHandler={e => {
              data.props.history.push(FORM_LINKS.addAnotherClaim);
            }}
          />
        </div>
      </div>

      {/* Modal */}
      <Modal
        aria-labelledby="Add Claimant Title"
        aria-describedby="Enter details for new Claimant"
        open={data.modalOpen}
        onClose={e => viewControls.modalClose(e, parentHandlers)}
        style={MODAL_OVERFLOW_STYLE}
      >
        <Card className="app-modal-card-style" square>
          <ClaimantDetailComponent
            key={data.selectedClaimantId + "-modal"}
            props={data}
            selectedClaimant={data.selectedClaimant}
            selectedClaimantId={data.selectedClaimantId}
            currentBenefitId={currentBenefitId}
            currentBenefitType={currentBenefitType}
            viewControls={viewControls}
            closeHandler={viewControls.modalClose}
            handleModalSaveAndClose={viewControls.handleModalSaveAndClose}
          />
        </Card>
      </Modal>
      {/* Modal end */}
    </React.Fragment>
  );
}

export default ClaimantInformationComponent;
