import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import WelcomePage from "components/forms/welcomePage/WelcomePage";
import SummaryPage from "components/forms/summaryPage/SummaryPage";
import ThankyouPage from "components/forms/thankyouPage/ThankyouPage";
import AboutSelf from "components/forms/aboutSelf/AboutSelf";
import Reporter from "components/forms/reporter/Reporter";
import BasicInformation from "components/forms/claims/basicInformation/BasicInformation";
import AddAnotherClaim from "components/forms/decisions/AddAnotherClaim";
import SelectClaimType from "components/forms/benefits/selectClaimType/SelectClaimType";
import FlightDelayClaim from "components/forms/benefits/flightDelayClaim/FlightDelayClaim";
import LostBaggageClaim from "components/forms/benefits/lostBaggageClaim/LostBaggageClaim";
import TripCancellationClaim from "components/forms/benefits/tripCancellationClaim/TripCancellationClaim";
import MedicalClaim from "components/forms/benefits/medicalClaim/MedicalClaim";
import ClaimantInformation from "components/forms/claims/claimantInformation/ClaimantInformation";
import TravelMisConnectionClaim from "components/forms/benefits/travelMisConnection/TravelMisConnectionClaim";
import OtherClaim from "components/forms/benefits/otherClaim/OtherClaim";
import FileUpload from "components/forms/fileUpload/FileUpload";
import PDPAPage from "components/forms/pdpa/PDPAPage";
import { FORM_LINKS, ERRORS } from "common/Constants";
import ErrorComponent from "common/components/ErrorComponent";

const isValidRoute = state => {
  let isValid = false;
  if (
    state &&
    state.region &&
    state.claim &&
    state.claim.claimDirectory &&
    state.claim.submissionId
  ) {
    let region = state.region;
    let dir = state.claim.claimDirectory;
    let submissionId = state.claim.submissionId;
    isValid = !!region && !!dir && !!submissionId;
  }
  return isValid;
};

const defaultRedirectComponent = () => (
  <Redirect to={{ pathname: FORM_LINKS.defaultRoot }} />
);

export const redirectToHKRoot = () => (
  <Redirect to={{ pathname: FORM_LINKS.defaultHKRoot }} />
);

export const redirectToSGRoot = () => (
  <Redirect to={{ pathname: FORM_LINKS.defaultSGRoot }} />
);

const redirectToErrorPage = () => {
  throw new Error(ERRORS.INVALID_ROUTE);
};

export const AppRoutes = (state, parentHandlers) => {
  let canRoute = isValidRoute(state);
  return (
    <React.Fragment>
      <ErrorComponent parentState={state}>
        <Switch>
          <Route
            exact
            path={FORM_LINKS.root}
            render={() => defaultRedirectComponent()}
          />
          <Route
            path={FORM_LINKS.welcomePage}
            render={props => (
              <WelcomePage
                {...props}
                parentHandlers={parentHandlers.getParentHandlersWelcomePage()}
                parentState={state}
              />
            )}
          />
          <Route
            exact
            path={FORM_LINKS.aboutSelf}
            render={props => {
              return canRoute ? (
                <AboutSelf
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersAboutSelf()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.reporter}
            render={props => {
              return canRoute ? (
                <Reporter
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersReporter()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.basicInformation}
            render={props => {
              return canRoute ? (
                <BasicInformation
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersBasicInformation()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.selectClaimType}
            render={props => {
              return canRoute ? (
                <SelectClaimType
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersSelectClaimType()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.flightDelayClaim}
            render={props => {
              return canRoute ? (
                <FlightDelayClaim
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersFlightDelayClaim()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.travelMisConnectionClaim}
            render={props => {
              return canRoute ? (
                <TravelMisConnectionClaim
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersTravelMisConnectionClaim()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.lostBaggageClaim}
            render={props => {
              return canRoute ? (
                <LostBaggageClaim
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersLostBaggageClaim()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.tripCancellationClaim}
            render={props => {
              return canRoute ? (
                <TripCancellationClaim
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersTripCancellationClaim()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.medicalClaim}
            render={props => {
              return canRoute ? (
                <MedicalClaim
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersMedicalClaim()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.otherClaim}
            render={props => {
              return canRoute ? (
                <OtherClaim
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersOtherClaim()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.addAnotherClaim}
            render={props => {
              return canRoute ? (
                <AddAnotherClaim
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersAddAnotherClaim()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.claimantInformation}
            render={props => {
              return canRoute ? (
                <ClaimantInformation
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersClaimantInformation()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.fileUpload}
            render={props => {
              return canRoute ? (
                <FileUpload
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersFileUpload()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.summary}
            render={props => {
              return canRoute ? (
                <SummaryPage
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersSummaryPage()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.pdpa}
            render={props => {
              return canRoute ? (
                <PDPAPage
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersPDPAPage()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route
            exact
            path={FORM_LINKS.thankyouPage}
            render={props => {
              return canRoute ? (
                <ThankyouPage
                  {...props}
                  parentHandlers={parentHandlers.getParentHandlersSummaryPage()}
                  parentState={state}
                />
              ) : (
                redirectToErrorPage()
              );
            }}
          />
          <Route path={""} render={() => defaultRedirectComponent()} />
        </Switch>
      </ErrorComponent>
    </React.Fragment>
  );
};
