import React from "react";
import {
  TextField,
  Grid,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from "@material-ui/core";
import { CURRENCY_TYPE } from "common/Constants";
import {
  MODAL_OK_BUTTON_STYLE,
  MODAL_HEADER_STYLE,
  MODAL_INPUT_STYLE,
  FORM_CONTROL_STYLE,
  BUTTON_MIN_WIDTH_STYLE
} from "common/styles/styles";
import { handleFloatingCurrencyFormatting } from "common/utils/CommonUtil";

class LostItemDetail extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let parentHandlers = this.props.props.props.parentHandlers;
    let benefitId = this.props.currentBenefitId;
    let viewControls = this.props.viewControls;
    let detail = this.props.detail;
    let validationHandlers = this.props.validationHandlers;

    return (
      <React.Fragment>
        <div style={MODAL_HEADER_STYLE}>
          <Typography variant="h4">Lost Item information</Typography>
        </div>

        <Grid container spacing={3} style={MODAL_INPUT_STYLE}>
          <Grid item xs={12}>
            <TextField
              error={validationHandlers.fieldEmptyValidation(
                "description",
                detail.description
              )}
              id="lostItemDescription"
              fullWidth
              required
              margin="normal"
              helperText=""
              name="description"
              placeholder="Description of item"
              label="Description of item"
              value={detail.description}
              onChange={e => {
                viewControls.updateModal(e.target.name, e.target.value);
              }}
              onBlur={validationHandlers.handleTouched}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              error={validationHandlers.fieldEmptyValidation(
                "makeModel",
                detail.makeModel
              )}
              id="makeModel"
              fullWidth
              margin="normal"
              helperText=""
              name="makeModel"
              placeholder="Make/Model"
              label="Make/Model"
              value={detail.makeModel}
              onChange={e => {
                viewControls.updateModal(e.target.name, e.target.value);
              }}
              onBlur={validationHandlers.handleTouched}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              error={validationHandlers.fieldEmptyValidation(
                "dateOfPurchase",
                detail.dateOfPurchase
              )}
              id="dateOfPurchase"
              fullWidth
              required
              margin="normal"
              helperText=""
              name="dateOfPurchase"
              placeholder="Purchase Date"
              label="Purchase Date"
              value={detail.dateOfPurchase}
              onChange={e => {
                viewControls.updateModal(e.target.name, e.target.value);
              }}
              onBlur={validationHandlers.handleTouched}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <form autoComplete="off">
              <FormControl
                style={FORM_CONTROL_STYLE}
                onBlur={e => {
                  e.target.name = "purchaseCurrency";
                  validationHandlers.handleTouched(e);
                }}
                error={validationHandlers.fieldEmptyValidation(
                  "purchaseCurrency",
                  detail.purchaseCurrency
                )}
              >
                <InputLabel htmlFor="purchaseCurrency" required>
                  Purchase Currency
                </InputLabel>
                <Select
                  value={detail.purchaseCurrency}
                  id="purchaseCurrency"
                  onChange={e => {
                    viewControls.updateModal(
                      "purchaseCurrency",
                      e.target.value
                    );
                  }}
                  inputProps={{
                    name: "purchaseCurrency",
                    id: "purchaseCurrency"
                  }}
                >
                  {CURRENCY_TYPE.map(currencyType => (
                    <MenuItem
                      key={currencyType.value}
                      value={currencyType.value}
                    >
                      <Typography variant="subtitle2">
                        {currencyType.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </form>
            {/* 
            <TextField
              error={validationHandlers.fieldEmptyValidation(
                "purchaseCurrency",
                detail.purchaseCurrency
              )}
              fullWidth
              required
              margin="normal"
              helperText=""
              name="purchaseCurrency"
              placeholder="Purchase Currency"
              label="Purchase Currency"
              value={detail.purchaseCurrency}
              onChange={e => {
                viewControls.updateModal(e.target.name, e.target.value);
              }}
              onBlur={validationHandlers.handleTouched}
            />
             */}
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              error={validationHandlers.fieldEmptyValidation(
                "purchasePrice",
                detail.purchasePrice
              )}
              id="purchasePrice"
              fullWidth
              required
              margin="normal"
              helperText=""
              name="purchasePrice"
              placeholder="Purchase Price"
              label="Purchase Price"
              value={detail.purchasePrice}
              onChange={e => {
                viewControls.updateModal(
                  e.target.name,
                  handleFloatingCurrencyFormatting(e.target.value)
                );
              }}
              onBlur={validationHandlers.handleTouched}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              error={validationHandlers.fieldEmptyValidation(
                "amountClaimed",
                detail.amountClaimed
              )}
              id="amountClaimed"
              fullWidth
              required
              margin="normal"
              helperText=""
              name="amountClaimed"
              placeholder="Amount Claimed"
              label="Amount Claimed"
              value={detail.amountClaimed}
              onChange={e => {
                viewControls.updateModal(
                  e.target.name,
                  handleFloatingCurrencyFormatting(e.target.value)
                );
              }}
              onBlur={validationHandlers.handleTouched}
            />
          </Grid>
        </Grid>

        <div style={MODAL_OK_BUTTON_STYLE}>
          <Button
            id="lost-item-detail-ok-button"
            onClick={e => {
              this.props.handleModalSaveAndClose(e, parentHandlers, benefitId);
            }}
            color="primary"
            variant="contained"
            style={BUTTON_MIN_WIDTH_STYLE}
            disabled={viewControls.checkModal(detail)}
          >
            OK
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default LostItemDetail;
