import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import {
  SUMMARY_LOST_ITEMS_LIST_STYLE,
  SUMMARY_CONTAINER_STYLE,
  FULL_WIDTH_STYLE
} from "common/styles/styles";
import { getDate, getCurrencyValue } from "common/utils/CommonUtil";
import { getCurrency } from "common/Constants";

class SummaryTripCancellationDetailComponent extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  causedByIID = detail => {
    return (
      <React.Fragment>
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            Name of Person who suffered injury, illness or death
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">{detail.nameOfIID}</Typography>
        </Grid>
        {/* {detail.relationToInsured === "Other" ? : } */}
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            Relation to an Insured Person
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            {detail.relationToInsured === "Other"
              ? detail.otherRelation
              : detail.relationToInsured}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  reasonForCancelling = detail => {
    return (
      <React.Fragment>
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            Reason(s) for cancelling your trip/cutting short your trip
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            {detail.cancellationReason}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    const detail = this.props.detail;
    const region = this.props.region;
    const currency = getCurrency(region);

    return (
      <React.Fragment>
        <Grid container style={SUMMARY_CONTAINER_STYLE} spacing={3}>
          {/* {detail.wasCurtailedByIID
            ? this.causedByIID(detail)
            : this.reasonForCancelling(detail)} */}
          {this.reasonForCancelling(detail)}
          <Grid item xs={8}>
            <Typography variant="subtitle1">
              Date of event that led to cancellation/trip being cut short
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {typeof detail.dateOfEvent === "string" ? (
              <Typography variant="subtitle2">{detail.dateOfEvent}</Typography>
            ) : (
              <Typography variant="subtitle2">
                {getDate(detail.dateOfEvent)}
              </Typography>
            )}
          </Grid>
          {this.causedByIID(detail)}

          <Grid item xs={8}>
            <Typography variant="subtitle1">Deposit Amount</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {currency} {getCurrencyValue(detail.depositAmount)}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="subtitle1">Refund Amount</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {currency} {getCurrencyValue(detail.refundAmount)}
            </Typography>
          </Grid>

          {detail.refundAmount === "0.00" ? (
            <React.Fragment>
              <Grid item xs={8}>
                <Typography variant="subtitle1">
                  Reason for no refund
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {detail.noRefundDescription}
                </Typography>
              </Grid>
            </React.Fragment>
          ) : null}

          <Divider
            variant="fullWidth"
            color="primary"
            style={FULL_WIDTH_STYLE}
          ></Divider>
          <Grid item xs={8} style={SUMMARY_LOST_ITEMS_LIST_STYLE}>
            <Typography variant="h6">
              Total amount claimed for trip cancellation
            </Typography>
          </Grid>
          <Grid item xs={4} style={SUMMARY_LOST_ITEMS_LIST_STYLE}>
            <Typography variant="h6">
              {currency} {getCurrencyValue(detail.totalAmount)}
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default SummaryTripCancellationDetailComponent;
