import axios from "axios";
import {
  API_RESPONSE_STATUS,
  REACT_APP_DELETE_FILE_API
} from "common/Constants";
import { handleAPIFailResponse } from "common/utils/CommonUtil";

export const DELETE_FILE = (
  fileName,
  mainDirectory,
  claimId,
  submissionId,
  region
) => {
  return new Promise((resolve, reject) => {
    const deleteFileRequest = {
      submissionId: submissionId,
      claimId: claimId,
      fileName: fileName,
      mainDirectory: mainDirectory,
      region
    };
    axios
      .post(REACT_APP_DELETE_FILE_API, deleteFileRequest)
      .then(result => {
        const responseData = result.data;
        if (responseData && responseData.status) {
          if (responseData.status === API_RESPONSE_STATUS.SUCCESS) {
            resolve("File deleted successfully.");
          } else {
            handleAPIFailResponse(
              responseData.msg,
              reject,
              "File Upload failed with an Error"
            );
          }
        } else {
          reject("File delete API responded with empty result.");
        }
      })
      .catch(err => {
        console.error(`Could not delete the file: ${fileName}; Error: ${err};`);
        reject("Could not delete the file: " + fileName);
      });
  });
};
