import axios from "axios";
import {
  FILE_UPLOAD_HEADER,
  APP_URL_EMAIL_API,
  API_RESPONSE_STATUS
} from "common/Constants";
import { handleAPIFailResponse } from "common/utils/CommonUtil";

export const sendEmail = (
  submissionId,
  claimId,
  emailToAddress,
  mainDirectory,
  region
) => {
  const requestBody = {
    submissionId,
    claimId,
    emailToAddress,
    mainDirectory,
    region
  };
  return new Promise((resolve, reject) => {
    axios
      .post(APP_URL_EMAIL_API, requestBody, FILE_UPLOAD_HEADER)
      .then(result => {
        const responseData = result.data;
        if (
          responseData &&
          responseData.status &&
          responseData.data &&
          responseData.data.msg
        ) {
          if (responseData.status === API_RESPONSE_STATUS.SUCCESS) {
            resolve(responseData.data.msg);
          } else {
            handleAPIFailResponse(
              responseData.msg,
              reject,
              "Send Email API failed with an Error"
            );
          }
        } else {
          reject("Email sender has returned invalid response.");
        }
      })
      .catch(err => {
        console.error("ERROR: ", JSON.stringify(err));
        reject(err);
      });
  });
};
