import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { getTime, getDate } from "common/utils/CommonUtil";

export const SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT = (label, value) => (
  <React.Fragment>
    <Grid item xs={6} sm={3}>
      <Typography variant="subtitle1">{label}</Typography>
    </Grid>
    <Grid item xs={6} sm={3}>
      <Typography variant="subtitle2">{value}</Typography>
    </Grid>
  </React.Fragment>
);

export const SUMMARY_DISPLAY_LABEL_VALUE_COMPONENT_FULL_LENGTH = (
  label,
  value
) => (
  <React.Fragment>
    <Grid item xs={12}>
      <Typography variant="subtitle1">{label}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="subtitle2">{value}</Typography>
    </Grid>
  </React.Fragment>
);

export const SUMMARY_DISPLAY_DATE_VALUE_COMPONENT = (label, value) => (
  <React.Fragment>
    <Grid item xs={6} sm={3}>
      <Typography variant="subtitle1">{label}</Typography>
    </Grid>
    <Grid item xs={6} sm={3}>
      <Typography variant="subtitle2">
        {typeof value === "string" ? value : getDate(value)}
      </Typography>
    </Grid>
  </React.Fragment>
);

export const SUMMARY_DISPLAY_TIME_VALUE_COMPONENT = (label, value) => (
  <React.Fragment>
    <Grid item xs={6} sm={3}>
      <Typography variant="subtitle1">{label}</Typography>
    </Grid>
    <Grid item xs={6} sm={3}>
      <Typography variant="subtitle2">
        {typeof value === "string" ? value : getTime(value)}
      </Typography>
    </Grid>
  </React.Fragment>
);
