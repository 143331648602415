import React from "react";
import FileUploadComponent from "components/forms/fileUpload/FileUploadComponent";
import { TIMEOUT_DELAY, FORM_LINKS } from "common/Constants";
import { uploadFile } from "common/utils/FileUploadUtil";
import {
  UPDATE_CLAIM_DIRECTORY,
  GET_DOCUMENT_UPLOAD_DIRECTORY
} from "common/utils/CommonUtil";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      disableUpload: true,
      succeededURL: [],
      hasExceededFileCount: false,
      selectedURL: [],
      inputKey: Date.now(),
      hasError: false,
      isLoading: false,
      requirementModalDisplay: false,
      requirementTreeView: {
        expanded: []
      }
    };
  }

  updateFileInputValue = value => {
    this.setState({ fileInputValue: value });
  };

  newSelectedFile = (fileName, fileSize) => ({
    fileName,
    fileSize
  });

  enableUpload = () => {
    this.setState({ disableUpload: false });
  };

  setExceededFileCount = () => {
    this.setState({ disableUpload: true, hasExceededFileCount: true });
  };

  newSucceededURLObject = (url, fileName) => ({
    url,
    fileName
  });

  updateSelectedFiles = selectedURLList => {
    setTimeout(() => {
      if (
        selectedURLList &&
        selectedURLList.length > 0 &&
        this.state.selectedURL.length === 0
      ) {
        this.setState({ selectedURL: selectedURLList });
      }
    }, TIMEOUT_DELAY);
  };

  resetInputFieldState = () => {
    this.setState({
      success: false,
      disableUpload: true,
      hasExceededFileCount: false,
      selectedURL: [],
      inputKey: Date.now(),
      hasError: false
    });
    if (this.props.onChange) this.props.onChange(null);
  };

  resetState = () => {
    this.setState({
      success: false,
      disableUpload: true,
      hasExceededFileCount: false,
      selectedURL: [],
      hasError: false
    });
  };

  // Perform the upload
  handleUpload = (
    uploadInput,
    e,
    currentClaimId,
    parentHandlers,
    fileMainDirectory,
    claimSubmissionId,
    region
  ) => {
    e.target.value = null;
    if (
      !(
        Object.keys(uploadInput).length === 0 &&
        uploadInput.constructor === Object
      )
    ) {
      let files = uploadInput.files || [];
      let fileLength = files.length;
      let i = 0;
      let uploadFileResponses = [];

      while (i < fileLength) {
        let element = files[i];
        let fileParts = element.name.split(".");
        let fileName = fileParts[0];
        let fileType = fileParts.pop();

        let fileToUpload = {
          fileDirectory: GET_DOCUMENT_UPLOAD_DIRECTORY(
            fileMainDirectory,
            claimSubmissionId
          ),
          fileName: fileName,
          fileType: fileType,
          file: element
        };
        uploadFileResponses.push(uploadFile(fileToUpload, region));
        i++;
      }
      this.setState({ isLoading: true });

      Promise.all(uploadFileResponses)
        .then(result => {
          parentHandlers.addSuccessURLs(result);
          this.uploadSucceeded();
          this.showModal();
        })
        .catch(err => {
          this.uploadHasError();
        });
      this.resetInputFieldState();
    }
  };

  uploadHasError = () => {
    this.setState({ hasError: true, success: false, isLoading: false });
  };

  uploadSucceeded = () => {
    this.setState({ hasError: false, success: true, isLoading: false });
  };

  showModal = () => {
    this.setState({ requirementModalDisplay: true });
  };

  closeModal = () => {
    this.setState({ requirementModalDisplay: false });
  };

  handleNextButton = (history, showModal) => {
    this.props.history.push(FORM_LINKS.summary);
  };

  componentDidMount() {
    if (!this.props.parentState.claim.claimDirectory) {
      UPDATE_CLAIM_DIRECTORY(
        this.props.parentHandlers.handleClaimDirectoryUpdate
      );
    }
  }

  render() {
    let data = {
      props: this.props,
      uploadFileState: this.state,
      resetState: this.resetState,
      handleUpload: this.handleUpload,
      enableUpload: this.enableUpload,
      newSelectedFile: this.newSelectedFile,
      setExceededFileCount: this.setExceededFileCount,
      updateSelectedFiles: this.updateSelectedFiles,
      updateFileInputValue: this.updateFileInputValue,
      validationHandlers: {},
      viewControls: {
        closeModal: this.closeModal,
        showModal: this.showModal
      }
    };
    return FileUploadComponent(data);
  }
}

export default FileUpload;
