import React from "react";
import { Typography, Grid, Button, Card, Modal } from "@material-ui/core";
import { SECONDARY_BUTTON_STYLE } from "common/styles/styles";
import {
  MAX_FILES_TO_UPLOAD,
  TIMEOUT_DELAY,
  FORM_LINKS,
  FILE_CONDITIONS,
  NAV_LINKS
} from "common/Constants";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE,
  MODAL_OK_BUTTON_STYLE,
  CLAIMANT_INFORMATION_ACTION_ICONS_STYLE,
  BUTTON_MIN_WIDTH_STYLE
} from "common/styles/styles";
import { getFileSize } from "common/utils/CommonUtil";
import NavButton from "components/elements/NavButton";
import MessageComponent from "components/messages/MessageComponent";
import { MESSAGE_TYPE } from "common/Constants";
import Loader from "components/elements/Loader";
import SGFileUploadConfirmation from "common/fileUploadRequirements/SGFileUploadConfirmation";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { DELETE_FILE } from "common/utils/DeleteFileUtil";

function FileUploadComponent(data) {
  let resetState = data.resetState;
  let handleUpload = data.handleUpload;
  let fileMainDirectory = data.props.parentState.claim.claimDirectory;
  let succeededURL = data.props.parentState.claim.fileUpload;
  let showUploadedFiles = succeededURL && succeededURL.length > 0;
  let currentClaimId = data.props.parentState.claim.claimId;
  let parentHandlers = data.props.parentHandlers;
  let viewControls = data.viewControls;
  let claimSubmissionId = data.props.parentState.claim.submissionId;
  const region = data.props.parentState.region;

  if (!claimSubmissionId) {
    console.error("ERROR: Claim Submission Id was not obtained.");
  }

  let defaultMsg = `File upload has encountered an error, please try again or send the documents through email. Do quote the claim id '${claimSubmissionId}' in your email.`;

  const formCheckStatus = succeededURL.length <= 0;
  const currentNavState = data.props.parentState.navItem || [];
  if (
    currentNavState &&
    formCheckStatus !== currentNavState[NAV_LINKS.summary.id].isDisabled
  ) {
    parentHandlers.handleNavBarStatus(NAV_LINKS.summary.id, formCheckStatus);
  }

  const deleteFile = (fileName, index) => {
    return (
      <IconButton
        id={"delete-file-button-" + index}
        style={CLAIMANT_INFORMATION_ACTION_ICONS_STYLE}
        fontSize="small"
        onClick={e => {
          console.log(
            `Delete file: ${fileName}; main: ${fileMainDirectory}; ClaimId: ${currentClaimId};`
          );
          DELETE_FILE(
            fileName,
            fileMainDirectory,
            currentClaimId,
            claimSubmissionId,
            region
          );
          parentHandlers.removeSuccessURLs(fileName);
        }}
      >
        <DeleteIcon fontSize="small" color="primary" />
      </IconButton>
    );
  };

  const exceededMaxFilesCount = () => (
    <div>
      <Typography variant="subtitle1">
        You can only upload a maximum of 20 files for a claim.
      </Typography>
    </div>
  );

  const hasExceededFileCount = () => {
    if (!data.uploadFileState.hasExceededFileCount) {
      setTimeout(() => {
        data.setExceededFileCount();
      }, TIMEOUT_DELAY);
    }
  };

  const updateSelectedFilesList = files => {
    let newSelectedFiles = [];
    let fileLength = files.length;
    let i = 0;
    while (i < fileLength) {
      let fileName = files[i].name;
      let fileSize = getFileSize(files[i].size);
      newSelectedFiles.push(data.newSelectedFile(fileName, fileSize));
      i++;
    }
    data.updateSelectedFiles(newSelectedFiles);
  };

  const showSucceededURL = () =>
    succeededURL.map((item, index) => {
      return (
        <div key={item.fileName + index}>
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            {item.fileName}
          </a>
          {"  "}
          {deleteFile(item.fileName, index)}
        </div>
      );
    });

  const checkFiles = ref => {
    if (!(Object.keys(ref).length === 0 && ref.constructor === Object)) {
      let files = ref.files || [];
      updateSelectedFilesList(files);
      let totalFiles = files.length + succeededURL.length;
      if (files.length > 0 && totalFiles <= MAX_FILES_TO_UPLOAD) {
        setTimeout(() => {
          if (data.uploadFileState.disableUpload) {
            data.enableUpload();
          }
        }, TIMEOUT_DELAY);
        uploadInput = ref;
      }
      if (totalFiles > MAX_FILES_TO_UPLOAD) {
        hasExceededFileCount();
      }
    }
  };

  const SuccessMessage = () => (
    <div style={{ padding: "12px" }}>
      {showUploadedFiles ? (
        <React.Fragment>
          <Typography
            variant="h5"
            style={{ color: "green", paddingBottom: "12px" }}
          >
            Check the uploaded files below:
          </Typography>
          {showSucceededURL()}
        </React.Fragment>
      ) : null}
      <br />
    </div>
  );

  const displaySelectedFiles = () => {
    return (
      <React.Fragment>
        <Typography variant="subtitle1">Selected files:</Typography>
        {data.uploadFileState.selectedURL.map((item, index) => {
          return (
            <div key={item.fileName + index} style={{ paddingBottom: "12px" }}>
              {item.fileName}, {item.fileSize}
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  let uploadInput = {};

  const fileCriteria = () => (
    <React.Fragment>
      <ul style={{ paddingInlineStart: "15px" }}>
        {FILE_CONDITIONS.map((cond, index) => (
          <li key={index}>{cond}</li>
        ))}
      </ul>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Typography variant="h1">Upload supporting documents</Typography>
      {data.uploadFileState.hasError ? (
        <MessageComponent
          type={MESSAGE_TYPE.error}
          title="ERROR:"
          subtitle={defaultMsg}
        />
      ) : null}

      {data.uploadFileState.success ? (
        <MessageComponent
          type={MESSAGE_TYPE.success}
          title="SUCCESS:"
          subtitle="Files uploaded successfully."
        />
      ) : null}

      <MessageComponent
        type={MESSAGE_TYPE.notify}
        title="NOTE:"
        subtitle={fileCriteria()}
      />

      <div style={SECONDARY_BUTTON_STYLE}>
        <Button
          id="view-requirements-button"
          onClick={e => viewControls.showModal()}
          color="primary"
          variant="outlined"
        >
          VIEW REQUIREMENTS
        </Button>
      </div>

      {data.uploadFileState.isLoading ? (
        <Loader />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <input
              id="upload-files"
              name="fileupload"
              key={data.uploadFileState.inputKey}
              onChange={e => {
                resetState();
              }}
              multiple
              accept="image/*,.pdf,text/plain,.zip,.docx,.doc"
              ref={ref => {
                if (ref) {
                  checkFiles(ref, uploadInput);
                }
              }}
              type="file"
            />
            <br />
          </Grid>
          <Grid item xs={12}>
            <div style={{ paddingTop: "12px" }}>
              {data.uploadFileState.selectedURL.length > 0
                ? displaySelectedFiles()
                : null}
              <label htmlFor="upload-files">
                <Button
                  id="upload-button"
                  onClick={e =>
                    handleUpload(
                      uploadInput,
                      e,
                      currentClaimId,
                      parentHandlers,
                      fileMainDirectory,
                      claimSubmissionId,
                      region
                    )
                  }
                  color="primary"
                  variant="outlined"
                  disabled={data.uploadFileState.disableUpload}
                >
                  Upload files
                </Button>
              </label>
            </div>
          </Grid>
          <Grid item xs={12}>
            {data.uploadFileState.hasExceededFileCount
              ? exceededMaxFilesCount()
              : null}
          </Grid>
          <Grid item xs={12}>
            {showUploadedFiles ? <SuccessMessage /> : null}
          </Grid>
        </Grid>
      )}

      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            id="back-button"
            name="Back"
            disabled={false}
            clickHandler={() => data.props.history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />
        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            id="next-button"
            name="Next"
            disabled={formCheckStatus}
            clickHandler={e => {
              data.props.history.push(FORM_LINKS.summary);
            }}
          />
        </div>
      </div>

      {/* Modal */}
      <Modal
        aria-labelledby="Display files"
        aria-describedby="Display files required for claim submission"
        open={data.uploadFileState.requirementModalDisplay}
        onClose={e => viewControls.closeModal()}
        style={{ overflow: "auto" }}
      >
        <Card className="app-modal-card-style square">
          <SGFileUploadConfirmation
            parentState={data.props.parentState}
            parentHandlers={parentHandlers}
          />
          <div style={MODAL_OK_BUTTON_STYLE}>
            <Button
              id="view-requirements-ok-button"
              onClick={e => viewControls.closeModal()}
              color="primary"
              variant="contained"
              style={BUTTON_MIN_WIDTH_STYLE}
            >
              OK
            </Button>
          </div>
        </Card>
      </Modal>
      {/* Modal end */}
    </React.Fragment>
  );
}

export default FileUploadComponent;
