import React from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { REGION } from "common/Constants";
import { FORM_DETAIL_STYLE } from "common/styles/styles";

class PolicyHolderDetailComponent extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const detail = this.props.detail;
    const region = this.props.region;
    return (
      <React.Fragment>
        <Grid container style={FORM_DETAIL_STYLE}>
          <Grid container style={FORM_DETAIL_STYLE}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Surname</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{detail.surname}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Name</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{detail.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">E-Mail</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{detail.email}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Phone</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{detail.phone}</Typography>
            </Grid>

            {region === REGION.SG ? (
              <React.Fragment>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">ID Type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">{detail.sgIDType}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">ID Number</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">
                    {detail.sgIDNumber}
                  </Typography>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">ID Type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">{detail.hkIDType}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">ID Number</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">
                    {detail.hkIDNumber}
                  </Typography>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default PolicyHolderDetailComponent;
