import React from "react";
import { Typography } from "@material-ui/core";
import { MODAL_TEXT_CONTENT } from "common/styles/styles";
import {
  MODAL_TIMEOUT_TIME_MINUTES,
  MODAL_TIMEOUT_TIME_SECONDS
} from "common/Constants";

class CountdownComponent extends React.Component {
  state = {
    minutes: MODAL_TIMEOUT_TIME_MINUTES,
    seconds: MODAL_TIMEOUT_TIME_SECONDS
  };

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59
          }));
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }
  render() {
    const { minutes, seconds } = this.state;
    return (
      <div style={MODAL_TEXT_CONTENT}>
        {minutes === 0 && seconds === 0 ? (
          this.props.handleReset()
        ) : (
          <Typography variant="h6">
            Your session will expire in {minutes}:
            {seconds < 10 ? `0${seconds}` : seconds} seconds. Do you want to
            continue?
          </Typography>
        )}
      </div>
    );
  }
}

export default CountdownComponent;
