import React from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Collapse,
  Typography,
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getSummaryBenefitComponent } from "common/utils/CommonUtil";
import Modal from "@material-ui/core/Modal";
import { REGION } from "common/Constants";
import {
  MODAL_OK_BUTTON_STYLE,
  MODAL_CANCEL_BUTTON_STYLE,
  BUTTON_MIN_WIDTH_STYLE
} from "common/styles/styles";
class BenefitClaimantDisplayComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false
    };
  }

  render() {
    let title = this.props.titleSuffix + ". " + this.props.title; //this.props.title + "-" + this.props.titleSuffix;  <-- Suffix was later changed to Pre-fix :S
    let benefitId = this.props.benefitId;
    let benefitDetail = this.props.benefitDetail;
    let benefitType = this.props.benefitType;
    let history = this.props.history;
    let removeBenefit = this.props.removeBenefit;
    let table_data = this.props.claimants.filter(claimant => {
      return claimant.relatedClaim.includes(benefitId);
    });
    const region = this.props.region;
    let claimantInformation = table_data;

    let benefitDetailComponent = getSummaryBenefitComponent(
      benefitType,
      benefitDetail,
      region
    );
    let detailsComponent = benefitDetailComponent.detailsComponent;
    let editLink = benefitDetailComponent.editLink;

    const summaryActionHeader = () => (
      <React.Fragment>
        <div
          style={{
            textAlign: "right",
            paddingRight: "20px",
            paddingBottom: "10px"
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={e => {
              if (e) {
                history.push({
                  pathname: editLink,
                  key: benefitType,
                  state: {
                    benefitId: benefitId,
                    benefitType: benefitType
                  }
                });
              }
            }}
          >
            Edit
          </Button>

          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ marginLeft: "12px" }}
            onClick={e => {
              if (e) {
                this.setState({ modalOpen: true });
              }
            }}
          >
            Delete
          </Button>
        </div>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <ExpansionPanel square={true} TransitionComponent={Collapse}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel1c-header"
          >
            <div className="claimsAppColumn">
              <Typography variant="subtitle1">{title}</Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ display: "flow-root", padding: "0px" }}
          >
            {summaryActionHeader()}
            <Typography
              variant="h5"
              style={{ padding: "0px 0px 0px 12px", textAlign: "left" }}
            >
              Claimant details:{" "}
            </Typography>
            <Paper square className="enable-horizontal-scroll">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Surname</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Relation to Policy holder</TableCell>
                    <TableCell>ID Type</TableCell>
                    <TableCell>ID Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {claimantInformation.map(claimant => (
                    <TableRow key={claimant.claimantId}>
                      <TableCell>{claimant.surname}</TableCell>
                      <TableCell>{claimant.name}</TableCell>
                      <TableCell>{claimant.email}</TableCell>
                      <TableCell>{claimant.phone}</TableCell>
                      <TableCell>{claimant.relationToPolicyHolder}</TableCell>
                      <TableCell>
                        {region === REGION.HK
                          ? claimant.hkIDType
                          : claimant.sgIDType}
                      </TableCell>
                      <TableCell>
                        {region === REGION.HK
                          ? claimant.hkIDNumber
                          : claimant.sgIDNumber}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>

            <Divider />
            <Typography
              variant="h5"
              style={{ padding: "24px 24px 12px 12px", textAlign: "left" }}
            >
              Claim details:{" "}
            </Typography>
            {detailsComponent}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <Modal
          aria-labelledby="Add Claimant Title"
          aria-describedby="Enter details for new Claimant"
          open={this.state.modalOpen}
          onClose={e => this.setState({ modalOpen: false })}
          style={{ overflow: "auto" }}
        >
          <Card className="app-modal-card-style" square>
            <div style={{ padding: "12px 0px" }}>
              <Typography variant="subtitle2">
                Please note that the Claim detail provided will be deleted
                permanently and cannot be recovered.
              </Typography>
              <Typography variant="subtitle2">
                Do you want to continue?
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={MODAL_OK_BUTTON_STYLE}>
                <Button
                  onClick={e => {
                    removeBenefit(benefitId, benefitType);
                    this.setState({ modalOpen: false });
                  }}
                  color="primary"
                  variant="contained"
                  style={BUTTON_MIN_WIDTH_STYLE}
                >
                  Yes
                </Button>
              </div>

              <div style={MODAL_CANCEL_BUTTON_STYLE}>
                <Button
                  onClick={e => this.setState({ modalOpen: false })}
                  color="primary"
                  variant="contained"
                  style={BUTTON_MIN_WIDTH_STYLE}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Card>
        </Modal>
      </React.Fragment>
    );
  }
}

export default BenefitClaimantDisplayComponent;
