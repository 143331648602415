import React from "react";
import { Typography } from "@material-ui/core";
import PDFGeneration from "common/pdfGeneration/PDFGeneration";
import { GET_SUPPORT_EMAIL } from "common/utils/CommonUtil";
import { THANKYOU_PDF_COMPONENT_STYLE } from "common/styles/styles";

function ThankyouPageComponent(data) {
  let submissionId = data.props.parentState.claim.submissionId || "not-found";
  let currentClaim = data.props.parentState.claim;
  let region = data.props.parentState.region;

  return (
    <React.Fragment>
      <Typography variant="h1">THANK YOU</Typography>
      Your claim has been submitted, please use the Claim ID:{" "}
      <Typography variant="h5">{submissionId}</Typography> for any future
      correspondence regarding this claim.
      <br />
      Please email us at:{" "}
      <a
        href={`mailto:${GET_SUPPORT_EMAIL(
          region
        )}?subject=Regarding claim: ${submissionId}`}
      >
        {GET_SUPPORT_EMAIL(region)}
      </a>{" "}
      if you need any further clarifications.
      <div style={THANKYOU_PDF_COMPONENT_STYLE}>
        <div>
          <PDFGeneration
            data={currentClaim}
            region={region}
            claimSubmissionId={submissionId}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ThankyouPageComponent;
