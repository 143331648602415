import React from "react";

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="loader-container">
          <div className="loader" />
        </div>
      </React.Fragment>
    );
  }
}

export default Loader;
