import React from "react";
import LostBaggageClaimComponent from "components/forms/benefits/lostBaggageClaim/LostBaggageClaimComponent";
import { BENEFIT_TYPES, FIELD_TYPE } from "common/Constants";
import {
  shouldDisplaySubQuestions,
  getBenefitDetailFromProps,
  getLastBenefitIndex,
  IS_EMPTY_FIELD_CHECK,
  HAS_ERROR,
  getClaimTitle,
  DOES_CURRENT_FIELD_HAVE_ERROR,
  IS_VALID_TEXT_INPUT,
  IS_CURRENT_FIELD_VALID_CHECK
} from "common/utils/CommonUtil";

class LostBaggageClaim extends React.Component {
  constructor() {
    super();
    this.state = {
      touchedLostBaggage: {
        dateOfLoss: false,
        timeOfLoss: false,
        locationOfLoss: false,
        description: false
      },
      modalOpen: false,
      selectedItemId: "",
      requirementModalDisplay: false,

      modalDetail: {
        description: "",
        makeModel: "",
        dateOfPurchase: "",
        purchaseCurrency: "",
        purchasePrice: "",
        amountClaimed: ""
      },
      touched: {
        description: false,
        makeModel: false,
        dateOfPurchase: false,
        purchaseCurrency: false,
        purchasePrice: false,
        amountClaimed: false
      },
      parentHandlers: {}
    };
  }

  validationResult = {
    hasErr: {
      dateOfLoss: false,
      timeOfLoss: false,
      locationOfLoss: false,
      description: false
    },
    errMsg: {
      dateOfLoss: "",
      timeOfLoss: "",
      locationOfLoss: "",
      description: ""
    }
  };

  validateFields = (currentFieldValues, touchedState, validationState) => {
    if (touchedState.dateOfLoss || currentFieldValues.dateOfLoss) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "dateOfLoss",
        "Date of Loss",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.date
      );
    }
    if (touchedState.timeOfLoss || currentFieldValues.timeOfLoss) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "timeOfLoss",
        "Time of Loss",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.time
      );
    }
    if (touchedState.locationOfLoss || currentFieldValues.locationOfLoss) {
      IS_EMPTY_FIELD_CHECK(
        "locationOfLoss",
        "Location of loss",
        "",
        currentFieldValues,
        validationState
      );
    }
    if (touchedState.description || currentFieldValues.description) {
      IS_EMPTY_FIELD_CHECK(
        "description",
        "Description",
        "",
        currentFieldValues,
        validationState
      );
    }
  };

  showModal = () => {
    this.setState({ requirementModalDisplay: true });
  };

  closeModal = () => {
    this.setState({ requirementModalDisplay: false });
  };

  resetTouched = () => ({
    description: false,
    makeModel: false,
    dateOfPurchase: false,
    purchaseCurrency: false,
    purchasePrice: false,
    amountClaimed: false
  });

  resetModal = () => ({
    description: "",
    makeModel: "",
    dateOfPurchase: "",
    purchaseCurrency: "",
    purchasePrice: "",
    amountClaimed: ""
  });

  getBenefitType = () => BENEFIT_TYPES.lostBaggageClaim;

  benefitIsEmpty = currentClaimDetail => {
    if (!!currentClaimDetail) {
      return (
        !!currentClaimDetail.dateOfLoss &&
        !!currentClaimDetail.timeOfLoss &&
        !!currentClaimDetail.locationOfLoss &&
        !!currentClaimDetail.description &&
        (currentClaimDetail.descriptionOfLostItems &&
          !!currentClaimDetail.descriptionOfLostItems.length)
      );
    }
    return true;
  };

  updateModal = (targetParam, targetValue) => {
    if (IS_VALID_TEXT_INPUT(targetValue)) {
      this.setState(prevState => ({
        modalDetail: {
          ...prevState.modalDetail,
          [targetParam]: targetValue
        }
      }));
    }
  };

  handleOpen = (e, id, detail) => {
    if (e && id && detail) {
      this.setState({
        modalOpen: true,
        selectedItemId: id,
        modalDetail: {
          description: detail.description,
          makeModel: detail.makeModel,
          dateOfPurchase: detail.dateOfPurchase,
          purchaseCurrency: detail.purchaseCurrency,
          purchasePrice: detail.purchasePrice,
          amountClaimed: detail.amountClaimed
        }
      });
    }
  };

  fieldEmptyValidation = (name, value) => {
    if (name) {
      if (this.state.touched[name]) {
        return !(value && ("" + value).length);
      }
      return false;
    }
    return false;
  };

  handleTouched = e => {
    let targetParam = e.target.name;
    if (!this.state.touched[targetParam]) {
      this.setState(prevState => ({
        touched: {
          ...prevState.touched,
          [targetParam]: true
        }
      }));
    }
  };

  handleTouchedLostBaggage = e => {
    let targetParam = e.target.name;
    if (!this.state.touchedLostBaggage[targetParam]) {
      this.setState(prevState => ({
        touchedLostBaggage: {
          ...prevState.touchedLostBaggage,
          [targetParam]: true
        }
      }));
    }
  };

  handleClose = (e, parentHandlers, benefitId) => {
    if (e) {
      if (parentHandlers && benefitId) {
        let lostItems = parentHandlers.getLostItems(benefitId);
        let latestLostItemIndex = lostItems.length - 1;
        let lostItemDetail = lostItems[latestLostItemIndex];
        let lostItemId = lostItemDetail.lostItemId;
        if (this.checkModal(lostItemDetail)) {
          parentHandlers.deleteLostItem(benefitId, lostItemId);
        }
      }
    }
    this.setState({
      modalOpen: false,
      selectedItemId: "",
      modalDetail: this.resetModal,
      touched: this.resetTouched
    });
  };

  handleModalSaveAndClose = (e, parentHandlers, benefitId) => {
    if (e) {
      parentHandlers.updateLostItemFromModal(
        this.state.selectedItemId,
        this.state.modalDetail,
        benefitId,
        () => {
          this.handleClose(e);
        }
      );
    }
  };

  componentDidMount() {
    this.showModal();
  }

  componentWillUnmount() {
    let benefitType = this.getBenefitType();
    let claimDetail = this.props.parentState.claim.claimDetail;
    let benefitDetailIndex = getLastBenefitIndex(benefitType, claimDetail);
    let benefitId = claimDetail[benefitType][benefitDetailIndex].benefitId;
    const currentClaimDetail = claimDetail[benefitType][benefitDetailIndex];
    if (this.benefitIsEmpty(currentClaimDetail)) {
      let benefitName = getClaimTitle(BENEFIT_TYPES.lostBaggageClaim);
      let parentHandlers = this.props.parentHandlers;
      parentHandlers.handleBenefitDetailInNavBar(
        benefitName,
        benefitId,
        benefitType
      );
    } else {
      this.props.parentHandlers.removeBenefit(benefitId, benefitType);
    }
  }

  getBenefitDetail(props) {
    return getBenefitDetailFromProps(
      props.location,
      props.parentState.claim,
      this.getBenefitType()
    );
  }

  checkForm = (hasError, currentFieldValues) => {
    if (this.benefitIsEmpty(currentFieldValues)) {
      return HAS_ERROR(hasError);
    } else {
      return true;
    }
  };

  checkModal = lostItem => {
    let result = !(
      lostItem.description &&
      lostItem.dateOfPurchase &&
      lostItem.purchaseCurrency &&
      !DOES_CURRENT_FIELD_HAVE_ERROR(
        lostItem.purchasePrice,
        true,
        FIELD_TYPE.currency
      ) &&
      !DOES_CURRENT_FIELD_HAVE_ERROR(
        lostItem.amountClaimed,
        true,
        FIELD_TYPE.currency
      )
    );
    return result;
  };

  render() {
    let benefitDetail = this.getBenefitDetail(this.props);

    let hasNotificationOfSearchClass = shouldDisplaySubQuestions(
      benefitDetail,
      "hasNotificationOfSearch"
    );

    let wasMoneyOrChequeLostClass = shouldDisplaySubQuestions(
      benefitDetail,
      "wasMoneyOrChequeLost"
    );
    let isBaggageLostClass = shouldDisplaySubQuestions(
      benefitDetail,
      "isBaggageLost"
    );

    let isBaggageLostInOtherCustodyClass = shouldDisplaySubQuestions(
      benefitDetail,
      "isBaggageLostInOtherCustody"
    );

    let isClaimAgainstOtherTakenClass = shouldDisplaySubQuestions(
      benefitDetail,
      "isClaimAgainstOtherTaken"
    );

    let isOtherCurrencyClass =
      benefitDetail.lossRegion === "Other" ? ["display"] : ["hidden"];

    this.validateFields(
      benefitDetail,
      this.state.touchedLostBaggage,
      this.validationResult
    );

    let data = {
      props: this.props,
      modalOpen: this.state.modalOpen,
      benefitDetail: benefitDetail,
      detail: this.state.modalDetail,
      requirementModalDisplay: this.state.requirementModalDisplay,
      benefitType: this.getBenefitType(),
      hasErr: this.validationResult.hasErr,
      errMsg: this.validationResult.errMsg,
      validationHandlers: {
        fieldEmptyValidation: this.fieldEmptyValidation,
        handleTouched: this.handleTouched,
        handleTouchedLostBaggage: this.handleTouchedLostBaggage
      },
      viewControls: {
        handleOpen: this.handleOpen,
        handleClose: this.handleClose,
        hasNotificationOfSearchClass: hasNotificationOfSearchClass,
        wasMoneyOrChequeLostClass: wasMoneyOrChequeLostClass,
        isBaggageLostClass: isBaggageLostClass,
        isBaggageLostInOtherCustodyClass: isBaggageLostInOtherCustodyClass,
        isClaimAgainstOtherTakenClass: isClaimAgainstOtherTakenClass,
        isOtherCurrencyClass: isOtherCurrencyClass,
        handleModalSaveAndClose: this.handleModalSaveAndClose,
        checkForm: this.checkForm,
        updateModal: this.updateModal,
        showModal: this.showModal,
        closeModal: this.closeModal,
        checkModal: this.checkModal
      }
    };

    return LostBaggageClaimComponent(data);
  }
}

export default LostBaggageClaim;
