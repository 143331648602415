export const FOOTER_BUTTON = {
  prevButton: "Previous",
  nextButton: "Next",
  startButton: "Start",
  doneButton: "Submit"
};

export const BENEFIT_TYPES = {
  flightDelayClaim: "flightDelayClaim",
  lostBaggageClaim: "lostBaggageClaim",
  tripCancellationClaim: "tripCancellationClaim",
  travelMisConnectionClaim: "travelMisConnectionClaim",
  accidentClaim: "accidentClaim",
  medicalClaim: "medicalClaim",
  otherClaim: "otherClaim"
};

export const PDPA_DOCUMENT_URL = "https://alliedworldinsurance.com/singapore/";

export const PICS_HK_DOCUMENT_URL =
  "https://awac.com/marketing/asiapacretail/hk/pics.pdf";

export const DISPLAY_COMPONENT = ["display"];

export const HIDE_COMPONENT = ["hidden"];

export const CLAIM_FORM_TYPES = {
  aboutSelf: "aboutSelf",
  reporter: "reporter",
  basicInformation: "basicInformation"
};

export const FIELD_TYPE = {
  text: "text",
  date: "date",
  time: "time",
  currency: "currency",
  phone: "phone",
  email: "email"
};

export const RESET_TIMEOUT = 1000 * 60 * 120; // 2 hours

export const RESET_TIMEOUT_DEBOUNCE_TIME = 1000;

export const MODAL_TIMEOUT_TIME_IN_MS = 60000; // 1 min

export const MODAL_TIMEOUT_TIME_SECONDS = 0;

export const MODAL_TIMEOUT_TIME_MINUTES = 1;

export const MEDICAL_CLAIM_MODAL_TYPE = {
  medical: "multipleConsultationMedical",
  accident: "multipleConsultationAccident"
};

export const BENEFIT_LIST = [
  BENEFIT_TYPES.accidentClaim,
  BENEFIT_TYPES.flightDelayClaim,
  BENEFIT_TYPES.lostBaggageClaim,
  BENEFIT_TYPES.medicalClaim,
  BENEFIT_TYPES.otherClaim,
  BENEFIT_TYPES.travelMisConnectionClaim,
  BENEFIT_TYPES.tripCancellationClaim
];

export const REGION = {
  SG: "SG",
  HK: "HK"
};

export const API_RESPONSE_STATUS = {
  FAIL: "FAIL",
  SUCCESS: "SUCCESS"
};
export const APP_URL_EMAIL_API = process.env.REACT_APP_URL_EMAIL_API;

export const REACT_APP_URL_FILE_UPLOAD_API =
  process.env.REACT_APP_URL_FILE_UPLOAD_API;

export const REACT_APP_URL_SUBMIT_CLAIM_API =
  process.env.REACT_APP_URL_SUBMIT_CLAIM_API;

export const REACT_APP_URL_GET_ID_API = process.env.REACT_APP_URL_GET_ID_API;

export const REACT_APP_GET_DIRECTORY_NAME_API =
  process.env.REACT_APP_GET_DIRECTORY_NAME_API;

export const REACT_APP_QUERY_SUBMISSION_TIME_API =
  process.env.REACT_APP_QUERY_SUBMISSION_TIME_API;

export const REACT_APP_DELETE_FILE_API = process.env.REACT_APP_DELETE_FILE_API;

export const REACT_APP_LOG_ERROR_API = process.env.REACT_APP_LOG_ERROR_API;

export const SG_ID_TYPE = [
  { value: "NRIC", label: "NRIC" },
  { value: "PASSPORT", label: "PASSPORT" }
];

export const HK_ID_TYPE = [
  { value: "HKID", label: "HKID" },
  { value: "PASSPORT", label: "PASSPORT" }
];

export const GET_REGION = region => {
  let currentRegion = REGION.SG;
  if (region) {
    currentRegion = region.toUpperCase() === REGION.HK ? REGION.HK : REGION.SG;
  }
  return currentRegion;
};

export const IS_SG_REGION = region => {
  return GET_REGION(region) === REGION.SG;
};

const SG_FILE_REQUIREMENTS = (benefitType, region) => {
  let fileUploadRequirements = [];
  switch (benefitType) {
    case BENEFIT_TYPES.accidentClaim:
      fileUploadRequirements = DEFAULT_REQUIREMENT(region);
      break;
    case BENEFIT_TYPES.flightDelayClaim:
      fileUploadRequirements = FLIGHT_DELAY_MISCONNECTION_DIVERSION_OVERBOOKING_REQUIREMENT(
        region
      );
      break;

    case BENEFIT_TYPES.lostBaggageClaim:
      fileUploadRequirements = LOST_BAGGAGE_REQUIREMENT(region);
      break;

    case BENEFIT_TYPES.medicalClaim:
      fileUploadRequirements = MEDICAL_REQUIREMENT(region);
      break;

    case BENEFIT_TYPES.otherClaim:
      fileUploadRequirements = DEFAULT_REQUIREMENT(region);
      break;

    case BENEFIT_TYPES.travelMisConnectionClaim:
      fileUploadRequirements = FLIGHT_DELAY_MISCONNECTION_DIVERSION_OVERBOOKING_REQUIREMENT(
        region
      );
      break;

    case BENEFIT_TYPES.tripCancellationClaim:
      fileUploadRequirements = TRIP_CANCELLATION_POSTPONEMENT_CURTAILMENT_REQUIREMENT(
        region
      );
      break;

    case BENEFIT_TYPES.tripCurtailmentClaim:
      fileUploadRequirements = TRIP_CANCELLATION_POSTPONEMENT_CURTAILMENT_REQUIREMENT(
        region
      );
      break;
    default:
      break;
  }
  return fileUploadRequirements;
};

const HK_FILE_REQUIREMENTS = benefitType => {
  let fileUploadRequirements = [];

  switch (benefitType) {
    case BENEFIT_TYPES.flightDelayClaim:
      fileUploadRequirements = HK_FLIGHT_DELAY_MISCONNECTION();
      break;

    case BENEFIT_TYPES.lostBaggageClaim:
      fileUploadRequirements = HK_LOST_BAGGAGE();
      break;

    case BENEFIT_TYPES.medicalClaim:
      fileUploadRequirements = HK_MEDICAL_REQUIREMENT();
      break;

    case BENEFIT_TYPES.otherClaim:
      fileUploadRequirements = HK_OTHER_CLAIM();
      break;

    case BENEFIT_TYPES.travelMisConnectionClaim:
      fileUploadRequirements = HK_FLIGHT_DELAY_MISCONNECTION();
      break;

    case BENEFIT_TYPES.tripCancellationClaim:
      fileUploadRequirements = HK_TRIP_CANCELLATION_CURTAILMENT();
      break;

    case BENEFIT_TYPES.tripCurtailmentClaim:
      fileUploadRequirements = HK_TRIP_CANCELLATION_CURTAILMENT();
      break;
    default:
      break;
  }
  return fileUploadRequirements;
};

export const FILE_REQUIREMENTS = (benefitType, region) => {
  let fileRequirements = [];
  fileRequirements =
    region === REGION.HK
      ? HK_FILE_REQUIREMENTS(benefitType)
      : SG_FILE_REQUIREMENTS(benefitType, region);
  return fileRequirements;
};

export const getCurrency = region => {
  let regionCurrency = region === REGION.HK ? "HKD" : "SGD";
  return regionCurrency;
};

const DEFAULT_REQUIREMENT = region => [
  {
    id: 1,
    requirement:
      "Travel Itinerary, Air ticket(s), Boarding Pass(es) or copy of Passport with stamp showing the date of departure and return to Singapore",
    mandatory: true,
    uploaded: false
  },
  {
    id: 2,
    requirement: `Credit Card Statement showing the ${getCurrency(
      region
    )} equivalent charged to your card if you claim contains foreign currency payment`,
    mandatory: true,
    uploaded: false
  }
];

const LOST_BAGGAGE_REQUIREMENT = region => [
  {
    id: 1,
    requirement:
      "Travel Itinerary, Air ticket(s), Boarding Pass(es) or copy of Passport with stamp showing the date of departure and return to Singapore",
    mandatory: true,
    uploaded: false
  },
  {
    id: 2,
    requirement: `Credit Card Statement showing the ${getCurrency(
      region
    )} equivalent charged to your card if you claim contains foreign currency payment`,
    mandatory: true,
    uploaded: false
  },
  {
    id: 3,
    requirement: "Police Report lodged at Country of Loss",
    mandatory: true,
    uploaded: false
  },
  {
    id: 4,
    requirement:
      "Purchase Invoices, Warranty Cards for item(s) claimed (if applicable)",
    mandatory: true,
    uploaded: false
  },
  {
    id: 5,
    requirement: "Coloured photographs of damaged item(s)",
    mandatory: true,
    uploaded: false
  },
  {
    id: 6,
    requirement: "Repair Invoices for damaged item(s)",
    mandatory: true,
    uploaded: false
  }
];

const MEDICAL_REQUIREMENT = region => [
  {
    id: 1,
    requirement:
      "Travel Itinerary, Air ticket(s), Boarding Pass(es) or copy of Passport with stamp showing the date of departure and return to Singapore",
    mandatory: true,
    uploaded: false
  },
  {
    id: 2,
    requirement: `Credit Card Statement showing the ${getCurrency(
      region
    )} equivalent charged to your card if you claim contains foreign currency payment`,
    mandatory: true,
    uploaded: false
  },
  {
    id: 3,
    requirement: "Itemised Medical Bill(s) / Receipt(s)",
    mandatory: true,
    uploaded: false
  },
  {
    id: 4,
    requirement:
      "Medical Report &/or Discharge Summary &/or Doctor's Memorandum",
    mandatory: true,
    uploaded: false
  }
];

const FLIGHT_DELAY_MISCONNECTION_DIVERSION_OVERBOOKING_REQUIREMENT = region => [
  {
    id: 1,
    requirement:
      "Travel Itinerary, Air ticket(s), Boarding Pass(es) or copy of Passport with stamp showing the date of departure and return to Singapore",
    mandatory: true,
    uploaded: false
  },
  {
    id: 2,
    requirement: `Credit Card Statement showing the ${getCurrency(
      region
    )} equivalent charged to your card if you claim contains foreign currency payment`,
    mandatory: true,
    uploaded: false
  },
  {
    id: 3,
    requirement:
      "Written confirmation from Carrier / Airline on reason(s) for delay and period of disruption/interruption to the trip",
    mandatory: true,
    uploaded: false
  }
];

const TRIP_CANCELLATION_POSTPONEMENT_CURTAILMENT_REQUIREMENT = region => [
  {
    id: 1,
    requirement:
      "Travel Itinerary, Air ticket(s), Boarding Pass(es) or copy of Passport with stamp showing the date of departure and return to Singapore",
    mandatory: true,
    uploaded: false
  },
  {
    id: 2,
    requirement: `Credit Card Statement showing the ${getCurrency(
      region
    )} equivalent charged to your card if you claim contains foreign currency payment`,
    mandatory: true,
    uploaded: false
  },
  {
    id: 3,
    requirement:
      "Hotel accomodation confirmation advice / Travel deposit receipt",
    mandatory: true,
    uploaded: false
  },
  {
    id: 4,
    requirement:
      "Written confirmation from Tour Agent / Carrier / Hotel on amount refunded / cancellation charges / postponement charges",
    mandatory: true,
    uploaded: false
  },
  {
    id: 5,
    requirement: "Proof of relationship (if applicable)",
    mandatory: true,
    uploaded: false
  },
  {
    id: 6,
    requirement:
      "Medical Report / Medical Notes certifying the diagnosis and not fit to travel (if applicable)",
    mandatory: true,
    uploaded: false
  }
];

const HK_MEDICAL_REQUIREMENT = () => [
  {
    id: 1,
    requirement:
      "Travel Itinerary, Air ticket(s), Boarding Pass(es) or copy of Passport with stamp showing the date of departure and return to Hong Kong",
    mandatory: true,
    uploaded: false
  },
  {
    id: 2,
    requirement:
      "Credit Card Statement showing the HKD equivalent charged to your card if your claim contains foreign currency payment, if any",
    mandatory: true,
    uploaded: false
  },
  {
    id: 3,
    requirement: "Itemised Medical Bill(s) / Receipt(s)",
    mandatory: true,
    uploaded: false
  },
  {
    id: 4,
    requirement:
      "Medical Report/Certificate &/or Discharge Summary &/or Doctor's Memorandum",
    mandatory: true,
    uploaded: false
  }
];

const HK_LOST_BAGGAGE = () => [
  {
    id: 1,
    requirement:
      "Travel Itinerary, Air ticket(s), Boarding Pass(es) or copy of Passport with stamp showing the date of departure and return to Hong Kong",
    mandatory: true,
    uploaded: false
  },
  {
    id: 2,
    requirement: "Police Report lodged at Country of Loss",
    mandatory: true,
    uploaded: false
  },
  {
    id: 3,
    requirement:
      "Purchase Invoices/Receipts, Warranty Cards for item(s) claimed (if applicable)",
    mandatory: true,
    uploaded: false
  },
  {
    id: 4,
    requirement: "Money exchange slip/ withdrawal record (if applicable)",
    mandatory: true,
    uploaded: false
  },
  {
    id: 5,
    requirement: "Repair quotation for damaged item(s)",
    mandatory: true,
    uploaded: false
  },
  {
    id: 6,
    requirement: "Colour photographs of damaged item(s)",
    mandatory: true,
    uploaded: false
  }
];

const HK_FLIGHT_DELAY_MISCONNECTION = () => [
  {
    id: 1,
    requirement:
      "Travel Itinerary, Air ticket(s), Boarding Pass(es) or copy of Passport with stamp showing the date of departure and return to Hong Kong",
    mandatory: true,
    uploaded: false
  },
  {
    id: 2,
    requirement:
      "Additional Transportation / Accommodation Expenses due to Re-routing",
    mandatory: true,
    uploaded: false
  },
  {
    id: 3,
    requirement:
      "Confirmation from airline/ common carrier on number of hours delayed & reasons",
    mandatory: true,
    uploaded: false
  },
  {
    id: 4,
    requirement:
      "Credit Card Statement showing the HKD equivalent charged to your card if your claim contains foreign currency payment",
    mandatory: true,
    uploaded: false
  }
];

const HK_TRIP_CANCELLATION_CURTAILMENT = () => [
  {
    id: 1,
    requirement:
      "Travel Itinerary, Air ticket(s), Boarding Pass(es) or copy of Passport with stamp showing the date of departure and return to Hong Kong",
    mandatory: true,
    uploaded: false
  },
  {
    id: 2,
    requirement:
      "Hotel accomodation confirmation advice / Travel deposit receipt",
    mandatory: true,
    uploaded: false
  },
  {
    id: 3,
    requirement:
      "Written confirmation from Tour Agent / Carrier / Hotel on amount refunded / cancellation charges / postponement charges",
    mandatory: true,
    uploaded: false
  },
  {
    id: 4,
    requirement:
      "Death Certification / Medical Report / Medical Certificate certifying the diagnosis and not fit to travel",
    mandatory: true,
    uploaded: false
  },
  {
    id: 5,
    requirement: "Proof of relationship (if applicable)",
    mandatory: true,
    uploaded: false
  }
];

const HK_OTHER_CLAIM = () => [
  {
    id: 1,
    requirement:
      "Travel Itinerary, Air ticket(s), Boarding Pass(es) or copy of Passport with stamp showing the date of departure and return to Hong Kong",
    mandatory: true,
    uploaded: false
  },
  {
    id: 2,
    requirement: "Any supporting document that related to the claim",
    mandatory: true,
    uploaded: false
  }
];

const HK_EMERGENCY_PURCHASE = () => [
  {
    id: 1,
    requirement:
      "Travel Itinerary, Air ticket(s), Boarding Pass(es) or copy of Passport with stamp showing the date of departure and return to Hong Kong",
    mandatory: true,
    uploaded: false
  },
  {
    id: 2,
    requirement: "Property Irregularity Report from carrier",
    mandatory: true,
    uploaded: false
  },
  {
    id: 3,
    requirement: "Receipt(s) with itemised breakdown for purchase of necessity",
    mandatory: true,
    uploaded: false
  }
];

export const SUMMARY_FILE_NAME = submissionId => "Summary_" + submissionId;

export const SUPPORT_EMAIL_SG = "sg.customerService@awac.com";

export const SUPPORT_EMAIL_HK = "hk_claims@awac.com";

export const FILE_TYPE_APPLICATION_PDF = "application/pdf";

export const FORM_LINKS = {
  root: "/",
  defaultRoot: "/sg/personal/travel",
  defaultSGRoot: "/sg/personal/travel",
  defaultHKRoot: "/hk/personal/travel",
  welcomePage: "/:region/personal/travel", //"/welcome/:region",
  aboutSelf: "/aboutself",
  reporter: "/reporter",
  basicInformation: "/basicinformation",
  selectClaimType: "/selectclaimtype",
  flightDelayClaim: "/flightdelayclaim",
  lostBaggageClaim: "/lostbaggageclaim",
  tripCancellationClaim: "/tripcancellationclaim",
  tripCurtailmentClaim: "/tripcurtailmentclaim",
  travelMisConnectionClaim: "/travelmisconnectionclaim",
  accidentClaim: "/accidentclaim",
  medicalClaim: "/medicalclaim",
  otherClaim: "/otherclaim",
  addMoreClaimant: "/addmoreclaimant",
  addAnotherClaim: "/addanotherclaim",
  claimantInformation: "/claimantInformation",
  fileUpload: "/fileupload",
  summary: "/summary",
  pdpa: "/pdpa",
  thankyouPage: "/thankyoupage"
};

export const APP_ROOT_ROUTES = [
  FORM_LINKS.defaultRoot,
  FORM_LINKS.defaultSGRoot,
  FORM_LINKS.defaultHKRoot,
  FORM_LINKS.thankyouPage
];

export const NAV_LINK_STATUS = {
  enable: false,
  disable: true
};

export const NAV_LINKS = {
  policyholderInformation: {
    name: "Policyholder Information",
    text: "Policyholder Information",
    id: 0
  },
  reporterInformation: {
    name: "Reporter Information",
    text: "Reporter Information",
    id: 1
  },
  policyInformation: {
    name: "Policy Information",
    text: "Policy Information",
    id: 2
  },
  claims: {
    name: "Claims",
    text: "Claims",
    id: 3
  },
  documentsUpload: {
    name: "Documents Upload",
    text: "Documents Upload",
    id: 4
  },
  summary: {
    name: "Summary",
    text: "Summary",
    id: 5
  },
  addNewClaim: {
    name: "Add New Claim",
    text: "Add New Claim",
    id: 6
  }
};

export const FILE_UPLOAD_HEADER = {
  headers: {
    "Content-Type": "application/json"
  }
};

export const CLAIMANT_DETAIL_COLUMNS = [
  { title: "Surname", field: "surname" },
  { title: "Name", field: "name" },
  { title: "E-mail", field: "email" },
  { title: "Phone", field: "phone" },
  { title: "Edit", field: "" },
  { title: "Delete", field: "" }
];

export const LOST_BAGGAGE_ITEMS_DESCRIPTION_COLUMNS = [
  { title: "Description of item", field: "description" },
  { title: "Make/Model", field: "makeModel" },
  { title: "Purchase Date", field: "dateOfPurchase" },
  { title: "Purchase Currency", field: "purchaseCurrency" },
  { title: "Purchase Price", field: "purchasePrice" },
  { title: "Amount Claimed", field: "amountClaimed" }
];

export const MESSAGE_TYPE = {
  success: "success",
  notify: "notify",
  warn: "warn",
  error: "error"
};

export const FILE_CONDITIONS = [
  "Select multiple files for upload",
  "Only '.ZIP', '.PDF', '.DOC', '.DOCX', image formats ('.PNG', '.JPEG' etc) are supported",
  "Each file cannot exceed 5MB",
  "Upload a maximum of 20 files"
];

export const TIMEOUT_DELAY = 20;

export const TIMEOUT_DELAY_50MS = 50;

export const ERROR_MSG = {
  errMsg: "error"
};

export const ERRORS = {
  INVALID_ROUTE: "Invalid Route"
};

export const RELATION_TO_POLICYHOLDER_TYPES = [
  { value: "Self", label: "Self" },
  { value: "FamilyMember", label: "Family Member (not dependent child)" },
  { value: "DependentChild", label: "Dependent Child" },
  { value: "Friend", label: "Friend" },
  { value: "Other", label: "Other" }
];

export const RELATION_TO_INSURED_TYPES = [
  { label: "Self", value: "Self" },
  { label: "Family Member", value: "FamilyMember" },
  { label: "Employee", value: "Employee" },
  { label: "Other", value: "Other" }
];

export const TYPE_OF_COMMON_CARRIER = [
  { value: "Flight", label: "Flight" },
  { value: "Train", label: "Train" },
  { value: "Ferry", label: "Ferry" },
  { value: "Coach", label: "Coach" },
  { value: "Other", label: "Other" }
];

export const COUNTRY = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "Andorra", label: "Andorra" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cape Verde", label: "Cape Verde" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Cote d'Ivoire", label: "Cote d'Ivoire" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "East Timor", label: "East Timor" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Greece", label: "Greece" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "North Korea", label: "North Korea" },
  { value: "South Korea", label: "South Korea" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macedonia", label: "Macedonia" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines"
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia and Montenegro", label: "Serbia and Montenegro" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Swaziland", label: "Swaziland" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Togo", label: "Togo" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Vatican City", label: "Vatican City" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" }
];

export const CURRENCY_TYPE = [
  { value: "DZD", label: "Algeria Dinars" },
  { value: "ARP", label: "Argentina Pesos" },
  { value: "AUD", label: "Australia Dollars" },
  { value: "ATS", label: "Austria Schillings" },
  { value: "BSD", label: "Bahamas Dollars" },
  { value: "BBD", label: "Barbados Dollars" },
  { value: "BEF", label: "Belgium Francs" },
  { value: "BMD", label: "Bermuda Dollars" },
  { value: "BRR", label: "Brazil Real" },
  { value: "BGL", label: "Bulgaria Lev" },
  { value: "CAD", label: "Canada Dollars" },
  { value: "CLP", label: "Chile Pesos" },
  { value: "CNY", label: "China Yuan Renminbi" },
  { value: "CYP", label: "Cyprus Pounds" },
  { value: "CSK", label: "Czech Republic Koruna" },
  { value: "DKK", label: "Denmark Kroner" },
  { value: "NLG", label: "Dutch Guilders" },
  { value: "XCD", label: "Eastern Caribbean Dollars" },
  { value: "EGP", label: "Egypt Pounds" },
  { value: "EUR", label: "Euro" },
  { value: "FJD", label: "Fiji Dollars" },
  { value: "FIM", label: "Finland Markka" },
  { value: "FRF", label: "France Francs" },
  { value: "DEM", label: "Germany Deutsche Marks" },
  { value: "XAU", label: "Gold Ounces" },
  { value: "GBP", label: "United Kingdom Pounds" },
  { value: "GRD", label: "Greece Drachmas" },
  { value: "HKD", label: "Hong Kong Dollars" },
  { value: "HUF", label: "Hungary Forint" },
  { value: "ISK", label: "Iceland Krona" },
  { value: "INR", label: "India Rupees" },
  { value: "IDR", label: "Indonesia Rupiah" },
  { value: "IEP", label: "Ireland Punt" },
  { value: "ILS", label: "Israel New Shekels" },
  { value: "ITL", label: "Italy Lira" },
  { value: "JMD", label: "Jamaica Dollars" },
  { value: "JPY", label: "Japan Yen" },
  { value: "JOD", label: "Jordan Dinar" },
  { value: "LBP", label: "Lebanon Pounds" },
  { value: "LUF", label: "Luxembourg Francs" },
  { value: "MYR", label: "Malaysia Ringgit" },
  { value: "MXP", label: "Mexico Pesos" },
  { value: "NZD", label: "New Zealand Dollars" },
  { value: "NOK", label: "Norway Kroner" },
  { value: "PKR", label: "Pakistan Rupees" },
  { value: "XPD", label: "Palladium Ounces" },
  { value: "PHP", label: "Philippines Pesos" },
  { value: "XPT", label: "Platinum Ounces" },
  { value: "PLZ", label: "Poland Zloty" },
  { value: "PTE", label: "Portugal Escudo" },
  { value: "ROL", label: "Romania Leu" },
  { value: "RUR", label: "Russia Rubles" },
  { value: "SAR", label: "Saudi Arabia Riyal" },
  { value: "XAG", label: "Silver Ounces" },
  { value: "SGD", label: "Singapore Dollars" },
  { value: "SKK", label: "Slovakia Koruna" },
  { value: "ZAR", label: "South Africa Rand" },
  { value: "KRW", label: "South Korea Won" },
  { value: "ESP", label: "Spain Pesetas" },
  { value: "XDR", label: "Special Drawing Right (IMF)" },
  { value: "SDD", label: "Sudan Dinar" },
  { value: "SEK", label: "Sweden Krona" },
  { value: "CHF", label: "Switzerland Francs" },
  { value: "TWD", label: "Taiwan Dollars" },
  { value: "THB", label: "Thailand Baht" },
  { value: "TTD", label: "Trinidad and Tobago Dollars" },
  { value: "TRL", label: "Turkey Lira" },
  { value: "USD", label: "United States Dollars" },
  { value: "VEB", label: "Venezuela Bolivar" },
  { value: "ZMK", label: "Zambia Kwacha" }
];

export const SUMMARY_CLAIMANT_DETAILS_COLUMNS = [
  { title: "Surname", field: "surname" },
  { title: "Name", field: "name" },
  { title: "E-mail", field: "email" },
  { title: "Phone", field: "phone" },
  { title: "Relation To Policyholder", field: "relationToPolicyHolder" }
];

export const NAVBAR_WIDTH = 240;

export const NAVBAR_BG_COLOR = "#ffffff";

export const MAX_FILES_TO_UPLOAD = 20;
