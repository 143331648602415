import React from "react";
import { Text, View } from "@react-pdf/renderer";
import {
  pdfStyles,
  BENEFIT_SECTION_SUBTITLE,
  PDF_DISPLAY_LABEL_VALUE_COMPONENT,
  PDF_DISPLAY_TIME_COMPONENT,
  PDF_DISPLAY_DATE_COMPONENT,
  CLAIMANT_DETAILS_FOR_BENEFIT,
  CLAIM_DETAILS_SUB_SECTION,
  GET_CURRENCY_VALUE_STRING
} from "common/utils/PDFDocumentUtil";
import { BENEFIT_TYPES, getCurrency } from "common/Constants";
import { getClaimTitle, getCurrencyValue } from "common/utils/CommonUtil";

const PDFMedicalDetailComponent = props => {
  let title = getClaimTitle(BENEFIT_TYPES.medicalClaim);
  let detail = props.detail;
  let suffix = props.suffix;
  const currency = getCurrency(props.region);

  const showConsultations = consultationDetails => (
    <React.Fragment>
      <View style={pdfStyles.subContainer}>
        <Text style={pdfStyles.subtitle2}>Consultation details:</Text>
      </View>
      {consultationDetails.map((item, index) => (
        <React.Fragment>
          <View style={pdfStyles.subContainer}>
            <Text style={pdfStyles.subtitle2}>Consultation # {index + 1}</Text>
          </View>
          {PDF_DISPLAY_DATE_COMPONENT(
            "Date of consultation",
            item.dateOfConsultation
          )}
          {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
            "Amount claimed",
            GET_CURRENCY_VALUE_STRING(
              currency,
              getCurrencyValue(item.amountClaimed)
            )
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {BENEFIT_SECTION_SUBTITLE(suffix, title)}
      {CLAIMANT_DETAILS_FOR_BENEFIT(
        detail.benefitId,
        props.claimants,
        props.region
      )}
      {CLAIM_DETAILS_SUB_SECTION("Claim details")}
      <View style={pdfStyles.container}>
        {detail.isAccidentRelated ? (
          <React.Fragment>
            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>Accident Details</Text>
            </View>

            {PDF_DISPLAY_DATE_COMPONENT(
              "Date of Accident",
              detail.dateOfAccident
            )}
            {PDF_DISPLAY_TIME_COMPONENT(
              "Time of Accident",
              detail.timeOfAccident
            )}

            {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
              "Place of Accident",
              detail.placeOfAccident
            )}

            {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
              "Description",
              detail.descriptionOfAccident
            )}

            {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
              "Description of injuries sustained",
              detail.injuriesInAccident
            )}
            {showConsultations(detail.multipleConsultationAccident)}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <View style={pdfStyles.subContainer}>
              <Text style={pdfStyles.label}>Medical Claim Details</Text>
            </View>
            {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
              "Type of symptoms",
              detail.typeOfSymptom
            )}
            {PDF_DISPLAY_DATE_COMPONENT(
              "Date of Accident",
              detail.dateOfSymptom
            )}
            {showConsultations(detail.multipleConsultationMedical)}
          </React.Fragment>
        )}

        {detail.hasHistory ? (
          <React.Fragment>
            {PDF_DISPLAY_LABEL_VALUE_COMPONENT(
              "Physician and previous treatment details",
              detail.historicalClaimConsultationDetails
            )}
          </React.Fragment>
        ) : null}
      </View>
    </React.Fragment>
  );
};

export default PDFMedicalDetailComponent;
