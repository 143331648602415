import React from "react";
import NavButton from "../../elements/NavButton";
import {
  FOOTER_BUTTON,
  SUMMARY_CLAIMANT_DETAILS_COLUMNS,
  BENEFIT_TYPES,
  FORM_LINKS
} from "common/Constants";
import {
  SUMMARY_DETAIL_HEADER_STYLE,
  SUMMARY_PAGE_DETAILS_PADDING_STYLE
} from "common/styles/styles";
import BenefitClaimantDisplayComponent from "components/forms/summaryPage/BenefitClaimantDisplayComponent";
import { Card, Typography } from "@material-ui/core";
import PolicyHolderDetailComponent from "components/forms/summaryPage/PolicyHolderDetailComponent";
import SummaryBasicInformationComponent from "components/forms/summaryPage/SummaryBasicInformationComponent";
import SummaryFileUploadComponent from "components/forms/summaryPage/SummaryFileUploadComponent";
import SummaryReporterComponent from "components/forms/summaryPage/SummaryReporterComponent";
import { getClaimTitle, SCROLL_TO_TOP } from "common/utils/CommonUtil";

function SummaryPageComponent(data, handleSubmission) {
  let history = data.history;
  let region = data.parentState.region;
  let currentClaim = data.parentState.claim;
  let policyHolderInformation = currentClaim.aboutSelf || {};
  let reporterInformation = currentClaim.reporter || {};
  let policyInformation = currentClaim.basicInformation || {};
  let flightDelayClaims =
    currentClaim.claimDetail[BENEFIT_TYPES.flightDelayClaim] || [];
  let lostBaggageClaims =
    currentClaim.claimDetail[BENEFIT_TYPES.lostBaggageClaim] || [];
  let tripCancellationClaims =
    currentClaim.claimDetail[BENEFIT_TYPES.tripCancellationClaim] || [];

  let accidentClaims =
    currentClaim.claimDetail[BENEFIT_TYPES.accidentClaim] || [];
  let medicalClaims =
    currentClaim.claimDetail[BENEFIT_TYPES.medicalClaim] || [];
  let travelMisConnectionClaim =
    currentClaim.claimDetail[BENEFIT_TYPES.travelMisConnectionClaim] || [];
  let claimants = currentClaim.claimantInformation || [];
  let uploadedFiles = currentClaim.fileUpload || [];
  let otherClaims = currentClaim.claimDetail[BENEFIT_TYPES.otherClaim] || [];

  let countOfAccident = accidentClaims.length;
  let countOfFlightDelay = countOfAccident + flightDelayClaims.length;
  let countOfLostBaggage = countOfFlightDelay + lostBaggageClaims.length;
  let countOfMedical = countOfLostBaggage + medicalClaims.length;
  let countOfOther = countOfMedical + otherClaims.length;
  let countOfTravelMisconnection =
    countOfOther + travelMisConnectionClaim.length;
  SCROLL_TO_TOP();

  return (
    <React.Fragment>
      <Typography variant="h1">Summary</Typography>
      <hr />
      <div style={SUMMARY_PAGE_DETAILS_PADDING_STYLE}>
        <Card
          id="summary-policy-holder-information"
          square
          style={SUMMARY_PAGE_DETAILS_PADDING_STYLE}
        >
          <div style={SUMMARY_DETAIL_HEADER_STYLE}>
            <Typography variant="subtitle1">
              Policyholder information
            </Typography>
          </div>
          <PolicyHolderDetailComponent
            detail={policyHolderInformation}
            region={region}
          />
        </Card>
      </div>
      <div style={SUMMARY_PAGE_DETAILS_PADDING_STYLE}>
        <Card
          id="summary-reporter-information"
          square
          style={SUMMARY_PAGE_DETAILS_PADDING_STYLE}
        >
          <div style={SUMMARY_DETAIL_HEADER_STYLE}>
            <Typography variant="subtitle1">Reporter information</Typography>
          </div>
          <SummaryReporterComponent
            policyholderDetail={policyHolderInformation}
            reporterDetail={reporterInformation}
            region={region}
          />
        </Card>
      </div>
      <div style={SUMMARY_PAGE_DETAILS_PADDING_STYLE}>
        <Card
          id="summary-policy-information"
          square
          style={SUMMARY_PAGE_DETAILS_PADDING_STYLE}
        >
          <div style={SUMMARY_DETAIL_HEADER_STYLE}>
            <Typography variant="subtitle1">Policy Information</Typography>
          </div>
          <SummaryBasicInformationComponent detail={policyInformation} />
        </Card>
      </div>
      <Typography variant="h3">Claims</Typography>
      <div>
        {/* flightDelayClaim */}
        {flightDelayClaims.map((flightDelayClaim, index) => {
          return (
            <BenefitClaimantDisplayComponent
              key={flightDelayClaim.benefitId}
              removeBenefit={data.parentHandlers.removeBenefit}
              claimants={claimants}
              benefitId={flightDelayClaim.benefitId}
              benefitType={BENEFIT_TYPES.flightDelayClaim}
              title={getClaimTitle(BENEFIT_TYPES.flightDelayClaim) + " "}
              columns={SUMMARY_CLAIMANT_DETAILS_COLUMNS}
              titleSuffix={index + 1 + countOfAccident}
              benefitDetail={flightDelayClaim}
              history={history}
              region={region}
            />
          );
        })}
        {/* lostBaggageClaim */}
        {lostBaggageClaims.map((lostBaggageClaim, index) => {
          return (
            <BenefitClaimantDisplayComponent
              key={lostBaggageClaim.benefitId}
              removeBenefit={data.parentHandlers.removeBenefit}
              claimants={claimants}
              benefitId={lostBaggageClaim.benefitId}
              benefitType={BENEFIT_TYPES.lostBaggageClaim}
              title={getClaimTitle(BENEFIT_TYPES.lostBaggageClaim) + " "}
              columns={SUMMARY_CLAIMANT_DETAILS_COLUMNS}
              titleSuffix={index + 1 + countOfFlightDelay}
              benefitDetail={lostBaggageClaim}
              history={history}
              region={region}
            />
          );
        })}
        {/* medicalClaim */}
        {medicalClaims.map((medicalClaim, index) => {
          return (
            <BenefitClaimantDisplayComponent
              key={medicalClaim.benefitId}
              removeBenefit={data.parentHandlers.removeBenefit}
              claimants={claimants}
              benefitId={medicalClaim.benefitId}
              benefitType={BENEFIT_TYPES.medicalClaim}
              title={getClaimTitle(BENEFIT_TYPES.medicalClaim) + " "}
              columns={SUMMARY_CLAIMANT_DETAILS_COLUMNS}
              titleSuffix={index + 1 + countOfLostBaggage}
              benefitDetail={medicalClaim}
              history={history}
              region={region}
            />
          );
        })}
        {/* otherClaim */}
        {otherClaims.map((otherClaim, index) => {
          return (
            <BenefitClaimantDisplayComponent
              key={otherClaim.benefitId}
              removeBenefit={data.parentHandlers.removeBenefit}
              claimants={claimants}
              benefitId={otherClaim.benefitId}
              benefitType={BENEFIT_TYPES.otherClaim}
              title={getClaimTitle(BENEFIT_TYPES.otherClaim) + " "}
              columns={SUMMARY_CLAIMANT_DETAILS_COLUMNS}
              titleSuffix={index + 1 + countOfMedical}
              benefitDetail={otherClaim}
              history={history}
              region={region}
            />
          );
        })}
        {/* travel misconnection claim */}
        {travelMisConnectionClaim.map((travelMisConnection, index) => {
          return (
            <BenefitClaimantDisplayComponent
              key={travelMisConnection.benefitId}
              removeBenefit={data.parentHandlers.removeBenefit}
              claimants={claimants}
              benefitId={travelMisConnection.benefitId}
              benefitType={BENEFIT_TYPES.travelMisConnectionClaim}
              title={
                getClaimTitle(BENEFIT_TYPES.travelMisConnectionClaim) + " "
              }
              columns={SUMMARY_CLAIMANT_DETAILS_COLUMNS}
              titleSuffix={index + 1 + countOfOther}
              benefitDetail={travelMisConnection}
              history={history}
              region={region}
            />
          );
        })}
        {/* trip cancellation claim */}
        {tripCancellationClaims.map((tripCancellationClaim, index) => {
          return (
            <BenefitClaimantDisplayComponent
              key={tripCancellationClaim.benefitId}
              removeBenefit={data.parentHandlers.removeBenefit}
              claimants={claimants}
              benefitId={tripCancellationClaim.benefitId}
              benefitType={BENEFIT_TYPES.tripCancellationClaim}
              title={getClaimTitle(BENEFIT_TYPES.tripCancellationClaim) + " "}
              columns={SUMMARY_CLAIMANT_DETAILS_COLUMNS}
              titleSuffix={index + 1 + countOfTravelMisconnection}
              benefitDetail={tripCancellationClaim}
              history={history}
              region={region}
            />
          );
        })}
      </div>
      <hr />
      <div>
        <Typography variant="h3">Supporting Documents</Typography>
        <Card
          id="summary-supporting-documents"
          square
          style={SUMMARY_PAGE_DETAILS_PADDING_STYLE}
        >
          <SummaryFileUploadComponent detail={uploadedFiles} />
        </Card>
      </div>
      <hr />

      <div className="footer mt-5">
        <NavButton
          name={FOOTER_BUTTON.doneButton}
          disabled={false}
          clickHandler={e => history.push(FORM_LINKS.pdpa)}
        />
      </div>
    </React.Fragment>
  );
}

export default SummaryPageComponent;
