import React from "react";
import TravelMisConnectionClaimComponent from "components/forms/benefits/travelMisConnection/TravelMisConnectionClaimComponent";
import { BENEFIT_TYPES, FIELD_TYPE } from "common/Constants";
import {
  getBenefitDetailFromProps,
  getLastBenefitIndex,
  IS_EMPTY_FIELD_CHECK,
  getClaimTitle,
  HAS_ERROR,
  IS_CURRENT_FIELD_VALID_CHECK
} from "common/utils/CommonUtil";

class TravelMisConnectionClaim extends React.Component {
  constructor() {
    super();
    this.state = {
      touched: {
        originalFlightNumber: false,
        originalPlaceOfDeparture: false,
        originalDate: false,
        originalTime: false,
        originalTransportProvider: false,
        rescheduledFlightNumber: false,
        rescheduledPlaceOfDeparture: false,
        rescheduledDate: false,
        rescheduledTime: false,
        rescheduledTransportProvider: false,
        actualArrivalDate: false,
        actualArrivalTime: false,
        reason: false
      },
      requirementModalDisplay: false
    };
  }

  validationResult = {
    hasErr: {
      originalFlightNumber: false,
      originalPlaceOfDeparture: false,
      originalDate: false,
      originalTime: false,
      originalTransportProvider: false,
      rescheduledFlightNumber: false,
      rescheduledPlaceOfDeparture: false,
      rescheduledDate: false,
      rescheduledTime: false,
      rescheduledTransportProvider: false,
      actualArrivalDate: false,
      actualArrivalTime: false,
      reason: false
    },
    errMsg: {
      originalFlightNumber: "",
      originalPlaceOfDeparture: "",
      originalDate: "",
      originalTime: "",
      originalTransportProvider: "",
      rescheduledFlightNumber: "",
      rescheduledPlaceOfDeparture: "",
      rescheduledDate: "",
      rescheduledTime: "",
      rescheduledTransportProvider: "",
      actualArrivalDate: "",
      actualArrivalTime: "",
      reason: ""
    }
  };

  checkForm = (hasError, currentFieldValues) => {
    if (this.benefitIsEmpty(currentFieldValues)) {
      return HAS_ERROR(hasError);
    } else {
      return true;
    }
  };

  showModal = () => {
    this.setState({ requirementModalDisplay: true });
  };

  closeModal = () => {
    this.setState({ requirementModalDisplay: false });
  };

  componentDidMount() {
    this.showModal();
  }

  getBenefitType = () => BENEFIT_TYPES.travelMisConnectionClaim;

  benefitIsEmpty = currentClaimDetail => {
    return (
      currentClaimDetail.originalFlightNumber &&
      currentClaimDetail.originalPlaceOfDeparture &&
      currentClaimDetail.originalDate &&
      currentClaimDetail.originalTime &&
      // currentClaimDetail.originalTransportProvider &&
      currentClaimDetail.rescheduledFlightNumber &&
      currentClaimDetail.rescheduledPlaceOfDeparture &&
      currentClaimDetail.rescheduledDate &&
      currentClaimDetail.rescheduledTime &&
      // currentClaimDetail.rescheduledTransportProvider &&
      currentClaimDetail.actualArrivalDate &&
      currentClaimDetail.actualArrivalTime &&
      currentClaimDetail.reason
    );
  };

  validateFields = (currentFieldValues, touchedState, validationState) => {
    if (
      touchedState.originalFlightNumber ||
      currentFieldValues.originalFlightNumber
    ) {
      IS_EMPTY_FIELD_CHECK(
        "originalFlightNumber",
        "Original Flight Number",
        "",
        currentFieldValues,
        validationState
      );
    }
    if (
      touchedState.originalPlaceOfDeparture ||
      currentFieldValues.originalPlaceOfDeparture
    ) {
      IS_EMPTY_FIELD_CHECK(
        "originalPlaceOfDeparture",
        "Original place of departure",
        "",
        currentFieldValues,
        validationState
      );
    }
    if (touchedState.originalDate || currentFieldValues.originalDate) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "originalDate",
        "Original date of departure",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.date
      );
    }
    if (touchedState.originalTime || currentFieldValues.originalTime) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "originalTime",
        "Original time of departure",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.time
      );
    }

    if (
      touchedState.rescheduledFlightNumber ||
      currentFieldValues.rescheduledFlightNumber
    ) {
      IS_EMPTY_FIELD_CHECK(
        "rescheduledFlightNumber",
        "Rescheduled Flight Number",
        "",
        currentFieldValues,
        validationState
      );
    }
    if (
      touchedState.rescheduledPlaceOfDeparture ||
      currentFieldValues.rescheduledPlaceOfDeparture
    ) {
      IS_EMPTY_FIELD_CHECK(
        "rescheduledPlaceOfDeparture",
        "Rescheduled place of departure",
        "",
        currentFieldValues,
        validationState
      );
    }
    if (touchedState.rescheduledDate || currentFieldValues.rescheduledDate) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "rescheduledDate",
        "Rescheduled date of departure",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.date
      );
    }
    if (touchedState.rescheduledTime || currentFieldValues.rescheduledTime) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "rescheduledTime",
        "Rescheduled time of departure",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.time
      );
    }

    if (
      touchedState.actualArrivalDate ||
      currentFieldValues.actualArrivalDate
    ) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "actualArrivalDate",
        "Actual arrival date",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.date
      );
    }
    if (
      touchedState.actualArrivalTime ||
      currentFieldValues.actualArrivalTime
    ) {
      IS_CURRENT_FIELD_VALID_CHECK(
        "actualArrivalTime",
        "Actual arrival Time",
        "",
        currentFieldValues,
        validationState,
        true,
        FIELD_TYPE.time
      );
    }

    if (touchedState.reason || currentFieldValues.reason) {
      IS_EMPTY_FIELD_CHECK(
        "reason",
        "Reason",
        "",
        currentFieldValues,
        validationState
      );
    }
  };

  handleTouched = e => {
    let targetParam = e.target.name;
    if (!this.state.touched[targetParam]) {
      this.setState(prevState => ({
        touched: {
          ...prevState.touched,
          [targetParam]: true
        }
      }));
    }
  };

  componentWillUnmount() {
    let benefitType = this.getBenefitType();
    let claimDetail = this.props.parentState.claim.claimDetail;
    let benefitDetailIndex = getLastBenefitIndex(benefitType, claimDetail);
    let benefitId = claimDetail[benefitType][benefitDetailIndex].benefitId;
    const currentClaimDetail = claimDetail[benefitType][benefitDetailIndex];
    if (this.benefitIsEmpty(currentClaimDetail)) {
      let benefitName = getClaimTitle(BENEFIT_TYPES.travelMisConnectionClaim);
      let parentHandlers = this.props.parentHandlers;
      parentHandlers.handleBenefitDetailInNavBar(
        benefitName,
        benefitId,
        benefitType
      );
    } else {
      this.props.parentHandlers.removeBenefit(benefitId, benefitType);
    }
  }

  getBenefitDetail(props) {
    return getBenefitDetailFromProps(
      props.location,
      props.parentState.claim,
      this.getBenefitType()
    );
  }

  render() {
    let benefitDetail = this.getBenefitDetail(this.props);

    this.validateFields(
      benefitDetail,
      this.state.touched,
      this.validationResult
    );

    let data = {
      props: this.props,
      benefitDetail: benefitDetail,
      requirementModalDisplay: this.state.requirementModalDisplay,
      benefitType: this.getBenefitType(),
      hasErr: this.validationResult.hasErr,
      errMsg: this.validationResult.errMsg,
      validationHandlers: {
        handleTouched: this.handleTouched
      },
      viewControls: {
        showModal: this.showModal,
        closeModal: this.closeModal,
        checkForm: this.checkForm
      }
    };
    return TravelMisConnectionClaimComponent(data);
  }
}

export default TravelMisConnectionClaim;
