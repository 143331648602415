import React from "react";
import {
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  Typography
} from "@material-ui/core";
import { BENEFIT_TYPES, TIMEOUT_DELAY, REGION } from "common/Constants";
import {
  SPACE_ELEMENT_STYLE,
  PREVIOUS_BUTTON_STYLE,
  NEXT_BUTTON_STYLE,
  SELECT_CLAIM_TYPE_RADIO_GROUP_STYLE
} from "common/styles/styles";
import NavButton from "components/elements/NavButton";
import { getNextSelectedForm, getClaimTitle } from "common/utils/CommonUtil";

function SelectClaimType(props) {
  let currentClaimType = props.parentState.claim.currentClaimType || "";
  const region = props.parentState.region;
  let selectClaimTypeParentHandlers = props.parentHandlers;
  let addNewClaim = () => {
    !!currentClaimType
      ? props.parentHandlers.handleAddNewBenefit(currentClaimType)
      : console.error(`Claim Type `, currentClaimType, ` is not supported`);
  };
  return (
    <React.Fragment>
      <Typography variant="h1">
        Please choose a claim to be submitted
      </Typography>
      <RadioGroup
        id="select-claim-type-radio-group"
        aria-label="claim"
        name="claim"
        value={currentClaimType}
        onChange={e => {
          selectClaimTypeParentHandlers.handleSelectClaimType(e.target.value);
        }}
      >
        <Grid container style={SELECT_CLAIM_TYPE_RADIO_GROUP_STYLE}>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              value={BENEFIT_TYPES.flightDelayClaim}
              control={<Radio color="primary" />}
              label={getClaimTitle(BENEFIT_TYPES.flightDelayClaim)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              value={BENEFIT_TYPES.medicalClaim}
              control={<Radio color="primary" />}
              label={getClaimTitle(BENEFIT_TYPES.medicalClaim)}
            />
          </Grid>
          {region === REGION.HK ? null : (
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                value={BENEFIT_TYPES.travelMisConnectionClaim}
                control={<Radio color="primary" />}
                label={getClaimTitle(BENEFIT_TYPES.travelMisConnectionClaim)}
              />
            </Grid>
          )}
          {/* CLMS-1265 Remove Accident Benefit
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              value={BENEFIT_TYPES.accidentClaim}
              control={<Radio color="primary" />}
              label="Accident Claim"
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              value={BENEFIT_TYPES.lostBaggageClaim}
              control={<Radio color="primary" />}
              label={getClaimTitle(BENEFIT_TYPES.lostBaggageClaim)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              value={BENEFIT_TYPES.tripCancellationClaim}
              control={<Radio color="primary" />}
              label={getClaimTitle(BENEFIT_TYPES.tripCancellationClaim)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              value={BENEFIT_TYPES.otherClaim}
              control={<Radio color="primary" />}
              label={getClaimTitle(BENEFIT_TYPES.otherClaim)}
            />
          </Grid>

          {/* 
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              value={BENEFIT_TYPES.tripCurtailmentClaim}
              control={<Radio color="primary" />}
              label="Trip Curtailment"
            />
          </Grid> */}
        </Grid>
      </RadioGroup>
      <div className="footer mt-5 d-flex">
        <div style={PREVIOUS_BUTTON_STYLE}>
          <NavButton
            id="back-button"
            name={"Back"}
            disabled={false}
            clickHandler={() => props.history.goBack()}
          />
        </div>
        <div style={SPACE_ELEMENT_STYLE} />
        <div style={NEXT_BUTTON_STYLE}>
          <NavButton
            id="next-button"
            name={"Next"}
            disabled={false}
            clickHandler={e => {
              if (e) {
                addNewClaim();
                setTimeout(() => {
                  props.history.push(getNextSelectedForm(currentClaimType));
                }, TIMEOUT_DELAY);
              }
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default SelectClaimType;
