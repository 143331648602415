import axios from "axios";
import { REACT_APP_LOG_ERROR_API } from "common/Constants";
import { handleAPIFailResponse } from "common/utils/CommonUtil";

export const LOG_ERROR = (error, errorInfo) => {
  return new Promise((resolve, reject) => {
    const logError = { error, errorInfo };
    axios
      .post(REACT_APP_LOG_ERROR_API, logError)
      .then(result => {
        const responseData = result.data;
        if (responseData) {
          resolve("Successfully logged error.");
        } else {
          handleAPIFailResponse(
            JSON.stringify(responseData),
            reject,
            "No response received for error log."
          );
        }
      })
      .catch(err => {
        let msg = `Could not log error: ${err}; for error: ${error}; info: ${errorInfo}`;
        console.log(msg);
        reject(msg);
      });
  });
};
