import React from "react";
import { styled } from "@material-ui/core/styles";
import { NAVBAR_WIDTH } from "../../common/Constants";
import { Drawer, Grid } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import NavBarComponent from "components/navbar/NavBarComponent";
import {
  FULL_WIDTH_STYLE,
  NAVBAR_STYLE,
  NAVBAR_LIST_ITEM_STYLE
} from "common/styles/styles";

class NavBar extends React.Component {
  constructor() {
    super();
    this.state = {
      mobileOpen: false
    };
  }

  render() {
    let theme = this.props.theme;
    let parentState = this.props.parentState;

    const drawer = (
      <div>
        <Divider />
        <List>
          <ListItem key="title" style={NAVBAR_STYLE}>
            <ListItemText style={NAVBAR_LIST_ITEM_STYLE}>
              <b>Claim Submission</b>
            </ListItemText>
          </ListItem>
          <Divider />
          <Divider />
          <Grid container>
            <div style={FULL_WIDTH_STYLE}>
              {parentState.navItem.map((value, index) => {
                return (
                  <NavBarComponent
                    key={index}
                    navItem={value}
                    index={index}
                  ></NavBarComponent>
                );
              })}
            </div>
          </Grid>
        </List>
      </div>
    );

    const MyDrawer = styled(Drawer)({
      width: NAVBAR_WIDTH
    });

    return (
      <div aria-label="Claim links">
        <Hidden lgUp implementation="css">
          <MyDrawer
            id="app-nav-bar-mobile"
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={this.props.mobileOpen}
            onClose={e => this.props.mobileNavBarToggle(e)}
            className={parentState.hideNavBarClass.join(" ")}
            ModalProps={{
              keepMounted: true
            }}
            style={{ width: NAVBAR_WIDTH }}
          >
            {drawer}
          </MyDrawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <MyDrawer
            variant="permanent"
            open
            className={parentState.hideNavBarClass.join(" ")}
          >
            {drawer}
          </MyDrawer>
        </Hidden>
      </div>
    );
  }
}

export default NavBar;
