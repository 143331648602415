import React from "react";
import ThankyouPageComponent from "components/forms/thankyouPage/ThankyouPageComponent";
import { getLoading } from "common/utils/CommonUtil";

class ThankyouPage extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !getLoading();
  }

  render() {
    const data = {
      props: this.props,
      validationHandlers: {},
      viewControls: {}
    };
    return ThankyouPageComponent(data);
  }
}

export default ThankyouPage;
