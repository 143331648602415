import React from "react";
import SummaryPageComponent from "components/forms/summaryPage/SummaryPageComponent";
import { UPDATE_CLAIM_DIRECTORY } from "common/utils/CommonUtil";
class SummaryPage extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.parentState.claim.claimDirectory) {
      UPDATE_CLAIM_DIRECTORY(
        this.props.parentHandlers.handleClaimDirectoryUpdate
      );
    }
  }

  render() {
    return SummaryPageComponent(this.props, this.handleSubmission);
  }
}

export default SummaryPage;
